<template>
  <div>
    <v-card class="indigo lighten-5">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Miscellaneous Office Settings
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData">
        <v-card>
          <v-card-text>
            <v-select
              v-model="formData.WorkOrderCopies"
              :items="selectLists.CopiesList"
              label="Number of work order copies to print"
              dense
            />
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-text>
            <v-currency-field
              v-model="formData.OverdueBalance"
              label="When entering a new service, if customer owes at least"
              dense
            />
            <v-select
              v-model="formData.OverdueLength"
              :items="selectLists.AgingList"
              label="That is aged over"
              item-text="Text"
              item-value="Value"
              dense
            />
            <v-text-field
              v-model="formData.OverdueBalanceMessage"
              label="Display this message"
              dense
            />
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-text>
            <v-checkbox
              v-model="formData.DefaultRecurringServiceAutoExtend"
              label="Default recurring service auto-extend"
              dense
            />
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="ml-1" @click="saveClick">Update</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'

export default {
  name: 'OfficeSettingsEditor',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()
    const selectLists = {
      CopiesList: [1, 2, 3, 4, 5],
      AgingList: [
        { Value: 0, Text: '0 Days' },
        { Value: 30, Text: '30 Days' },
        { Value: 60, Text: '60 Days' },
        { Value: 90, Text: '90 Days' },
        { Value: 120, Text: '120 Days' }
      ]
    }

    const initDialog = async () => {
      formData.value = null
      formData.value = await retrieveOfficeSettings()
    }

    const retrieveOfficeSettings = async () => {
      const rq = {}
      const rs = await host.office.retrieveSettings(rq)
      return rs.Info
    }

    const updateOfficeSettings = async () => {
      const rq = { Info: formData.value }
      const rs = await host.office.updateSettings(rq)
      return rs
    }

    const saveClick = async () => {
      const rs = await updateOfficeSettings()
      if (rs.IsSuccess) {
        await dialogUtil.value.inform({
          text: rs.Message,
          snackbarTimeout: 3000,
          color: 'success'
        })
        context.emit('close')
      } else {
        await dialogUtil.value.error(rs.Message)
      }
    }

    const cancelClick = () => {
      context.emit('close')
    }

    initDialog()

    return { dialogUtil, formData, selectLists, saveClick, cancelClick }
  }
}
</script>

<style lang="scss" scoped></style>
