<template>
  <div>
    <v-menu :nudge-width="250" translate="slide-x-reverse-transition" offset-x>
      <template #activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template #activator="{ on: onTooltip }">
            <v-btn
              small
              rounded
              color="secondary"
              v-on="{ ...onMenu, ...onTooltip }"
            >
              {{ userData.UserName.substring(0, 1) }}
            </v-btn>
          </template>
          <span>{{ userData.UserName }}</span>
        </v-tooltip>
      </template>
      <v-card height="240" width="300" shaped class="mx-auto indigo lighten-5">
        <v-card-text>
          <v-card>
            <v-card-title>{{ userData.UserName }}</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item
                  v-for="(dialogMenuItem, index) in menuItemList"
                  :key="index"
                  @click="menuItemClick(dialogMenuItem)"
                  dense
                >
                  <v-list-item-icon>
                    <v-icon color="primary">{{ dialogMenuItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ dialogMenuItem.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/login" dense>
                  <v-list-item-icon>
                    <v-icon color="primary">{{ icon.signout }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Sign Out
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-menu>

    <v-dialog
      v-model="menuItemList.userStatus.showDialog"
      :width="menuItemList.userStatus.dialogWidth"
    >
      <UserPreferenceEditor
        :isDialogOpen="menuItemList.userStatus.showDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.changePassword.showDialog"
      :width="menuItemList.changePassword.dialogWidth"
    >
      <ChangePasswordEditor
        :isDialogOpen="menuItemList.changePassword.showDialog"
        @close="menuItemList.changePassword.closeEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { SetupMenuItem } from '@/components/layout/SetupMenuItem'
import ChangePasswordEditor from '@/components/User/ChangePasswordEditor'
import UserPreferenceEditor from '@/components/User/UserPreferenceEditor'
import { userData, mustChangePassword } from '@/store/UserSession'
export default {
  name: 'LoginButton',
  components: {
    UserPreferenceEditor,
    ChangePasswordEditor
  },
  setup() {
    const menuItemList = ref({
      userStatus: new SetupMenuItem('My Preferences', {
        icon: icon.preferences,
        dialogWidth: 500
      }),
      changePassword: new SetupMenuItem('Change Password', {
        icon: icon.password,
        dialogWidth: 500
      })
    })

    const menuItemClick = menuItem => {
      menuItem.showDialog = true
    }

    watch(
      () => mustChangePassword.value,
      newVal => {
        menuItemList.value.changePassword.showDialog = newVal
      },
      { immediate: true }
    )

    return { userData, menuItemList, menuItemClick, icon }
  }
}
</script>

<style lang="scss" scoped></style>
