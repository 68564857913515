<template>
  <v-card width="400">
    <v-card-title>
      <h1 class="display-1">Login</h1>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="officeCode"
        label="Office"
        :prepend-icon="icon.office"
        :error-messages="errorMessages(vuelidate.officeCode)"
        @blur="vuelidate.officeCode.$touch()"
      />
      <v-text-field
        v-model="formData.LoginName"
        label="User Name"
        :prepend-icon="icon.user"
        :error-messages="errorMessages(vuelidate.formData.LoginName)"
        @blur="vuelidate.formData.LoginName.$touch()"
      />
      <v-text-field
        v-model="formData.Password"
        :type="showPassword ? 'text' : 'password'"
        label="Password"
        :prepend-icon="icon.password"
        :append-icon="showPassword ? icon.passwordShow : icon.passwordHide"
        :error-messages="errorMessages(vuelidate.formData.Password)"
        @blur="vuelidate.formData.Password.$touch()"
        @click:append="showPassword = !showPassword"
      />
    </v-card-text>
    <v-alert v-model="showError" type="error" dismissible>
      {{ loginResultMessage }}
    </v-alert>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn v-if="!showError" color="info" @click="loginClick">Login</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { isLoggedIn, login, userData, urlOfficeCode } from '@/store/UserSession'

export default {
  name: 'Login',
  props: {
    defaultOfficeCode: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const officeCode = ref()
    const formData = ref({
      LoginName: null,
      Password: null
    })

    const showPassword = ref(false)
    const showError = ref(false)
    const loginResultMessage = ref()

    const initDialog = () => {
      isLoggedIn()
      formData.value.LoginName = userData.value.LoginName
      officeCode.value =
        props.defaultOfficeCode !== null
          ? props.defaultOfficeCode
          : urlOfficeCode.value
    }

    const rules = {
      officeCode: { required },
      formData: {
        LoginName: { required, email },
        Password: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { officeCode, formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const loginClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      const loginResult = await login(officeCode.value, formData.value)
      if (loginResult.IsSuccess) {
        context.emit('loggedIn')
      } else {
        loginResultMessage.value = loginResult.Message
        showError.value = true
      }
    }

    initDialog()
    return {
      officeCode,
      formData,
      showPassword,
      loginClick,
      icon,
      showError,
      loginResultMessage,
      errorMessages,
      vuelidate
    }
  }
}
</script>
