<template>
  <div v-if="formData">
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit User' : 'New User' }}
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-wrap">
          <v-card width="300">
            <v-card-text>
              <v-text-field
                v-model="formData.UserName"
                label="User Name"
                :error-messages="errorMessages(vuelidate.formData.UserName)"
                @blur="vuelidate.formData.UserName.$touch()"
                dense
              />
              <v-text-field
                v-model="formData.LoginName"
                label="Login Name"
                :error-messages="errorMessages(vuelidate.formData.LoginName)"
                @blur="vuelidate.formData.LoginName.$touch()"
                dense
              />
              <ColorPicker v-model="formData.Color" label="Color" dense />
              <v-checkbox
                v-model="formData.IsDisabled"
                label="Disabled"
                dense
              />
            </v-card-text>
          </v-card>
          <AddressEditor
            class="ml-3"
            :name="formData.Address.Name"
            @updateName="updateAddressName"
            :street="formData.Address.Street"
            @updateStreet="updateAddressStreet"
            :city="formData.Address.City"
            @updateCity="updateAddressCity"
            :state="formData.Address.State"
            @updateState="updateAddressState"
            :postCode="formData.Address.PostCode"
            @updatePostCode="updateAddressPostCode"
          />
          <v-card width="300" class="ml-4">
            <v-card-title>
              <div class="text-subtitle-1 blue--text">
                Member of Groups
              </div>
            </v-card-title>
            <v-card-text>
              <v-checkbox
                v-for="item in formData.UserGroupMemberList"
                :key="item.UserGroupMemberId"
                v-model="item.IsMember"
                :label="item.UserGroupName"
              />
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="isEditMode"
          color="success"
          class="ml-3"
          @click="updateClick"
        >
          Update
        </v-btn>
        <v-btn v-else color="success" class="ml-3" @click="saveClick">
          Save
        </v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        <v-spacer />
        <v-btn v-if="isEditMode" color="primary" @click="resetPasswordClick">
          Reset Password
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { host } from '@/services/HostAPI'
import AddressEditor from '@/components/Common/AddressEditor'
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { selectListCache } from '@/services/SelectListCache'
export default {
  name: 'UserEditor',
  props: {
    userId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddressEditor
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()

    const initDialog = () => {
      vuelidate.value.$reset()
      if (props.userId) {
        retrieveUser(props.userId)
      } else {
        newTemplate()
      }
    }

    const isEditMode = computed(() => {
      return formData.value != null && formData.value.UserId != null
    })

    const retrieveUser = async id => {
      const rq = { InfoId: id }
      const rs = await host.user.retrieve(rq)
      formData.value = rs.Info
    }

    const newTemplate = async () => {
      const rq = {}
      const rs = await host.user.newTemplate(rq)
      formData.value = rs.Info
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const rules = {
      formData: {
        UserName: { required },
        LoginName: { required, email }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = await saveNew()
      afterSaveOrUpdate(result)
    }

    const updateClick = async () => {
      if (validate()) {
        return
      }

      const result = await update()
      afterSaveOrUpdate(result)
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        selectListCache.invalidate(selectListCache.name.Users)
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.user.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.user.update(rq)
      return rs
    }

    const resetPassword = async rq => {
      const rs = await host.user.resetPassword(rq)
      return rs.data
    }

    const resetPasswordClick = () => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Reset password.`
        })
        .then(() => performResetPassword())
        .catch(() => {
          return
        })
    }

    const performResetPassword = async () => {
      const rs = await resetPassword({ UserId: formData.value.UserId })
      if (rs.IsSuccess) {
        dialogUtil.value.inform(rs.Message)
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const updateAddressName = event => {
      formData.value.Address.Name = event
    }

    const updateAddressStreet = event => {
      formData.value.Address.Street = event
    }

    const updateAddressCity = event => {
      formData.value.Address.City = event
    }

    const updateAddressState = event => {
      formData.value.Address.State = event
    }

    const updateAddressPostCode = event => {
      formData.value.Address.PostCode = event
    }

    initDialog()

    return {
      dialogUtil,
      formData,
      isEditMode,
      saveClick,
      updateClick,
      cancelClick,
      resetPasswordClick,
      updateAddressName,
      updateAddressStreet,
      updateAddressCity,
      updateAddressState,
      updateAddressPostCode,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
