<template>
  <div>
    <v-card v-if="criteria" width="450">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          {{ criteria.ReportName }}
        </div>
      </v-card-title>
      <v-card-text>
        <div
          v-for="parameter in criteria.ParameterList"
          v-bind:key="parameter.ReportParameterId"
        >
          <div v-if="!parameter.Hide">
            <DatePicker
              v-if="parameter.ParameterType == 'date'"
              v-model="parameter.ValueHolder.Value"
              :label="parameter.Label"
              dense
            />
            <DateTimePicker
              v-if="parameter.ParameterType == 'datetime'"
              v-model="parameter.ValueHolder.Value"
              :label="parameter.Label"
              dense
            />
            <v-text-field
              v-else-if="parameter.ParameterType == 'textbox'"
              v-model="parameter.ValueHolder.Value"
              :label="parameter.Label"
              dense
            />
            <v-currency-field
              v-else-if="parameter.ParameterType == 'money'"
              v-model="parameter.ValueHolder.Value"
              :label="parameter.Label"
              dense
            />
            <v-text-field
              v-else-if="parameter.ParameterType == 'integer'"
              v-model="parameter.ValueHolder.Value"
              type="number"
              :label="parameter.Label"
              dense
            />
            <v-textarea
              v-else-if="parameter.ParameterType == 'textarea'"
              v-model="parameter.ValueHolder.Value"
              :label="parameter.Label"
              auto-grow
              dense
            />
            <v-radio-group
              v-else-if="parameter.ParameterType == 'radiobuttons'"
              v-model="parameter.ValueHolder.Value"
            >
              <v-radio
                v-for="selectListItem in parameter.SelectList"
                v-bind:key="selectListItem.Text"
                :label="selectListItem.Text"
                :value="selectListItem.Value"
              />
            </v-radio-group>
            <v-container
              v-else-if="parameter.ParameterType == 'checkboxgroup'"
              class="my=0"
              fluid
            >
              <div>{{ parameter.Label }}</div>
              <v-checkbox
                v-for="selectListItem in parameter.SelectList"
                v-bind:key="selectListItem.Text"
                v-model="parameter.ValueHolder.Value"
                :label="selectListItem.Text"
                :value="selectListItem.Value"
                class="shrink my-0"
              />
            </v-container>
            <v-checkbox
              v-else-if="parameter.ParameterType == 'checkbox'"
              v-model="parameter.ValueHolder.Value"
              :label="parameter.Label"
              class="my-0"
              dense
            />
            <v-select
              v-else-if="parameter.ParameterType == 'dropdown'"
              v-model="parameter.ValueHolder.Value"
              :items="parameter.SelectList"
              item-text="Text"
              item-value="Value"
              :label="parameter.Label"
              clearable
              dense
            />
          </div>
          <div v-else></div>
        </div>
        <v-select
          v-if="selectLists"
          v-model="criteria.ReportFormat"
          :items="selectLists.ReportMediaTypes"
          item-text="Text"
          item-value="Value"
          label="Report Format"
          dense
        />
        <v-card v-if="reportEmailInfo.SendEmail">
          <v-card-title>
            <div class="text-subtitle-1 blue--text">
              Email Info
            </div>
          </v-card-title>
          <v-card-text>
            <v-select
              v-if="selectLists && !isParameterInHideList('EmailTemplateId')"
              v-model="emailTemplateId"
              :items="selectLists.EmailTemplatesReport"
              item-text="Text"
              item-value="Value"
              label="Email Template"
              clearable
              dense
            />
            <v-text-field
              v-model="reportEmailInfo.Recipient"
              label="Email to"
              :error-messages="
                errorMessages(vuelidate.reportEmailInfo.Recipient)
              "
              @blur="vuelidate.reportEmailInfo.Recipient.$touch()"
              dense
            />
            <v-text-field
              v-if="!isParameterInHideList('EmailFromAddress')"
              v-model="emailFromAddress"
              label="Email from (override)"
              clearable
              dense
            />
            <v-text-field
              v-if="false"
              v-model="reportEmailInfo.Cc"
              label="Email Cc"
              dense
            />
            <v-text-field
              v-model="reportEmailInfo.Subject"
              label="Subject"
              :error-messages="errorMessages(vuelidate.reportEmailInfo.Subject)"
              @blur="vuelidate.reportEmailInfo.Subject.$touch()"
              dense
            />
            <v-card width="400" height="400">
              <v-card-title class="caption">Email body</v-card-title>
              <ckeditor
                :editor="editor"
                v-model="reportEmailInfo.Body"
              ></ckeditor>
            </v-card>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn small color="primary" class="ml-2" @click="processButtonClick">
          <v-icon class="mr-2">{{ feature.reports.icon }}</v-icon>
          Process
        </v-btn>
        <v-checkbox
          v-if="emailOnlySelected"
          class="ml-5"
          v-model="reportEmailInfo.SendEmail"
          label="Send Email"
        />
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { selectListCache } from '@/services/SelectListCache'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import { feature } from '@/use/Constants'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'ReportCriteriaPicker',
  props: {
    value: {
      type: String,
      default: null
    },
    reportInfo: {
      type: Object,
      default: null
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)
    const editor = ClassicEditor

    const criteria = ref(null)
    const reportEmailInfo = ref()

    const emailFromAddress = ref()
    const emailTemplateId = ref()

    const customerName = ref(null)
    const selectLists = ref()

    const getReportCriteria = async id => {
      const rq = { InfoId: id }
      const rs = await host.report.retrieveCriteria(rq)

      if (!rs.IsSuccess) {
        dialogUtil.value.error(rs.Message)
        return
      }

      criteria.value = rs.Info

      emailTemplateId.value =
        rs.Info.EmailTemplateId === null ? null : rs.Info.EmailTemplateId
      setDefaultValues(props.reportInfo)
    }

    const resetReportEmailInfo = () => {
      reportEmailInfo.value = {
        Recipient: props.reportInfo?.EmailInfo
          ? props.reportInfo.EmailInfo.Recipient
          : null,
        Cc: null,
        Subject: 'Your report is attached',
        Body: 'Your report is attached to this email.'
      }

      emailTemplateId.value = null
    }

    const processButtonClick = () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      context.emit('update', queueReportInfo.value)
    }

    const setDefaultValues = reportInfo => {
      if (reportInfo) {
        const thisCriteria = criteria.value
        if (reportInfo.ParameterList) {
          reportInfo.ParameterList.forEach(parm => {
            const found = thisCriteria.ParameterList.find(
              parmToMatch => parmToMatch.ParameterName == parm.Name
            )
            if (found) {
              found.ValueHolder.Value = parm.Value
            }
          })
        }

        thisCriteria.ParameterList.forEach(parm => {
          parm.Hide =
            reportInfo.ParameterHideList &&
            isParameterInHideList(parm.ParameterName)
        })

        if (reportInfo.EmailInfo) {
          reportEmailInfo.value = {
            ...reportEmailInfo.value,
            ...reportInfo.EmailInfo
          }
        }

        vuelidate.value.$reset()
      }
    }

    const isParameterInHideList = parameterName => {
      let result =
        props.reportInfo &&
        props.reportInfo.ParameterHideList &&
        props.reportInfo.ParameterHideList.find(x => x === parameterName)
          ? true
          : false

      console.log({
        loc: 'ReportCriteriaPicker/isParameterInHideList',
        parameterName: parameterName,
        reportInfo: props.reportInfo,
        result: result
      })

      return result
    }

    const queueReportInfo = computed(() => {
      const result = {
        ReportId: criteria.value.ReportId,
        Title: criteria.value.ReportName,
        ReportFormat: criteria.value.ReportFormat,
        Params: criteria.value.ParameterList.reduce(
          (obj, item) => (
            (obj[[item.ParameterName]] =
              item.ValueHolder.Value === null ||
              item.ValueHolder.Value === undefined
                ? null
                : item.ParameterType === 'checkboxgroup'
                ? item.ValueHolder.Value.join()
                : item.ValueHolder.Value.toString()),
            obj
          ),
          {}
        ),
        EmailInfo: reportEmailInfo.value,
        EmailFromAddress: emailFromAddress.value,
        EmailTemplateId: emailTemplateId.value
      }
      return result
    })

    const emailOnlySelected = computed(() => {
      const emailOnlyNodes = criteria.value.ParameterList.filter(
        parm => parm.ParameterName === 'EmailOnly'
      )
      return !(
        emailOnlyNodes && emailOnlyNodes.some(parm => parm.ValueHolder.Value)
      )
    })

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.EmailTemplatesReport,
        selectListCache.name.ReportMediaTypes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    watch(
      () => props.value,
      newVal => {
        if (newVal) {
          getReportCriteria(newVal)
        }
      },
      { immediate: true }
    )

    watch(
      () => props.reportInfo,
      newVal => {
        if (newVal) {
          resetReportEmailInfo()
          getReportCriteria(newVal.ReportId)
        }
      },
      { deep: true, immediate: true }
    )

    const rules = {
      reportEmailInfo: {
        Recipient: {
          required: requiredIf(() => reportEmailInfo.value.SendEmail)
        },
        Subject: {
          required: requiredIf(() => reportEmailInfo.value.SendEmail)
        }
      }
    }

    const vuelidate = useVuelidate(rules, { reportEmailInfo })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    watch(
      () => emailTemplateId.value,
      newVal => {
        if (newVal) {
          getReportEmailInfo(newVal)
        } else {
          resetReportEmailInfo()
        }
      }
    )

    const getReportEmailInfo = async emailTemplateId => {
      const rq = { InfoId: emailTemplateId }
      const rs = await host.emailTemplate.retrieve(rq)
      if (rs.IsSuccess) {
        reportEmailInfo.value.EmailTemplateId = emailTemplateId
        reportEmailInfo.value.Cc = rs.Info.Cc
        reportEmailInfo.value.Subject = rs.Info.Subject
        reportEmailInfo.value.Body = rs.Info.Body
        emailFromAddress.value = rs.Info.EmailFromAddress
      }
    }

    resetReportEmailInfo()
    loadSelectLists()

    return {
      dialogUtil,
      editor,
      criteria,
      reportEmailInfo,
      emailTemplateId,
      emailFromAddress,
      selectLists,
      customerName,
      processButtonClick,
      queueReportInfo,
      emailOnlySelected,
      isParameterInHideList,
      feature,
      vuelidate,
      errorMessages
    }
  }
}
</script>

<style lang="scss" scoped></style>
