<template>
  <v-card flat width="1000" height="500">
    <v-toolbar flat>
      <v-text-field
        v-model="internalSearchText"
        ref="searchTextField"
        label="Search Text"
        :prepend-icon="icon.search"
        class="mt-5"
        @keyup.enter="searchTextBlur"
        @focus="$event.target.select()"
        @blur="searchTextBlur"
      />
      <v-spacer />
      <v-btn small class="ma-1" color="info" @click="searchButtonClick">
        Search
      </v-btn>
    </v-toolbar>
    <v-data-table
      :items-per-page="10"
      :headers="headers"
      :items="dataTable.LineItemList"
      :options.sync="options"
      :sort-by.sync="options.sortBy"
      :sort-desc.sync="options.sortDesc"
      :server-items-length="dataTable.TotalRecords"
      :loading="loading"
      :footer-props="{
        disableItemsPerPage: true,
        itemsPerPageText: ''
      }"
      @click:row="onSelect"
      class="elevation-1"
      dense
    >
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch, nextTick } from 'vue'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'
export default {
  name: 'CustomerSearch',
  props: {
    searchText: {
      type: String,
      default: ''
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const searchTextField = ref(null)
    const options = ref({})

    watch(
      () => props.isDialogOpen,
      newVal => {
        if (newVal) {
          initDialog()
        }
      }
    )

    watch(
      () => options.value,
      () => {
        fetchData()
      }
    )

    const internalSearchText = ref()
    const loading = ref(false)
    const initFocus = ref(false)

    const headers = ref([
      { value: 'CustomerCode', text: 'Code' },
      { value: 'CustomerName', text: 'Name' },
      { value: 'Status', text: 'Status' },
      { value: 'SiteDescription', text: 'Site Name' },
      { value: 'SiteStreet', text: 'Site Address' },
      { value: 'Phone', text: 'Phone' }
    ])

    const dataTable = ref({})

    const initDialog = () => {
      internalSearchText.value = props.searchText

      nextTick(() => {
        searchTextField.value.focus()
      })

      fetchData()
    }

    const fetchData = async () => {
      if (internalSearchText.value === '') {
        dataTable.value = []
        return
      }

      loading.value = true

      const { sortBy, sortDesc, page, itemsPerPage } = options.value

      const rq = {
        SearchText: internalSearchText.value,
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy && sortBy.length > 0 ? sortBy[0] : '',
          IsDescending: sortDesc && sortDesc.length > 0 ? sortDesc[0] : false
        }
      }
      const rs = await host.customer.keywordSearch(rq)
      dataTable.value = rs
      loading.value = false
    }

    const onSelect = event => {
      context.emit('select', {
        CustomerId: event.CustomerId,
        CustomerName: event.CustomerName,
        CustomerEmail: event.Email,
        SiteId: event.SiteId
      })
    }

    const searchTextBlur = () => {
      fetchData()
    }

    const searchButtonClick = () => {
      fetchData()
    }

    initDialog()

    return {
      icon,
      internalSearchText,
      loading,
      options,
      headers,
      dataTable,
      onSelect,
      searchTextBlur,
      searchButtonClick,
      searchTextField,
      initFocus
    }
  }
}
</script>
