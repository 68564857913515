<template>
  <div>
    <v-card v-if="readonly === false" class="my-2">
      <v-card-text class="d-flex justify-space-between">
        <div>
          <div>Current: {{ cardData.QtyCurrentTasks }}</div>
          <div>Future: {{ cardData.QtyFutureTasks }}</div>
        </div>
        <div class="d-flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" v-on="on" @click="newTaskClick">
                <v-icon :color="feature.tasks.color">
                  {{ feature.tasks.iconNewTask }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              Create new Task
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="taskSearchClick"
                class="pr-2"
              >
                <v-icon :color="feature.tasks.color">
                  {{ feature.tasks.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              See Task list
            </span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="showScheduledTaskEditorDialog"
      :width="feature.tasks.dialogWidth"
      persistent
    >
      <ScheduledTaskEditor
        :isDialogOpen="showScheduledTaskEditorDialog"
        :customerId="cardData.CustomerId"
        @update="scheduledTaskEditorUpdateEvent"
        @close="scheduledTaskEditorCloseEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { feature } from '@/use/Constants'
import ScheduledTaskEditor from '@/components/ScheduledTask/ScheduledTaskEditor'
export default {
  props: {
    cardData: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      requied: true
    }
  },
  components: {
    ScheduledTaskEditor
  },
  setup(props, context) {
    const router = useRouter()
    const showScheduledTaskEditorDialog = ref(false)

    const newTaskClick = () => {
      showScheduledTaskEditorDialog.value = true
    }

    const scheduledTaskEditorCloseEvent = () => {
      showScheduledTaskEditorDialog.value = false
      context.emit('close')
    }

    const scheduledTaskEditorUpdateEvent = () => {
      showScheduledTaskEditorDialog.value = false
      context.emit('update')
    }

    const taskSearchClick = () => {
      router.push({
        name: feature.tasks.routerName,
        params: {
          customerId: props.cardData.CustomerId,
          customerName: props.cardData.Name
        }
      })
    }

    return {
      showScheduledTaskEditorDialog,
      feature,
      taskSearchClick,
      newTaskClick,
      scheduledTaskEditorCloseEvent,
      scheduledTaskEditorUpdateEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
