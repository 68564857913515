import SearchServices from '@/views/ScheduledService/SearchServices'

export default [
  {
    path: '/scheduledservice/search/:siteId?/:siteDescription?',
    name: 'scheduledservice-search',
    component: SearchServices,
    props: true
  }
]
