<template>
  <div>
    <v-card class="my-2">
      <v-card-text class="d-flex">
        <div>
          <div>Balance: {{ toCurrency(cardData.AgingSummary.Balance) }}</div>
          <div v-if="cardData.AgingSummary.A30 != 0">
            Over 30 days: {{ toCurrency(cardData.AgingSummary.A30) }}
          </div>
          <div v-if="cardData.AgingSummary.A60 != 0" class="red--text">
            Over 60 days: {{ toCurrency(cardData.AgingSummary.A60) }}
          </div>
          <div
            v-if="cardData.AgingSummary.A90 != 0"
            class="red--text font-weight-bold"
          >
            Over 90 days: {{ toCurrency(cardData.AgingSummary.A90) }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!readonly"
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="newTransactionClick"
              >
                <v-icon :color="feature.billing.color">
                  {{ icon.addNew }}
                </v-icon>
              </v-btn>
            </template>
            <span>New Transaction</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!readonly"
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="newInvoiceClick"
              >
                <v-icon :color="feature.invoice.color">
                  {{ icon.addNewInvoice }}
                </v-icon>
              </v-btn>
            </template>
            <span>New Invoice</span>
          </v-tooltip>
          <v-tooltip v-if="hideSummaryLink == false" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!readonly"
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="billingClick"
                ><v-icon :color="feature.billing.color">{{
                  feature.billing.icon
                }}</v-icon></v-btn
              >
            </template>
            <span>Go to Billing</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showTransactionDialog" max-width="400" persistent>
      <Transaction
        :customerId="cardData.CustomerId"
        :transactionId="selectedTransactionId"
        :isDialogOpen="showTransactionDialog"
        @close="hideTransactionDialog(false)"
        @update="hideTransactionDialog(true)"
      ></Transaction>
    </v-dialog>

    <v-dialog v-model="showInvoiceDialog" width="1000" persistent>
      <Invoice
        :isDialogOpen="showInvoiceDialog"
        :customerId="cardData.CustomerId"
        :invoiceId="selectedTransactionId"
        @close="hideInvoiceDialog(false)"
        @update="hideInvoiceDialog(true)"
      ></Invoice>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import Invoice from '@/components/Billing/Invoice'
import Transaction from '@/components/Billing/Transaction'
import { toCurrency } from '@/use/NumberFormatter'
import { icon, feature } from '@/use/Constants'
export default {
  name: 'BillingSummaryCard',
  props: {
    cardData: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideSummaryLink: {
      type: Boolean,
      default: false
    }
  },
  components: { Invoice, Transaction },
  setup(props, context) {
    const router = useRouter()
    const showTransactionDialog = ref(false)
    const showInvoiceDialog = ref(false)
    const selectedTransactionId = ref()

    const newTransactionClick = () => {
      selectedTransactionId.value = null
      showTransactionDialog.value = true
    }

    const newInvoiceClick = () => {
      selectedTransactionId.value = null
      showInvoiceDialog.value = true
    }

    const billingClick = () => {
      router.push({
        name: feature.billing.routerNameEdit,
        params: { id: props.cardData.CustomerId }
      })
    }

    const hideTransactionDialog = reload => {
      showTransactionDialog.value = false
      if (reload) {
        context.emit('update')
      }
    }

    const hideInvoiceDialog = reload => {
      showInvoiceDialog.value = false
      if (reload) {
        context.emit('update')
      }
    }

    return {
      selectedTransactionId,
      showTransactionDialog,
      showInvoiceDialog,
      newTransactionClick,
      newInvoiceClick,
      billingClick,
      hideTransactionDialog,
      hideInvoiceDialog,
      toCurrency,
      icon,
      feature
    }
  }
}
</script>

<style lang="scss" scoped></style>
