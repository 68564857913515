<template>
  <div>
    <v-menu translate="slide-x-reverse-transition" offset-y>
      <template #activator="{ on: onMenu }">
        <v-btn
          v-on="{ ...onMenu }"
          rounded
          color="primary"
          class="ma-5"
          width="220"
        >
          <v-icon class="mr-3">{{ icon.addNew }}</v-icon>
          New
        </v-btn>
      </template>
      <v-card height="190" width="220" class="mx-auto indigo lighten-5">
        <v-list dense>
          <v-list-item
            v-for="(dialogMenuItem, index) in menuItemList"
            :key="index"
            @click="menuItemClick(dialogMenuItem)"
            dense
          >
            <v-list-item-icon>
              <v-icon :color="dialogMenuItem.color">
                {{ dialogMenuItem.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ dialogMenuItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <v-dialog
      persistent
      v-model="menuItemList.customer.showDialog"
      :width="menuItemList.customer.dialogWidth"
    >
      <CustomerEditor
        :isDialogOpen="menuItemList.customer.showDialog"
        @update="customerEditorUpdateEvent"
        @close="menuItemList.customer.closeEvent"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="menuItemList.recurringService.showDialog"
      :width="menuItemList.recurringService.dialogWidth"
    >
      <RecurringServiceEditor
        :isDialogOpen="menuItemList.recurringService.showDialog"
        @update="menuItemList.recurringService.closeEvent"
        @close="menuItemList.recurringService.closeEvent"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="menuItemList.scheduledService.showDialog"
      :width="menuItemList.scheduledService.dialogWidth"
    >
      <ScheduledServiceEditor
        :isDialogOpen="menuItemList.scheduledService.showDialog"
        @update="menuItemList.scheduledService.closeEvent"
        @close="menuItemList.scheduledService.closeEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="menuItemList.scheduledTask.showDialog"
      :width="menuItemList.scheduledTask.dialogWidth"
    >
      <ScheduledTaskEditor
        :isDialogOpen="menuItemList.scheduledTask.showDialog"
        @update="menuItemList.scheduledTask.closeEvent"
        @close="menuItemList.scheduledTask.closeEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { icon, feature } from '@/use/Constants'
import AddNewItemList from '@/components/layout/AddNewItemList'
import CustomerEditor from '@/components/Customer/CustomerEditor'
import RecurringServiceEditor from '@/components/ScheduledService/RecurringServiceEditor'
import ScheduledServiceEditor from '@/components/ScheduledService/ScheduledServiceEditor'
import ScheduledTaskEditor from '@/components/ScheduledTask/ScheduledTaskEditor'
export default {
  name: 'AddDialog',
  components: {
    CustomerEditor,
    RecurringServiceEditor,
    ScheduledServiceEditor,
    ScheduledTaskEditor
  },
  setup() {
    const router = useRouter()
    const menuItemList = ref(AddNewItemList)

    const menuItemClick = menuItem => {
      menuItem.showDialog = true
    }

    const customerEditorUpdateEvent = event => {
      menuItemList.value.customer.showDialog = false
      router.push({
        name: feature.customers.routerNameSummary,
        params: { id: event }
      })
    }

    return {
      menuItemList,
      menuItemClick,
      customerEditorUpdateEvent,
      icon
    }
  }
}
</script>

<style lang="scss" scoped>
.rounded-card {
  border-radius: 10px;
}
</style>
