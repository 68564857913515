<template>
  <v-card flat width="1000" height="500">
    <v-toolbar flat>
      <v-radio-group v-model="formData.SearchType" row>
        <v-radio label="Reference" value="R"></v-radio>
        <v-radio label="Invoice" value="I"></v-radio>
      </v-radio-group>
      <v-text-field
        v-model="formData.SearchText"
        label="Search Text"
        :prepend-icon="icon.search"
        class="mt-5"
        @blur="searchClick"
      />
      <v-spacer></v-spacer>
      <v-btn small class="ma-1" color="info" @click="searchClick">Search</v-btn>
    </v-toolbar>
    <v-data-table
      dense
      :headers="headers"
      :items-per-page="10"
      :items="dataTable.LineItemList"
      :options.sync="options"
      :sort-by.sync="options.sortBy"
      :sort-desc.sync="options.sortDesc"
      :server-items-length="dataTable.TotalRecords"
      :loading="loading"
      :footer-props="{
        disableItemsPerPage: true
      }"
      @click:row="selectRow"
      class="elevation-1"
      ><template v-slot:item.BusinessDate="{ item }"
        ><span width="100">{{
          toLocaleDateString(item.BusinessDate)
        }}</span></template
      ><template v-slot:item.Amount="{ item }"
        ><span>{{ toMoney(item.Amount) }}</span></template
      >
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { toLocaleDateString } from '@/use/DateFormatter'
import { toMoney } from '@/use/NumberFormatter'
import { icon } from '@/use/Constants'
export default {
  name: 'BillingSearch',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    searchText: {
      type: String,
      default: ''
    },
    searchType: {
      type: String,
      default: 'R'
    }
  },
  setup(props, context) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref({
      searchType: 'R',
      searchText: null
    })

    const loading = ref(false)

    const options = ref({})
    const dataTable = ref()
    const headers = [
      { value: 'InvoiceNumber', text: 'Invoice' },
      { value: 'CustomerName', text: 'Customer' },
      { value: 'BusinessDate', text: 'Date' },
      { value: 'TranCode', text: 'TranCode' },
      { value: 'Amount', text: 'Amount', aligh: 'right' },
      { value: 'Reference', text: 'Reference' }
    ]

    watch(
      () => options,
      () => {
        searchClick()
      }
    )

    const initDialog = () => {
      formData.value = {
        SearchType: props.searchType,
        SearchText: props.searchText
      }
      dataTable.value = []
    }

    const searchClick = () => {
      if (formData.value.SearchText === '') {
        return
      }
      fetchData()
    }

    const fetchData = async () => {
      loading.value = true
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      const rq = {
        SearchText: formData.value.SearchText,
        SearchType: formData.value.SearchType,
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy !== null && sortBy.length > 0 ? sortBy[0] : '',
          IsDescending:
            sortDesc !== null && sortDesc.length > 0 ? sortDesc[0] : false
        }
      }

      dataTable.value = await host.billing.keywordSearch(rq)
      loading.value = false
    }

    const selectRow = event => {
      const transactionType = event.IsInvoice
        ? 'I'
        : event.IsOnlinePayment
        ? 'O'
        : 'T'
      const newEvent = {
        TransactionId: event.TransactionId,
        TransactionType: transactionType
      }
      context.emit('select', newEvent)
    }

    initDialog()

    return {
      icon,
      formData,
      loading,
      options,
      headers,
      dataTable,
      searchClick,
      selectRow,
      toLocaleDateString,
      toMoney
    }
  }
}
</script>
