<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Service Seasons
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="infoList"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.IconName="{ item }">
            <v-icon :color="item.Color">
              {{ item.IconName }}
            </v-icon>
          </template>
          <template v-slot:item.Starts="{ item }">
            {{
              seasonDateRangeDescription(
                item.StartMode,
                item.StartMonth,
                item.StartDay,
                item.StartWeek,
                item.StartDayOfWeek
              )
            }}
          </template>
          <template v-slot:item.Ends="{ item }">
            {{
              seasonDateRangeDescription(
                item.EndMode,
                item.EndMonth,
                item.EndDay,
                item.EndWeek,
                item.EndDayOfWeek
              )
            }}
          </template>
          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New Service Season</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showEditorDialog" width="430" persistent>
      <ScheduledServiceSeasonEditor
        :infoId="selectedItemId"
        :isDialogOpen="showEditorDialog"
        @close="editorCloseEvent"
        @update="editorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { icon, feature } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import ScheduledServiceSeasonEditor from '@/components/Setup/Service/ScheduledServiceSeasonEditor'
import { getMonthName, getDayOfWeekDesc } from '@/use/DateFormatter'
import { ordinal } from '@/use/NumberFormatter'
export default {
  name: 'ScheduledServiceSeasonList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { ScheduledServiceSeasonEditor },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      infoList.value = []
      getList()
    }

    const infoList = ref([])
    const selectedItemId = ref(null)
    const showEditorDialog = ref(false)

    const getList = async () => {
      loading.value = true
      const rq = {}
      const rs = await host.scheduledServiceSeason.list(rq)
      infoList.value = rs.InfoList
      loading.value = false
    }

    function editItemClick(item) {
      selectedItemId.value = item.ScheduledServiceSeasonId
      showEditorDialog.value = true
    }

    function deleteItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Season ${item.Description}`
        })
        .then(() => performDelete(item))
        .catch(() => {
          return
        })
    }

    async function performDelete(item) {
      const rq = { InfoId: item.ScheduledServiceSeasonId }
      const rs = await host.scheduledServiceSeason.delete(rq)
      if (rs.IsSuccess) {
        selectListCache.invalidate(selectListCache.name.ServiceTypes)
        getList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newItemClick() {
      selectedItemId.value = null
      showEditorDialog.value = true
    }

    const headers = [
      { value: 'IconName', text: 'Icon' },
      { value: 'Description', text: 'Description' },
      { value: 'Starts', text: 'Starts' },
      { value: 'Ends', text: 'Ends' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const editorCloseEvent = () => {
      showEditorDialog.value = false
    }

    const editorUpdateEvent = () => {
      showEditorDialog.value = false
      getList()
    }

    const seasonDateRangeDescription = (mode, month, day, week, dayOfWeek) => {
      return mode === 'D'
        ? `${getMonthName(month)} ${day}`
        : `${ordinal(week)} ${getDayOfWeekDesc(
            dayOfWeek - 1
          )} of ${getMonthName(month)}`
    }

    initDialog()

    return {
      dialogUtil,
      infoList,
      headers,
      seasonDateRangeDescription,
      showEditorDialog,
      selectedItemId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      editorCloseEvent,
      editorUpdateEvent,
      feature,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
