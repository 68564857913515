<template>
  <v-card width="400px">
    <v-card-title>{{ operation }} Email Address</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="formData.Email"
        label="Email"
        :error-messages="errorMessages(vuelidate.formData.Email)"
        @blur="vuelidate.formData.Email.$touch()"
        dense
      />
      <v-checkbox
        v-model="formData.IsSentStatements"
        label="Send Statements"
        dense
      />
      <v-checkbox
        v-model="formData.IsSentServiceNotifications"
        label="Send Service Notifications"
        dense
      />
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" class="ml-3" @click="save">Save</v-btn>
      <v-btn color="error" class="ml-3" @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: 'CustomerEmailAddressEditor',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    emailInfo: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()
    const operation = ref()

    const initDialog = () => {
      formData.value = {
        Id: props.emailInfo.Id,
        Email: props.emailInfo.Email,
        IsSentStatements: props.emailInfo.IsSentStatements,
        IsSentServiceNotifications: props.emailInfo.IsSentServiceNotifications
      }

      operation.value = formData.value.Id === null ? 'Add' : 'Update'
    }

    const rules = {
      formData: {
        Email: { required, email }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const save = () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }
      context.emit('update', formData.value)
    }

    const cancel = () => {
      context.emit('close')
    }

    initDialog()

    return { formData, operation, save, cancel, vuelidate, errorMessages }
  }
}
</script>

<style lang="scss" scoped></style>
