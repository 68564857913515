<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Routes
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="selectLists"
          dense
          :items-per-page="50"
          :footer-props="{
            'items-per-page-options': items_per_page,
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="infoList"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.Color="{ item }">
            <v-chip x-small :color="item.Color" />
          </template>
          <template v-slot:item.ServiceTypeId="{ item }">
            <v-icon :color="feature.services.color">
              {{ serviceTypeIcon(item.ServiceTypeId) }}
            </v-icon>
            {{ serviceTypeDescription(item.ServiceTypeId) }}
          </template>
          <template v-slot:item.FrequencyInfo.InitialDate="{ item }">
            {{ toLocaleDateString(item.FrequencyInfo.InitialDate) }}
          </template>
          <template v-slot:item.UserId="{ item }">
            <v-icon :color="userColor(item.UserId)">
              {{ icon.user }}
            </v-icon>
            {{ userDescription(item.UserId) }}
          </template>
          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New Service Type</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showEditorDialog" width="875" persistent>
      <ServiceRouteEditor
        :infoId="selectedItemId"
        :isDialogOpen="showEditorDialog"
        @close="editorCloseEvent"
        @update="editorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { icon, feature } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { toLocaleDateString } from '@/use/DateFormatter'
import ServiceRouteEditor from '@/components/Setup/Service/ServiceRouteEditor'
import { selectListCache } from '@/services/SelectListCache'
export default {
  name: 'ServiceRouteList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { ServiceRouteEditor },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)
    const items_per_page = [10, 15, 20, 50]

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      infoList.value = []
      loadSelectLists()
      getServiceRouteList()
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.Users,
        selectListCache.name.ServiceTypes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const infoList = ref([])
    const selectedItemId = ref(null)
    const showEditorDialog = ref(false)
    const selectLists = ref()

    const getServiceRouteList = async () => {
      loading.value = true
      const rq = {}
      const rs = await host.serviceRoute.list(rq)
      infoList.value = rs.InfoList
      loading.value = false
    }

    function editItemClick(item) {
      selectedItemId.value = item.Id
      showEditorDialog.value = true
    }

    function deleteItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Route ${item.Code}`
        })
        .then(() => performDeleteServiceRoute(item))
        .catch(() => {
          return
        })
    }

    async function performDeleteServiceRoute(item) {
      const rq = { InfoId: item.Id }
      const rs = await host.serviceRoute.delete(rq)
      if (rs.IsSuccess) {
        selectListCache.invalidate(selectListCache.name.ServiceRoutes)
        getServiceRouteList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newItemClick() {
      selectedItemId.value = null
      showEditorDialog.value = true
    }

    const headers = [
      { value: 'Color', text: 'Color' },
      { value: 'Code', text: 'Code' },
      { value: 'ServiceTypeId', text: 'Service Type' },
      { value: 'Description', text: 'Description' },
      { value: 'FrequencyInfo.InitialDate', text: 'Initial Date' },
      { value: 'UserId', text: 'Assigned To' },
      { value: 'SortOrder', text: 'Sort Order', align: 'right' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const editorCloseEvent = () => {
      showEditorDialog.value = false
    }

    const editorUpdateEvent = () => {
      showEditorDialog.value = false
      getServiceRouteList()
    }

    const serviceTypeDescription = value => {
      return selectListCache.attribute(
        selectListCache.name.ServiceTypes,
        value,
        'Text'
      )
    }

    const serviceTypeIcon = value => {
      return selectListCache.attribute(
        selectListCache.name.ServiceTypes,
        value,
        'IconName'
      )
    }

    const userColor = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Color'
      )
    }

    const userDescription = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Text'
      )
    }

    initDialog()

    return {
      dialogUtil,
      infoList,
      headers,
      items_per_page,
      serviceTypeDescription,
      userDescription,
      toLocaleDateString,
      showEditorDialog,
      selectedItemId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      editorCloseEvent,
      editorUpdateEvent,
      icon,
      selectLists,
      feature,
      serviceTypeIcon,
      userColor,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
