<template>
  <div>
    <v-card class="mb-2 light-green lighten-4">
      <v-card-text>
        <div class="d-flex">
          <div style="width: 240px;">
            <div class="d-flex">
              <div>Stop {{ ss.StopNumber }}</div>
              <v-spacer />
              <div>{{ ss.StatusDescription }}</div>
            </div>
            <div class="d-flex">
              <div>{{ ss.AssignedTo }}</div>
              <v-spacer />
              <div>{{ ss.ServiceType }}</div>
            </div>
            <div class="d-flex">
              <div>{{ toLocaleDateString(ss.ServiceDate) }}</div>
              <v-spacer />
              <div v-if="ss.FrequencyDescription">
                <span>{{ ss.FrequencyDescription }}</span
                ><span v-if="ss.RouteCode">Route {{ ss.RouteCode }}</span>
              </div>
            </div>
          </div>
          <v-spacer />
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  absolute
                  right
                  top
                  v-bind="attrs"
                  v-on="on"
                  @click="editScheduledServiceClick"
                  ><v-icon :color="feature.services.color">
                    {{ feature.services.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit Service</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { feature } from '@/use/Constants'
import { toLocaleDateString } from '@/use/DateFormatter'
export default {
  props: {
    ss: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const editScheduledServiceClick = () => {
      context.emit('editScheduledService', props.ss.ScheduledServiceId)
    }

    return { feature, editScheduledServiceClick, toLocaleDateString }
  }
}
</script>

<style lang="scss" scoped></style>
