import Vue from 'vue'
import VueAuthHref from 'vue-auth-href'
import FieldService from '@/services/FieldService'

const options = {
  token: () => FieldService.authToken
}

Vue.use(VueAuthHref, options)

export default {}
