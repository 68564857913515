<template>
  <v-menu
    v-model="showMenu"
    :disabled="readonly"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formattedDate"
        v-bind="$attrs"
        readonly
        v-on="on"
        @click:clear="clearDate"
      >
        <template slot="append">
          <v-icon v-if="!readonly" @click="showMenu = true">
            {{ icon.date }}
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable @change="updateDate">
      <v-spacer />
      <v-btn text color="primary" @click="showMenu = false">Cancel</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { ref, computed } from 'vue'
import { icon } from '@/use/Constants'
import { parseISO, format } from 'date-fns'
export default {
  name: 'DatePicker',
  props: {
    value: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    displayFormat: {
      type: String,
      default: 'MMMM d, yyyy'
    }
  },
  setup(props, context) {
    const showMenu = ref(false)
    const date = ref()

    const formattedDate = computed(() => {
      return props.value
        ? format(parseISO(props.value), props.displayFormat)
        : ''
    })

    const updateDate = () => {
      showMenu.value = false
      context.emit('input', date.value)
      context.emit('change', date.value)
    }

    const clearDate = () => {
      date.value = null
      updateDate()
    }

    return { showMenu, date, formattedDate, updateDate, clearDate, icon }
  }
}
</script>

<style lang="scss" scoped></style>
