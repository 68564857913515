<template>
  <div v-if="selectLists">
    <PageTitle
      featureName="routes"
      functionName="Assign"
      :pageName="$options.name"
    />
    <div class="d-flex">
      <v-card v-if="!isMapDisplayed" width="150" height="840" class="ml-2 mt-2">
        <div class="text-subtitle-1 blue--text mt-1 ml-5">Routes</div>
        <v-btn v-if="hasChanges" class="mx-2" color="success" @click="save">
          Save
        </v-btn>
        <v-card-text>
          <v-btn
            small
            width="100"
            class="mt-0"
            color="primary"
            rounded
            @click="organizeClick"
          >
            Organize
          </v-btn>
          <v-btn
            small
            width="100"
            class="mt-2"
            color="primary"
            rounded
            @click="trackClick"
          >
            Track
          </v-btn>
          <v-checkbox
            v-for="route in selectLists.ServiceRoutes"
            :key="route.Value"
            v-model="route.Selected"
            :label="route.Text"
            :readonly="
              !route.Selected &&
                qtyDisplayedServiceRoutes >= maxDisplayedServiceRoutes
            "
            dense
          />
        </v-card-text>
      </v-card>
      <v-card
        v-if="qtyDisplayedServiceRoutes == 0"
        width="200"
        height="100"
        class="ma-2"
      >
        <v-card-text>
          Choose up to {{ maxDisplayedServiceRoutes }} routes from the Routes
          list to edit assignments.
        </v-card-text>
      </v-card>
      <div v-for="(item, index) in displayedServiceRoutes" :key="index">
        <v-card
          v-if="item.Info && (!isMapDisplayed || mapColumnIndex == index)"
          class="ml-2 mt-2 indigo lighten-4"
          height="840"
        >
          <v-card-text>
            <div class="d-flex">
              <div class="text-subtitle-1 blue--text">
                Route Code {{ item.Info.Code }}
              </div>
              <v-spacer />
              <div v-if="!isMapDisplayed">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click="mapShowClick(index)">
                      <v-icon :color="feature.maps.color">
                        {{ feature.maps.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ feature.maps.toolTip }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      icon
                      v-on="on"
                      @click="closeDisplayedRoute(item)"
                    >
                      <v-icon>{{ icon.mapDirections }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Hide Route</span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click="toggleDirectionsClick">
                      <v-icon :color="toggleDirectionsIconColor">
                        {{ icon.mapDirections }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ toggleDirectionsText }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click="optimizeRouteClick">
                      <v-icon color="purple">{{ icon.mapOptimize }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Optimize Route</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click="mapHideClick(item)">
                      <v-icon color="red">{{ icon.exit }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Hide Map</span>
                </v-tooltip>
              </div>
            </div>
            <div>{{ item.Info.Description }}</div>
            <draggable
              v-model="item.Info.SiteList"
              group="routes"
              @change="hasChanges = true"
            >
              <v-card
                v-for="srs in item.Info.SiteList"
                :key="srs.ServiceRouteSiteId"
                class="my-2 focusable"
                width="240"
                tabindex="0"
              >
                <v-card-text class="pa-1">
                  <div class="d-flex justify-space-between">
                    <div>{{ srs.SiteDescription }}</div>
                    <div class="mr-2">{{ srs.StopNumber }}</div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div>{{ srs.Address.Street }}</div>
                    <v-icon
                      @click="toggleExpanded(srs.ServiceRouteSiteId)"
                      tabindex="-1"
                    >
                      {{
                        isExpanded(srs.ServiceRouteSiteId)
                          ? icon.cardCollapse
                          : icon.cardExpand
                      }}
                    </v-icon>
                  </div>
                  <div v-if="isExpanded(srs.ServiceRouteSiteId)">
                    {{ srs.Phone }}
                  </div>
                </v-card-text>
              </v-card>
            </draggable>
          </v-card-text>
        </v-card>
      </div>

      <div
        ref="mapCanvas"
        v-show="isMapDisplayed"
        class="flex-grow-1 red lighten-4 subtitle-1"
        style="height: 840px;"
      >
        Map goes here
      </div>

      <div
        width="240"
        ref="mapDirectionsCanvas"
        v-show="isMapDisplayed && showDirections"
        class="subtitle-1 px-2"
        style="width: 300px; height: 840px; overflow-y: scroll;"
      ></div>
    </div>

    <WaitDialog
      v-model="showWaitDialog"
      title="Routes"
      subtitle="Saving Route Assignments"
    />

    <v-dialog
      v-if="isMapDisplayed"
      v-model="showOptimizeDialog"
      :width="feature.services.optimizeDialogWidth"
      persistent
    >
      <ServiceRouteOptimizer
        :isDialogOpen="showOptimizeDialog"
        :serviceRouteId="displayedServiceRoutes[mapColumnIndex].ServiceRouteId"
        @close="optimizeDialogCloseEvent"
        @update="optimizeDialogUpdateEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import ServiceRouteOptimizer from '@/components/ScheduledService/ServiceRouteOptimizer'
import WaitDialog from '@/components/Common/WaitDialog'
import { mapService } from '@/services/GoogleMapService'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { icon, feature } from '@/use/Constants'
import draggable from 'vuedraggable'

export default {
  name: 'ServiceRouteAssignments',
  components: { draggable, ServiceRouteOptimizer, WaitDialog },
  setup() {
    const mapCanvas = ref()
    const mapDirectionsCanvas = ref()

    const router = useRouter()
    const selectLists = ref()
    const maxDisplayedServiceRoutes = ref(5)
    const displayedServiceRoutes = ref([])
    const showWaitDialog = ref(false)
    const hasChanges = ref(false)
    const expandedItems = ref({})
    const mapColumnIndex = ref(-1)
    const showDirections = ref(false)
    const showOptimizeDialog = ref(false)

    const init = () => {
      loadSelectLists()
    }

    const mapShowClick = index => {
      mapColumnIndex.value = index

      const item = displayedServiceRoutes.value[index]

      mapService.showRoute(
        mapCanvas.value,
        mapDirectionsCanvas.value,
        item.Info.SiteList,
        item.Info.OptimizeStartFrom,
        item.Info.OptimizeEndAt
      )
    }

    const isMapDisplayed = computed(() => mapColumnIndex.value >= 0)

    const mapHideClick = () => {
      mapColumnIndex.value = -1
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.ServiceRoutes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const toggleDirectionsClick = () => {
      showDirections.value = !showDirections.value
    }

    const toggleDirectionsIconColor = computed(() =>
      showDirections.value ? 'red' : 'green'
    )

    const toggleDirectionsText = computed(() =>
      showDirections.value ? 'Hide Directions' : 'Show Directions'
    )

    const optimizeRouteClick = () => {
      showOptimizeDialog.value = true
    }

    const optimizeDialogCloseEvent = () => {
      showOptimizeDialog.value = false
    }

    const optimizeDialogUpdateEvent = async () => {
      showOptimizeDialog.value = false
      const mapServiceRoute = displayedServiceRoutes.value[mapColumnIndex.value]
      const rs = await loadServiceRoute(mapServiceRoute.ServiceRouteId)
      mapServiceRoute.Info = rs.Info
      mapShowClick(mapColumnIndex.value)
    }

    const loadServiceRoute = async serviceRouteId => {
      const rq = { InfoId: serviceRouteId }
      const rs = await host.serviceRoute.retrieveWithSiteList(rq)
      return rs
    }

    const selectedServiceRoutes = computed(() => {
      return selectLists.value
        ? selectLists.value.ServiceRoutes.filter(item => item.Selected).map(
            item => item.Value
          )
        : []
    })

    const createDisplayedItem = item => {
      const response = {
        ServiceRouteId: item,
        Info: null
      }

      loadServiceRoute(item).then(rs => {
        response.Info = rs.Info
      })

      return response
    }

    const qtyDisplayedServiceRoutes = computed(() => {
      return displayedServiceRoutes.value.length
    })

    const save = async () => {
      showWaitDialog.value = true
      const rq = {
        ChangeInfo: displayedServiceRoutes.value.map(route => ({
          ServiceRouteId: route.ServiceRouteId,
          SiteIdList: route.Info.SiteList.map(site => site.SiteId)
        }))
      }
      const rs = await host.serviceRoute.updateSitesForRoutes(rq)
      if (rs.IsSuccess) {
        for (const sr of displayedServiceRoutes.value) {
          const refreshedRoute = await loadServiceRoute(sr.ServiceRouteId)
          sr.Info = refreshedRoute.Info
        }
        hasChanges.value = false
      }
      showWaitDialog.value = false
    }

    const organizeClick = () => {
      router.push({ name: 'serviceroute-organizer' })
    }

    const trackClick = () => {
      router.push({ name: 'serviceroute-tracker' })
    }

    const toggleExpanded = id => {
      if (!expandedItems.value[id]) {
        expandedItems.value = {
          ...expandedItems.value,
          [id]: { expand: false }
        }
      }
      const newVal = !expandedItems.value[id].expand
      expandedItems.value[id].expand = newVal
      return newVal
    }

    const isExpanded = id => {
      return (
        expandedItems.value[id] != undefined && expandedItems.value[id].expand
      )
    }

    const closeDisplayedRoute = item => {
      const serviceRoute = selectLists.value.ServiceRoutes.find(
        sr => sr.Value === item.Info.Id
      )
      serviceRoute.Selected = false
    }

    watch(
      () => selectedServiceRoutes.value,
      newVal => {
        displayedServiceRoutes.value = displayedServiceRoutes.value.filter(
          itemToKeep =>
            newVal.find(
              selectedItem => selectedItem === itemToKeep.ServiceRouteId
            )
        )

        const missingItems = newVal.filter(
          item =>
            !displayedServiceRoutes.value.find(x => x.ServiceRouteId === item)
        )

        displayedServiceRoutes.value = displayedServiceRoutes.value.concat(
          missingItems.map(item => createDisplayedItem(item))
        )
      },
      { deep: true }
    )

    init()

    return {
      mapCanvas,
      mapDirectionsCanvas,
      displayedServiceRoutes,
      maxDisplayedServiceRoutes,
      selectLists,
      expandedItems,
      qtyDisplayedServiceRoutes,
      showWaitDialog,
      hasChanges,
      toggleExpanded,
      isExpanded,
      mapColumnIndex,
      isMapDisplayed,
      organizeClick,
      trackClick,
      closeDisplayedRoute,
      save,
      createDisplayedItem,
      mapShowClick,
      mapHideClick,
      optimizeRouteClick,
      toggleDirectionsClick,
      toggleDirectionsIconColor,
      toggleDirectionsText,
      showDirections,
      showOptimizeDialog,
      optimizeDialogCloseEvent,
      optimizeDialogUpdateEvent,
      feature,
      icon
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.focusable:focus {
  background-color: Aqua;
}
</style>
