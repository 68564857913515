<template>
  <div>
    <v-card width="800">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div class="subtitle-1">
            Sites with addresses that cannot be shown on the map
          </div>
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :items-per-page="10"
          :headers="headers"
          :items="invalidAddressList"
          class="elevation-1"
          @click:row="selectInvalidAddressRow"
        >
          <template v-slot:item.SiteName="{ item }">
            <v-icon :color="feature.sites.color">
              {{ feature.sites.icon }}
            </v-icon>
            <span class="ml-1">{{ item.SiteName }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="showSiteEditor"
      :max-width="feature.sites.dialogWidth"
      persistent
    >
      <SiteEditor
        v-if="selectedItem"
        :isDialogOpen="showSiteEditor"
        :siteId="selectedItem.SiteId"
        @close="siteEditorCloseEvent"
        @update="siteEditorUpdateEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import SiteEditor from '@/components/Site/SiteEditor'
import { feature } from '@/use/Constants'

export default {
  name: 'InvalidSiteAddressList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    invalidAddressList: {
      type: Array,
      default: () => []
    }
  },
  components: { SiteEditor },
  setup(props, context) {
    const showSiteEditor = ref(false)
    const selectedItem = ref()

    const siteEditorCloseEvent = () => {
      showSiteEditor.value = false
    }

    const siteEditorUpdateEvent = () => {
      showSiteEditor.value = false
      context.emit('update')
    }

    const selectInvalidAddressRow = event => {
      selectedItem.value = event
      showSiteEditor.value = true
    }

    const headers = [
      { value: 'SiteName', text: 'Site' },
      { value: 'Street', text: 'Street' },
      { value: 'City', text: 'City' },
      { value: 'State', text: 'State' }
    ]

    return {
      headers,
      showSiteEditor,
      selectedItem,
      siteEditorCloseEvent,
      siteEditorUpdateEvent,
      selectInvalidAddressRow,
      feature
    }
  }
}
</script>

<style lang="scss" scoped></style>
