<template>
  <div>
    <v-card class="indigo lighten-5">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Serviceable Type
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData && selectLists">
        <v-card width="400">
          <v-card-text>
            <v-text-field
              v-model="formData.Description"
              label="Description"
              :error-messages="errorMessages(vuelidate.formData.Description)"
              @blur="vuelidate.formData.Description.$touch()"
              dense
            />
            <v-checkbox v-model="formData.IsDeleted" label="Deleted" />
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title class="subtitle-2">Attribute Types</v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="detailHeaderList"
              :items="detailDataList"
              class="elevation-1"
            >
              <template v-slot:item.AttributeType="{ item }">
                {{ attributeTypeDescription(item.AttributeType) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editServiceableAttributeTypeClick(item)"
                >
                  {{ icon.lineItemEdit }}
                </v-icon>
                <v-icon
                  small
                  @click="deleteServiceableAttributeTypeClick(item)"
                >
                  {{ icon.lineItemDelete }}
                </v-icon>
              </template>
              <template v-slot:footer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      absolute
                      class="mt-3 ml-2"
                      @click="addServiceableAttributeTypeClick"
                    >
                      <v-icon>{{ icon.lineItemAdd }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Serviceable Attribute</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions
            ><v-btn color="success" class="ml-1" @click="saveClick">{{
              isEditMode ? 'Update' : 'Save'
            }}</v-btn>
            <v-btn color="error" class="ml-3" @click="cancelClick"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showServiceableAttributeTypeEditorDialog"
      :max-width="
        selectedServiceableAttributeTypeItem &&
        selectedServiceableAttributeTypeItem.info.AttributeType == 'C'
          ? '800'
          : '400'
      "
      persistent
    >
      <ServiceableAttributeTypeEditor
        :isDialogOpen="showServiceableAttributeTypeEditorDialog"
        :item="selectedServiceableAttributeTypeItem"
        :selectLists="selectLists"
        @close="serviceableAttributeTypeEditorClose"
        @update="serviceableAttributeTypeEditorUpdate"
        @updateAttributeType="updateAttributeTypeEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { icon } from '@/use/Constants'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ServiceableAttributeTypeEditor from '@/components/Setup/Service/ServiceableAttributeTypeEditor'
export default {
  name: 'ServiceableTypeEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { ServiceableAttributeTypeEditor },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const formData = ref()
    const monthsApply = ref([])

    watch(
      () => monthsApply.value,
      newVal => {
        formData.value.MonthsApply = newVal.join('')
      }
    )

    const showServiceableAttributeTypeEditorDialog = ref(false)
    const selectedServiceableAttributeTypeItem = ref()
    const selectLists = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      if (isEditMode.value) {
        await loadServiceableType()
      } else {
        await initNewServiceableType()
      }
      loadSelectLists()
      vuelidate.value.$reset()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.ServiceableAttributeTypes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const attributeTypeDescription = value => {
      return value
        ? selectLists.value.ServiceableAttributeTypes.find(
            item => item.Value == value
          ).Text
        : ''
    }

    const rules = {
      formData: {
        Description: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const loadServiceableType = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.serviceableType.retrieve(rq)
      formData.value = rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.serviceableType.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.serviceableType.update(rq)
      return rs
    }

    const initNewServiceableType = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.serviceableType.newTemplate(rq)
      formData.value = rs.Info
    }

    const monthName = index => {
      let baseDate = new Date()
      baseDate.setDate(1)
      baseDate.setMonth(index)
      return baseDate.toLocaleString('en-us', { month: 'long' })
    }

    const detailHeaderList = [
      {
        value: 'SortOrder',
        text: 'Sort Order',
        width: 10,
        align: 'right'
      },
      {
        value: 'Description',
        text: 'Description',
        width: 40,
        align: 'left'
      },
      { value: 'AttributeType', text: 'Type', width: 40, align: 'left' },
      { value: 'actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const detailDataList = computed(() => {
      return formData.value.AttributeTypeList.map((item, index) => {
        return { ...item, actions: null, _idx: index }
      })
    })

    const addServiceableAttributeTypeClick = () => {
      selectedServiceableAttributeTypeItem.value = {
        index: -1,
        info: {
          ServiceableAttributeTypeId: null,
          SortOrder: 0,
          AttributeType: 'T'
        }
      }
      showServiceableAttributeTypeEditorDialog.value = true
    }

    const editServiceableAttributeTypeClick = item => {
      selectedServiceableAttributeTypeItem.value = {
        index: item._idx,
        info: formData.value.AttributeTypeList[item._idx]
      }
      showServiceableAttributeTypeEditorDialog.value = true
    }

    const updateAttributeTypeEvent = event => {
      selectedServiceableAttributeTypeItem.value.info.AttributeType = event
    }

    const deleteServiceableAttributeTypeClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete Attribute Type ' + item.Description + '. Are you sure?'
        })
        .then(() => deleteServiceableAttributeType(item))
        .catch(() => {
          return
        })
    }

    const deleteServiceableAttributeType = item => {
      if (item.ServiceableAttributeTypeId) {
        formData.value.DeletedAttributeTypeIdList.push(
          item.ServiceableAttributeTypeId
        )
      }
      formData.value.AttributeTypeList.splice(item._idx, 1)
    }

    const serviceableAttributeTypeEditorClose = () => {
      showServiceableAttributeTypeEditorDialog.value = false
    }

    const serviceableAttributeTypeEditorUpdate = newVal => {
      if (newVal.index > -1) {
        Object.assign(
          formData.value.AttributeTypeList[newVal.index],
          newVal.info
        )
      } else {
        formData.value.AttributeTypeList.push(newVal.info)
      }
      showServiceableAttributeTypeEditorDialog.value = false
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      monthName,
      saveClick,
      cancelClick,
      detailHeaderList,
      detailDataList,
      attributeTypeDescription,
      addServiceableAttributeTypeClick,
      editServiceableAttributeTypeClick,
      updateAttributeTypeEvent,
      deleteServiceableAttributeTypeClick,
      serviceableAttributeTypeEditorClose,
      serviceableAttributeTypeEditorUpdate,
      showServiceableAttributeTypeEditorDialog,
      selectedServiceableAttributeTypeItem,
      monthsApply,
      selectLists,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
