<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small @click="editCustomerClick">
          <v-icon
            :small="small"
            :color="feature.customers.color"
            v-bind="attrs"
            v-on="on"
          >
            {{ icon.customerEdit }}
          </v-icon>
        </v-btn>
      </template>
      <span>Edit Customer</span>
    </v-tooltip>

    <v-dialog
      persistent
      v-model="showCustomerEditDialog"
      :width="feature.customers.dialogWidth"
    >
      <CustomerEditor
        :isDialogOpen="showCustomerEditDialog"
        :customerId="customerId"
        @close="customerEditorCloseEvent"
        @update="customerEditorUpdateEvent"
        @delete="customerEditorDeleteEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { icon, feature } from '@/use/Constants'
import CustomerEditor from '@/components/Customer/CustomerEditor'
export default {
  name: 'CustomerEditButton',
  components: { CustomerEditor },
  props: {
    customerId: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const showCustomerEditDialog = ref(false)

    const editCustomerClick = () => {
      showCustomerEditDialog.value = true
    }

    const customerEditorCloseEvent = () => {
      showCustomerEditDialog.value = false
    }

    const customerEditorUpdateEvent = () => {
      context.emit('update', props.customerId)
      customerEditorCloseEvent()
    }

    const customerEditorDeleteEvent = () => {
      context.emit('delete', props.customerId)
      customerEditorCloseEvent()
    }

    return {
      showCustomerEditDialog,
      icon,
      feature,
      editCustomerClick,
      customerEditorCloseEvent,
      customerEditorUpdateEvent,
      customerEditorDeleteEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
