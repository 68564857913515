<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Referral Types
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="includeDeletedItems"
          label="Include Deleted Items"
          dense
        />

        <v-data-table
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="referralTypeList"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.IsInfoRequired="{ item }">
            <v-icon v-if="item.IsInfoRequired" color="success">
              {{ icon.lineItemIsChecked }}
            </v-icon>
          </template>
          <template v-slot:item.IsDeleted="{ item }">
            <v-icon v-if="item.IsDeleted" color="error">
              {{ icon.lineItemIsDeleted }}
            </v-icon>
          </template>
          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New Referral Type</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showEditorDialog" width="400" persistent>
      <ReferralTypeEditor
        :referralTypeId="selectedItemId"
        :isDialogOpen="showEditorDialog"
        @close="editorCloseEvent"
        @update="editorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch, computed } from 'vue'
import { host } from '@/services/HostAPI'
import ReferralTypeEditor from '@/components/Setup/Customer/ReferralTypeEditor'
import { icon } from '@/use/Constants'
export default {
  name: 'ReferralTypeList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { ReferralTypeEditor },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      list.value = []
      includeDeletedItems.value = false
      getReferralTypeList()
    }

    const list = ref([])
    const selectedItemId = ref(null)
    const includeDeletedItems = ref(false)
    const showEditorDialog = ref(false)

    const getReferralTypeList = async () => {
      loading.value = true
      const rq = {
        IncludeInactive: false
      }
      const rs = await host.referralType.list(rq)
      list.value = rs.InfoList
      loading.value = false
    }

    function editItemClick(item) {
      selectedItemId.value = item.ReferralTypeId
      showEditorDialog.value = true
    }

    function deleteItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Referral Type ${item.Code}`
        })
        .then(() => performDeleteReferralType(item))
        .catch(() => {
          return
        })
    }

    async function performDeleteReferralType(item) {
      const rq = { InfoId: item.ReferralTypeId }
      const rs = await host.referralType.delete(rq)
      if (rs.IsSuccess) {
        getReferralTypeList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newItemClick() {
      selectedItemId.value = null
      showEditorDialog.value = true
    }

    const headers = [
      { value: 'Code', text: 'Code', width: 100, align: 'left' },
      { value: 'Description', text: 'Description', width: 100, align: 'left' },
      {
        value: 'IsInfoRequired',
        text: 'Info Required',
        width: 20,
        align: 'center'
      },
      { value: 'IsDeleted', text: 'Deleted', width: 20, align: 'center' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const referralTypeList = computed(() => {
      return list.value
        ? list.value.filter(
            item => includeDeletedItems.value || !item.IsDeleted
          )
        : null
    })

    const editorCloseEvent = () => {
      showEditorDialog.value = false
    }

    const editorUpdateEvent = () => {
      showEditorDialog.value = false
      getReferralTypeList()
    }

    initDialog()

    return {
      dialogUtil,
      includeDeletedItems,
      referralTypeList,
      headers,
      showEditorDialog,
      selectedItemId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      editorCloseEvent,
      editorUpdateEvent,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
