<template>
  <div>
    <v-data-table
      dense
      :items-per-page="10"
      :footer-props="{
        'show-first-last-page': true
      }"
      :headers="paymentFrequencyHeaders"
      :items="paymentFrequencyList"
      :loading="loadingPaymentFrequencyList"
      class="elevation-1"
    >
      <template v-slot:item.InitialDate="{ item }">
        {{ toLocaleDateString(item.FrequencyInfo.InitialDate) }}
      </template>
      <template v-slot:item.Actions="{ item }">
        <v-icon small class="mr-2" @click="editPaymentFrequencyItemClick(item)">
          {{ icon.lineItemEdit }}
        </v-icon>
        <v-icon small @click="deletePaymentFrequencyItemClick(item)">
          {{ icon.lineItemDelete }}
        </v-icon>
      </template>
    </v-data-table>
    <div class="table-footer-prepend d-flex pl-2 align-center">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="newPaymentFrequencyItemClick">
            <v-icon>{{ icon.lineItemAdd }}</v-icon>
          </v-btn>
        </template>
        <span>New Payment Frequency</span>
      </v-tooltip>
    </div>

    <v-dialog v-model="showPaymentFrequencyEditorDialog" width="400" persistent>
      <RecurringPaymentFrequencyEditor
        :infoId="selectedItemId"
        :isDialogOpen="showPaymentFrequencyEditorDialog"
        @close="paymentFrequencyEditorCloseEvent"
        @update="paymentFrequencyEditorUpdateEvent"
      />
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { toLocaleDateString } from '@/use/DateFormatter'
import RecurringPaymentFrequencyEditor from '@/components/Setup/OnlinePayments/RecurringPaymentFrequencyEditor'
export default {
  name: 'RecurringPaymentFrequencyList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RecurringPaymentFrequencyEditor
  },
  setup(props) {
    const dialogUtil = ref(null)

    const paymentFrequencyList = ref([])
    const loadingPaymentFrequencyList = ref(false)
    const showPaymentFrequencyEditorDialog = ref(false)
    const selectedItemId = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      await getRecurringPaymentFrequencyList()
    }

    const paymentFrequencyHeaders = [
      { value: 'SortOrder', text: 'Sort Order' },
      { value: 'Description', text: 'Description' },
      { value: 'InitialDate', text: 'Initial Date' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const getRecurringPaymentFrequencyList = async () => {
      loadingPaymentFrequencyList.value = true
      const rq = {}
      const rs = await host.recurringPaymentFrequency.list(rq)
      loadingPaymentFrequencyList.value = false
      paymentFrequencyList.value = rs.InfoList
    }

    function editPaymentFrequencyItemClick(item) {
      selectedItemId.value = item.Id
      showPaymentFrequencyEditorDialog.value = true
    }

    function deletePaymentFrequencyItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Payment Frequency ${item.Description}`
        })
        .then(() => performDeleteRecurringPaymentFrequency(item))
        .catch(() => {
          return
        })
    }

    async function performDeleteRecurringPaymentFrequency(item) {
      const rq = { InfoId: item.Id }
      const rs = await host.recurringPaymentFrequency.delete(rq)
      if (rs.IsSuccess) {
        paymentFrequencyList.value = await getRecurringPaymentFrequencyList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newPaymentFrequencyItemClick() {
      selectedItemId.value = null
      showPaymentFrequencyEditorDialog.value = true
    }

    const paymentFrequencyEditorCloseEvent = () => {
      showPaymentFrequencyEditorDialog.value = false
    }

    const paymentFrequencyEditorUpdateEvent = async () => {
      await getRecurringPaymentFrequencyList()
      showPaymentFrequencyEditorDialog.value = false
    }

    initDialog()

    return {
      dialogUtil,
      paymentFrequencyList,
      paymentFrequencyHeaders,
      showPaymentFrequencyEditorDialog,
      editPaymentFrequencyItemClick,
      deletePaymentFrequencyItemClick,
      newPaymentFrequencyItemClick,
      paymentFrequencyEditorCloseEvent,
      paymentFrequencyEditorUpdateEvent,
      selectedItemId,
      toLocaleDateString,
      icon,
      loadingPaymentFrequencyList
    }
  }
}
</script>

<style lang="scss" scoped></style>
