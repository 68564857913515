<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="1000"
    ><template v-slot:activator="{ on }">
      <v-text-field
        :value="text"
        :label="label"
        readonly
        v-on="on"
        :dense="dense"
        :error-messages="errorMessages"
      >
        <template slot="append">
          <v-icon v-if="clearable && text" @click="clearValue">
            {{ icon.clear }}
          </v-icon>
          <v-icon v-if="value === null" @click="menu = true">
            {{ icon.search }}
          </v-icon>
          <v-icon
            v-if="value && showLink"
            :color="feature.customers.color"
            @click="navigateToCustomer"
          >
            {{ icon.customer }}
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <CustomerSearch :searchText="text" @select="onSelect" />
  </v-menu>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import CustomerSearch from '@/components/Customer/CustomerSearch'
import { icon, feature } from '@/use/Constants'

export default {
  name: 'CustomerPicker',
  components: { CustomerSearch },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    showLink: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const router = useRouter()
    const menu = ref(false)

    const onSelect = event => {
      context.emit('input', event.CustomerId)
      context.emit('update:text', event.CustomerName)
      context.emit('update:email', event.CustomerEmail)
      menu.value = false
    }

    const clearValue = () => {
      context.emit('input', null)
      context.emit('update:text', '')
    }

    const navigateToCustomer = () => {
      router.push({
        name: feature.customers.routerNameSummary,
        params: { id: props.value }
      })
    }

    return { menu, onSelect, clearValue, navigateToCustomer, feature, icon }
  }
}
</script>

<style lang="scss" scoped></style>
