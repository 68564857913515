<template>
  <div>
    <v-card class="indigo lighten-5">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Service Season
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData">
        <div class="d-flex">
          <div>
            <v-card width="400">
              <v-card-text class="pb-0">
                <v-text-field
                  v-model="formData.Description"
                  label="Description"
                  :error-messages="
                    errorMessages(vuelidate.formData.Description)
                  "
                  @blur="vuelidate.formData.Description.$touch()"
                  dense
                />
                <IconPicker
                  v-model="formData.IconName"
                  label="Icon"
                  :color="feature.services.color"
                  :choices="icon.seasonChoices"
                  clearable
                />
                <ColorPicker v-model="formData.Color" label="Color" dense />
                <SeasonDatePicker
                  title="Season Starts"
                  :mode="formData.StartMode"
                  :month="formData.StartMonth"
                  :day="formData.StartDay"
                  :week="formData.StartWeek"
                  :dayOfWeek="formData.StartDayOfWeek"
                  @modeChange="startModeChange"
                  @monthChange="startMonthChange"
                  @dayChange="startDayChange"
                  @weekChange="startWeekChange"
                  @dayOfWeekChange="startDayOfWeekChange"
                />
                <SeasonDatePicker
                  title="Season Ends"
                  :mode="formData.EndMode"
                  :month="formData.EndMonth"
                  :day="formData.EndDay"
                  :week="formData.EndWeek"
                  :dayOfWeek="formData.EndDayOfWeek"
                  @modeChange="endModeChange"
                  @monthChange="endMonthChange"
                  @dayChange="endDayChange"
                  @weekChange="endWeekChange"
                  @dayOfWeekChange="endDayOfWeekChange"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" class="ml-1" @click="saveClick">
                  {{ isEditMode ? 'Update' : 'Save' }}
                </v-btn>
                <v-btn color="error" class="ml-3" @click="cancelClick">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { feature, icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { selectListCache } from '@/services/SelectListCache'
import SeasonDatePicker from '@/components/controls/SeasonDatePicker'
export default {
  name: 'ScheduledServiceSeasonEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { SeasonDatePicker },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const formData = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      if (isEditMode.value) {
        await retrieve(props.infoId)
      } else {
        await initNew()
      }
      vuelidate.value.$reset()
    }

    const saveClick = async () => {
      if (vuelidate.value.$invalid) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        selectListCache.invalidate(selectListCache.name.ServiceTypes)
        context.emit('update')
      } else {
        dialogUtil.value.inform({
          text: result.Message,
          snackbarTimeout: 10000,
          color: 'error'
        })
      }
    }

    const rules = {
      formData: {
        Description: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const retrieve = async id => {
      const rq = { InfoId: id }
      const rs = await host.scheduledServiceSeason.retrieve(rq)
      formData.value = rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.scheduledServiceSeason.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.scheduledServiceSeason.update(rq)
      return rs
    }

    const startModeChange = val => {
      formData.value.StartMode = val
    }

    const startMonthChange = val => {
      formData.value.StartMonth = val
    }

    const startDayChange = val => {
      formData.value.StartDay = val
    }

    const startWeekChange = val => {
      formData.value.StartWeek = val
    }

    const startDayOfWeekChange = val => {
      formData.value.StartDayOfWeek = val
    }

    const endModeChange = val => {
      formData.value.EndMode = val
    }

    const endMonthChange = val => {
      formData.value.EndMonth = val
    }

    const endDayChange = val => {
      formData.value.EndDay = val
    }

    const endWeekChange = val => {
      formData.value.EndWeek = val
    }

    const endDayOfWeekChange = val => {
      formData.value.EndDayOfWeek = val
    }

    const initNew = async () => {
      const rq = {}
      const rs = await host.scheduledServiceSeason.newTemplate(rq)
      formData.value = rs.Info
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      startModeChange,
      startMonthChange,
      startDayChange,
      startWeekChange,
      startDayOfWeekChange,
      endModeChange,
      endMonthChange,
      endDayChange,
      endWeekChange,
      endDayOfWeekChange,
      saveClick,
      cancelClick,
      feature,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
