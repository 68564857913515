import Vue from 'vue'
import VueRouter from 'vue-router'
import Billing from '@/router/Billing'
import Calendar from '@/router/Calendar'
import Commission from '@/router/Commission'
import Customer from '@/router/Customer'
import Dashboard from '@/views/Dashboard.vue'
import Estimate from '@/router/Estimate'
import LoginPage from '@/views/LoginPage.vue'
import Payment from '@/router/Payment'
import ReportMenu from '@/views/Report/ReportMenu'
import ServiceRoute from '@/router/ServiceRoute'
import ScheduledService from '@/router/ScheduledService'
import ScheduledTask from '@/router/ScheduledTask'

Vue.use(VueRouter)

const routes = [
  ...Billing,
  ...Calendar,
  ...Commission,
  ...Customer,
  ...Estimate,
  ...Payment,
  ...ScheduledService,
  ...ScheduledTask,
  ...ServiceRoute,
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/login/:officeCode?',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportMenu
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
