<template>
  <div>
    <div v-if="formData">
      <v-card height="830">
        <v-card-title>
          <DialogTitle :dialogName="$options.name">
            {{ isEditMode ? 'Edit' : 'New' }} Email Template
          </DialogTitle>
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-wrap">
            <div>
              <div class="d-flex flex-wrap">
                <v-card width="300" height="330">
                  <v-card-text>
                    <v-select
                      v-if="selectLists"
                      v-model="formData.EmailEvent"
                      label="Email Event"
                      :items="selectLists.EmailEvents"
                      item-text="Text"
                      item-value="Value"
                      :error-messages="
                        errorMessages(vuelidate.formData.EmailEvent)
                      "
                      @blur="vuelidate.formData.EmailEvent.$touch()"
                      dense
                    />
                    <v-text-field
                      v-model="formData.TemplateName"
                      label="Template Name"
                      :error-messages="
                        errorMessages(vuelidate.formData.TemplateName)
                      "
                      @blur="vuelidate.formData.TemplateName.$touch()"
                      dense
                    />
                    <v-text-field
                      v-model="formData.SortOrder"
                      label="Sort Order"
                      type="number"
                      dense
                    />
                    <v-checkbox
                      v-model="formData.DoNotSend"
                      label="Do Not Send"
                      dense
                    />
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="formData.Bcc"
                          label="Bcc"
                          :error-messages="
                            errorMessages(vuelidate.formData.Bcc)
                          "
                          @blur="vuelidate.formData.Bcc.$touch()"
                          dense
                        />
                      </template>
                      <span
                        >Use BCC to send a copy of the email to someone within
                        your company.</span
                      >
                    </v-tooltip>
                    <v-text-field
                      v-model="formData.Subject"
                      label="Subject"
                      :error-messages="
                        errorMessages(vuelidate.formData.Subject)
                      "
                      @blur="vuelidate.formData.Subject.$touch()"
                      dense
                    />
                    <v-text-field
                      v-model="formData.EmailFromAddress"
                      label="Email From Address (Override)"
                      clearable
                      dense
                    />
                  </v-card-text>
                </v-card>
                <v-card width="300" height="330" class="ml-4">
                  <v-card-title>
                    <div class="text-caption">
                      Substitutions
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <div style="height: 260px; overflow-y: scroll;">
                      <div
                        v-for="(item, index) in substitutionList"
                        :key="index"
                      >
                        <span>{</span>{{ index }}<span>}</span> {{ item }}
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <v-card width="620" height="340" class="mt-4">
                <v-card-text>
                  <v-data-table
                    dense
                    :items-per-page="5"
                    :headers="emailInsertHeaders"
                    :items="formData.Inserts"
                    class="elevaton-1"
                  >
                    <template v-slot:item.Actions="{ item, index }">
                      <v-icon small class="mr-2" @click="editItemClick(index)">
                        {{ icon.lineItemEdit }}
                      </v-icon>
                      <v-icon small @click="deleteItemClick(item, index)">
                        {{ icon.lineItemDelete }}
                      </v-icon>
                    </template>
                  </v-data-table>
                  <div class="table-footer-prepend d-flex pl-2 align-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="addItemClick">
                          <v-icon>{{ icon.lineItemAdd }}</v-icon>
                        </v-btn>
                      </template>
                      <span>New Email Insert</span>
                    </v-tooltip>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <v-card width="400" height="400" class="ml-3">
              <ckeditor :editor="editor" v-model="formData.Body"></ckeditor>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="isEditMode"
            color="success"
            class="ml-3"
            @click="updateClick"
          >
            Update
          </v-btn>
          <v-btn v-else color="success" class="ml-3" @click="saveClick">
            Save
          </v-btn>
          <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog
        v-model="showEmailTemplateInsertEditorDialog"
        width="700"
        persistent
      >
        <EmailTemplateInsertEditor
          :emailTemplateInsertItem="selectedEmailTemplateInsertItem"
          :isDialogOpen="showEmailTemplateInsertEditorDialog"
          @close="emailTemplateInsertEditorCloseEvent"
          @update="emailTemplateInsertEditorUpdateEvent"
        />
      </v-dialog>
    </div>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { onBeforeMount, ref, computed, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { icon } from '@/use/Constants'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import EmailTemplateInsertEditor from '@/components/Setup/EmailTemplate/EmailTemplateInsertEditor'

export default {
  name: 'EmailTemplateEditor',
  props: {
    emailTemplateId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { EmailTemplateInsertEditor },
  setup(props, context) {
    const dialogUtil = ref()

    onBeforeMount(() => {
      initDialog()
    })

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()
    const selectLists = ref()
    const editor = ClassicEditor
    const emailInsertHeaders = [
      { value: 'Name', text: 'Name', width: 40, align: 'left' },
      { value: 'Text', text: 'Text' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const selectedEmailTemplateInsertIndex = ref(-1)
    const selectedEmailTemplateInsertItem = ref()
    const showEmailTemplateInsertEditorDialog = ref(false)

    const initDialog = () => {
      if (props.emailTemplateId) {
        retrieveEmailTemplate(props.emailTemplateId)
      } else {
        newTemplate()
      }
      loadSelectLists()
    }

    const isEditMode = computed(() => {
      return formData.value != null && formData.value.EmailTemplateId != null
    })

    const substitutionList = computed(() => {
      return formData.value != null &&
        formData.value.EmailEvent != null &&
        selectLists.value != null
        ? getsubstitutionListFor(formData.value.EmailEvent)
        : []
    })

    const getsubstitutionListFor = emailEvent => {
      const selectItem = selectLists.value.EmailEvents.find(
        item => item.Value == emailEvent
      )
      return selectItem ? JSON.parse(selectItem.ExtraData) : ['(unknown)']
    }

    const retrieveEmailTemplate = async id => {
      const rq = { InfoId: id }
      const rs = await host.emailTemplate.retrieve(rq)
      formData.value = rs.Info
    }

    const newTemplate = async () => {
      const rq = {}
      const rs = await host.emailTemplate.newTemplate(rq)
      formData.value = rs.Info
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const rules = {
      formData: {
        EmailEvent: { required },
        TemplateName: { required },
        Bcc: { email },
        Subject: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = await saveNew()
      afterSaveOrUpdate(result)
    }

    const updateClick = async () => {
      if (validate()) {
        return
      }

      const result = await update()
      afterSaveOrUpdate(result)
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.emailTemplate.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.emailTemplate.update(rq)
      return rs
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.EmailEvents]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const addItemClick = () => {
      selectedEmailTemplateInsertIndex.value = -1
      selectedEmailTemplateInsertItem.value = {
        EmailTemplateInsertId: null,
        Name: null,
        Text: null,
        SortOrder: 0
      }
      showEmailTemplateInsertEditorDialog.value = true
    }

    const editItemClick = index => {
      selectedEmailTemplateInsertIndex.value = index
      selectedEmailTemplateInsertItem.value = formData.value.Inserts[index]
      showEmailTemplateInsertEditorDialog.value = true
    }

    const deleteItemClick = (item, index) => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Email Insert ${item.Name}`
        })
        .then(() => performDelete(index))
        .catch(() => {
          return
        })
    }

    const performDelete = index => {
      const itemId = formData.value.Inserts[index].EmailTemplateInsertId
      if (itemId) {
        formData.value.DeletedEmailTemplateInsertList.push(itemId)
      }
      formData.value.Inserts.splice(index, 1)
    }

    const emailTemplateInsertEditorCloseEvent = () => {
      showEmailTemplateInsertEditorDialog.value = false
    }

    const emailTemplateInsertEditorUpdateEvent = item => {
      const index = selectedEmailTemplateInsertIndex.value
      if (index < 0) {
        formData.value.Inserts.push(item)
      } else {
        formData.value.Inserts.splice(index, 1, item)
      }
      showEmailTemplateInsertEditorDialog.value = false
    }

    initDialog()

    return {
      dialogUtil,
      formData,
      selectLists,
      isEditMode,
      substitutionList,
      editor,
      saveClick,
      updateClick,
      cancelClick,
      emailInsertHeaders,
      showEmailTemplateInsertEditorDialog,
      selectedEmailTemplateInsertItem,
      editItemClick,
      deleteItemClick,
      addItemClick,
      emailTemplateInsertEditorCloseEvent,
      emailTemplateInsertEditorUpdateEvent,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
