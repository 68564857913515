<template>
  <div>
    <v-card v-if="formData" width="450px">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Post Late Fees
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="formData.AgedDays"
          label="For balances older than"
          :items="selectLists.AgedDayList"
          item-text="Text"
          item-value="Value"
          :error-messages="errorMessages(vuelidate.formData.AgedDays)"
          @blur="vuelidate.formData.AgedDays.$touch()"
          dense
        />
        <DatePicker
          v-model="formData.AgingDate"
          label="Aging Date"
          :error-messages="errorMessages(vuelidate.formData.AgingDate)"
          @blur="vuelidate.formData.AgingDate.$touch()"
          dense
        />
        <v-currency-field
          v-model="formData.MinBalance"
          label="For aged balances over"
          dense
        />
        <v-text-field
          v-model="formData.Percentage"
          type="number"
          label="Late fee percentage"
          dense
        />
        <v-currency-field
          v-model="formData.FlatAmount"
          label="Late fee flat amount"
          dense
        />
        <v-select
          v-model="formData.TranCodeId"
          label="Transaction type"
          :items="selectLists.TranCodes"
          item-text="Text"
          item-value="Value"
          :error-messages="errorMessages(vuelidate.formData.TranCodeId)"
          @blur="vuelidate.formData.TranCodeId.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.Reference"
          label="Description to show on statement"
          :error-messages="errorMessages(vuelidate.formData.Reference)"
          @blur="vuelidate.formData.Reference.$touch()"
          dense
        />
        <v-select
          v-model="formData.Option"
          label="Option"
          :items="selectLists.LateFeeOptions"
          item-text="Text"
          item-value="Value"
          dense
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="ml-3" @click="saveClick">Post</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'PostLateFees',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      getLateFeeDefaults()
      loadSelectLists()
    }

    const formData = ref()
    const selectLists = ref()

    const getLateFeeDefaults = async () => {
      const rq = {}
      const rs = await host.billing.getLateFeeDefaults(rq)
      formData.value = rs.Info
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.TranCodes,
        selectListCache.name.AgedDayList,
        selectListCache.name.LateFeeOptions
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const rules = {
      formData: {
        AgedDays: { required },
        AgingDate: { required },
        TranCodeId: { required },
        Reference: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    function errorMessages(item) {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }
      const rq = { Info: formData.value }
      const rs = await host.billing.postLateFees(rq)
      if (rs.IsSuccess === true) {
        context.emit('update')
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    initDialog()
    return {
      dialogUtil,
      formData,
      selectLists,
      cancelClick,
      saveClick,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
