<template>
  <div>
    <v-app-bar flat app>
      <v-app-bar-nav-icon class="grey--text" @click="navDrawerToggle" />

      <v-toolbar-title class="grey--text">
        <span class="font-weight-light text-h5">PoolCare</span>
        <span class="font-weight-bold text-uppercase">Pro</span>
      </v-toolbar-title>

      <v-spacer />
      <SubscribeButton />
      <SearchButton />
      <CommitHistoryButton />
      <MessagesButton />
      <FeedbackButton />
      <LoginButton />
      <SetupButton />
    </v-app-bar>

    <NavDrawer :state="navDrawerState" />
    <ReportHandler />
  </div>
</template>

<script>
import { ref } from 'vue'
import NavDrawer from '@/components/layout/NavDrawer'
import CommitHistoryButton from '@/components/layout/CommitHistoryButton'
import MessagesButton from '@/components/layout/MessagesButton'
import SearchButton from '@/components/layout/SearchButton'
import SetupButton from '@/components/layout/SetupButton'
import SubscribeButton from '@/components/layout/SubscribeButton'
import LoginButton from '@/components/layout/LoginButton'
import FeedbackButton from '@/components/layout/FeedbackButton'
import ReportHandler from '@/components/layout/ReportHandler'

export default {
  name: 'NavBar',
  components: {
    CommitHistoryButton,
    FeedbackButton,
    LoginButton,
    MessagesButton,
    NavDrawer,
    ReportHandler,
    SearchButton,
    SetupButton,
    SubscribeButton
  },
  setup() {
    const navDrawerState = ref(1)

    const navDrawerToggle = () => {
      navDrawerState.value++
    }

    return {
      navDrawerState,
      navDrawerToggle
    }
  }
}
</script>
