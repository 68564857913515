<template>
  <div class="d-flex mt-3 indigo lighten-5">
    <div>
      <v-card width="400">
        <v-card-title class="subtitle-1 blue--text">
          <span class="mt-2">Connect to {{ interfaceName }}</span>
          <v-spacer />
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="mt-3 mr-2" @click="refreshClick">
                  <v-icon>{{ icon.refresh }}</v-icon>
                </v-btn>
              </template>
              <span>Refresh Connect Info</span>
            </v-tooltip>
          </div>
        </v-card-title>
        <v-card-text>
          <div
            class="text-subtitle-1"
            v-if="onlineSettings && onlineSettings.WhenAuthenticated"
          >
            <div>Access to {{ interfaceName }} was granted</div>
            <div>{{ onlineSettings.WhenAuthenticated }}</div>
            <v-btn color="primary" width="300" @click="authorizeClick">
              <span>Redo Access to QuickBooks</span>
              <v-icon right>{{ icon.login }}</v-icon>
            </v-btn>
            <div class="mt-8">
              <v-btn color="success" width="300" @click="syncClick">
                <span>Initiate Sync with QuickBooks</span>
                <v-icon right>{{ icon.login }}</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else>
            <div>
              In order to interface with QuickBooks Online, you must give
              permission for {{ productName }} to access your company data.
            </div>
            <v-btn color="primary" width="300" @click="authorizeClick">
              <span>Grant Access to QuickBooks</span>
              <v-icon right>{{ icon.login }}</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'

export default {
  name: 'SyncWithConnectQuickBooksOnline',
  props: {
    infoId: {
      type: String,
      default: null
    },
    interfaceType: {
      type: String,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const dialogUtil = ref(null)

    const onlineSettings = ref()
    const productName = ref('PoolCarePro')

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const isSandbox = computed(
      () => props.interfaceType == 'QuickBooksOnlineSandbox'
    )

    const interfaceName = computed(
      () => 'Quickbooks Online' + (isSandbox.value ? ' (sandbox)' : '')
    )

    const initDialog = async () => {
      await loadOnlineSettings()
    }

    const loadOnlineSettings = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.quickBooksOnline.retrieveSettings(rq)
      onlineSettings.value = rs.Info
    }

    const authorizeClick = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.quickBooksOnline.getAuthRequestUrl(rq)
      window.open(rs)
    }

    const syncClick = async () => {
      const rq = { InfoId: props.infoId }
      await host.quickBooksOnline.queueNewSession(rq)
      dialogUtil.value.inform('Sync has been initiated.')
    }

    const refreshClick = async () => {
      await loadOnlineSettings()
    }

    initDialog()

    return {
      dialogUtil,
      interfaceName,
      onlineSettings,
      productName,
      authorizeClick,
      syncClick,
      refreshClick,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
