import { parseISO, format } from 'date-fns'

const toExpDateString = isoDateTime => {
  return isoDateTime ? format(parseISO(isoDateTime), 'MM/yyyy') : ''
}

const toLocaleDateString = isoDateTime => {
  return isoDateTime ? format(parseISO(isoDateTime), 'MMMM d, yyyy') : ''
}

const toLocaleDateTimeString = isoDateTime => {
  return isoDateTime ? format(parseISO(isoDateTime), 'MMMM d, yyyy h:mm a') : ''
}

const toLocaleDayOfWeekString = isoDateTime => {
  return isoDateTime ? format(parseISO(isoDateTime), 'EEEE') : ''
}

const toLocaleISOString = date => {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000
  const msLocal = date.getTime() - offsetMs
  const dateLocal = new Date(msLocal)
  const iso = dateLocal.toISOString()
  const isoLocal = iso.slice(0, 19)
  return isoLocal
}

const todayISOString = () => {
  return toLocaleISOString(new Date()).substring(0, 10)
}

const getLocaleString = () => {
  return navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
        navigator.language ||
        navigator.browserLanguage ||
        'en'
}

const getDayOfWeekDesc = dayOfWeekNumber => {
  if (dayOfWeekNumber >= 0 && dayOfWeekNumber <= 6) {
    const sundayDate = parseISO('2021-03-14')
    var theDate = new Date(sundayDate)
    theDate.setDate(theDate.getDate() + dayOfWeekNumber)
    return format(theDate, 'eeee')
  } else {
    return ''
  }
}

const getDaysOfWeek = bitPattern => {
  const daysOfWeek = []
  for (let i = 0; i < 7; i++) {
    if (bitPattern & Math.pow(2, i)) {
      daysOfWeek.push(getDayOfWeekDesc(i))
    }
  }

  return daysOfWeek.join(', ')
}

const getMonthName = number => {
  const firstDayOfYear = parseISO('2021-01-01')
  var theDate = new Date(firstDayOfYear)
  theDate.setMonth(theDate.getMonth() + number - 1)
  return format(theDate, 'MMMM')
}

const addDays = (isoDate, qtyDays) => {
  var givenDate = new Date(isoDate.substring(0, 10) + 'T00:00:00Z')
  givenDate.setDate(givenDate.getDate() + qtyDays)
  return givenDate.toISOString().substring(0, 10)
}

export {
  getDayOfWeekDesc,
  getDaysOfWeek,
  getLocaleString,
  getMonthName,
  toExpDateString,
  toLocaleDateString,
  toLocaleDateTimeString,
  toLocaleDayOfWeekString,
  toLocaleISOString,
  todayISOString,
  addDays
}
