<template>
  <div>
    <div v-if="selectLists">
      <PageTitle
        featureName="estimates"
        functionName="List"
        :pageName="$options.name"
      />
      <v-card class="ma-2" max-width="850">
        <v-card-title>
          <div class="text-subtitle-1 blue--text">
            Search Criteria
          </div>
        </v-card-title>
        <v-card-text>
          <div class="d-flex align-content-space-around flex-wrap">
            <v-card width="400px" class="mt-2 mr-2">
              <v-card-text>
                <UserPicker
                  v-model="searchCriteria.UserId"
                  label="Created By"
                  clearable
                  @change="refreshSearch"
                />
                <DatePicker
                  v-model="searchCriteria.DateFirst"
                  label="First Estimate Date"
                  @change="refreshSearch"
                  clearable
                  dense
                />
                <DatePicker
                  v-model="searchCriteria.DateLast"
                  label="Last Estimate Date"
                  @change="refreshSearch"
                  clearable
                  dense
                />
              </v-card-text>
            </v-card>
            <v-card width="400px" class="mt-2 mr-2">
              <v-card-text>
                <v-select
                  v-model="searchCriteria.Status"
                  :items="selectLists.EstimateStatus"
                  item-text="Text"
                  item-value="Value"
                  label="Status"
                  clearable
                  dense
                  @change="refreshSearch"
                />
                <CustomerPicker
                  v-model="searchCriteria.CustomerId"
                  v-bind:text.sync="syncCustomerName"
                  label="Customer"
                  dense
                  clearable
                  @input="refreshSearch"
                />
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mx-2" max-width="1200">
        <v-card-title>
          <div class="text-subtitle-1 blue--text">
            Search Results
          </div>
        </v-card-title>
        <v-data-table
          v-if="dataTable"
          :items-per-page="10"
          :headers="extendedHeaderList"
          :items="dataTable.LineItemList"
          :options.sync="options"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length="dataTable.TotalRecords"
          :loading="loading"
          class="elevation-1"
          dense
        >
          <template v-slot:item.WhenCreated="{ item }">
            <span>{{ toLocaleDateString(item.WhenCreated) }}</span>
          </template>
          <template v-slot:item.Total="{ item }">
            <span>{{ toCurrency(item.Total) }}</span>
          </template>
          <template v-slot:item.CustomerName="{ item }">
            <span>
              <v-icon
                v-if="item.CustomerName"
                :color="feature.customers.color"
                @click="navigateToCustomer(item.CustomerId)"
              >
                {{ feature.customers.icon }}
              </v-icon>
              {{ item.CustomerName }}
            </span>
          </template>
          <template v-slot:item.CreatedBy="{ item }">
            <v-icon :color="userColor(item.UserId)">
              {{ icon.user }}
            </v-icon>
            <span class="ml-1">{{ item.CreatedBy }}</span>
          </template>
          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newEstimateClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New Estimate</span>
          </v-tooltip>
        </div>
      </v-card>

      <v-dialog v-model="showEstimateDialog" width="1000" persistent>
        <Estimate
          :isDialogOpen="showEstimateDialog"
          :customerId="selectedCustomerId"
          :estimateId="selectedEstimateId"
          @close="hideEstimateDialog(false)"
          @update="hideEstimateDialog(true)"
          @invoiced="estimateToInvoice"
        />
      </v-dialog>

      <v-dialog v-model="showInvoiceDialog" width="1000" persistent>
        <Invoice
          :invoiceId="selectedInvoiceId"
          @close="hideInvoiceDialog(false)"
          @update="hideInvoiceDialog(true)"
        />
      </v-dialog>
    </div>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { selectListCache } from '@/services/SelectListCache'
import { host } from '@/services/HostAPI'
import { icon, feature } from '@/use/Constants'
import { toLocaleDateString, toLocaleISOString } from '@/use/DateFormatter'
import CustomerPicker from '@/components/Customer/CustomerPicker'
import Estimate from '@/components/Estimate/Estimate'
import Invoice from '@/components/Billing/Invoice'
import { userData } from '@/store/UserSession'
import { toCurrency } from '@/use/NumberFormatter'

export default {
  name: 'SearchEstimates',
  components: { CustomerPicker, Estimate, Invoice },
  props: {
    customerId: {
      type: String,
      default: null
    },
    customerName: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const dialogUtil = ref()

    const router = useRouter()
    const selectLists = ref()
    const dataTable = ref({})

    const searchCriteria = ref({
      UserId: null,
      DateFirst: null,
      DateLast: null,
      CustomerId: null,
      Status: 'A'
    })

    const showEstimateDialog = ref(false)
    const showInvoiceDialog = ref(false)
    const selectedEstimateId = ref()
    const selectedCustomerId = ref()
    const selectedInvoiceId = ref()

    const syncCustomerName = ref()

    const loading = ref(true)
    const options = ref({})
    const sortBy = ref('WhenDue')
    const sortDesc = ref(false)

    const items_per_page = [5, 10, 15, 20, 50]

    const initForm = () => {
      loadSelectLists()
      searchCriteria.value.UserId = userData.value.UserId
      searchCriteria.value.DateLast = toLocaleISOString(new Date()).substring(
        0,
        10
      )
      searchCriteria.value.customerId = props.customerId
      syncCustomerName.value = props.customerName
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.Users,
        selectListCache.name.EstimateStatus
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    watch(
      () => options.value,
      () => {
        getDataFromApi().catch(e => alert('error: ' + e.message))
      }
    )

    const getDataFromApi = async () => {
      loading.value = true
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      const rq = {
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy.length > 0 ? sortBy[0] : 'Date',
          IsDescending: sortDesc.length > 0 ? sortDesc[0] : false
        },
        ...searchCriteria.value
      }
      const rs = await host.estimate.loadDataTable(rq)
      dataTable.value = rs
      loading.value = false
    }

    const refreshSearch = () => {
      getDataFromApi()
    }

    const selectRow = event => {
      router.push({
        name: 'scheduledtask-edit',
        params: { id: event.ItemKey }
      })
    }

    const editItemClick = item => {
      selectedEstimateId.value = item.EstimateId
      selectedCustomerId.value = item.CustomerId
      showEstimateDialog.value = true
    }

    const deleteItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Estimate ${item.EstimateNumber}`
        })
        .then(() => performDeleteEstimate(item))
        .catch(() => {
          return
        })
    }

    const hideEstimateDialog = reload => {
      showEstimateDialog.value = false
      if (reload) {
        refreshSearch()
      }
    }

    const hideInvoiceDialog = reload => {
      showInvoiceDialog.value = false
      if (reload) {
        refreshSearch()
      }
    }

    const performDeleteEstimate = async item => {
      const rq = { InfoId: item.EstimateId }
      const rs = await host.estimate.delete(rq)
      if (rs.IsSuccess) {
        refreshSearch()
      }
    }

    const newEstimateClick = () => {
      selectedEstimateId.value = null
      selectedCustomerId.value = null
      showEstimateDialog.value = true
    }

    const estimateToInvoice = value => {
      hideEstimateDialog(true)
      selectedInvoiceId.value = value
      showInvoiceDialog.value = true
    }

    const navigateToCustomer = customerId => {
      router.push({
        name: feature.customers.routerNameSummary,
        params: { id: customerId }
      })
    }

    const extendedHeaderList = computed(() => {
      return dataTable.value.HeaderList
        ? [
            ...dataTable.value.HeaderList,
            {
              text: 'Actions',
              width: 4,
              alighn: 'left',
              value: 'Actions'
            }
          ]
        : []
    })

    const userColor = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Color'
      )
    }

    initForm()

    return {
      dialogUtil,
      selectLists,
      dataTable,
      searchCriteria,
      syncCustomerName,
      extendedHeaderList,
      items_per_page,
      loading,
      options,
      sortBy,
      sortDesc,
      selectRow,
      refreshSearch,
      editItemClick,
      deleteItemClick,
      showEstimateDialog,
      showInvoiceDialog,
      hideInvoiceDialog,
      hideEstimateDialog,
      newEstimateClick,
      estimateToInvoice,
      navigateToCustomer,
      selectedEstimateId,
      selectedCustomerId,
      selectedInvoiceId,
      userColor,
      icon,
      feature,
      toCurrency,
      toLocaleDateString
    }
  }
}
</script>

<style lang="scss" scoped></style>
