import Vue from 'vue'
import App from './App.vue'

import CKeditor from './plugins/CKEditor'
import router from './router'
import VCurrencyField from './plugins/VCurrencyField'
import Vuelidate from './plugins/vuelidate'
import vuetify from './plugins/vuetify'
import VueAuthHref from './plugins/VueAuthHref'
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader

import ColorPicker from '@/components/controls/ColorPicker'
import DatePicker from '@/components/controls/DatePicker'
import DateTimePicker from '@/components/controls/DateTimePicker'
import DialogTitle from '@/components/controls/DialogTitle'
import DialogUtil from '@/components/controls/DialogUtil'
import DurationPicker from '@/components/controls/DurationPicker'
import IconPicker from '@/components/controls/IconPicker'
import MeasurementPicker from '@/components/controls/MeasurementPicker'
import PageTitle from '@/components/controls/PageTitle'
import ServiceRoutePicker from '@/components/controls/ServiceRoutePicker'
import ServiceTypePicker from '@/components/controls/ServiceTypePicker'
import TaskPriorityPicker from '@/components/controls/TaskPriorityPicker'
import TimePicker from '@/components/controls/TimePicker'
import TranCodePicker from '@/components/controls/TranCodePicker'
import UserPicker from '@/components/controls/UserPicker'

Vue.component('ColorPicker', ColorPicker)
Vue.component('DatePicker', DatePicker)
Vue.component('DateTimePicker', DateTimePicker)
Vue.component('DialogTitle', DialogTitle)
Vue.component('DialogUtil', DialogUtil)
Vue.component('DurationPicker', DurationPicker)
Vue.component('IconPicker', IconPicker)
Vue.component('MeasurementPicker', MeasurementPicker)
Vue.component('PageTitle', PageTitle)
Vue.component('ServiceRoutePicker', ServiceRoutePicker)
Vue.component('ServiceTypePicker', ServiceTypePicker)
Vue.component('TaskPriorityPicker', TaskPriorityPicker)
Vue.component('TimePicker', TimePicker)
Vue.component('TranCodePicker', TranCodePicker)
Vue.component('UserPicker', UserPicker)

new Vue({
  CKeditor,
  router,
  VCurrencyField,
  VueAuthHref,
  Vuelidate,
  vuetify,
  render: h => h(App)
}).$mount('#app')
