<template>
  <div>
    <v-card width="400px">
      <v-card-title>
        <div class="d-flex" style="width: 100%">
          <div :class="`text-subtitle-1 ${captionColor}`">
            Address
          </div>
          <v-spacer />
          <MapIcon
            :street="street"
            :city="city"
            :state="state"
            :postCode="postCode"
          />
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-if="!omitName"
          :value="name"
          @input="updateName"
          label="Care Of"
          dense
        />
        <v-text-field
          :value="street"
          @input="updateStreet"
          label="Street"
          dense
        />
        <v-text-field :value="city" @input="updateCity" label="City" dense />
        <v-text-field :value="state" @input="updateState" label="State" dense />
        <v-text-field
          :value="postCode"
          @input="updatePostCode"
          label="Postal Code"
          dense
        />
        <v-text-field
          :value="latitude"
          @input="updateLatitude"
          number
          label="Latitude"
          dense
        />
        <v-text-field
          :value="longitude"
          @input="updateLongitude"
          number
          label="Longitude"
          dense
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MapIcon from '@/components/Common/MapIcon'
export default {
  name: 'AddressEditor',
  props: {
    captionColor: {
      type: String,
      default: 'blue--text'
    },
    name: {
      type: String,
      default: null
    },
    street: {
      type: String,
      default: null
    },
    city: {
      type: String,
      default: null
    },
    state: {
      type: String,
      default: null
    },
    postCode: {
      type: String,
      default: null
    },
    latitude: {
      type: Number,
      default: 0
    },
    longitude: {
      type: Number,
      default: 0
    },
    omitName: {
      type: Boolean,
      default: false
    }
  },
  components: { MapIcon },
  setup(props, context) {
    const updateName = event => {
      context.emit('updateName', event)
    }

    const updateStreet = event => {
      context.emit('updateStreet', event)
    }

    const updateCity = event => {
      context.emit('updateCity', event)
    }

    const updateState = event => {
      context.emit('updateState', event)
    }

    const updatePostCode = event => {
      context.emit('updatePostCode', event)
    }

    const updateLatitude = event => {
      context.emit('updateLatitude', event)
    }

    const updateLongitude = event => {
      context.emit('updateLongitude', event)
    }

    return {
      updateName,
      updateStreet,
      updateCity,
      updateState,
      updatePostCode,
      updateLatitude,
      updateLongitude
    }
  }
}
</script>

<style lang="scss" scoped></style>
