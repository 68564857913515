<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Commission Types / Commission Rules
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-tabs>
          <v-tab key="1">Commission Types</v-tab>
          <v-tab key="2">Service Commission Rules</v-tab>

          <v-tab-item key="1">
            <v-data-table
              dense
              :items-per-page="10"
              :footer-props="{
                'show-first-last-page': true
              }"
              :headers="commissionTypeHeaders"
              :items="commissionTypeList"
              :loading="loadingCommissionTypes"
              class="elevation-1"
            >
              <template v-slot:item.IsDeleted="{ item }">
                <v-icon v-if="item.IsDeleted" color="error">
                  {{ icon.lineItemIsDeleted }}
                </v-icon>
              </template>
              <template v-slot:item.Actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editCommissionTypeClick(item)"
                >
                  {{ icon.lineItemEdit }}
                </v-icon>
                <v-icon small @click="deleteCommissionTypeClick(item)">
                  {{ icon.lineItemDelete }}
                </v-icon>
              </template>
            </v-data-table>
            <div class="table-footer-prepend d-flex pl-2 align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="newCommissionTypeClick">
                    <v-icon>{{ icon.lineItemAdd }}</v-icon>
                  </v-btn>
                </template>
                <span>New Commission Type</span>
              </v-tooltip>
            </div>
          </v-tab-item>

          <v-tab-item key="2">
            <v-data-table
              dense
              :items-per-page="10"
              :footer-props="{
                'show-first-last-page': true
              }"
              :headers="serviceCommissionRuleHeaders"
              :items="serviceCommissionRuleList"
              :loading="loadingCommissionRules"
              class="elevation-1"
            >
              <template v-slot:item.CommissionTypeId="{ item }">
                {{ commissionTypeDescription(item.CommissionTypeId) }}
              </template>
              <template v-slot:item.ServiceTypeId="{ item }">
                {{ serviceTypeDescription(item.ServiceTypeId) }}
              </template>
              <template v-slot:item.UserId="{ item }">
                {{ userDescription(item.UserId) }}
              </template>
              <template v-slot:item.Amount="{ item }">
                {{ toMoney(item.Amount) }}
              </template>
              <template v-slot:item.PayTo="{ item }">
                {{ serviceCommissionRulePayToDescription(item.PayTo) }}
              </template>
              <template v-slot:item.Actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editServiceCommissionRuleClick(item)"
                >
                  {{ icon.lineItemEdit }}
                </v-icon>
                <v-icon small @click="deleteServiceCommissionRuleClick(item)">
                  {{ icon.lineItemDelete }}
                </v-icon>
              </template>
            </v-data-table>
            <div class="table-footer-prepend d-flex pl-2 align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="newServiceCommissionRuleClick">
                    <v-icon>{{ icon.lineItemAdd }}</v-icon>
                  </v-btn>
                </template>
                <span>New Service Commission Rule</span>
              </v-tooltip>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showCommissionTypeEditorDialog" width="400" persistent>
      <CommissionTypeEditor
        :infoId="selectedItemId"
        :isDialogOpen="showCommissionTypeEditorDialog"
        @close="CommissionTypeEditorCloseEvent"
        @update="CommissionTypeEditorUpdateEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="showServiceCommissionRuleEditorDialog"
      width="400"
      persistent
    >
      <ServiceCommissionRuleEditor
        :infoId="selectedItemId"
        :selectLists="selectLists"
        :commissionTypeList="commissionTypeList"
        :isDialogOpen="showServiceCommissionRuleEditorDialog"
        @close="serviceCommissionRuleEditorCloseEvent"
        @update="serviceCommissionRuleEditorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { toMoney } from '@/use/NumberFormatter'
import { icon } from '@/use/Constants'
import CommissionTypeEditor from '@/components/Setup/Billing/CommissionTypeEditor'
import ServiceCommissionRuleEditor from '@/components/Setup/Billing/ServiceCommissionRuleEditor'
export default {
  name: 'CommissionLists',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CommissionTypeEditor,
    ServiceCommissionRuleEditor
  },
  setup(props) {
    const dialogUtil = ref(null)
    const loadingCommissionTypes = ref(false)
    const loadingCommissionRules = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      commissionTypeList.value = []
      serviceCommissionRuleList.value = []
      commissionTypeList.value = await getcommissionTypeList()
      serviceCommissionRuleList.value = await getserviceCommissionRuleList()
      loadSelectLists()
    }

    const commissionTypeList = ref([])
    const serviceCommissionRuleList = ref([])
    const selectedItemId = ref(null)
    const showCommissionTypeEditorDialog = ref(false)
    const showServiceCommissionRuleEditorDialog = ref(false)
    const selectLists = ref()

    const getcommissionTypeList = async () => {
      loadingCommissionTypes.value = true
      const rq = {}
      const rs = await host.commissionType.list(rq)
      loadingCommissionTypes.value = false
      return rs.InfoList
    }

    const getserviceCommissionRuleList = async () => {
      loadingCommissionRules.value = true
      const rq = {}
      const rs = await host.serviceCommissionRule.list(rq)
      loadingCommissionRules.value = false
      return rs.InfoList
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.ServiceTypes,
        selectListCache.name.Users,
        selectListCache.name.ServiceCommissionRulePayTos
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const editCommissionTypeClick = item => {
      selectedItemId.value = item.CommissionTypeId
      showCommissionTypeEditorDialog.value = true
    }

    const deleteCommissionTypeClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Commission Type ${item.Code}`
        })
        .then(() => performDeleteCommissionType(item))
        .catch(() => {
          return
        })
    }

    const performDeleteCommissionType = async item => {
      const rq = { InfoId: item.CommissionTypeId }
      const rs = await host.commissionType.delete(rq)
      if (rs.IsSuccess) {
        commissionTypeList.value = await getcommissionTypeList()
        serviceCommissionRuleList.value = await getserviceCommissionRuleList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const newCommissionTypeClick = () => {
      selectedItemId.value = null
      showCommissionTypeEditorDialog.value = true
    }

    const editServiceCommissionRuleClick = item => {
      selectedItemId.value = item.ServiceCommissionRuleId
      showServiceCommissionRuleEditorDialog.value = true
    }

    const deleteServiceCommissionRuleClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Service Commission Rule ${item.Reason}`
        })
        .then(() => performDeleteServiceCommissionRule(item))
        .catch(() => {
          return
        })
    }

    const performDeleteServiceCommissionRule = async item => {
      const rq = { InfoId: item.ServiceCommissionRuleId }
      const rs = await host.serviceCommissionRule.delete(rq)
      if (rs.IsSuccess) {
        serviceCommissionRuleList.value = await getserviceCommissionRuleList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const newServiceCommissionRuleClick = () => {
      selectedItemId.value = null
      showServiceCommissionRuleEditorDialog.value = true
    }

    const commissionTypeHeaders = [
      { value: 'Code', text: 'Code' },
      { value: 'Description', text: 'Description' },
      { value: 'SortOrder', text: 'Sort Order', align: 'right' },
      { value: 'IsDeleted', text: 'Deleted', align: 'center' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const serviceCommissionRuleHeaders = [
      { value: 'CommissionTypeId', text: 'Commission Type' },
      { value: 'ServiceTypeId', text: 'Service Type' },
      { value: 'PayTo', text: 'Pay To' },
      { value: 'UserId', text: 'User' },
      { value: 'Amount', text: 'Amount', align: 'right' },
      { value: 'Reason', text: 'Reason' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const serviceTypeDescription = value => {
      const selectedServiceType = selectLists.value.ServiceTypes.find(
        item => item.Value == value
      )
      return selectedServiceType ? selectedServiceType.Text : ''
    }

    const userDescription = value => {
      const selectedUser = selectLists.value.Users.find(
        item => item.Value == value
      )
      return selectedUser ? selectedUser.Text : ''
    }

    const CommissionTypeEditorCloseEvent = () => {
      showCommissionTypeEditorDialog.value = false
    }

    const CommissionTypeEditorUpdateEvent = async () => {
      showCommissionTypeEditorDialog.value = false
      commissionTypeList.value = await getcommissionTypeList()
      serviceCommissionRuleList.value = await getserviceCommissionRuleList()
    }

    const serviceCommissionRuleEditorCloseEvent = () => {
      showServiceCommissionRuleEditorDialog.value = false
    }

    const serviceCommissionRuleEditorUpdateEvent = async () => {
      showServiceCommissionRuleEditorDialog.value = false
      serviceCommissionRuleList.value = await getserviceCommissionRuleList()
    }

    const commissionTypeDescription = value => {
      const result =
        value && commissionTypeList.value
          ? commissionTypeList.value.find(
              item => item.CommissionTypeId == value
            )
          : null

      return result ? result.Description : ''
    }

    const serviceCommissionRulePayToDescription = value => {
      return value && value !== ' '
        ? selectLists.value.ServiceCommissionRulePayTos.find(
            item => item.Value == value
          ).Text
        : 'S'
    }

    initDialog()

    return {
      dialogUtil,
      commissionTypeList,
      commissionTypeHeaders,
      serviceTypeDescription,
      userDescription,
      serviceCommissionRulePayToDescription,
      showCommissionTypeEditorDialog,
      selectedItemId,
      editCommissionTypeClick,
      deleteCommissionTypeClick,
      newCommissionTypeClick,
      showServiceCommissionRuleEditorDialog,
      editServiceCommissionRuleClick,
      deleteServiceCommissionRuleClick,
      newServiceCommissionRuleClick,
      CommissionTypeEditorCloseEvent,
      CommissionTypeEditorUpdateEvent,
      serviceCommissionRuleEditorCloseEvent,
      serviceCommissionRuleEditorUpdateEvent,
      selectLists,
      serviceCommissionRuleList,
      serviceCommissionRuleHeaders,
      commissionTypeDescription,
      toMoney,
      icon,
      loadingCommissionTypes,
      loadingCommissionRules
    }
  }
}
</script>

<style lang="scss" scoped></style>
