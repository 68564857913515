<template>
  <v-select v-model="month" v-bind="$attrs" :items="monthList" />
</template>

<script>
import { ref, watch } from 'vue'
import { getMonthName } from '@/use/DateFormatter'
export default {
  name: 'MonthPicker',
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  setup(props, context) {
    const month = ref()

    const monthList = [...Array(12)].map(
      (key, value) =>
        new Object({ value: value + 1, text: getMonthName(value + 1) })
    )

    watch(
      () => props.value,
      newVal => {
        month.value = newVal
      }
    )

    watch(
      () => month.value,
      newVal => {
        context.emit('input', Number(newVal))
      }
    )

    const initControl = () => {
      month.value = props.value
    }

    initControl()

    return { monthList, month }
  }
}
</script>

<style lang="scss" scoped></style>
