import { host } from '@/services/HostAPI.js'
const containerName = 'v4-tutorials'
const fileExtension = '.mp4'
let tutorialList = null
let whenTutorialListRetrieved = 0
const tutorialListTimeoutInSeconds = 600

const refreshListIfNecessary = async () => {
  if (isTutorialListTimedOut()) {
    const rq = { Criteria: containerName }
    const rs = await host.video.list(rq)
    tutorialList = rs.InfoList
    whenTutorialListRetrieved = Date.now()
  }
}

const isTutorialListTimedOut = () => {
  const timeSinceLastRetrievedInSeconds =
    (Date.now() - whenTutorialListRetrieved) / 1000
  return timeSinceLastRetrievedInSeconds > tutorialListTimeoutInSeconds
}

const uploadVideo = async (tutorialName, fileLocation) => {
  const rq = {
    ContainerName: containerName,
    FileName: tutorialName + fileExtension
  }
  const rs = await host.video.upload(rq, fileLocation)
  return rs
}

const deleteVideo = async tutorialName => {
  const rq = {
    ContainerName: containerName,
    FileName: tutorialName + fileExtension
  }
  const rs = await host.video.delete(rq)
  return rs
}

const getVideoUrl = tutorialName => {
  return host.video.getUrl(containerName, tutorialName + fileExtension)
}

const isTutorialAvailable = async tutorialName => {
  await refreshListIfNecessary()

  return (
    tutorialName != null &&
    tutorialName != '' &&
    tutorialList.includes(tutorialName + fileExtension)
  )
}

const invalidateCache = () => {
  whenTutorialListRetrieved = 0
}

export {
  isTutorialAvailable,
  uploadVideo,
  deleteVideo,
  getVideoUrl,
  invalidateCache
}
