<template>
  <v-card width="320px" height="330px" class="pt-2 pl-3 ma-2">
    <div class="overline grey--text text-uppercase">Calendar</div>
    <v-date-picker
      v-model="picker"
      no-title
      class="elevation-0"
    ></v-date-picker>
  </v-card>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'Calendar',
  setup() {
    const picker = ref(new Date().toISOString().substr(0, 10))
    return { picker }
  }
}
</script>
