<template>
  <div>
    <v-card width="400">
      <v-card-title class="subtitle-1">
        Tutorial Video for {{ tutorialName }}
      </v-card-title>
      <v-card-text>
        <v-file-input
          v-model="fileLocation"
          accept="image/pdf"
          show-size
          :prepend-icon="icon.documentUpload"
          label="Video to Upload"
          hint="Replaces existing video (if any)"
          :error-messages="errorMessages(vuelidate.fileLocation)"
          @change="fileLocationChange"
          @blur="vuelidate.fileLocation.$touch()"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="ml-1" @click="saveClick">Upload</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        <v-spacer />
        <v-btn v-if="tutorialExists" color="secondary" @click="deleteClick">
          Remove
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { icon } from '@/use/Constants'
import { ref, watch } from 'vue'
import {
  isTutorialAvailable,
  uploadVideo,
  deleteVideo,
  invalidateCache
} from '@/store/TutorialCache'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'TutorialUploader',
  props: {
    tutorialName: {
      type: String,
      default: ''
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      vuelidate.value.$reset()
      tutorialExists.value = await isTutorialAvailable(props.tutorialName)
    }

    const tutorialExists = ref(false)
    const fileLocation = ref()

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      const result = await uploadVideo(props.tutorialName, fileLocation.value)
      afterSaveOrUpdate(result)
      context.emit('update')
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const deleteClick = () => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Remove this tutorial. Are you sure?'
        })
        .then(() => performDelete())
        .catch(() => {
          return
        })
    }

    const performDelete = async () => {
      const result = await deleteVideo(props.tutorialName)
      afterSaveOrUpdate(result)
      context.emit('update')
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        invalidateCache()
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const fileLocationChange = () => {}

    const rules = {
      fileLocation: {
        required: required
      }
    }

    const vuelidate = useVuelidate(rules, { fileLocation })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    initDialog()

    return {
      dialogUtil,
      tutorialExists,
      fileLocation,
      fileLocationChange,
      saveClick,
      cancelClick,
      deleteClick,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
