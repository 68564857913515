<template>
  <v-card width="400">
    <v-card-title>
      <DialogTitle :dialogName="$options.name">
        {{ isEditMode ? 'Edit' : 'New' }} Rate Amount
      </DialogTitle>
    </v-card-title>
    <v-card-text>
      <TranCodePicker
        v-model="formData.TranCodeId"
        :error-messages="errorMessages(vuelidate.formData.TranCodeId)"
        @blur="vuelidate.formData.TranCodeId.$touch()"
      />
      <v-text-field
        v-model="formData.SortOrder"
        label="Sort Order"
        :error-messages="errorMessages(vuelidate.formData.SortOrder)"
        @blur="vuelidate.formData.SortOrder.$touch()"
        dense
      />
      <v-currency-field
        v-model="formData.Amount"
        label="Amount"
        :error-messages="errorMessages(vuelidate.formData.Amount)"
        @blur="vuelidate.formData.Amount.$touch()"
        dense
      />
    </v-card-text>
    <v-card-actions
      ><v-btn color="success" class="ml-1" @click="saveClick">{{
        isEditMode ? 'Update' : 'Save'
      }}</v-btn>
      <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'RateDetailEditor',
  props: {
    item: {
      type: Object,
      required: true
    },
    selectLists: {
      type: Object,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const formData = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      formData.value = { ...props.item.info }
      vuelidate.value.$reset()
    }

    const isEditMode = computed(() => props.item.index >= 0)

    const saveClick = async () => {
      if (!validate()) {
        context.emit('update', {
          info: formData.value,
          index: props.item.index
        })
      }
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const rules = {
      formData: {
        TranCodeId: { required },
        SortOrder: { required },
        Amount: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    initDialog()
    return {
      isEditMode,
      formData,
      errorMessages,
      saveClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
