<template>
  <v-checkbox
    v-if="type == 'B'"
    :value="value"
    :label="label"
    @change="updateValue"
    true-value="true"
    false-value="false"
    :input-value="value"
    dense
  />
  <v-combobox
    v-else-if="type == 'S'"
    :value="value"
    @input="updateValue"
    :label="label"
    :items="optionList.split(',')"
    clearable
    dense
  />
  <DatePicker
    v-else-if="type == 'D'"
    :value="value"
    @input="updateValue"
    :label="label"
    clearable
    dense
  />
  <v-textarea
    v-else-if="type == 'A'"
    :value="value"
    @input="updateValue"
    :label="label"
    dense
  />
  <v-text-field
    v-else
    :value="value"
    @input="updateValue"
    :label="label"
    dense
  />
</template>

<script>
export default {
  name: 'ServiceableAttributeEditor',
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'T'
    },
    optionList: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    }
  },
  setup(props, context) {
    const updateValue = event => {
      context.emit('input', event)
    }

    return { updateValue }
  }
}
</script>

<style lang="scss" scoped></style>
