<template>
  <div>
    <div v-if="formData">
      <v-card width="6000">
        <v-card-title>
          <DialogTitle :dialogName="$options.name">
            {{ isEditMode ? 'Edit' : 'New' }} User Group
          </DialogTitle>
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-wrap">
            <v-card width="300">
              <v-card-text>
                <v-text-field
                  v-model="formData.UserGroupName"
                  label="User Group Name"
                  :error-messages="
                    errorMessages(vuelidate.formData.UserGroupName)
                  "
                  @blur="vuelidate.formData.UserGroupName.$touch()"
                  dense
                ></v-text-field>
              </v-card-text>
            </v-card>
            <v-card width="300" class="ml-4">
              <v-card-title
                ><div class="text-subtitle-1 blue--text">
                  Member of Roles
                </div></v-card-title
              >
              <v-card-text>
                <v-checkbox
                  v-for="item in formData.RoleList"
                  :key="item.RoleId"
                  v-model="item.IsInRole"
                  :label="item.RoleName"
                  dense
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions
          ><v-btn
            v-if="isEditMode"
            color="success"
            class="ml-3"
            @click="updateClick"
            >Update</v-btn
          >
          <v-btn v-else color="success" class="ml-3" @click="saveClick"
            >Save</v-btn
          >
          <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'UserGroupEditor',
  props: {
    userGroupId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()

    function initDialog() {
      if (props.userGroupId) {
        retrieveUserGroup(props.userGroupId)
      } else {
        newTemplate()
      }
    }

    const isEditMode = computed(() => {
      return formData.value != null && formData.value.UserGroupId != null
    })

    async function retrieveUserGroup(id) {
      const rq = { InfoId: id }
      const rs = await host.userGroup.retrieve(rq)
      formData.value = rs.Info
    }

    async function newTemplate() {
      const rq = {}
      const rs = await host.userGroup.newTemplate(rq)
      formData.value = rs.Info
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const rules = {
      formData: {
        UserGroupName: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    function errorMessages(item) {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    function validate() {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    async function saveClick() {
      if (validate()) {
        return
      }

      const result = await saveNew()
      afterSaveOrUpdate(result)
    }

    async function updateClick() {
      if (validate()) {
        return
      }

      const result = await update()
      afterSaveOrUpdate(result)
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    async function saveNew() {
      const rq = { Info: formData.value }
      const rs = await host.userGroup.create(rq)
      return rs
    }

    async function update() {
      const rq = { Info: formData.value }
      const rs = await host.userGroup.update(rq)
      return rs
    }

    initDialog()

    return {
      dialogUtil,
      formData,
      isEditMode,
      errorMessages,
      saveClick,
      updateClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
