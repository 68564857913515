<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
          color="primary"
          @click="sendEmailClick"
        >
          <v-icon :color="feature.sendEmail.color">
            {{ feature.sendEmail.icon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ hint }}</span>
    </v-tooltip>

    <v-dialog
      v-model="showSendEmailDialog"
      :max-width="feature.sendEmail.dialogWidth"
      persistent
    >
      <SendEmailDialog
        :isDialogOpen="showSendEmailDialog"
        :showWaitDialog="showWaitDialog"
        :emailEventType="emailEventType"
        :subtitle="subtitle"
        @update="sendEmailDialogUpdate"
        @close="sendEmailDialogClose"
      />
    </v-dialog>
  </div>
</template>

<script>
import { feature } from '@/use/Constants'
import { ref } from 'vue'
import SendEmailDialog from '@/components/Common/SendEmailDialog'
export default {
  name: 'SendEmailButton',
  props: {
    subtitle: {
      type: String,
      default: 'Please wait'
    },
    showWaitDialog: {
      type: Boolean,
      default: false
    },
    emailEventType: {
      type: String,
      default: 'C'
    },
    hint: {
      type: String,
      default: 'Send Email'
    }
  },
  components: { SendEmailDialog },
  setup(props, context) {
    const showSendEmailDialog = ref(false)

    const sendEmailClick = () => {
      showSendEmailDialog.value = true
    }

    const sendEmailDialogUpdate = event => {
      context.emit('update', event)
      showSendEmailDialog.value = false
    }

    const sendEmailDialogClose = () => {
      showSendEmailDialog.value = false
    }

    return {
      showSendEmailDialog,
      sendEmailClick,
      sendEmailDialogUpdate,
      sendEmailDialogClose,
      feature
    }
  }
}
</script>

<style lang="scss" scoped></style>
