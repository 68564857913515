import { ref } from 'vue'
import CloudManagerService from '@/services/CloudManagerService'
import { userData } from '@/store/UserSession'

const summary = ref({
  QtyUnreadAlerts: 0,
  IsSubscribed: true,
  WhenTrialExpires: null
})

var intervalHandle = null

const subscribe = () => {
  unsubscribe()
  poll()
  intervalHandle = setInterval(poll, 300000)
}

const unsubscribe = () => {
  if (intervalHandle) {
    clearInterval(intervalHandle)
    intervalHandle = null
  }
}

const poll = async () => {
  if (userData.value.OfficeId && userData.value.UserId) {
    const rq = {
      OfficeId: userData.value.OfficeId,
      UserId: userData.value.UserId
    }
    try {
      const rs = await CloudManagerService.doPost(
        '/OfficeClientAlert/Summary',
        rq
      )
      summary.value = rs.data
    } catch (e) {
      console.log('something went wrong:')
    }
  }
}

const retrieve = async () => {
  const rq = {
    OfficeId: userData.value.OfficeId,
    UserId: userData.value.UserId
  }
  try {
    const rs = await CloudManagerService.doPost('/OfficeClientAlert/List', rq)
    return rs.data.AlertList
  } catch (e) {
    console.log('something went wrong:')
    return null
  }
}

const markAsRead = async officeAlertIdList => {
  const rq = {
    UserId: userData.value.UserId,
    OfficeAlertIdList: officeAlertIdList
  }
  try {
    await CloudManagerService.doPost('/OfficeClientAlert/MarkAsRead', rq)
    officeAlertService.clearUnreadAlertCount()
  } catch (e) {
    console.log('something went wrong:')
  }
}

const clearUnreadAlertCount = () => {
  summary.value.QtyUnreadAlerts = 0
}

const clearUnsubscribedStatus = async () => {
  const rq = {
    OfficeId: userData.value.OfficeId,
    IsSubscribed: true
  }
  try {
    const rs = await CloudManagerService.doPost(
      '/Office/UpdateSubscriptionStatus',
      rq
    )
    if (!rs.IsSuccess) {
      console.log({
        loc: 'OfficeAlertService.clearUnsubscribedStatus',
        status: 'IsSuccess is false',
        message: rs.Message
      })
    }
  } catch (e) {
    console.log({
      loc: 'OfficeAlertService.clearUnsubscribedStatus',
      status: 'error',
      error: e
    })
  }

  summary.value.IsSubscribed = false
  summary.value.WhenTrialExpires = null
}

const officeAlertService = {
  summary: summary,
  subscribe: subscribe,
  unsubscribe: unsubscribe,
  poll: poll,
  retrieve: retrieve,
  markAsRead: markAsRead,
  clearUnreadAlertCount: clearUnreadAlertCount,
  clearUnsubscribedStatus: clearUnsubscribedStatus
}

export { officeAlertService }
