<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="editScheduledServiceClick">
          <v-icon :color="feature.services.color">
            {{ feature.services.icon }}
          </v-icon>
        </v-btn>
      </template>
      <span>Edit Service</span>
    </v-tooltip>

    <v-dialog
      v-model="showScheduledServiceEditorDialog"
      :width="feature.services.dialogWidth"
    >
      <ScheduledServiceEditor
        :isDialogOpen="showScheduledServiceEditorDialog"
        :siteId="siteId"
        :scheduledServiceId="scheduledServiceId"
        @close="scheduledServiceEditorCloseEvent"
        @update="scheduledServiceEditorUpdateEvent"
        @editRecurringService="editRecurringServiceEvent"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="showRecurringServiceEditorDialog"
      :width="feature.recurringServices.dialogWidth"
    >
      <RecurringServiceEditor
        :siteId="siteId"
        :recurringServiceId="selectedRecurringServiceId"
        :isDialogOpen="showRecurringServiceEditorDialog"
        @update="recurringServiceEditorUpdateEvent"
        @close="recurringServiceEditorCloseEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { feature } from '@/use/Constants'
import ScheduledServiceEditor from '@/components/ScheduledService/ScheduledServiceEditor'
import RecurringServiceEditor from '@/components/ScheduledService/RecurringServiceEditor'
export default {
  name: 'EditScheduledServiceButton',
  props: {
    siteId: {
      type: String,
      default: null
    },
    scheduledServiceId: {
      type: String,
      default: null
    }
  },
  components: {
    ScheduledServiceEditor,
    RecurringServiceEditor
  },
  setup(props, context) {
    const showScheduledServiceEditorDialog = ref(false)
    const showRecurringServiceEditorDialog = ref(false)
    const selectedRecurringServiceId = ref()

    const editScheduledServiceClick = () => {
      showScheduledServiceEditorDialog.value = true
    }

    const scheduledServiceEditorCloseEvent = () => {
      showScheduledServiceEditorDialog.value = false
      context.emit('close')
    }

    const scheduledServiceEditorUpdateEvent = () => {
      showScheduledServiceEditorDialog.value = false
      context.emit('update')
    }

    const editRecurringServiceEvent = id => {
      selectedRecurringServiceId.value = id
      showRecurringServiceEditorDialog.value = true
    }

    const recurringServiceEditorCloseEvent = () => {
      showRecurringServiceEditorDialog.value = false
    }

    const recurringServiceEditorUpdateEvent = () => {
      showRecurringServiceEditorDialog.value = false
      context.emit('update')
    }

    return {
      feature,
      editScheduledServiceClick,
      showScheduledServiceEditorDialog,
      scheduledServiceEditorCloseEvent,
      scheduledServiceEditorUpdateEvent,
      editRecurringServiceEvent,
      selectedRecurringServiceId,
      showRecurringServiceEditorDialog,
      recurringServiceEditorCloseEvent,
      recurringServiceEditorUpdateEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
