<template>
  <div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <Banner class="ml-5 mb-0" />
      </div>
      <div class="mt-3 ml-2 mr-2">
        <TutorialButton :tutorialName="$options.name" />
      </div>
    </div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row align="start" justify="start" class="ml-0">
            <Calendar />
            <Weather />
            <AgingCard />
            <TaskSummary />
            <MobileLink />
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters align="start" justify="start" height="400px"> </v-row>
    </v-container>
    <v-footer>
      <span class="caption">Copyright © 2024 ArrowStorm Corporation</span>
      <span>&nbsp;|&nbsp;</span>
      <span class="caption">
        <a
          :href="privacyStatementWindow"
          title="Privacy Statement (New Window)"
        >
          Privacy Statement
        </a>
      </span>
      <span>&nbsp;|&nbsp;</span>
      <span class="caption">
        <a
          :href="securityStatementWindow"
          title="Security Statement (New Window)"
        >
          Security Statement
        </a>
      </span>
      <span>&nbsp;|&nbsp;</span>
      <span class="caption">
        <a :href="termsOfUseWindow" title="Terms of Use (New Window)">
          Terms of Use Agreement
        </a>
      </span>
    </v-footer>
  </div>
</template>

<script>
import Calendar from '@/components/dashboard/Calendar'
import Weather from '@/components/dashboard/Weather'
import AgingCard from '@/components/dashboard/AgingCard'
import TaskSummary from '@/components/dashboard/TaskSummary'
import MobileLink from '@/components/dashboard/MobileLink'
import Banner from '@/components/dashboard/Banner'
import TutorialButton from '@/components/controls/TutorialButton'
export default {
  name: 'Dashboard',
  components: {
    Calendar,
    Weather,
    AgingCard,
    TaskSummary,
    MobileLink,
    Banner,
    TutorialButton
  },
  setup() {
    const tosWindowTemplate =
      "javascript:window.open('{url}', '_blank', 'width=550,height=350,resizable=yes,toolbar=no,status=no,scrollbars=yes,menubar=no,directories=no,location=no,dependant=no', false).focus();"

    const privacyStatementWindow = tosWindowTemplate.replace(
      '{url}',
      'https://termsofservice.poolcareonline.com/PrivacyStatement'
    )

    const securityStatementWindow = tosWindowTemplate.replace(
      '{url}',
      'https://termsofservice.poolcareonline.com/SecurityStatement'
    )

    const termsOfUseWindow = tosWindowTemplate.replace(
      '{url}',
      'https://termsofservice.poolcareonline.com/TermsOfUse'
    )

    return {
      privacyStatementWindow,
      securityStatementWindow,
      termsOfUseWindow
    }
  }
}
</script>

<style lang="scss" scoped></style>
