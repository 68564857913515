export default {
  report: {
    transactionBatch: {
      title: 'Transaction Batch Report',
      reportId: '61662720-8037-4F80-920E-289203F2DCA0'
    },
    customerStatements: {
      title: 'Customer Statements',
      reportId: '1f645c17-9edc-4927-a1e1-cd8326be85c4'
    },
    statement: {
      title: 'Statement',
      reportId: '1f645c17-9edc-4927-a1e1-cd8326be85c4'
    },
    transactionDetail: {
      title: 'Transaction Detail Report',
      reportId: 'b0d77c38-0ee3-4eb6-8128-6252ef6d4903'
    },
    invoice: {
      title: 'Invoice',
      reportId: 'f52fb5d3-8ec2-4c8d-9ca1-1d1e39017d9a'
    },
    estimate: {
      title: 'Estimate',
      reportId: '43329ea4-df47-4bc4-b164-709c118449a4'
    },
    workorderOpenClose: {
      title: 'Open/Close Work Order',
      reportId: 'b9216175-1b93-4e3c-9d18-312ce362ab21'
    },
    workorderRepair: {
      title: 'Repair Work Order',
      reportId: 'f6eab9f6-8a29-4d98-95be-befd2457f9db'
    },
    workorderService: {
      title: 'Service Work Order',
      reportId: '2d87e49f-78bf-4fac-b22d-c53512add965'
    },
    workorderStartup: {
      title: 'Startup Work Order',
      reportId: '64ffa49d-ca48-4339-86bd-308647eb5531'
    }
  }
}
