<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Users
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="includeDisabled"
          label="Include Disabled Users"
          dense
        ></v-checkbox>
        <v-data-table
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="userList"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:item.Color="{ item }">
            <v-chip x-small :color="item.Color" />
          </template>
          <template v-slot:item.IsDisabled="{ item }">
            <v-icon v-if="item.IsDisabled" color="red">
              {{ icon.lineItemIsDeleted }}
            </v-icon>
          </template>
          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New User</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showUserEditorDialog" width="1080" persistent>
      <UserEditor
        :userId="selectedUserId"
        :isDialogOpen="showUserEditorDialog"
        @close="userEditorCloseEvent"
        @update="userEditorUpdateEvent"
      />
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { host } from '@/services/HostAPI'
import UserEditor from '@/components/Setup/User/UserEditor'
import { selectListCache } from '@/services/SelectListCache'
import { icon } from '@/use/Constants'

export default {
  name: 'UserList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { UserEditor },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      formData.value = null
      formData.value = await getUserList()
    }

    const includeDisabled = ref(false)
    const formData = ref(null)
    const selectedUserId = ref(null)
    const showUserEditorDialog = ref(false)

    const userList = computed(() => {
      return formData.value && formData.value.InfoList
        ? formData.value.InfoList.filter(
            item => includeDisabled.value || !item.IsDisabled
          )
        : []
    })

    const headers = [
      { value: 'Color', text: 'Color' },
      { value: 'UserName', text: 'User Name' },
      { value: 'LoginName', text: 'Login Name' },
      { value: 'IsDisabled', text: 'Disabled' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const editItemClick = item => {
      selectedUserId.value = item.UserId
      showUserEditorDialog.value = true
    }

    const deleteItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete User ${item.UserName}`
        })
        .then(() => performDeleteUser(item))
        .catch(() => {
          return
        })
    }

    const userEditorCloseEvent = () => {
      showUserEditorDialog.value = false
    }

    const userEditorUpdateEvent = async () => {
      showUserEditorDialog.value = false
      formData.value = await getUserList()
    }

    const getUserList = async () => {
      loading.value = true
      const rq = {}
      const rs = await host.user.list(rq)
      loading.value = false
      return rs
    }

    const performDeleteUser = async item => {
      const rq = { InfoId: item.UserId }
      const rs = await host.user.delete(rq)
      if (rs.IsSuccess) {
        selectListCache.invalidate(selectListCache.name.Users)
        formData.value = await getUserList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const newItemClick = () => {
      selectedUserId.value = null
      showUserEditorDialog.value = true
    }

    initDialog()

    return {
      dialogUtil,
      includeDisabled,
      headers,
      userList,
      selectedUserId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      showUserEditorDialog,
      userEditorCloseEvent,
      userEditorUpdateEvent,
      formData,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
