<template>
  <div v-if="formData && selectLists">
    <v-card width="9000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit Invoice' : 'New Invoice' }}
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-wrap">
          <v-card width="300">
            <v-card-text>
              <v-text-field
                v-model="formData.InvoiceNumber"
                label="Invoice Number"
                :error-messages="
                  errorMessages(vuelidate.formData.InvoiceNumber)
                "
                @blur="vuelidate.formData.InvoiceNumber.$touch()"
                dense
              />
              <DatePicker
                v-model="formData.BusinessDate"
                label="Invoice Date"
                :error-messages="errorMessages(vuelidate.formData.BusinessDate)"
                @blur="vuelidate.formData.BusinessDate.$touch()"
                dense
              />
            </v-card-text>
          </v-card>
          <v-card width="300" class="ml-4">
            <v-card-text>
              <v-textarea
                v-model="formData.Description"
                label="Description"
                :error-messages="errorMessages(vuelidate.formData.Description)"
                @blur="vuelidate.formData.Description.$touch()"
                dense
              />
            </v-card-text>
          </v-card>
          <v-card width="300" class="ml-4 indigo lighten-5">
            <v-card-text>
              <div>
                {{ formData.CustomerName }}
                <div class="float-right">
                  <SyncInfoButton
                    v-if="isEditMode"
                    :recordId="formData.InvoiceId"
                  />
                </div>
              </div>
              <div>{{ formData.Address.Street }}</div>
              <div>
                <span>{{ formData.Address.City }}, </span>
                <span>{{ formData.Address.State }} </span>
                <span>{{ formData.Address.PostCode }}</span>
              </div>
              <div class="mt-3 body-1 font-weight-medium purple--text">
                Invoice Total: {{ toMoney(invoiceTotal) }}
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div>
          <v-card class="mt-2"
            ><v-card-text>
              <v-data-table
                dense
                :items-per-page="5"
                :footer-props="{
                  'items-per-page-options': items_per_page,
                  'show-first-last-page': true
                }"
                :headers="headers"
                :items="formData.LineItemList"
                class="elevation-1"
              >
                <template v-slot:item.TranCodeId="{ item }"
                  ><span>{{
                    selectLists.TranCodes.find(
                      sl => sl.Value == item.TranCodeId
                    ).Text
                  }}</span>
                </template>
                <template v-slot:item.Rate="{ item }"
                  ><span>{{ toMoney(item.Rate) }}</span>
                </template>
                <template v-slot:item.Quantity="{ item }"
                  ><span>{{ item.Quantity }}</span>
                </template>
                <template v-slot:item.Amount="{ item }"
                  ><span>{{ toMoney(item.Amount) }}</span>
                </template>
                <template v-slot:item.Actions="{ item }">
                  <v-icon small class="mr-2" @click="editLineItemClick(item)">
                    {{ icon.lineItemEdit }}
                  </v-icon>
                  <v-icon small @click="deleteLineItemClick(item)">
                    {{ icon.lineItemDelete }}
                  </v-icon>
                </template>
              </v-data-table>
              <div class="table-footer-prepend d-flex pl-2 align-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="newLineItemClick">
                      <v-icon>{{ icon.lineItemAdd }}</v-icon>
                    </v-btn>
                  </template>
                  <span>New Invoice Line</span>
                </v-tooltip>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="isEditMode"
          color="success"
          class="ml-3"
          @click="updateClick(false)"
        >
          Update
        </v-btn>
        <v-btn v-else color="success" class="ml-3" @click="saveClick(false)">
          Save
        </v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn small color="primary" class="mr-2" @click="printClick">
          <v-icon class="mr-2">{{ icon.report }}</v-icon>
          Print / Email
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showInvoiceLineDialog" max-width="400" persistent>
      <InvoiceLine
        :isDialogOpen="showInvoiceLineDialog"
        :itemInfo="selectedItemInfo"
        :isEdit="isEditInvoiceLine"
        lineType="Invoice"
        @close="showInvoiceLineDialog = false"
        @update="invoiceLineUpdate"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import InvoiceLine from '@/components/Billing/InvoiceLine'
import reportInfo from '@/constants/reports'
import { host } from '@/services/HostAPI'
import ReportService from '@/services/ReportService'
import { selectListCache } from '@/services/SelectListCache'
import { icon } from '@/use/Constants'
import { toMoney } from '@/use/NumberFormatter'
import SyncInfoButton from '@/components/Common/SyncInfoButton'

export default {
  name: 'Invoice',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: String
    },
    invoiceId: {
      type: String,
      default: null
    },
    scheduledServiceId: {
      type: String,
      default: null
    }
  },
  components: { InvoiceLine, SyncInfoButton },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const selectLists = ref([])
    const formData = ref()
    const isEditMode = ref(false)
    const showInvoiceLineDialog = ref(false)
    const isEditInvoiceLine = ref(false)
    const selectedItemInfo = ref()
    const updated = ref(false)
    const isSaving = ref(false)

    const initDialog = () => {
      isSaving.value = false
      loadSelectLists()
      isEditMode.value = props.invoiceId != null
      if (isEditMode.value) {
        retrieveInvoice(props.invoiceId)
      } else {
        newInvoiceTemplate()
      }
      vuelidate.value.$reset()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const headers = [
      { value: 'SortOrder', text: 'Line', width: 40, align: 'right' },
      { value: 'TranCodeId', text: 'Code' },
      { value: 'Reference', text: 'Reference' },
      { value: 'Rate', text: 'Rate', width: 100, align: 'right' },
      { value: 'Quantity', text: 'Quantity', width: 100, align: 'right' },
      { value: 'Amount', text: 'Amount', width: 100, align: 'right' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const items_per_page = [5, 10, 15, 20, 50]

    const retrieveInvoice = async id => {
      const rq = { InfoId: id }
      const rs = await host.invoice.retrieve(rq)
      formData.value = rs.Info
    }

    const newInvoiceTemplate = async () => {
      const rq = {
        Defaults: {
          CustomerId: props.customerId,
          ScheduledServiceId: props.scheduledServiceId
        }
      }
      const rs = await host.invoice.newTemplate(rq)
      formData.value = rs.Info
    }

    const invoiceTotal = computed(() => {
      return formData.value.LineItemList.reduce(function(total, currentValue) {
        return total + currentValue.Amount
      }, 0)
    })

    const maxSortOrder = computed(() => {
      return formData.value.LineItemList.length === 0
        ? 0
        : parseInt(
            formData.value.LineItemList.reduce(function(prev, current) {
              return current.SortOrder < 999 &&
                current.SortOrder > prev.SortOrder
                ? current
                : prev
            }).SortOrder
          )
    })

    const rules = {
      formData: {
        InvoiceNumber: { required },
        BusinessDate: { required },
        Description: { required },
        LineItemList: { required, minLength: minLength(1) }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    function errorMessages(item) {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const cancelClick = () => {
      if (updated.value) {
        context.emit('update')
      } else {
        context.emit('close')
      }
    }

    const lineItemListErrors = computed(() => {
      const errors = []
      vuelidate.value.formData.LineItemList.$dirty &&
        vuelidate.value.formData.LineItemList.required.$invalid &&
        errors.push('At least one invoice line is required.')
      return errors
    })

    const saveClick = async doNotExit => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        if (lineItemListErrors.value) {
          dialogUtil.value.error(lineItemListErrors.value.join('<br/>'))
        }
        return
      }

      if (!isSaving.value) {
        isSaving.value = true

        const rq = { Info: formData.value }
        const rs = await host.invoice.create(rq)
        if (rs.IsSuccess) {
          formData.value.InvoiceId = rs.InfoId
          updated.value = true
          if (!doNotExit) {
            context.emit('update', rs.InfoId)
          }
        } else {
          dialogUtil.value.inform({
            text: rs.Message,
            snackbarTimeout: 10000,
            color: 'error'
          })
        }
      }
    }

    const updateClick = async doNotExit => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }
      const rq = { Info: formData.value }
      const rs = await host.invoice.update(rq)
      if (rs.IsSuccess) {
        if (!doNotExit) {
          context.emit('update', formData.value.InvoiceId)
        }
      } else {
        dialogUtil.value.inform({
          text: rs.Message,
          snackbarTimeout: 10000,
          color: 'error'
        })
      }
    }

    const printClick = async () => {
      if (isEditMode.value) {
        await updateClick(true)
      } else {
        await saveClick(true)
      }

      await retrieveInvoice(formData.value.InvoiceId)

      ReportService.emitShowReportDialog({
        ReportId: reportInfo.report.invoice.reportId,
        ParameterList: [
          { Name: 'TransactionId', Value: formData.value.InvoiceId }
        ],
        ParameterHideList: ['TransactionId'],
        EmailInfo: {
          SendEmail: false,
          CustomerId: formData.value.CustomerId,
          CustomerName: formData.value.CustomerName,
          Recipient: formData.value.Email
        }
      })
    }

    const editLineItemClick = item => {
      selectedItemInfo.value = item
      isEditInvoiceLine.value = true
      showInvoiceLineDialog.value = true
    }

    const newLineItemClick = () => {
      selectedItemInfo.value = {
        LineId: null,
        TranCodeId: null,
        Quantity: 1,
        Rate: 0,
        Amount: 0,
        Reference: '',
        SortOrder: maxSortOrder.value + 1,
        InventoryItemId: null
      }
      isEditInvoiceLine.value = false
      showInvoiceLineDialog.value = true
    }

    const deleteLineItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete this invoice line item.`
        })
        .then(() => performDeleteLineItem(item))
        .catch(() => {
          return
        })
    }

    const performDeleteLineItem = item => {
      const index = formData.value.LineItemList.indexOf(item)
      formData.value.LineItemList.splice(index, 1)
    }

    const invoiceLineUpdate = event => {
      selectedItemInfo.value.SortOrder = event.SortOrder
      selectedItemInfo.value.TranCodeId = event.TranCodeId
      selectedItemInfo.value.Reference = event.Reference
      selectedItemInfo.value.Rate = parseFloat(event.Rate)
      selectedItemInfo.value.Quantity = parseFloat(event.Quantity)
      selectedItemInfo.value.Amount = parseFloat(event.Rate * event.Quantity)
      if (!isEditInvoiceLine.value) {
        formData.value.LineItemList.push(selectedItemInfo.value)
      }
      showInvoiceLineDialog.value = false
    }

    initDialog()

    return {
      dialogUtil,
      selectLists,
      headers,
      items_per_page,
      reportInfo,
      formData,
      invoiceTotal,
      maxSortOrder,
      isEditMode,
      showInvoiceLineDialog,
      isEditInvoiceLine,
      selectedItemInfo,
      saveClick,
      updateClick,
      cancelClick,
      editLineItemClick,
      newLineItemClick,
      deleteLineItemClick,
      invoiceLineUpdate,
      printClick,
      toMoney,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
