<template>
  <div class="d-flex flex-wrap">
    <v-card
      v-for="document in documentList"
      :key="document.DocumentId"
      class="ml-3 mb-3 pa-2 fluid"
      width="300"
    >
      <div class="d-flex">
        <v-btn icon class="mr-1" small @click="editClick(document.DocumentId)">
          <v-icon color="primary">{{ icon.documentEdit }}</v-icon>
        </v-btn>
        <div>
          <div class="caption">{{ document.FileName }}</div>
          <div class="caption">
            {{ toLocaleDateString(document.WhenTaken) }}
          </div>
        </div>
      </div>
      <a
        v-auth-href
        :href="createDocumentUrlFor(document.URI)"
        :download="document.FileName"
      >
        <v-icon large class="mt-2 ml-6">{{ icon.document }}</v-icon>
      </a>
      <span class="ml-2">{{ document.Description }}</span>
    </v-card>

    <v-card class="ml-3 mb-3 pa-2 fluid" width="300" height="80">
      <v-card-text>
        <v-btn rounded color="primary" @click="uploadClick">
          <v-icon>{{ icon.addNew }}</v-icon>
          Upload new document
        </v-btn>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showDialog" width="400">
      <DocumentEditor
        :isDialogOpen="showDialog"
        :documentId="selectedDocumentId"
        :customerId="customerId"
        :siteId="siteId"
        @close="documentEditorClose"
        @update="documentEditorUpdate"
      />
    </v-dialog>
  </div>
</template>
<script>
import { ref } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import FieldService from '@/services/FieldService'
import { toLocaleDateString } from '@/use/DateFormatter'
import DocumentEditor from '@/components/Common/DocumentEditor'
export default {
  name: 'DocumentList',
  props: {
    customerId: {
      type: String,
      default: null
    },
    siteId: {
      type: String,
      default: null
    }
  },
  components: {
    DocumentEditor
  },
  setup(props) {
    const initDialog = () => {
      fetchData()
    }

    const documentList = ref()
    const selectedDocumentId = ref()
    const showDialog = ref(false)

    const fetchData = async () => {
      const rq = {
        Criteria: {
          CustomerId: props.customerId,
          SiteId: props.siteId
        }
      }
      const rs = await host.document.list(rq)
      documentList.value = rs.InfoList
    }

    const createDocumentUrlFor = uri => {
      return `${
        FieldService.apiURL
      }${uri}&Tenant-Id=${FieldService.getTenantId()}`
    }

    const editClick = id => {
      selectedDocumentId.value = id
      showDialog.value = true
    }

    const documentEditorClose = () => {
      showDialog.value = false
    }

    const documentEditorUpdate = () => {
      showDialog.value = false
      fetchData()
    }

    const uploadClick = () => {
      selectedDocumentId.value = null
      showDialog.value = true
    }

    initDialog()

    return {
      documentList,
      toLocaleDateString,
      createDocumentUrlFor,
      editClick,
      uploadClick,
      showDialog,
      selectedDocumentId,
      documentEditorClose,
      documentEditorUpdate,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
