import ServiceRouteAssignments from '@/views/ServiceRoute/ServiceRouteAssignments'
import ServiceRouteOrganizer from '@/views/ServiceRoute/ServiceRouteOrganizer'
import ServiceRouteTracker from '@/views/ServiceRoute/ServiceRouteTracker'

export default [
  {
    path: '/ServiceRoute/RouteAssignments',
    name: 'serviceroute-assignments',
    component: ServiceRouteAssignments
  },
  {
    path: '/ServiceRoute/RouteOrganizer',
    name: 'serviceroute-organizer',
    component: ServiceRouteOrganizer
  },
  {
    path: '/ServiceRoute/RouteTracker',
    name: 'serviceroute-tracker',
    component: ServiceRouteTracker
  }
]
