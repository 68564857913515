<template>
  <div>
    <v-card class="indigo lighten-5">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">Subscription</DialogTitle>
      </v-card-title>
      <v-card-subtitle>
        <div v-if="formData.IsSubscribed">
          You are already subscribed.
        </div>
        <div v-else>Please provide the following information.</div>
      </v-card-subtitle>
      <v-card-text v-if="formData">
        <div class="d-flex">
          <div>
            <v-card width="320px">
              <v-card-subtitle>Contact Information</v-card-subtitle>
              <v-card-text>
                <v-text-field
                  v-model="formData.FirstName"
                  label="First Name"
                  :error-messages="errorMessages(vuelidate.formData.FirstName)"
                  @blur="vuelidate.formData.FirstName.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.LastName"
                  label="Last Name"
                  :error-messages="errorMessages(vuelidate.formData.LastName)"
                  @blur="vuelidate.formData.LastName.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.Street"
                  label="Address"
                  :error-messages="errorMessages(vuelidate.formData.Street)"
                  @blur="vuelidate.formData.Street.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.City"
                  label="City"
                  :error-messages="errorMessages(vuelidate.formData.City)"
                  @blur="vuelidate.formData.City.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.State"
                  label="State"
                  :error-messages="errorMessages(vuelidate.formData.State)"
                  @blur="vuelidate.formData.State.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.PostCode"
                  label="Postal Code"
                  :error-messages="errorMessages(vuelidate.formData.PostCode)"
                  @blur="vuelidate.formData.PostCode.$touch()"
                  dense
                />
              </v-card-text>
            </v-card>
          </div>
          <div>
            <v-card width="320" class="ml-4">
              <v-card-subtitle>Payment Information</v-card-subtitle>
              <v-card-text>
                <v-radio-group
                  v-model="formData.PaymentType"
                  dense
                  row
                  class="my-0"
                >
                  <v-radio label="Credit Card" value="C" />
                  <v-radio label="eCheck" value="B" />
                </v-radio-group>
                <div v-if="formData.PaymentType == 'C'">
                  <v-text-field
                    v-model="formData.CreditCardNumber"
                    label="Card Number"
                    :error-messages="
                      errorMessages(vuelidate.formData.CreditCardNumber)
                    "
                    @blur="vuelidate.formData.CreditCardNumber.$touch()"
                    dense
                  />
                  <div class="d-inline-flex">
                    <v-select
                      v-model="formData.ExpireMonth"
                      label="Expiration Month"
                      :items="monthSelectList"
                      item-text="Text"
                      item-value="Value"
                      :error-messages="
                        errorMessages(vuelidate.formData.ExpireMonth)
                      "
                      @blur="vuelidate.formData.ExpireMonth.$touch()"
                      clearable
                      dense
                    />
                    <v-spacer class="px-2" />
                    <v-select
                      v-model="formData.ExpireYear"
                      label="Expiration Year"
                      :items="yearSelectList"
                      item-text="Text"
                      item-value="Value"
                      :error-messages="
                        errorMessages(vuelidate.formData.ExpireYear)
                      "
                      @blur="vuelidate.formData.ExpireYear.$touch()"
                      clearable
                      dense
                    />
                  </div>
                  <v-text-field
                    v-model="formData.Cvv"
                    label="Cvv"
                    :error-messages="errorMessages(vuelidate.formData.Cvv)"
                    @blur="vuelidate.formData.Cvv.$touch()"
                    type="number"
                    dense
                  />
                </div>
                <div v-else>
                  <v-radio-group
                    v-model="formData.PaymentSubType"
                    dense
                    row
                    class="my-0"
                  >
                    <v-radio label="Checking" value="C" />
                    <v-radio label="Savings" value="S" />
                  </v-radio-group>
                  <v-text-field
                    v-model="formData.AccountNumber"
                    label="Account Number"
                    :error-messages="
                      errorMessages(vuelidate.formData.AccountNumber)
                    "
                    @blur="vuelidate.formData.AccountNumber.$touch()"
                    dense
                  />
                  <v-text-field
                    v-model="formData.RoutingNumber"
                    label="Routing Number"
                    type="number"
                    :error-messages="
                      errorMessages(vuelidate.formData.RoutingNumber)
                    "
                    @blur="vuelidate.formData.RoutingNumber.$touch()"
                    dense
                  />
                </div>
                <v-currency-field
                  v-model="formData.MonthlyCharge"
                  label="Payment Amount"
                  dense
                  :error-messages="
                    errorMessages(vuelidate.formData.MonthlyCharge)
                  "
                  @blur="vuelidate.formData.MonthlyCharge.$touch()"
                />
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card-text>
      <v-card-actions v-if="formData">
        <v-col>
          <v-btn color="success" @click="saveClick">
            {{ formData.IsSubscribed ? 'Update' : 'Subscribe' }}
          </v-btn>
          <v-btn color="error" @click="cancelClick" class="ml-2">Cancel</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn
            v-if="formData.IsSubscribed"
            color="primary"
            small
            @click="unsubscribeClick"
            >Unsubscribe
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { luhnCheck, cvvCheck } from '@/use/CustomValidators'
import {
  populateMonthSelectList,
  populateYearSelectList
} from '@/use/OnlinePaymentUtil'
import { officeAlertService } from '@/services/OfficeAlertService'

export default {
  name: 'SubscriptionEditor',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()
    const monthSelectList = ref([])
    const yearSelectList = ref([])

    const initDialog = async () => {
      const response = await retrieveSubscriptionInfo()
      formData.value = response.Info
      monthSelectList.value = populateMonthSelectList()
      yearSelectList.value = populateYearSelectList()
      vuelidate.value.$reset()
    }

    const retrieveSubscriptionInfo = async () => {
      const rq = {}
      const rs = await host.subscription.retrieve(rq)
      return rs
    }

    const subscribe = async info => {
      const rq = { Info: info }
      const rs = await host.subscription.subscribe(rq)

      officeAlertService.clearUnsubscribedStatus()

      return rs
    }

    const updateSubscription = async info => {
      const rq = { Info: info }
      const rs = await host.subscription.update(rq)
      return rs
    }

    const unsubscribe = async () => {
      const rq = {}
      const rs = await host.subscription.unsubscribe(rq)
      return rs
    }

    const saveClick = async () => {
      vuelidate.value.$reset()
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      const response = formData.value.IsSubscribed
        ? await updateSubscription(formData.value)
        : await subscribe(formData.value)

      if (response.IsSuccess) {
        dialogUtil.value.inform(response.Message)
        context.emit('update')
      } else {
        dialogUtil.value.error(response.Message)
      }
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const unsubscribeClick = () => {
      dialogUtil.value
        .confirm('Unsubscribe. Are you sure?')
        .then(() => doUnsubscribe())
        .catch(() => {
          return
        })
    }

    const doUnsubscribe = async () => {
      const response = await unsubscribe()

      if (response.IsSuccess) {
        dialogUtil.value.inform(response.Message)
        context.emit('update')
      } else {
        dialogUtil.value.error(response.Message)
      }
    }

    const rules = {
      formData: {
        FirstName: { required },
        LastName: { required },
        Street: { required },
        City: { required },
        State: { required },
        PostCode: { required },
        CreditCardNumber: {
          required: requiredIf(() => {
            return (
              formData.value.PaymentType === 'C' && !formData.value.IsSubscribed
            )
          }),
          luhnCheck
        },
        ExpireMonth: {
          required: requiredIf(() => {
            return formData.value.PaymentType === 'C'
          })
        },
        ExpireYear: {
          required: requiredIf(() => {
            return formData.value.PaymentType === 'C'
          })
        },
        Cvv: {
          cvvCheck
        },
        AccountNumber: {
          required: requiredIf(() => {
            return (
              formData.value.PaymentType === 'B' && !formData.value.IsSubscribed
            )
          })
        },
        RoutingNumber: {
          required: requiredIf(() => {
            return formData.value.PaymentType === 'B'
          })
        },
        MonthlyCharge: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    function errorMessages(item) {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    initDialog()
    return {
      dialogUtil,
      formData,
      saveClick,
      cancelClick,
      unsubscribeClick,
      monthSelectList,
      yearSelectList,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
