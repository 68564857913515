<template>
  <v-menu
    v-model="menu"
    :disabled="readonly"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="550px"
    min-width="550px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formattedValue"
        :label="label"
        :dense="dense"
        readonly
        v-on="on"
        :error-messages="errorMessages"
      >
        <template slot="append">
          <v-btn icon v-if="clearable && value" @click="clearButtonClick">
            <v-icon>{{ icon.clear }}</v-icon>
          </v-btn>
          <v-icon v-if="!readonly" @click="timeIconClick">
            {{ icon.time }}
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <div class="d-flex">
      <div>
        <v-date-picker
          v-model="date"
          ampm-in-title
          scrollable
          class="elevation-0"
        />
      </div>
      <div>
        <v-time-picker
          v-model="time"
          scrollable
          width="260"
          class="elevation-0"
        />
      </div>
    </div>
    <div class="white">
      <v-btn text color="primary" @click="saveButtonClick">Ok</v-btn>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="nowButtonClick">Now</v-btn>
    </div>
  </v-menu>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { icon } from '@/use/Constants'
import { parseISO, formatISO, format } from 'date-fns'
export default {
  name: 'DateTimePicker',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const menu = ref(false)
    const date = ref(null)
    const time = ref(null)

    watch(
      () => menu.value,
      () => {
        if (props.value == null) {
          setDateTimeNow()
        } else {
          date.value = datePartISO.value
          time.value = timePartISO.value.substring(0, 5)
        }
      }
    )

    const setDateTimeNow = () => {
      const newValue = formatISO(new Date()).substring(0, 19)
      date.value = newValue.substring(0, 10)
      time.value = newValue.substring(11, 16)
    }

    const formattedValue = computed(() => {
      return [formattedDate.value, formattedTime.value].join(' ')
    })

    const formattedDate = computed(() => {
      return props.value == null
        ? null
        : format(parseISO(props.value), 'MMMM d, yyyy')
    })

    const formattedTime = computed(() => {
      if (
        props.value == null ||
        timePartISO.value.substring(0, 5) === '00:00'
      ) {
        return null
      } else {
        return format(parseISO(props.value), 'h:mm a')
      }
    })

    const datePartISO = computed(() => {
      const timeStart = props.value == null ? null : props.value.indexOf('T')
      return props.value == null
        ? null
        : props.value.substring(0, timeStart < 0 ? 10 : timeStart)
    })

    const timePartISO = computed(() => {
      const timeStart = props.value == null ? null : props.value.indexOf('T')
      return props.value == null
        ? null
        : props.value.substring(timeStart + 1, timeStart + 9)
    })

    const saveButtonClick = () => {
      const newValue = date.value + 'T' + time.value + ':00'
      context.emit('input', newValue)
      menu.value = false
    }

    const nowButtonClick = () => {
      setDateTimeNow()
      const newValue = date.value + 'T' + time.value + ':00'
      context.emit('input', newValue)
      menu.value = false
    }

    const clearButtonClick = () => {
      const newValue = null
      context.emit('input', newValue)
    }

    const timeIconClick = () => {
      menu.value = true
    }

    return {
      menu,
      date,
      time,
      formattedValue,
      saveButtonClick,
      nowButtonClick,
      clearButtonClick,
      timeIconClick,
      icon
    }
  }
}
</script>
<style lang="scss">
.v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  height: 55px;
  font-size: 50px;
}
</style>
