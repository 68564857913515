<template>
  <div>
    <v-card width="900">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} TranCode Group
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData">
        <div class="d-flex">
          <v-card class="mt-3" width="400">
            <v-card-text>
              <v-text-field
                v-model="formData.Description"
                label="Description"
                :error-messages="errorMessages(vuelidate.formData.Description)"
                @blur="vuelidate.formData.Description.$touch()"
                dense
              />
              <v-checkbox
                v-model="formData.BillAsInvoice"
                label="Bill as Invoice"
                dense
              />
              <v-checkbox
                v-model="formData.IsAutoBill"
                label="Auto Bill"
                dense
              />
              <v-checkbox v-model="formData.IsDeleted" label="Deleted" dense />
            </v-card-text>
          </v-card>

          <div v-if="formData.IsAutoBill" class="ml-4 mt-2">
            <RecurringRulesPicker
              v-model="formData.FrequencyInfo"
              title="Auto Bill Recurring Rules"
              noEndDate
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions
        ><v-btn color="success" class="ml-1" @click="saveClick">{{
          isEditMode ? 'Update' : 'Save'
        }}</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import RecurringRulesPicker from '@/components/controls/RecurringRulesPicker'
export default {
  name: 'BillingGroupEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RecurringRulesPicker
  },
  setup(props, context) {
    const dialogUtil = ref()

    const isEditMode = ref(false)
    const formData = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      isEditMode.value = props.infoId != null
      if (isEditMode.value) {
        loadBillingGroup()
      } else {
        initNewBillingGroup()
      }
      vuelidate.value.$reset()
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.inform({
          text: result.Message,
          snackbarTimeout: 10000,
          color: 'error'
        })
      }
    }

    const rules = {
      formData: {
        Description: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const loadBillingGroup = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.billingGroup.retrieve(rq)
      formData.value = rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.billingGroup.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.billingGroup.update(rq)
      return rs
    }

    const initNewBillingGroup = () => {
      formData.value = {
        Id: null,
        Code: null,
        Description: null,
        SortOrder: 0,
        IsDeleted: false
      }
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      errorMessages,
      saveClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
