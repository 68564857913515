<template>
  <div>
    <div v-if="selectLists">
      <PageTitle
        featureName="commissions"
        functionName="Search"
        :pageName="$options.name"
      />
      <v-card class="ma-2" max-width="850">
        <v-card-title>
          <div class="text-subtitle-1 blue--text">
            Search Criteria
          </div>
        </v-card-title>
        <v-card-text v-if="selectLists">
          <div class="d-flex align-content-space-around flex-wrap">
            <v-card width="400px" class="mt-2 mr-2">
              <v-card-text>
                <v-radio-group
                  v-model="searchCriteria.DateType"
                  label="Date Type:"
                  dense
                  row
                  class="my-0"
                  @change="refreshSearch"
                >
                  <v-radio label="When Due" value="WhenDue" />
                  <v-radio label="When Created" value="WhenCreated" />
                </v-radio-group>
                <DatePicker
                  v-model="searchCriteria.DateFirst"
                  label="First Date"
                  @change="refreshSearch"
                  clearable
                  dense
                />
                <DatePicker
                  v-model="searchCriteria.DateLast"
                  label="Last Date"
                  @change="refreshSearch"
                  clearable
                  dense
                />
                <UserPicker
                  v-model="searchCriteria.UserId"
                  label="Commission For User"
                  clearable
                  @change="refreshSearch"
                />
              </v-card-text>
            </v-card>
            <v-card width="400px" class="mt-2 mr-2">
              <v-card-text>
                <v-select
                  v-model="searchCriteria.CommissionTypeId"
                  :items="selectLists.CommissionTypes"
                  item-text="Text"
                  item-value="Value"
                  label="Commission Type"
                  clearable
                  dense
                  @change="refreshSearch"
                />
                <CustomerPicker
                  v-model="searchCriteria.CustomerId"
                  v-bind:text.sync="syncCustomerName"
                  label="Customer"
                  clearable
                  dense
                  @input="refreshSearch"
                />
                <ServiceTypePicker
                  v-model="searchCriteria.ServiceTypeId"
                  clearable
                  @change="refreshSearch"
                />
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mx-2" max-width="1000">
        <v-card-title>
          <div class="text-subtitle-1 blue--text">
            Search Results
          </div>
        </v-card-title>
        <v-data-table
          :items-per-page="10"
          :headers="headers"
          :items="dataTable.LineItemList"
          :options.sync="options"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length="dataTable.TotalRecords"
          :loading="loading"
          class="elevation-1"
          dense
        >
          <template v-slot:item.WhenDue="{ item }">
            <span>{{ toLocaleDateString(item.WhenDue) }}</span>
          </template>
          <template v-slot:item.Amount="{ item }">
            <span>{{ toMoney(item.Amount) }}</span>
          </template>
          <template v-slot:item.UserName="{ item }">
            <v-icon :color="userColor(item.UserId)">
              {{ icon.user }}
            </v-icon>
            <span class="ml-1">{{ item.UserName }}</span>
          </template>
          <template v-slot:item.ServiceType="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-if="item.ServiceTypeId"
                  @click="serviceClick(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon button :color="feature.services.color">
                    {{ serviceTypeIcon(item.ServiceTypeId) }}
                  </v-icon>
                  <span class="ml-1">{{ item.ServiceType }}</span>
                </div>
              </template>
              <span>View / edit service</span>
            </v-tooltip>
          </template>

          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newCommissionClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New Commission</span>
          </v-tooltip>
        </div>
      </v-card>
    </div>

    <v-dialog v-model="showCommissionDialog" width="400" persistent>
      <Commission
        :isDialogOpen="showCommissionDialog"
        :commissionId="selectedCommissionId"
        @close="hideCommissionDialog(false)"
        @update="hideCommissionDialog(true)"
      ></Commission>
    </v-dialog>

    <v-dialog
      v-model="showScheduledServiceEditorDialog"
      :width="feature.services.dialogWidth"
      persistent
    >
      <ScheduledServicesEditor
        :isDialogOpen="showScheduledServiceEditorDialog"
        :scheduledServiceId="selectedScheduledServiceId"
        @close="scheduledServiceEditorCloseEvent"
        @update="scheduledServiceEditorUpdateEvent"
        @editRecurringService="editRecurringServiceEvent"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="showRecurringServiceEditorDialog"
      :width="feature.recurringServices.dialogWidth"
    >
      <RecurringServiceEditor
        :recurringServiceId="selectedRecurringServiceId"
        :isDialogOpen="showRecurringServiceEditorDialog"
        @update="recurringServiceEditorUpdateEvent"
        @close="recurringServiceEditorCloseEvent"
      />
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { toLocaleDateString, toLocaleISOString } from '@/use/DateFormatter'
import { toMoney } from '@/use/NumberFormatter'
import { feature, icon } from '@/use/Constants'
import Commission from '@/components/Commission/Commission'
import CustomerPicker from '@/components/Customer/CustomerPicker'
import RecurringServiceEditor from '@/components/ScheduledService/RecurringServiceEditor'
import ScheduledServicesEditor from '@/components/ScheduledService/ScheduledServiceEditor'
export default {
  name: 'ScheduledTaskList',
  components: {
    CustomerPicker,
    Commission,
    RecurringServiceEditor,
    ScheduledServicesEditor
  },
  props: {
    customerId: {
      type: String,
      default: null
    },
    customerName: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const dialogUtil = ref()

    const initDialog = () => {
      loadSelectLists()
      searchCriteria.value.DateFirst = toLocaleISOString(new Date()).substring(
        0,
        10
      )
      searchCriteria.value.DateLast = toLocaleISOString(new Date()).substring(
        0,
        10
      )
      searchCriteria.value.CustomerId = props.customerId
      syncCustomerName.value = props.customerName
    }

    const loading = ref(true)
    const options = ref({})
    const sortBy = ref('WhenDue')
    const sortDesc = ref(false)

    const headers = [
      { value: 'WhenDue', text: 'Due' },
      { value: 'CommissionTypeCode', text: 'Type' },
      { value: 'UserName', text: 'For User' },
      { value: 'CustomerName', text: 'Customer' },
      { value: 'ServiceType', text: 'Service' },
      { value: 'Amount', text: 'Amount', align: 'right' },
      { value: 'Actions', text: 'Actions' }
    ]

    const searchCriteria = ref({
      DateType: 'WhenDue',
      DateFirst: null,
      DateLast: null,
      CommissionTypeId: null,
      CustomerId: null,
      UserId: null,
      ServiceTypeId: null
    })

    const syncCustomerName = ref()
    const selectLists = ref([])
    const dataTable = ref({})
    const selectedCommissionId = ref()
    const showCommissionDialog = ref(false)

    watch(
      () => options.value,
      () => {
        getDataFromApi().catch(e => alert('error: ' + e.message))
      }
    )

    const getDataFromApi = async () => {
      loading.value = true
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      const rq = {
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy.length > 0 ? sortBy[0] : 'Date',
          IsDescending: sortDesc.length > 0 ? sortDesc[0] : false
        },
        ...searchCriteria.value
      }
      const rs = await host.commission.loadDataTable(rq)
      dataTable.value = rs
      loading.value = false
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.Users,
        selectListCache.name.CommissionTypes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const refreshSearch = () => {
      getDataFromApi()
    }

    const editItemClick = item => {
      selectedCommissionId.value = item.CommissionId
      showCommissionDialog.value = true
    }

    const deleteItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Commission to ${item.UserName} for ${toMoney(
            item.Amount
          )}`
        })
        .then(() => performDeleteCommission(item))
        .catch(() => {
          return
        })
    }

    const performDeleteCommission = async item => {
      const rq = { CommissionId: item.CommissionId }
      const rs = await host.commission.delete(rq)
      if (rs.IsSuccess) {
        refreshSearch()
      }
    }

    const newCommissionClick = () => {
      selectedCommissionId.value = null
      showCommissionDialog.value = true
    }

    const hideCommissionDialog = reload => {
      showCommissionDialog.value = false
      if (reload) {
        refreshSearch()
      }
    }

    const userColor = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Color'
      )
    }

    const serviceTypeIcon = value => {
      return selectListCache.attribute(
        selectListCache.name.ServiceTypes,
        value,
        'IconName'
      )
    }

    const showScheduledServiceEditorDialog = ref(false)
    const selectedScheduledServiceId = ref()

    const serviceClick = item => {
      selectedScheduledServiceId.value = item.ScheduledServiceId
      showScheduledServiceEditorDialog.value = true
    }

    const scheduledServiceEditorCloseEvent = () => {
      showScheduledServiceEditorDialog.value = false
    }

    const scheduledServiceEditorUpdateEvent = () => {
      showScheduledServiceEditorDialog.value = false
      refreshSearch()
    }

    const editRecurringServiceEvent = id => {
      selectedRecurringServiceId.value = id
      showRecurringServiceEditorDialog.value = true
    }

    const showRecurringServiceEditorDialog = ref(false)
    const selectedRecurringServiceId = ref()

    const recurringServiceEditorCloseEvent = () => {
      showRecurringServiceEditorDialog.value = false
    }

    const recurringServiceEditorUpdateEvent = () => {
      showRecurringServiceEditorDialog.value = false
      refreshSearch()
    }

    initDialog()

    return {
      dialogUtil,
      loading,
      options,
      sortBy,
      sortDesc,
      headers,
      searchCriteria,
      feature,
      icon,
      syncCustomerName,
      selectLists,
      dataTable,
      selectedCommissionId,
      showCommissionDialog,
      refreshSearch,
      editItemClick,
      deleteItemClick,
      newCommissionClick,
      hideCommissionDialog,
      toMoney,
      toLocaleDateString,
      userColor,
      serviceTypeIcon,
      serviceClick,
      showScheduledServiceEditorDialog,
      selectedScheduledServiceId,
      scheduledServiceEditorCloseEvent,
      scheduledServiceEditorUpdateEvent,
      editRecurringServiceEvent,
      showRecurringServiceEditorDialog,
      selectedRecurringServiceId,
      recurringServiceEditorCloseEvent,
      recurringServiceEditorUpdateEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
