<template>
  <div>
    <v-card width="875">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Route
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData">
        <div class="d-flex">
          <div>
            <v-card width="400" class="mt-2">
              <v-card-text>
                <v-text-field
                  v-model="formData.Code"
                  label="Code"
                  :error-messages="errorMessages(vuelidate.formData.Code)"
                  @blur="vuelidate.formData.Code.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.Description"
                  label="Description"
                  :error-messages="
                    errorMessages(vuelidate.formData.Description)
                  "
                  @blur="vuelidate.formData.Description.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.SortOrder"
                  label="SortOrder"
                  type="number"
                  dense
                />
                <ServiceTypePicker
                  v-model="formData.ServiceTypeId"
                  :error-messages="
                    errorMessages(vuelidate.formData.ServiceTypeId)
                  "
                  @blur="vuelidate.formData.ServiceTypeId.$touch()"
                  dense
                />
                <ServiceSeasonPicker
                  v-model="formData.ScheduledServiceSeasonId"
                  label="Season"
                  dense
                  clearable
                />
                <UserPicker v-model="formData.UserId" label="Assigned To" />
                <ColorPicker v-model="formData.Color" label="Color" dense />
              </v-card-text>
              <v-card-actions
                ><v-btn color="success" class="ml-1" @click="saveClick">
                  {{ isEditMode ? 'Update' : 'Save' }}
                </v-btn>
                <v-btn color="error" class="ml-3" @click="cancelClick">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
          <div class="ml-4 mt-2">
            <RecurringRulesPicker
              v-model="formData.FrequencyInfo"
              @updateDescription="updateDescription"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import RecurringRulesPicker from '@/components/controls/RecurringRulesPicker'
import ServiceSeasonPicker from '@/components/controls/ServiceSeasonPicker'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { selectListCache } from '@/services/SelectListCache'
export default {
  name: 'ServiceRouteEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { RecurringRulesPicker, ServiceSeasonPicker },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const formData = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      formData.value = isEditMode.value
        ? await retrieve(props.infoId)
        : await newTemplate()
      vuelidate.value.$reset()
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        selectListCache.invalidate(selectListCache.name.ServiceRoutes)
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const rules = {
      formData: {
        Code: { required },
        Description: { required },
        ServiceTypeId: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const retrieve = async id => {
      const rq = { InfoId: id }
      const rs = await host.serviceRoute.retrieve(rq)
      return rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.serviceRoute.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.serviceRoute.update(rq)
      return rs
    }

    const newTemplate = async () => {
      const rq = {}
      const rs = await host.serviceRoute.newTemplate(rq)
      return rs.Info
    }

    const updateDescription = event => {
      formData.value.Description = event
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      errorMessages,
      saveClick,
      cancelClick,
      updateDescription,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
