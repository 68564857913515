<template>
  <div v-if="cardData">
    <v-card width="400px" class="pt-2 pl-3 ma-2 indigo lighten-5">
      <v-card-title>
        <span>{{ cardData.Name }}</span>
        <v-spacer />
        <CustomerEditButton
          v-if="readonly === false"
          :customerId="cardData.CustomerId"
          @update="customerEditorUpdateEvent"
          @delete="customerEditorDeleteEvent"
        />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="addSiteClick">
              <v-icon
                v-if="readonly === false"
                :color="feature.sites.color"
                v-bind="attrs"
                v-on="on"
              >
                {{ feature.sites.iconAdd }}
              </v-icon>
            </v-btn>
          </template>
          <span>Add Site</span>
        </v-tooltip>
        <SyncInfoButton :recordId="cardData.CustomerId" />
      </v-card-title>
      <v-card-subtitle :class="statusClass">
        <div>
          <span>{{ cardData.StatusDescription }}</span>
          <span class="float-right black--text mr-2">{{ cardData.Code }}</span>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <div v-if="cardData.AlertType">
          <span>Alert: </span
          ><span class="red--text font-weight-bold"
            >{{ cardData.AlertType }} / {{ cardData.AlertText }}</span
          >
        </div>
        <AddressCard
          :name="cardData.Address.Name"
          :street="cardData.Address.Street"
          :city="cardData.Address.City"
          :state="cardData.Address.State"
          :postCode="cardData.Address.PostCode"
        />
        <div v-if="cardData.StartupInfo" class="my-2">
          {{ cardData.StartupInfo }}
        </div>
        <div v-if="cardData.TerminationInfo" class="my-2">
          {{ cardData.TerminationInfo }}
        </div>
        <v-card v-if="cardData.PhoneList.length > 0">
          <v-card-text>
            <div v-for="phone in cardData.PhoneList" v-bind:key="phone.Id">
              {{ phone.PhoneNumberType }} : {{ phone.FormattedPhone }}
            </div>
          </v-card-text>
        </v-card>
        <BillingSummaryCard
          :cardData="cardData"
          :readonly="readonly"
          @update="billingSummaryCardUpdateEvent"
        />
        <OnlinePaymentCard
          :cardData="cardData"
          :readonly="readonly"
          @update="onlinePaymentCardUpdateEvent"
        />
        <ScheduledTaskSummaryCard :cardData="cardData" :readonly="readonly" />
        <v-card v-if="readonly === false" class="my-2">
          <v-card-text class="d-flex justify-space-between">
            <div>
              <div>Active Estimates: {{ cardData.QtyActiveEstimates }}</div>
            </div>
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="newEstimateClick"
                  >
                    <v-icon :color="feature.estimates.color">
                      {{ feature.estimates.iconNew }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  Create new Estimate
                </span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="estimateSearchClick"
                    class="pr-2"
                  >
                    <v-icon :color="feature.estimates.color">
                      {{ feature.estimates.icon }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  See Estimate list
                </span>
              </v-tooltip>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showEstimateDialog" width="1000" persistent>
      <Estimate
        :customerId="cardData.CustomerId"
        :instanceValue="instanceValue"
        @close="hideEstimateDialog(false)"
        @update="hideEstimateDialog(true)"
      />
    </v-dialog>

    <v-dialog
      v-model="showSiteEditorDialog"
      :width="feature.sites.dialogWidth"
      persistent
    >
      <SiteEditor
        :customerId="cardData.CustomerId"
        :isDialogOpen="showSiteEditorDialog"
        @close="siteEditorClose"
        @update="siteEditorUpdate"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { feature } from '@/use/Constants'
import AddressCard from '@/components/Common/AddressCard'
import BillingSummaryCard from '@/components/Billing/BillingSummaryCard'
import OnlinePaymentCard from '@/components/OnlinePayment/OnlinePaymentCard'
import ScheduledTaskSummaryCard from '@/components/ScheduledTask/ScheduledTaskSummaryCard'
import CustomerEditButton from '@/components/Customer/CustomerEditButton'
import Estimate from '@/components/Estimate/Estimate'
import SiteEditor from '@/components/Site/SiteEditor'
import SyncInfoButton from '@/components/Common/SyncInfoButton'

export default {
  name: 'CustomerCard',
  components: {
    AddressCard,
    BillingSummaryCard,
    CustomerEditButton,
    Estimate,
    OnlinePaymentCard,
    ScheduledTaskSummaryCard,
    SiteEditor,
    SyncInfoButton
  },
  props: {
    cardData: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const router = useRouter()
    const showEstimateDialog = ref(false)
    const showSiteEditorDialog = ref(false)

    const instanceValue = ref(0)

    const statusClass = computed(() => {
      return feature.customers.statusColors[props.cardData.Status]
    })

    const customerEditorUpdateEvent = () => {
      context.emit('update')
    }

    const customerEditorDeleteEvent = () => {
      context.emit('delete')
    }

    const estimateSearchClick = () => {
      router.push({
        name: feature.estimates.routerName,
        params: {
          customerId: props.cardData.CustomerId,
          customerName: props.cardData.Name
        }
      })
    }

    const newEstimateClick = () => {
      instanceValue.value++
      showEstimateDialog.value = true
    }

    const hideEstimateDialog = reload => {
      showEstimateDialog.value = false
      if (reload) {
        context.emit('reload')
      }
    }

    const addSiteClick = () => {
      showSiteEditorDialog.value = true
    }

    const siteEditorClose = () => {
      showSiteEditorDialog.value = false
    }

    const siteEditorUpdate = () => {
      showSiteEditorDialog.value = false
      context.emit('update')
    }

    const billingSummaryCardUpdateEvent = () => {
      context.emit('update')
    }

    const onlinePaymentCardUpdateEvent = () => {
      context.emit('update')
    }

    return {
      feature,
      statusClass,
      customerEditorUpdateEvent,
      customerEditorDeleteEvent,
      showEstimateDialog,
      estimateSearchClick,
      newEstimateClick,
      hideEstimateDialog,
      instanceValue,
      addSiteClick,
      showSiteEditorDialog,
      siteEditorClose,
      siteEditorUpdate,
      billingSummaryCardUpdateEvent,
      onlinePaymentCardUpdateEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
