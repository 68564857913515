<template>
  <div>
    <div>
      <PageTitle
        featureName="routes"
        functionName="Track"
        :pageName="$options.name"
      />
      <div class="d-flex">
        <v-card width="250" height="840" class="ml-2 mt-2">
          <v-switch class="my-0" v-model="limitByUser" label="Track User" />
          <v-card-text>
            <div v-if="limitByUser">
              <UserPicker v-model="userId" label="User to track" dense />
              <DatePicker v-model="trackDate" label="Track Date" dense />
            </div>
          </v-card-text>
        </v-card>
        <div ref="mapOrganizerCanvas" class="flex-grow-1">
          Map goes here
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { feature } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { mapService } from '@/services/GoogleMapService'
import { todayISOString } from '@/use/DateFormatter'
export default {
  name: 'ServiceRouteOrganizer',
  components: {},
  setup() {
    const mapOrganizerCanvas = ref()
    const userId = ref()
    const trackDate = ref(todayISOString())

    const trackList = ref([])
    const limitByUser = ref(false)

    const selectedtrackListIndex = ref(-1)

    const isAfterInit = ref(false)

    const getTrackForCurrentState = async () => {
      const baseDate = trackDate.value.substring(0, 10)
      const rq = {
        Criteria: {
          StartDate: baseDate + 'T00:00:00',
          EndDate: baseDate + 'T23:59:59'
        }
      }
      const rs = await host.geoLocation.trackCurrentState(rq)
      return rs
    }

    const getTrackForUser = async () => {
      const baseDate = trackDate.value.substring(0, 10)
      const rq = {
        Criteria: {
          UserId: userId.value,
          StartDate: baseDate + 'T00:00:00',
          EndDate: baseDate + 'T23:59:59'
        }
      }
      const rs = await host.geoLocation.trackUser(rq)
      return rs
    }

    const mapMarkerCallback = index => {
      selectedtrackListIndex.value = index
      const selectedInfo = trackList.value[index]
      console.log({
        loc: 'ServiceRouteTracker/mapMarkerCallback',
        index: index,
        selectedInfo: selectedInfo
      })
    }

    onMounted(async () => {
      await updateMap()
      isAfterInit.value = true
    })

    watch(
      () => limitByUser.value,
      async () => {
        if (isAfterInit.value) {
          await updateMap()
        }
      }
    )

    watch(
      () => userId.value,
      async () => {
        if (isAfterInit.value) {
          await updateMap()
        }
      }
    )

    watch(
      () => trackDate.value,
      async () => {
        if (isAfterInit.value) {
          await updateMap()
        }
      }
    )

    const updateMap = async () => {
      if (limitByUser.value === true && userId.value === null) {
        return
      }

      console.log({
        loc: 'ServiceRouteTracker/updateMap',
        limitByUser: limitByUser.value
      })

      trackList.value = limitByUser.value
        ? (await getTrackForUser()).InfoList
        : (await getTrackForCurrentState()).InfoList

      const args = {
        canvas: mapOrganizerCanvas.value,
        trackList: trackList.value,
        callback: mapMarkerCallback
      }

      console.log({
        loc: 'ServiceRouteTracker/updateMap/call map service',
        args: args
      })

      mapService.showTracker(args)
    }

    return {
      feature,
      limitByUser,
      userId,
      trackDate,
      mapOrganizerCanvas,
      trackList,
      selectedtrackListIndex
    }
  }
}
</script>

<style lang="scss" scoped></style>
