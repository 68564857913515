<template>
  <div>
    <v-card width="480">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Sales Tax Group
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData">
        <v-text-field
          v-model="formData.Code"
          label="Code"
          :error-messages="errorMessages(vuelidate.formData.Code)"
          @blur="vuelidate.formData.Code.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.Description"
          label="Description"
          :error-messages="errorMessages(vuelidate.formData.Description)"
          @blur="vuelidate.formData.Description.$touch()"
          dense
        />
        <v-checkbox v-model="formData.IsDeleted" label="Deleted" />
        <div class="caption">Sales Taxes Included in this Group</div>
        <v-checkbox
          v-for="salesTax in selectedSalesTaxList"
          :key="salesTax.SalesTaxId"
          v-model="salesTax.IsSelected"
          :label="salesTax.Code"
        />
      </v-card-text>
      <v-card-actions
        ><v-btn color="success" class="ml-1" @click="saveClick">{{
          isEditMode ? 'Update' : 'Save'
        }}</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'SalesTaxGroupEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    salesTaxList: {
      type: Array,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref()

    const isEditMode = ref(false)
    const formData = ref()
    const selectedSalesTaxList = ref([])

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      formData.value = isEditMode.value
        ? await loadSalesTaxGroup()
        : await initNewSalesTaxGroup()

      selectedSalesTaxList.value = props.salesTaxList.map(st => {
        return {
          SalesTaxId: st.SalesTaxId,
          Code: st.Code,
          IsSelected: formData.value.SalesTaxIdList.includes(st.SalesTaxId)
        }
      })
      vuelidate.value.$reset()
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      formData.value.SalesTaxIdList = selectedSalesTaxList.value
        .filter(st => st.IsSelected)
        .map(st => st.SalesTaxId)

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const rules = {
      formData: {
        Code: { required },
        Description: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const loadSalesTaxGroup = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.salesTaxGroup.retrieve(rq)
      return rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.salesTaxGroup.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.salesTaxGroup.update(rq)
      return rs
    }

    const initNewSalesTaxGroup = async () => {
      const rq = {}
      const rs = await host.salesTaxGroup.newTemplate(rq)
      return rs.Info
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      selectedSalesTaxList,
      errorMessages,
      saveClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
