<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Customers using Rate {{ rateCode }}
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="infoList"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.Actions="{ item }">
            <CustomerEditButton
              small
              :customerId="item.CustomerId"
              @update="customerEditorUpdateEvent"
              @delete="customerEditorDeleteEvent"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import CustomerEditButton from '@/components/Customer/CustomerEditButton'
export default {
  name: 'RateCustomerList',
  props: {
    rateId: {
      type: String,
      required: true
    },
    rateCode: {
      type: String,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { CustomerEditButton },
  setup(props) {
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      infoList.value = []
      getCustomerList()
    }

    const infoList = ref([])

    const getCustomerList = async () => {
      loading.value = true
      const rq = { Criteria: { ParentInfoId: props.rateId } }
      const rs = await host.rate.customerList(rq)
      infoList.value = rs.InfoList
      loading.value = false
    }

    const headers = [
      { value: 'CustomerCode', text: 'Code' },
      { value: 'CustomerName', text: 'Customer Name' },
      { value: 'Status', text: 'Status', align: 'center' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const customerEditorUpdateEvent = () => {
      getCustomerList()
    }

    const customerEditorDeleteEvent = () => {
      getCustomerList()
    }

    initDialog()

    return {
      infoList,
      headers,
      customerEditorUpdateEvent,
      customerEditorDeleteEvent,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
