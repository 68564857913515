import { SetupMenuItem } from '@/components/layout/SetupMenuItem'
import { feature } from '@/use/Constants'
export default {
  customer: new SetupMenuItem('Customer', {
    icon: feature.customers.icon,
    color: feature.customers.color,
    dialogWidth: feature.customers.dialogWidth
  }),
  scheduledService: new SetupMenuItem('Service', {
    icon: feature.services.icon,
    color: feature.services.color,
    dialogWidth: feature.services.dialogWidth
  }),
  recurringService: new SetupMenuItem('Recurring Service', {
    icon: feature.recurringServices.icon,
    color: feature.recurringServices.color,
    dialogWidth: feature.recurringServices.dialogWidth
  }),
  scheduledTask: new SetupMenuItem('Task', {
    icon: feature.tasks.icon,
    color: feature.tasks.color,
    dialogWidth: feature.tasks.dialogWidth
  })
}
