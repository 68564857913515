<template>
  <div>
    <PageTitle
      featureName="billing"
      functionName="Home"
      :pageName="$options.name"
    />
    <v-card class="mt-2 ml-2" max-width="860">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          Billing History
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :items-per-page="15"
          :footer-props="{
            'items-per-page-options': items_per_page,
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="dataTable.LineItemList"
          :options.sync="options"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length="dataTable.TotalRecords"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.WhenCreated="{ item }">
            <span>{{ toLocaleDateTimeString(item.WhenCreated) }}</span>
          </template>
          <template v-slot:item.BusinessDate="{ item }">
            <span>{{ toLocaleDateString(item.BusinessDate) }}</span>
          </template>
          <template v-slot:item.CreatedBy="{ item }">
            <v-icon :color="userColor(item.UserId)">
              {{ icon.user }}
            </v-icon>
            <span class="ml-1">{{ item.CreatedBy }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  class="mr-2"
                  @click="transactionBatchReport(item)"
                  :color="feature.reports.color"
                >
                  {{ icon.report }}
                </v-icon>
              </template>
              <span>{{ report.transactionBatch.title }}</span>
            </v-tooltip>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="showBatchBillingDialog = true">
          Post Batch Billing
        </v-btn>
        <v-btn color="primary" @click="postLateFeesClick">Post Late Fees</v-btn>
        <v-btn color="primary" @click="statementsReport">
          Print Statements
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showBatchBillingDialog" max-width="400" persistent>
      <PostBillingBatch
        :isDialogOpen="showBatchBillingDialog"
        @close="hideBatchBillingDialog(false)"
        @update="hideBatchBillingDialog(true)"
      ></PostBillingBatch>
    </v-dialog>

    <v-dialog v-model="showPostLateFeesDialog" max-width="450" persistent>
      <PostLateFees
        :isDialogOpen="showPostLateFeesDialog"
        @close="hidePostLateFeesDialog(false)"
        @update="hidePostLateFeesDialog(true)"
      ></PostLateFees>
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { feature, icon, report } from '@/use/Constants'
import PostBillingBatch from '@/components/Billing/PostBillingBatch'
import PostLateFees from '@/components/Billing/PostLateFees'
import ReportService from '@/services/ReportService'
import reports from '@/constants/reports'
import { selectListCache } from '@/services/SelectListCache'
import { toLocaleDateString, toLocaleDateTimeString } from '@/use/DateFormatter'

export default {
  name: 'BillingHome',
  components: {
    PostBillingBatch,
    PostLateFees
  },
  setup() {
    const dialogUtil = ref()

    const loading = ref(true)
    const options = ref({})
    const sortBy = ref('WhenCreated')
    const sortDesc = ref(true)
    const dataTable = ref({})
    const items_per_page = [10, 15, 20, 50]
    const firstTime = ref(true)
    const showBatchBillingDialog = ref(false)
    const showPostLateFeesDialog = ref(false)
    const transactionBatchId = ref()
    const selectLists = ref()

    const headers = [
      { value: 'WhenCreated', text: 'When Posted' },
      { value: 'BusinessDate', text: 'Business Date' },
      { value: 'CreatedBy', text: 'Posted By' },
      { value: 'Reference', text: 'Reference' },
      { value: 'actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const initForm = () => {
      loadSelectLists()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.Users]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    watch(
      () => options.value,
      () => {
        loadBatchList().catch(e => alert('error: ' + e.message))
      }
    )

    const loadBatchList = async () => {
      loading.value = true
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      const rq = {
        LastPage: firstTime.value,
        BatchTypeList: ['I', 'L'],
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy.length > 0 ? sortBy[0] : 'Date',
          IsDescending: sortDesc.length > 0 ? sortDesc[0] : false
        }
      }
      const rs = await host.billing.loadTransactionBatchesDataTable(rq)
      dataTable.value = rs
      if (firstTime.value) {
        firstTime.value = false
      }
      loading.value = false
    }

    const editItemClick = item => {
      transactionBatchId.value = item.TransactionBatchId
    }

    const deleteItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text:
            'Delete all transactions posted in this payment batch. Are you sure?',
          acceptText: 'Delete'
        })
        .then(() => performDeleteItem(item))
        .catch(() => {
          return
        })
    }

    const performDeleteItem = async item => {
      const rq = { InfoId: item.TransactionBatchId }
      const rs = await host.billing.deleteTransactionBatch(rq)
      if (rs.IsSuccess === true) {
        loadBatchList()
      }
    }

    const hideBatchBillingDialog = refresh => {
      showBatchBillingDialog.value = false
      if (refresh) {
        loadBatchList()
      }
    }

    const hidePostLateFeesDialog = refresh => {
      showPostLateFeesDialog.value = false
      if (refresh) {
        loadBatchList()
      }
    }

    const postLateFeesClick = () => {
      showPostLateFeesDialog.value = true
    }

    const transactionBatchReport = item => {
      ReportService.emitShowReportDialog({
        ReportId: reports.report.transactionBatch.reportId,
        ParameterList: [
          { Name: 'TransactionBatchId', Value: item.TransactionBatchId }
        ],
        ParameterHideList: ['TransactionBatchId']
      })
    }

    const statementsReport = () => {
      ReportService.emitShowReportDialog({
        ReportId: reports.report.statement.reportId
      })
    }

    const userColor = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Color'
      )
    }

    initForm()

    return {
      dialogUtil,
      loading,
      options,
      sortBy,
      sortDesc,
      dataTable,
      items_per_page,
      showBatchBillingDialog,
      showPostLateFeesDialog,
      feature,
      report,
      icon,
      headers,
      toLocaleDateString,
      toLocaleDateTimeString,
      editItemClick,
      deleteItemClick,
      hideBatchBillingDialog,
      hidePostLateFeesDialog,
      postLateFeesClick,
      transactionBatchReport,
      statementsReport,
      userColor
    }
  }
}
</script>

<style lang="scss" scoped></style>
