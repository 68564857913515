// Custom Marker class for Google Maps
const createHTMLMapMarker = ({
  OverlayView = window.google.maps.OverlayView,
  ...args
}) => {
  class HTMLMapMarker extends OverlayView {
    constructor(args) {
      super()
      this.latlng = args.latlng
      this.html = args.html
      this.info = args.info
      this.setMap(args.map)
    }

    draw() {
      this.positionDiv()
    }

    onAdd() {
      if (!this.div) {
        this.createDiv()
        this.appendDivToOverlay()
      }

      if (this.info) {
        const infoWindow = new window.google.maps.InfoWindow({
          content: this.info
        })

        const infoWindowAnchor = {
          anchor: this,
          map: this.map,
          shouldFocus: false
        }

        this.getPanes().overlayMouseTarget.appendChild(this.div)

        this.div.addEventListener('mouseover', () => {
          infoWindow.open(infoWindowAnchor)
        })

        this.div.addEventListener('mouseout', () => {
          infoWindow.close()
        })
      }
    }

    remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div)
        this.div = null
      }
    }

    getPosition() {
      return this.latlng
    }

    getDraggable() {
      return false
    }

    createDiv() {
      this.div = document.createElement('div')
      this.div.style.position = 'absolute'
      if (this.html) {
        this.div.innerHTML = this.html
      }

      this.div.addEventListener('click', () => {
        window.google.maps.event.trigger(this, 'click')
      })
    }

    appendDivToOverlay() {
      const panes = this.getPanes()
      panes.overlayImage.appendChild(this.div)
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng)
      if (point) {
        this.div.style.left = `${point.x}px`
        this.div.style.top = `${point.y}px`
      }
    }
  }

  return new HTMLMapMarker(args)
}

export { createHTMLMapMarker }
