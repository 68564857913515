<template>
  <div>
    <v-card width="400px">
      <v-card-title class="subtitle-1"
        >{{ formData.ServiceRouteSiteId ? 'Update' : 'Add' }} Route for
        {{ siteName }}</v-card-title
      >
      <v-card-text>
        <div v-if="formData && selectLists" class="d-flex">
          <v-select
            v-model="formData.ServiceRouteId"
            label="Route Code"
            :items="selectLists.ServiceRoutes"
            item-text="Text"
            item-value="Value"
            :error-messages="errorMessages(vuelidate.formData.ServiceRouteId)"
            @blur="vuelidate.formData.ServiceRouteId.$touch()"
            dense
          />
          <NearbyServicesButton
            :siteId="siteId"
            :serviceDate="todayISOString()"
            routeOnly
            @update="nearbyServiceSelected"
          />
        </div>
        <v-text-field
          v-model="formData.StopNumber"
          type="number"
          label="Stop"
          :error-messages="errorMessages(vuelidate.formData.StopNumber)"
          @blur="vuelidate.formData.StopNumber.$touch()"
          dense
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="ml-3" @click="saveClick">Save</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { selectListCache } from '@/services/SelectListCache'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { todayISOString } from '@/use/DateFormatter'
import NearbyServicesButton from '@/components/ScheduledService/NearbyServicesButton'

export default {
  name: 'SiteRouteEditor',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    routeInfo: {
      type: Object,
      default: null
    },
    serviceRouteSiteId: {
      type: String,
      default: null
    },
    siteId: {
      type: String,
      required: true
    },
    siteName: {
      type: String,
      default: null
    }
  },
  components: {
    NearbyServicesButton
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref({
      ServiceRouteId: null
    })

    const selectLists = ref()

    const initDialog = async () => {
      await loadSelectLists()

      if (props.routeInfo) {
        formData.value = {
          ServiceRouteSiteId: props.routeInfo.ServiceRouteSiteId,
          ServiceRouteId: props.routeInfo.ServiceRouteId,
          RouteCode: null,
          RouteDescription: null,
          StopNumber: props.routeInfo.StopNumber
        }
      } else if (props.serviceRouteSiteId) {
        const rq = { InfoId: props.serviceRouteSiteId }
        formData.value = await retrieveServiceRouteSite(rq)
      }

      vuelidate.value.$reset()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.ServiceRoutes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const retrieveServiceRouteSite = async rq => {
      const rs = await host.serviceRouteSite.retrieve(rq)
      return rs.Info
    }

    const updateServiceRouteSite = async rq => {
      const rs = await host.serviceRouteSite.update(rq)
      return rs
    }

    watch(
      () => formData.value.ServiceRouteId,
      newVal => {
        formData.value.RouteCode = selectListCache.attribute(
          selectListCache.name.ServiceRoutes,
          newVal,
          'Text'
        )
        formData.value.RouteDescription = selectListCache.attribute(
          selectListCache.name.ServiceRoutes,
          newVal,
          'Description'
        )
      }
    )

    const rules = {
      formData: {
        ServiceRouteId: { required },
        StopNumber: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      if (props.serviceRouteSiteId) {
        const rq = { Info: formData.value }
        const result = await updateServiceRouteSite(rq)
        if (!result.IsSuccess) {
          dialogUtil.value.error(result.Message)
          return
        }
      }

      context.emit('update', formData.value)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const nearbyServiceSelected = args => {
      formData.value.ServiceRouteId = args.ServiceRouteId
      formData.value.StopNumber = args.StopNumber
    }

    initDialog()

    return {
      dialogUtil,
      formData,
      saveClick,
      cancelClick,
      selectLists,
      nearbyServiceSelected,
      todayISOString,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
