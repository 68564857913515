<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="400px"
    min-width="400px"
    max-height="200px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formattedDuration"
        :label="label"
        readonly
        :dense="dense"
        v-on="on"
        :error-messages="errorMessages"
      >
        <v-btn icon v-if="value > 0" @click="clearButtonClick">
          <v-icon>{{ icon.clear }}</v-icon>
        </v-btn>
        <template slot="append">
          <v-icon @click="menu = true">{{ icon.duration }}</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card class="pa-2">
      <v-card-actions class="pa-0 ma-0">
        <span>Duration {{ formattedDuration }}</span>
        <v-spacer />
        <v-btn text @click="closeButtonClick">
          <v-icon>{{ icon.close }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div style="display: flex;" class="pt-3">
        <v-slider
          max="12"
          step="1"
          tick-size="4"
          ticks="always"
          v-model="hours"
          :tick-labels="hourLabels"
        />
        <span class="slider-label">Hours</span>
      </div>
      <div style="display: flex;">
        <v-slider
          max="55"
          step="5"
          tick-size="4"
          ticks="always"
          v-model="minutes"
          :tick-labels="minuteLabels"
        />
        <span class="slider-label">Minutes</span>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { icon } from '@/use/Constants'
import { ref, watch, computed } from 'vue'
export default {
  name: 'DurationPicker',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const menu = ref(false)
    const hours = ref(0)
    const minutes = ref(0)

    const hourLabels = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '',
      ''
    ]

    const minuteLabels = ['', '', '', '15', '', '', '30', '', '', '45', '', '']

    watch(
      () => props.value,
      newVal => {
        hours.value = Math.floor(newVal / 60)
        minutes.value = newVal % 60
      },
      { immediate: true }
    )

    watch(
      () => hours.value,
      () => {
        context.emit('input', computeDuration())
      }
    )

    watch(
      () => minutes.value,
      () => {
        context.emit('input', computeDuration())
      }
    )

    const computeDuration = () => {
      return hours.value * 60 + minutes.value
    }

    const closeButtonClick = () => {
      menu.value = false
    }

    const clearButtonClick = () => {
      hours.value = 0
      minutes.value = 0
    }

    const formattedDuration = computed(() => {
      return (
        (hours.value > 0
          ? hours.value.toString() + (hours.value == 1 ? ' Hour' : ' Hours')
          : '') +
        (hours.value > 0 ? ' ' : '') +
        (minutes.value > 0 ? minutes.value.toString() + ' Minutes' : '')
      )
    })

    return {
      menu,
      hours,
      minutes,
      hourLabels,
      minuteLabels,
      formattedDuration,
      closeButtonClick,
      clearButtonClick,
      icon
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-label {
  width: 80px;
}
</style>
