<template>
  <div>
    <PageTitle
      featureName="reports"
      functionName="Report"
      :target="selectedReportName"
      :pageName="$options.name"
    />
    <div class="d-flex">
      <div>
        <v-card width="400">
          <v-treeview
            dense
            hoverable
            activatable
            transition
            :items="menuItemList"
            open-on-click
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="item.children.length > 0" color="orange">
                {{ open ? icon.reportFolderOpen : icon.reportFolder }}
              </v-icon>
              <v-icon v-else color="grey">
                {{ icon.reportName }}
              </v-icon>
            </template>
            <template slot="label" slot-scope="{ item }">
              <a @click="selectItem(item)">{{ item.name }}</a>
            </template>
          </v-treeview></v-card
        >
      </div>
      <div class="d-flex flex-wrap">
        <div class="ml-2">
          <ReportCriteriaPicker
            v-if="selectedReportId"
            :value="selectedReportId"
            @update="reportCriteriaPickerUpdate"
          />
        </div>
        <div class="ml-2">
          <ReportHistory
            v-if="selectedReportId"
            :value="selectedReportId"
            :label="selectedReportName"
            :version="historyVersion"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import ReportCriteriaPicker from '@/components/Report/ReportCriteriaPicker'
import ReportHistory from '@/components/Report/ReportHistory'
import ReportService from '@/services/ReportService'

export default {
  name: 'ReportMenu',
  components: {
    ReportCriteriaPicker,
    ReportHistory
  },
  setup() {
    const menuItemList = ref([])
    const selectedReportId = ref()
    const selectedReportName = ref('')
    const historyVersion = ref(0)

    const initForm = async () => {
      const rq = {}
      const rs = await host.report.menuItemList(rq)
      menuItemList.value = rs.InfoList
    }

    const selectItem = item => {
      if (item.id) {
        selectedReportId.value = item.id
        selectedReportName.value = item.name
      }
    }

    const reportCriteriaPickerUpdate = reportInfo => {
      ReportService.emitLaunchReport(reportInfo)
      historyVersion.value++
    }

    initForm()

    return {
      menuItemList,
      selectedReportId,
      selectedReportName,
      historyVersion,
      icon,
      selectItem,
      reportCriteriaPickerUpdate
    }
  }
}
</script>

<style lang="scss" scoped></style>
