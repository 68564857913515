<template>
  <div>
    <v-dialog v-model="showDialog" max-width="800" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          rounded
          color="primary"
          class="mx-1"
          v-bind="attrs"
          v-on="on"
          @click="showDialogClick"
        >
          <span class="mr-1">Feedback</span>
          <v-icon>{{ icon.feedback }}</v-icon>
        </v-btn>
      </template>
      <v-card height="490" width="800">
        <v-card-title>
          <DialogTitle :dialogName="$options.name">
            Give us Feedback
          </DialogTitle>
        </v-card-title>
        <v-card-subtitle>
          Do you have a question or comment? Please complete the information
          below, and we will get back to you.
        </v-card-subtitle>
        <v-card-text v-if="formData">
          <v-divider />
          <v-radio-group v-model="formData.Subject" class="mt-2" dense>
            <v-radio label="A Question" value="Q" />
            <v-radio label="A Comment" value="C" />
            <v-radio label="A Suggestion" value="S" />
            <v-radio label="An Error" value="E" />
          </v-radio-group>
          <v-textarea
            v-model="formData.Message"
            label="Details"
            dense
          ></v-textarea>
          <div>
            To contact us by phone, please call (480) 626-2176, or email us at
            support@poolcarepro.com
          </div>
        </v-card-text>
        <v-card-actions
          ><v-btn color="success" class="ml-3" @click="saveClick">Send</v-btn>
          <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogUtil ref="dialogUtil" /></div
></template>

<script>
import { ref } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
export default {
  name: 'FeedbackButton',
  setup() {
    const dialogUtil = ref()

    const showDialog = ref(false)

    const formData = ref()

    const initDialog = () => {
      formData.value = {
        Subject: 'Q',
        Message: null
      }
    }

    const showDialogClick = () => {
      initDialog()
    }

    const saveClick = async () => {
      const result = await sendMessage()

      dialogUtil.value.inform({
        text: result.Message,
        snackbarTimeout: 10000,
        color: result.IsSuccess ? 'success' : 'error'
      })

      showDialog.value = false
    }

    const cancelClick = () => {
      showDialog.value = false
    }

    const sendMessage = async () => {
      const rq = { Info: formData.value }
      const rs = await host.office.feedback(rq)
      return rs
    }

    return {
      dialogUtil,
      showDialog,
      showDialogClick,
      saveClick,
      cancelClick,
      formData,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
