<template>
  <div>
    <div>
      <PageTitle
        featureName="payments"
        functionName="Batch"
        target="Batch Payment Post"
        :pageName="$options.name"
      />
    </div>
    <v-container v-if="cardData && selectLists" fluid>
      <v-row>
        <v-col cols="12">
          <v-row align="start" justify="start" class="ml-0">
            <div>
              <BillingBatchCard :cardData="cardData" />
              <v-card
                v-if="cardData.Status !== 'A'"
                class="ml-2 mt-3 indigo lighten-5"
                width="400px"
              >
                <v-container row>
                  <v-btn
                    small
                    color="primary"
                    class="ml-2"
                    @click="launchReport"
                  >
                    <v-icon class="mr-2">
                      {{ icon.report }}
                    </v-icon>
                    Report
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    v-if="cardData.CanEditClosedBatch === true"
                    small
                    color="primary"
                    class="ml-3"
                    @click="changeBatchStatus('A')"
                  >
                    <v-icon class="ml-0 mr-2">
                      {{ batchStatus.A.icon }}
                    </v-icon>
                    Re-Open
                  </v-btn>
                </v-container>
              </v-card>
              <CustomerCard
                v-if="customerCardData !== null"
                :cardData="customerCardData"
                class="mt-3"
                readonly
              />
            </div>
            <div>
              <v-card
                v-if="cardData.Status === 'A' && paymentTranCodeId !== null"
                width="400px"
                class="pt-2 px-3 ma-2"
              >
                <v-card-title>Post Payment</v-card-title>
                <TranCodePicker v-model="paymentTranCodeId" />
                <v-radio-group v-model="searchBy" row dense>
                  <v-radio label="Customer Code" value="C" />
                  <v-radio label="Customer Name" value="N" />
                </v-radio-group>
                <v-text-field
                  v-model="keyword"
                  ref="keywordField"
                  :label="keywordLabel"
                  :error-messages="errorMessages(vuelidate.keyword)"
                  :readonly="transactionId !== null"
                  @blur="keywordBlur"
                  @focus="$event.target.select()"
                  dense
                />
                <v-text-field
                  v-model="reference"
                  ref="referenceField"
                  label="Check #"
                  :error-messages="errorMessages(vuelidate.reference)"
                  @focus="$event.target.select()"
                  dense
                />
                <v-currency-field
                  v-model="amount"
                  label="Amount"
                  type="number"
                  :error-messages="errorMessages(vuelidate.amount)"
                  @focus="$event.target.select()"
                  dense
                />
                <div>
                  <v-btn color="success" class="mb-3" @click="saveClick">
                    {{ transactionId === null ? 'Post' : 'Update' }}
                  </v-btn>
                  <v-btn
                    v-if="transactionId !== null"
                    color="error"
                    class="mb-3 ml-3"
                    @click="cancelClick"
                  >
                    Cancel
                  </v-btn>
                </div>
              </v-card>
              <v-card
                v-if="cardData.Status === 'A'"
                class="ml-2 mt-3 indigo lighten-5"
                width="400px"
              >
                <v-container row>
                  <v-btn
                    small
                    color="primary"
                    class="ml-2"
                    @click="launchReport"
                  >
                    <v-icon class="mr-2">
                      {{ icon.report }}
                    </v-icon>
                    Report
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    small
                    color="primary"
                    class="ml-3"
                    @click="changeBatchStatus('C')"
                  >
                    <v-icon class="ml-0 mr-2">
                      {{ batchStatus.C.icon }}
                    </v-icon>
                    Close
                  </v-btn>
                </v-container>
              </v-card>
            </div>
            <v-card v-if="itemList !== null" width="780px" class="mt-3 ml-2">
              <v-card-title>
                <span>Posted Payments</span>
                <v-spacer />
                <span class="mr-2">
                  Batch Total: {{ toMoney(batchTotal) }}
                </span>
              </v-card-title>
              <v-data-table
                dense
                :items-per-page="20"
                :footer-props="{
                  'items-per-page-options': items_per_page,
                  'show-first-last-page': true
                }"
                :headers="headers"
                :items="itemList"
                class="elevation-1"
              >
                <template v-slot:item.Amount="{ item }">
                  <span>{{ toMoney(item.Amount) }}</span>
                </template>
                <template
                  v-if="cardData.Status === 'A'"
                  v-slot:item.actions="{ item }"
                >
                  <v-icon small class="mr-2" @click="editItemClick(item)">
                    {{ icon.edit }}
                  </v-icon>
                  <v-icon small @click="deleteItemClick(item)">
                    {{ icon.delete }}
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters align="start" justify="start" height="400px"> </v-row>
    </v-container>

    <v-dialog v-model="showSearchDialog" max-width="1000">
      <CustomerSearch
        :searchText="keyword"
        :isDialogOpen="showSearchDialog"
        @close="customerSearchClose"
        @select="selectedSearchItem"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { icon, batchStatus } from '@/use/Constants'
import BillingBatchCard from '@/components/Payment/BillingBatchCard'
import CustomerCard from '@/components/Customer/CustomerCard'
import CustomerSearch from '@/components/Customer/CustomerSearch'
import ReportService from '@/services/ReportService'
import reportInfo from '@/constants/reports'
import { toMoney } from '@/use/NumberFormatter'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { customErrorMessage } from '@/use/CustomValidators'

export default {
  name: 'PaymentBatch',
  components: {
    BillingBatchCard,
    CustomerCard,
    CustomerSearch
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const dialogUtil = ref(null)

    const loading = ref(true)
    const cardData = ref()
    const paymentTranCodeId = ref()
    const selectLists = ref()
    const itemList = ref()
    const showSearchDialog = ref(false)
    const activateSearchDialog = ref(false)
    const items_per_page = [10, 15, 20, 50]

    const headers = [
      { value: 'TransactionType', text: 'Transaction Type' },
      { value: 'CustomerName', text: 'Name' },
      { value: 'CustomerCode', text: 'Code' },
      { value: 'Reference', text: 'Check #', align: 'right' },
      { value: 'Amount', text: 'Amount', align: 'right' },
      { value: 'actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const searchBy = ref('C')
    const keyword = ref()
    const reference = ref()
    const amount = ref()
    const customerCardData = ref()
    const keywordErrorMessage = ref()
    const editItemIndex = ref()
    const customerId = ref()
    const transactionId = ref(null)

    const initForm = async () => {
      await loadSelectLists()
      await loadBatch()
      await loadPaymentTranCode()
      clearFormData()
    }

    const loadPaymentTranCode = async () => {
      const rq = {}
      const rs = await host.tranCode.retrievePaymentTranCode(rq)
      paymentTranCodeId.value = rs.Info.TranCodeId
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const keywordField = ref()
    const referenceField = ref()

    const setFocus = field => {
      setTimeout(() => {
        field.$refs.input.focus()
      })
    }

    const loadBatch = async () => {
      loading.value = true
      const rq = {
        InfoId: props.id
      }
      const rs = await host.transactionBatch.retrieveWithItems(rq)
      cardData.value = rs.Info.Info
      itemList.value = rs.Info.ItemList
      loading.value = false
    }

    const launchReport = () => {
      ReportService.emitShowReportDialog({
        ReportId: reportInfo.report.transactionBatch.reportId,
        ParameterList: [
          {
            Name: 'TransactionBatchId',
            Value: cardData.value.TransactionBatchId
          }
        ],
        ParameterHideList: ['TransactionBatchId']
      })
    }

    const batchTotal = computed(() => {
      const total =
        itemList.value && itemList.value.length > 0
          ? itemList.value.reduce(function(a, b) {
              return a + b.Amount
            }, 0)
          : 0
      return total
    })

    watch(
      () => customerCardData.value,
      newVal => {
        if (newVal) {
          keyword.value = newVal.Code

          if (transactionId.value === null) {
            amount.value = newVal.AgingSummary.Balance
          }

          customerId.value = null
        }
      },
      { deep: true }
    )

    const clearFormData = () => {
      keyword.value = null
      customerCardData.value = null
      reference.value = null
      amount.value = null
      keywordErrorMessage.value = null
      transactionId.value = null
      customerId.value = null
      editItemIndex.value = null
      vuelidate.value.$reset()
      setFocus(keywordField.value)
    }

    const keywordBlur = () => {
      if (keyword.value) {
        getCustomerByKeyword()
      }
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      const rq = {
        Info: {
          TransactionBatchId: props.id,
          TransactionId: transactionId.value,
          CustomerId: customerCardData.value.CustomerId,
          TranCodeId: paymentTranCodeId.value,
          Amount: amount.value,
          Reference: reference.value,
          Source: 'B'
        }
      }

      const rs = await host.transaction.saveOrUpdateInBatch(rq)
      if (rs.IsSuccess) {
        if (editItemIndex.value !== null) {
          Object.assign(itemList.value[editItemIndex.value], rs.Info)
        } else {
          itemList.value.push(rs.Info)
        }
        clearFormData()
      }
    }

    const editItemClick = item => {
      editItemIndex.value = itemList.value.indexOf(item)
      transactionId.value = item.TransactionId
      customerId.value = item.CustomerId
      keyword.value = item.CustomerCode
      amount.value = -item.Amount
      reference.value = 'Check# ' + item.Reference
      getCustomerByKeyword()
    }

    const deleteItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: 'Are you sure you want to delete this item?'
        })
        .then(() => performDeleteItem(item))
        .catch(() => {
          return
        })
    }

    const performDeleteItem = async item => {
      const index = itemList.value.indexOf(item)
      const rq = {
        InfoId: item.TransactionId
      }
      const rs = await host.transaction.delete(rq)
      if (rs.IsSuccess) {
        itemList.value.splice(index, 1)
      }
    }

    const cancelClick = () => {
      clearFormData()
    }

    const getCustomerByKeyword = async () => {
      const rq = {
        IsKeywordCustomerCode: searchBy.value === 'C',
        Id: customerId.value,
        Keyword: keyword.value,
        RetrieveSites: false
      }
      const rs = await host.customer.card(rq)
      if (rs.IsSuccess) {
        customerCardData.value = rs.Info
        keywordErrorMessage.value = null
        setFocus(referenceField.value)
      } else {
        customerCardData.value = null
        keywordErrorMessage.value = rs.Message
        showSearchDialog.value = true
      }

      vuelidate.value.keyword.$touch()
    }

    const selectedSearchItem = event => {
      customerId.value = event.CustomerId
      showSearchDialog.value = false
      getCustomerByKeyword()
    }

    const changeBatchStatus = async newVal => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: (newVal === 'C' ? 'Close' : 'Re-open') + ' this batch?'
        })
        .then(() => performChangeBatchStatus(newVal))
        .catch(() => {
          return
        })
    }

    const performChangeBatchStatus = async newVal => {
      const rq = {
        TransactionBatchId: cardData.value.TransactionBatchId,
        Status: newVal
      }
      const rs = await host.transactionBatch.changeStatus(rq)

      if (rs.IsSuccess === false) {
        dialogUtil.value.error('Error changing batch status: ' + rs.Message)
      } else {
        loadBatch()
      }
    }

    const keywordLabel = computed(() => {
      return searchBy.value === 'C' ? 'Customer Code' : 'Customer Name'
    })

    const customerSearchClose = () => {
      showSearchDialog.value = false
    }

    const rules = {
      customerCardData: {
        CustomerId: {
          required
        }
      },
      amount: {
        required
      },
      reference: {
        required
      },
      keyword: {
        required,
        other: customErrorMessage(() => keywordErrorMessage.value)
      }
    }

    const vuelidate = useVuelidate(rules, {
      customerCardData,
      amount,
      reference,
      keyword
    })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    initForm()

    return {
      dialogUtil,
      keywordField,
      referenceField,
      loading,
      cardData,
      paymentTranCodeId,
      selectLists,
      itemList,
      showSearchDialog,
      activateSearchDialog,
      items_per_page,
      headers,
      toMoney,
      icon,
      batchStatus,
      searchBy,
      keyword,
      reference,
      amount,
      customerCardData,
      keywordErrorMessage,
      editItemIndex,
      customerId,
      transactionId,
      launchReport,
      batchTotal,
      keywordBlur,
      editItemClick,
      deleteItemClick,
      cancelClick,
      changeBatchStatus,
      keywordLabel,
      selectedSearchItem,
      saveClick,
      customerSearchClose,
      vuelidate,
      errorMessages
    }
  }
}
</script>

<style lang="scss" scoped>
.formColumn {
  width: 50%;
}
.card-title {
  height: 22px;
  margin-left: 10px;
  background-color: lightgrey;
}
.no-margin td {
  padding: 0px;
}
</style>
