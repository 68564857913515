import SearchTasks from '@/views/ScheduledTask/SearchTasks'

export default [
  {
    path: '/scheduledtask/search/:customerId?/:customerName?',
    name: 'scheduledtask-search',
    component: SearchTasks,
    props: true
  }
]
