<template>
  <div v-if="formData" class="d-flex mt-3 indigo lighten-5">
    <div>
      <v-card width="400">
        <v-card-title class="subtitle-1 blue--text">
          <span class="mt-2">Online Payment Settings</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="formData.SummaryEmailAddress"
            type="email"
            label="Email Address for Payment Summary"
            :error-messages="
              errorMessages(vuelidate.formData.SummaryEmailAddress)
            "
            @blur="vuelidate.formData.SummaryEmailAddress.$touch()"
            dense
          />
          <TranCodePicker
            v-model="formData.PaymentTranCodeId"
            label="Online Payment TranCode"
            clearable
            :error-messages="
              errorMessages(vuelidate.formData.PaymentTranCodeId)
            "
            @blur="vuelidate.formData.PaymentTranCodeId.$touch()"
          />
          <TranCodePicker
            v-model="formData.SurchargeTranCodeId"
            label="Surcharge TranCode"
            clearable
            :error-messages="
              errorMessages(vuelidate.formData.SurchargeTranCodeId)
            "
            @blur="vuelidate.formData.SurchargeTranCodeId.$touch()"
          />
          <v-card-actions>
            <v-btn color="success" @click="saveClick">
              <span>Save</span>
              <v-icon right>{{ icon.save }}</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </div>
    <div class="ml-3">
      <v-card>
        <v-card-title class="subtitle-1 blue--text">
          <span>Credit Card Surcharge:</span>
        </v-card-title>
        <v-card-text>
          <v-currency-field
            v-model="formData.CreditCardSurchargePercentage"
            label="Percentage"
            dense
            :error-messages="
              errorMessages(vuelidate.formData.CreditCardSurchargePercentage)
            "
            @blur="vuelidate.formData.CreditCardSurchargePercentage.$touch()"
          />
          <v-currency-field
            v-model="formData.CreditCardSurchargeFlatAmount"
            label="Flat Amount"
            dense
            :error-messages="
              errorMessages(vuelidate.formData.CreditCardSurchargeFlatAmount)
            "
            @blur="vuelidate.formData.CreditCardSurchargeFlatAmount.$touch()"
          />
          <v-checkbox
            v-model="formData.IsCreditCardSurchargeMaxFlatOrPercent"
            label="Percentage or Flat whichever is greater"
            dense
          />
        </v-card-text>
      </v-card>
    </div>
    <div class="ml-3">
      <v-card>
        <v-card-title class="subtitle-1 blue--text">
          <span>ACH Surcharge:</span>
        </v-card-title>
        <v-card-text>
          <v-currency-field
            v-model="formData.AchSurchargePercentage"
            label="Percentage"
            dense
            :error-messages="
              errorMessages(vuelidate.formData.AchSurchargePercentage)
            "
            @blur="vuelidate.formData.AchSurchargePercentage.$touch()"
          />
          <v-currency-field
            v-model="formData.AchSurchargeFlatAmount"
            label="Flat Amount"
            dense
            :error-messages="
              errorMessages(vuelidate.formData.AchSurchargeFlatAmount)
            "
            @blur="vuelidate.formData.AchSurchargeFlatAmount.$touch()"
          />
          <v-checkbox
            v-model="formData.IsAchSurchargeMaxFlatOrPercent"
            label="Percentage or Flat whichever is greater"
            dense
          />
        </v-card-text>
      </v-card>
    </div>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'
import { useVuelidate } from '@vuelidate/core'
import { email } from '@vuelidate/validators'
export default {
  name: 'OnlinePaymentSettings',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const dialogUtil = ref(null)
    const formData = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      await retrieveSettings()
    }

    const retrieveSettings = async () => {
      const rq = {}
      const rs = await host.onlinePaymentSettings.retrieve(rq)
      formData.value = rs.Info
    }

    const rules = {
      formData: {
        SummaryEmailAddress: { email },
        PaymentTranCodeId: {},
        SurchargeTranCodeId: {},
        CreditCardSurchargePercentage: {},
        CreditCardSurchargeFlatAmount: {},
        AchSurchargePercentage: {},
        AchSurchargeFlatAmount: {}
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        dialogUtil.value.error('Please fix validation errors')
        return
      }

      const rq = { Info: formData.value }
      const rs = await host.onlinePaymentSettings.update(rq)

      if (rs.IsSuccess) {
        dialogUtil.value.inform({
          text: rs.Message,
          color: 'success'
        })
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    initDialog()

    return { dialogUtil, formData, icon, saveClick, errorMessages, vuelidate }
  }
}
</script>
<style lang="scss" scoped></style>
