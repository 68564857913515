<template>
  <div>
    <v-card class="indigo lighten-5">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Rate
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData && selectLists">
        <v-card width="780">
          <v-card-text>
            <v-card width="400">
              <v-card-text class="pb-0">
                <v-text-field
                  v-model="formData.Code"
                  label="Code"
                  :error-messages="errorMessages(vuelidate.formData.Code)"
                  @blur="vuelidate.formData.Code.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.Description"
                  label="Description"
                  :error-messages="
                    errorMessages(vuelidate.formData.Description)
                  "
                  @blur="vuelidate.formData.Description.$touch()"
                  dense
                />
                <v-checkbox v-model="formData.IsDeleted" label="Deleted" />
              </v-card-text>
            </v-card>

            <v-card class="mt-3">
              <v-card-title
                ><span class="subtitle-2">Months of Service</span>
                <v-spacer />
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        small
                        color="success"
                        class="mt-3 ml-2"
                        @click="checkAllClick"
                      >
                        <v-icon>{{ icon.checkBoxCheckAll }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Check all</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        small
                        color="error"
                        class="mt-3 ml-2"
                        @click="uncheckAllClick"
                      >
                        <v-icon>{{ icon.checkBoxUncheckAll }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Uncheck all</span>
                  </v-tooltip>
                </span>
              </v-card-title>
              <v-card-text>
                <div style="column-count: 4">
                  <div v-for="(value, index) in monthsApply" :key="index">
                    <v-checkbox
                      v-model="monthsApply[index]"
                      :label="monthName(index)"
                      :true-value="'Y'"
                      :false-value="'N'"
                      dense
                    />
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title class="subtitle-2">Rate Amounts</v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="detailHeaderList"
              :items="detailDataList"
              class="elevation-1"
            >
              <template v-slot:item.TranCodeId="{ item }">
                {{ tranCodeDescription(item.TranCodeId) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editRateDetailClick(item)">
                  {{ icon.lineItemEdit }}
                </v-icon>
                <v-icon small @click="deleteRateDetailClick(item)">
                  {{ icon.lineItemDelete }}
                </v-icon>
              </template>
              <template v-slot:footer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      absolute
                      class="mt-3 ml-2"
                      @click="addRateDetailClick"
                    >
                      <v-icon>{{ icon.lineItemAdd }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Rate Amount</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" class="ml-1" @click="saveClick">
              {{ isEditMode ? 'Update' : 'Save' }}
            </v-btn>
            <v-btn color="error" class="ml-3" @click="cancelClick">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showRateDetailEditorDialog" max-width="400" persistent>
      <RateDetailEditor
        :isDialogOpen="showRateDetailEditorDialog"
        :item="selectedRateDetailItem"
        :selectLists="selectLists"
        @close="rateDetailEditorClose"
        @update="rateDetailEditorUpdate"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import RateDetailEditor from '@/components/Setup/Billing/RateDetailEditor'
export default {
  name: 'RateEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { RateDetailEditor },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const formData = ref()
    const monthsApply = ref()

    watch(
      () => monthsApply.value,
      newVal => {
        formData.value.MonthsApply = newVal.join('')
      },
      { deep: true }
    )

    const showRateDetailEditorDialog = ref(0)
    const selectedRateDetailItem = ref()
    const selectLists = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      formData.value = isEditMode.value
        ? await retrieveRate(props.infoId)
        : await newTemplateRate()
      monthsApply.value = formData.value.MonthsApply.split('')
      loadSelectLists()
      vuelidate.value.$reset()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        selectListCache.invalidate(selectListCache.name.Rates)
        context.emit('update')
      } else {
        await dialogUtil.value.inform({
          text: result.Message,
          snackbarTimeout: 10000,
          color: 'error'
        })
      }
    }

    const tranCodeDescription = value => {
      return value
        ? selectLists.value.TranCodes.find(item => item.Value == value).Text
        : ''
    }

    const rules = {
      formData: {
        Code: { required },
        Description: { required },
        DetailList: { required, minLength: minLength(1) }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        if (lineItemListErrors.value) {
          dialogUtil.value.error({
            text: lineItemListErrors.value.join('<br/>')
          })
        }
      }
      return vuelidate.value.$invalid
    }

    const lineItemListErrors = computed(() => {
      const errors = []
      if (
        vuelidate.value.formData.DetailList.$dirty &&
        vuelidate.value.formData.DetailList.$error
      ) {
        errors.push('At least one amount line is required.')
      }
      return errors
    })

    const retrieveRate = async id => {
      const rq = { InfoId: id }
      const rs = await host.rate.retrieve(rq)
      return rs.Info
    }

    const newTemplateRate = async () => {
      const rq = {}
      const rs = await host.rate.newTemplate(rq)
      return rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.rate.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = host.rate.update(rq)
      return rs
    }

    const monthName = index => {
      let baseDate = new Date()
      baseDate.setDate(1)
      baseDate.setMonth(index)
      return baseDate.toLocaleString('en-us', { month: 'long' })
    }

    const detailHeaderList = [
      {
        value: 'SortOrder',
        text: 'Line',
        width: 20,
        align: 'right'
      },
      {
        value: 'TranCodeId',
        text: 'Transaction Code',
        width: 40,
        align: 'left'
      },
      { value: 'Amount', text: 'Amount', width: 40, align: 'right' },
      { value: 'actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const detailDataList = computed(() => {
      return formData.value.DetailList.map((item, index) => {
        return { ...item, actions: null, _idx: index }
      })
    })

    const addRateDetailClick = () => {
      selectedRateDetailItem.value = {
        index: -1,
        info: {
          RateDetailIdId: null,
          TranCodeId: null,
          SortOrder: 0,
          Amount: 0
        }
      }
      showRateDetailEditorDialog.value = true
    }

    const editRateDetailClick = item => {
      selectedRateDetailItem.value = {
        index: item._idx,
        info: formData.value.DetailList[item._idx]
      }
      showRateDetailEditorDialog.value = true
    }

    const deleteRateDetailClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete rate amount ' + item.Amount + '. Are you sure?'
        })
        .then(() => deleteRateDetail(item))
        .catch(() => {
          return
        })
    }

    const deleteRateDetail = item => {
      if (item.RateDetailId !== null) {
        formData.value.DeletedRateIdList.push(item.RateDetailId)
      }
      formData.value.DetailList.splice(item._idx, 1)
    }

    const rateDetailEditorClose = () => {
      showRateDetailEditorDialog.value = false
    }

    const rateDetailEditorUpdate = newVal => {
      if (newVal.index > -1) {
        Object.assign(formData.value.DetailList[newVal.index], newVal.info)
      } else {
        formData.value.DetailList.push(newVal.info)
      }
      showRateDetailEditorDialog.value = false
    }

    const checkAllClick = () => {
      formData.value.MonthsApply = 'YYYYYYYYYYYY'
      monthsApply.value = formData.value.MonthsApply.split('')
    }

    const uncheckAllClick = () => {
      formData.value.MonthsApply = 'NNNNNNNNNNNN'
      monthsApply.value = formData.value.MonthsApply.split('')
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      monthName,
      selectLists,
      saveClick,
      cancelClick,
      detailHeaderList,
      detailDataList,
      tranCodeDescription,
      addRateDetailClick,
      editRateDetailClick,
      deleteRateDetailClick,
      rateDetailEditorClose,
      rateDetailEditorUpdate,
      showRateDetailEditorDialog,
      selectedRateDetailItem,
      monthsApply,
      checkAllClick,
      uncheckAllClick,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
