<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          {{ title }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-card class="mt-3" width="400">
          <v-card-text>
            <DatePicker v-model="initialDate" label="Initial Date" dense />
            <DatePicker
              v-if="!noEndDate"
              v-model="endDate"
              label="End Date"
              clearable
              dense
            />
            <v-checkbox
              v-if="!noEndDate"
              v-model="autoExtend"
              label="Auto Extend"
              dense
            />
          </v-card-text>
        </v-card>
        <v-radio-group v-model="recurPeriod" dense class="my-2" row>
          <v-radio label="Daily" value="D" />
          <v-radio label="Weekly" value="W" />
          <v-radio label="Monthly" value="M" />
          <v-radio label="Yearly" value="Y" />
        </v-radio-group>
        <div>
          <div v-if="recurPeriod === 'D'">
            <v-radio-group v-model="formData.D.option" dense>
              <div class="d-flex">
                <v-radio dense label="Every" value="0" />
                <div style="width: 50px;" class="ml-2">
                  <v-select
                    v-model="formData.D.option0.interval1"
                    :items="selectLists.DayInterval1"
                    dense
                  ></v-select>
                </div>
                <div class="mt-2 ml-1 text--secondary">days</div>
              </div>
              <v-radio label="Every weekday" value="1" dense />
            </v-radio-group>
          </div>
          <div v-else-if="recurPeriod === 'W'">
            <div class="d-flex">
              <div class="mt-2 text--secondary">Every</div>
              <div style="width: 50px;" class="ml-2">
                <v-select
                  v-model="formData.W.option0.interval1"
                  :items="selectLists.WeekInterval1"
                  dense
                ></v-select>
              </div>
              <div class="mt-2 ml-1 text--secondary">weeks</div>
            </div>
            <v-checkbox
              v-for="dayOfWeek of selectLists.DaysOfWeek"
              :key="dayOfWeek.value"
              v-model="daysOfWeek"
              :label="dayOfWeek.text"
              :value="dayOfWeek.value"
              dense
            />
          </div>
          <div v-else-if="recurPeriod === 'M'">
            <v-radio-group v-model="formData.M.option" dense>
              <div class="d-flex">
                <v-radio value="0" label="The"></v-radio>
                <div style="width: 70px;" class="ml-2">
                  <v-select
                    v-model="formData.M.option0.interval1"
                    :items="selectLists.DaysOfMonth"
                    item-text="text"
                    item-value="value"
                    dense
                  />
                </div>
                <div class="mt-2 ml-1 text--secondary">day of every</div>
                <div style="width: 50px;" class="ml-2">
                  <v-select
                    v-model="formData.M.option0.interval2"
                    :items="selectLists.MonthIntervalF2"
                    dense
                  ></v-select>
                </div>
                <div class="mt-2 ml-1 text--secondary">months.</div>
              </div>
              <div class="d-flex">
                <v-radio value="1" label="The" />
                <div style="width: 90px;" class="ml-2">
                  <v-select
                    v-model="formData.M.option1.interval1"
                    :items="selectLists.WeeksOfMonth"
                    item-text="text"
                    item-value="value"
                    dense
                  ></v-select>
                </div>
                <div style="width: 120px;" class="ml-2">
                  <v-select
                    v-model="formData.M.option1.interval2"
                    :items="selectLists.DaysOfWeek"
                    item-text="text"
                    item-value="value"
                    dense
                  ></v-select>
                </div>
              </div>
              <div class="d-flex">
                <div class="mt-2 ml-9 text--secondary">of every</div>
                <div style="width: 50px;" class="ml-2">
                  <v-select
                    v-model="formData.M.option1.interval3"
                    :items="selectLists.MonthIntervalT3"
                    item-text="text"
                    item-value="value"
                    dense
                  ></v-select>
                </div>
                <div class="mt-2 ml-1 text--secondary">months.</div>
              </div>
            </v-radio-group>
          </div>
          <div v-else-if="recurPeriod === 'Y'">
            <v-radio-group v-model="formData.Y.option" dense>
              <div class="d-flex">
                <v-radio dense value="0" label="On every" />
                <div style="width: 120px;" class="mt-2 ml-1">
                  <v-select
                    v-model="formData.Y.option0.interval1"
                    :items="selectLists.Months"
                    item-text="text"
                    item-value="value"
                    dense
                  ></v-select>
                </div>
                <div style="width: 70px;" class="mt-2 ml-2">
                  <v-select
                    v-model="formData.Y.option0.interval2"
                    :items="selectLists.DaysOfMonth"
                    dense
                  ></v-select>
                </div>
              </div>

              <div class="d-flex">
                <v-radio label="The" value="1" dense />
                <div style="width: 90px;" class="mt-2 ml-2">
                  <v-select
                    v-model="formData.Y.option1.interval1"
                    :items="selectLists.WeeksOfMonth"
                    item-text="text"
                    item-value="value"
                    dense
                  ></v-select>
                </div>
                <div style="width: 120px;" class="mt-2 ml-2">
                  <v-select
                    v-model="formData.Y.option1.interval2"
                    :items="selectLists.DaysOfWeek"
                    item-text="text"
                    item-value="value"
                    dense
                  ></v-select>
                </div>
              </div>
              <div class="d-flex">
                <div class="mt-2 ml-9 text--secondary">of</div>
                <div style="width: 120px;" class="ml-2">
                  <v-select
                    v-model="formData.Y.option1.interval3"
                    :items="selectLists.Months"
                    item-text="text"
                    item-value="value"
                    dense
                  ></v-select>
                </div>
              </div>
            </v-radio-group>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { formatFrequency } from '@/use/FrequencyFormatter'
import { selectLists } from '@/components/controls/RecurringRulesPickerSelectLists'
export default {
  name: 'RecurringRulesPicker',
  props: {
    value: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: 'Recurring Rules'
    },
    noEndDate: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    watch(
      () => props.value,
      newVal => {
        newVal && initDialog()
      },
      { deep: true }
    )

    const initDialog = () => {
      initFromProps()
    }

    const initialDate = ref()
    const endDate = ref()
    const autoExtend = ref(true)
    const recurPeriod = ref('D')
    const daysOfWeek = ref([])

    const formData = ref({
      D: {
        option: '0',
        option0: {
          // every interval1 days
          interval1: '1',
          interval2: '0',
          interval3: '0'
        },
        option1: {
          // every weekday
          interval1: '0',
          interval2: '0',
          interval3: '0'
        }
      },
      W: {
        option: '0',
        option0: {
          // every interval1 week of the month, on interval2 days of the week (bit pattern)
          interval1: '1',
          interval2: '1',
          interval3: '0'
        },
        option1: {
          // n/a
          interval1: '0',
          interval2: '0',
          interval3: '0'
        }
      },
      M: {
        option: '0',
        option0: {
          // the interval1 day of every interval2 month(s)
          interval1: '1',
          interval2: '1',
          interval3: '0'
        },
        option1: {
          // the interval1 (week of month) interval2 (day of week) of every interval3 months
          interval1: '1',
          interval2: '1',
          interval3: '1'
        }
      },
      Y: {
        option: '0',
        option0: {
          // on every interval1 (month) interval2 (day of month)
          interval1: '1',
          interval2: '1',
          interval3: '0'
        },
        option1: {
          // the interval1 (week of month) interval2 (day of week) of every interval3 (month)
          interval1: '1',
          interval2: '1',
          interval3: '1'
        }
      }
    })

    const update = () => {
      const optionVal = formData.value[recurPeriod.value].option
      const root = formData.value[recurPeriod.value]['option' + optionVal]
      const newVal = {
        InitialDate: initialDate.value,
        EndDate: endDate.value,
        AutoExtend: autoExtend.value,
        RecurPeriod: recurPeriod.value,
        RecurOption: optionVal === '1',
        Interval1: parseInt(root.interval1),
        Interval2: parseInt(root.interval2),
        Interval3: parseInt(root.interval3)
      }
      context.emit('input', newVal)
      context.emit(
        'updateDescription',
        formatFrequency(
          newVal.RecurPeriod,
          newVal.RecurOption,
          newVal.Interval1,
          newVal.Interval2,
          newVal.Interval3
        )
      )
    }

    watch(
      () => initialDate.value,
      () => update()
    )
    watch(
      () => endDate.value,
      () => update()
    )
    watch(
      () => autoExtend.value,
      () => update()
    )
    watch(
      () => recurPeriod.value,
      () => update()
    )
    watch(
      () => daysOfWeek.value,
      newVal => {
        formData.value.W.option0.interval2 = dowArrayToBits(newVal).toString()
      }
    )

    watch(
      () => formData.value,
      () => update(),
      { deep: true }
    )

    watch(
      () => props.value,
      () => initFromProps(),
      { deep: true }
    )

    const initFromProps = () => {
      const newVal = props.value
      initialDate.value = newVal.InitialDate
      endDate.value = newVal.EndDate
      autoExtend.value = newVal.AutoExtend
      recurPeriod.value = newVal.RecurPeriod
      const optionVal = newVal.RecurOption ? '1' : '0'
      formData.value[newVal.RecurPeriod].option = optionVal
      const root = formData.value[newVal.RecurPeriod]['option' + optionVal]
      root.interval1 = newVal.Interval1.toString()
      root.interval2 = newVal.Interval2.toString()
      root.interval3 = newVal.Interval3.toString()
      if (newVal.RecurPeriod === 'W' && !newVal.RecurOption) {
        daysOfWeek.value = dowBitsToArray(newVal.Interval2)
      }
    }

    const dowArrayToBits = arr => {
      var result = 0
      arr.forEach(val => {
        result += 2 ** parseInt(val)
      })
      return result
    }

    const dowBitsToArray = bits => {
      const result = []
      for (var i = 0; i < 7; i++) {
        if (bits & 1) {
          result.push(i.toString())
        }
        bits >>= 1
      }
      return result
    }

    initDialog()

    return {
      formData,
      initialDate,
      endDate,
      autoExtend,
      recurPeriod,
      daysOfWeek,
      selectLists
    }
  }
}
</script>

<style lang="scss" scoped></style>
