<template>
  <div>
    <v-card width="400">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit Photo Info' : 'Upload Info' }}
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData">
        <v-text-field
          v-model="formData.Title"
          label="Title"
          :error-messages="errorMessages(vuelidate.formData.Title)"
          @blur="vuelidate.formData.Title.$touch()"
          dense
        />
        <div v-if="isEditMode" class="ml-6">
          <div class="caption">
            {{ toLocaleDateString(formData.WhenTaken) }}
          </div>
          <a target="_blank" :href="createPhotoUrlFor(formData.URI)">
            <img
              style="height:150px;width:200px;border-width:0px;"
              :alt="formData.Title"
              :src="createPhotoUrlFor(formData.URI)"
            />
          </a>
        </div>
        <div v-else>
          <v-file-input
            v-model="fileLocation"
            accept="image/*"
            capture="environment"
            show-size
            :prepend-icon="icon.photo"
            label="Photo Location"
            :error-messages="errorMessages(vuelidate.fileLocation)"
            @blur="vuelidate.fileLocation.$touch()"
          />
        </div>
        <v-textarea v-model="formData.Description" label="Description" />
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="ml-1" @click="saveClick">
          {{ isEditMode ? 'Update' : 'Upload' }}
        </v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        <v-spacer />
        <v-btn v-if="isEditMode" color="secondary" @click="deleteClick">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" /></div
></template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import FieldService from '@/services/FieldService'
import { toLocaleDateString } from '@/use/DateFormatter'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
export default {
  name: 'PhotoEditor',
  props: {
    photoId: {
      type: String,
      default: null
    },
    siteId: {
      type: String,
      default: null
    },
    scheduledServiceId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref()

    const isEditMode = ref(false)
    const formData = ref()
    const fileLocation = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      isEditMode.value = props.photoId != null
      if (isEditMode.value) {
        loadPhoto()
      } else {
        initNewPhoto()
      }
      vuelidate.value.$reset()
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const deleteClick = () => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete this photo. Are you sure?'
        })
        .then(() => performDelete())
        .catch(() => {
          return
        })
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.inform({
          text: result.Message,
          snackbarTimeout: 10000,
          color: 'error'
        })
      }
    }

    const rules = {
      fileLocation: {
        required: requiredIf(() => {
          return !isEditMode.value
        })
      },
      formData: {
        Title: { required },
        Description: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { fileLocation, formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const loadPhoto = async () => {
      const rq = { InfoId: props.photoId }
      const rs = await host.photo.retrieve(rq)
      formData.value = rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.photo.createWithAttachment(rq, fileLocation.value)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.photo.update(rq)
      return rs
    }

    const performDelete = async () => {
      const result = await deletePhoto(props.photoId)
      afterSaveOrUpdate(result)
      context.emit('update')
    }

    const deletePhoto = async id => {
      const rq = { InfoId: id }
      const rs = await host.photo.delete(rq)
      return rs
    }

    const initNewPhoto = () => {
      fileLocation.value = null
      formData.value = {
        PhotoId: null,
        SiteId: props.siteId,
        ScheduledServiceId: props.scheduledServiceId,
        Title: 'Uploaded Photo',
        Description: null
      }
    }

    const createPhotoUrlFor = uri => {
      return `${
        FieldService.apiURL
      }${uri}&Tenant-Id=${FieldService.getTenantId()}`
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      fileLocation,
      createPhotoUrlFor,
      saveClick,
      cancelClick,
      deleteClick,
      toLocaleDateString,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
