<template>
  <v-card width="400px">
    <v-card-title>
      <DialogTitle :dialogName="$options.name">
        {{ isEdit ? `Edit ${lineType} Line` : `New ${lineType} Line` }}
      </DialogTitle>
    </v-card-title>
    <v-card-text v-if="formData">
      <v-text-field
        v-model="formData.SortOrder"
        label="Line Number"
        type="number"
        :error-messages="errorMessages(vuelidate.formData.SortOrder)"
        @blur="vuelidate.formData.SortOrder.$touch()"
        dense
      >
      </v-text-field>
      <TranCodePicker
        v-model="formData.TranCodeId"
        :error-messages="errorMessages(vuelidate.formData.TranCodeId)"
        @blur="vuelidate.formData.TranCodeId.$touch()"
      />
      <v-text-field
        v-model="formData.Reference"
        label="Reference"
        :error-messages="errorMessages(vuelidate.formData.Reference)"
        @blur="vuelidate.formData.Reference.$touch()"
        dense
      />
      <v-currency-field
        :value="adjRate"
        label="Rate"
        dense
        @input="adjRateUpdate"
      />
      <v-text-field
        v-model="formData.Quantity"
        label="Quantity"
        type="number"
        :error-messages="errorMessages(vuelidate.formData.Quantity)"
        @blur="vuelidate.formData.Quantity.$touch()"
        dense
      />
    </v-card-text>
    <v-card-actions
      ><v-btn color="success" class="ml-3" @click="saveClick">{{
        isEdit ? 'Update' : 'Post'
      }}</v-btn>
      <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { selectListCache } from '@/services/SelectListCache'
export default {
  name: 'InvoiceLine',
  props: {
    itemInfo: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    lineType: {
      type: String,
      default: 'Invoice'
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const formData = ref()
    const isEditMode = ref(false)
    const adjRate = ref()
    const selectLists = ref([])

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      await loadSelectLists()
      vuelidate.value.$reset()
      formData.value = { ...props.itemInfo }
      beginFormDataWatches()
      adjRate.value = isCredit() ? -formData.value.Rate : formData.value.Rate
    }

    const adjRateUpdate = newVal => {
      adjRate.value = newVal
      formData.value.Rate = isCredit() ? -newVal : newVal
    }

    const tranCodeAttribute = (tranCodeId, attrName) => {
      return selectListCache.attribute(
        selectListCache.name.TranCodes,
        tranCodeId,
        attrName
      )
    }

    const isCredit = () => {
      const result = tranCodeAttribute(formData.value.TranCodeId, 'IsCredit')
      return result
    }

    const beginFormDataWatches = () => {
      watch(
        () => formData.value.TranCodeId,
        newVal => {
          if (newVal) {
            formData.value.Reference = tranCodeAttribute(newVal, 'Text')
            const defaultPrice = tranCodeAttribute(newVal, 'DefaultPrice')
            const isCredit = tranCodeAttribute(newVal, 'IsCredit')
            adjRate.value = defaultPrice
            formData.value.Rate = isCredit ? -defaultPrice : defaultPrice
          }
        }
      )

      watch(
        () => formData.value.Rate,
        newVal => {
          if (newVal) {
            formData.value.Amount = newVal * formData.value.Quantity
          }
        }
      )

      watch(
        () => formData.value.Quantity,
        newVal => {
          if (newVal) {
            formData.value.Amount = newVal * formData.value.Rate
          }
        }
      )
    }

    const rules = {
      formData: {
        SortOrder: { required },
        TranCodeId: { required },
        Reference: { required },
        Quantity: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    function errorMessages(item) {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    function validate() {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const saveClick = () => {
      if (validate()) {
        return
      }
      context.emit('update', formData.value)
    }

    initDialog()

    return {
      formData,
      adjRate,
      adjRateUpdate,
      isEditMode,
      errorMessages,
      saveClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
