<template>
  <div>
    <div>
      <PageTitle
        featureName="routes"
        functionName="Organize"
        :pageName="$options.name"
      />
      <div class="d-flex">
        <v-card :width="limitByUser ? 500 : 250" height="840" class="ml-2 mt-2">
          <v-btn
            v-if="invalidAddressList.length > 0"
            color="error"
            class="ml-1 mt-1"
            @click="invalidAddressButtonClick"
            >{{ invalidAddressList.length }} Invalid Addresses</v-btn
          >
          <v-checkbox
            v-model="limitByUser"
            label="Limit by User"
            class="ml-3"
            dense
          />
          <v-card-text>
            <div class="d-flex">
              <div style="width: 250px; height: 770px; overflow-y: scroll;">
                <table>
                  <tr v-for="route in routeList" :key="route.Key">
                    <td width="100%">{{ route.Text }}</td>
                    <td>
                      <v-icon :color="route.color">{{
                        feature.routes.icon
                      }}</v-icon>
                    </td>
                    <td>
                      <v-checkbox v-model="route.visible" class="mb-1" dense />
                    </td>
                  </tr>
                </table>
              </div>
              <div
                v-if="limitByUser"
                style="width: 250px; height: 770px; overflow-y: scroll;"
              >
                <table>
                  <tr v-for="user in userList" :key="user.UserId">
                    <td width="100%">{{ user.UserName }}</td>
                    <td>
                      <v-icon :color="user.color">{{
                        SetupMenuItemList.users.icon
                      }}</v-icon>
                    </td>
                    <td>
                      <v-checkbox v-model="user.visible" class="mb-1" dense />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <div ref="mapOrganizerCanvas" class="flex-grow-1">
          Map goes here
        </div>
      </div>
    </div>

    <v-dialog
      persistent
      v-model="showRecurringService"
      :width="feature.recurringServices.dialogWidth"
    >
      <RecurringServiceEditor
        v-if="selectedSiteListIndex >= 0"
        :isDialogOpen="showRecurringService"
        :siteId="siteList[selectedSiteListIndex].SiteId"
        :recurringServiceId="siteList[selectedSiteListIndex].RecurringServiceId"
        @update="recurringServiceUpdate"
        @close="recurringServiceClose"
      />
    </v-dialog>

    <v-dialog persistent v-model="showSiteRouteEditor" max-width="400">
      <SiteRouteEditor
        v-if="selectedSiteListIndex >= 0"
        :isDialogOpen="showSiteRouteEditor"
        :serviceRouteSiteId="siteList[selectedSiteListIndex].ServiceRouteSiteId"
        :siteName="siteList[selectedSiteListIndex].SiteName"
        @update="siteRouteEditorUpdate"
        @close="siteRouteEditorClose"
      />
    </v-dialog>

    <v-dialog v-model="showInvalidSiteAddressList" max-width="800">
      <InvalidSiteAddressList
        :isDialogOpen="showInvalidSiteAddressList"
        :invalidAddressList="invalidAddressList"
        @update="siteRouteEditorUpdate"
      />
    </v-dialog>
  </div>
</template>

<script>
import { onMounted, ref, watch, computed } from 'vue'
import { icon, feature } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { mapService } from '@/services/GoogleMapService'
import { toLocaleISOString } from '@/use/DateFormatter'
import RecurringServiceEditor from '@/components/ScheduledService/RecurringServiceEditor'
import SiteRouteEditor from '@/components/Site/SiteRouteEditor'
import SetupMenuItemList from '@/components/layout/SetupMenuItemList'
import InvalidSiteAddressList from '@/components/Site/InvalidSiteAddressList'
export default {
  name: 'ServiceRouteOrganizer',
  components: {
    InvalidSiteAddressList,
    RecurringServiceEditor,
    SiteRouteEditor
  },
  setup() {
    const mapOrganizerCanvas = ref()
    const siteList = ref([])
    const userList = ref([])
    const routeList = ref([])
    const limitByUser = ref(false)

    const showRecurringService = ref(false)
    const selectedSiteListIndex = ref(-1)

    const showSiteRouteEditor = ref(false)

    const showInvalidSiteAddressList = ref(false)

    const isAfterInit = ref(false)

    const invalidAddressList = computed(() => {
      return siteList.value.reduce((array, item) => {
        if (item.Address.Latitude == 0 && item.Address.Longitude == 0) {
          array.push({
            SiteId: item.SiteId,
            SiteName: item.SiteName,
            ...item.Address
          })
        }
        return array
      }, [])
    })

    const invalidAddressButtonClick = () => {
      showInvalidSiteAddressList.value = true
    }

    const getOrganizeList = async rq => {
      const rs = await host.serviceRoute.organizeList(rq)
      return rs
    }

    const mapMarkerCallback = index => {
      selectedSiteListIndex.value = index
      const selectedInfo = siteList.value[index]
      if (selectedInfo.RecurringServiceId) {
        showRecurringService.value = true
      } else if (selectedInfo.ServiceRouteSiteId) {
        showSiteRouteEditor.value = true
      }
    }

    onMounted(async () => {
      const todayDate = toLocaleISOString(new Date())
      const rq = { ServiceDate: todayDate }
      siteList.value = (await getOrganizeList(rq)).InfoList
      createUserList()
      createRouteList()
      assignColorsAndIcons()
      updateMap()
      isAfterInit.value = true
    })

    const colorList = [
      'red',
      'blue',
      'green',
      'orange',
      'brown',
      'purple',
      'yellow',
      'pink',
      'magenta',
      'black'
    ]

    const defaultServiceIcon = icon.site

    const createUserList = () => {
      let colorListIndex = 0
      userList.value = siteList.value
        .reduce((accumulator, site) => {
          if (!accumulator.find(value => site.UserId === value.UserId)) {
            accumulator.push({
              UserId: site.UserId,
              UserName: site.UserName,
              visible: false,
              color: site.UserColor
            })
          }
          return accumulator
        }, [])
        .sort((a, b) => {
          return a.UserName < b.UserName ? -1 : 1
        })
        .map(value => {
          if (!value.color) {
            value.color = colorList[colorListIndex]
            if (++colorListIndex > colorList.length) {
              colorListIndex = 0
            }
          }
          return value
        })
    }

    const createRouteList = () => {
      let colorListIndex = 0
      routeList.value = siteList.value
        .reduce((accumulator, site) => {
          const key = site.ServiceRouteId || site.FrequencyDescription
          if (!accumulator.find(value => value.Key === key)) {
            accumulator.push({
              Key: key,
              Text: site.ServiceRouteId ? site.ServiceRouteCode : key,
              visible: false,
              color: site.ServiceRouteColor
            })
          }
          return accumulator
        }, [])
        .sort((a, b) => {
          return a.Text < b.Text ? -1 : 1
        })
        .map(value => {
          if (!value.color) {
            value.color = colorList[colorListIndex]
            if (++colorListIndex > colorList.length) {
              colorListIndex = 0
            }
          }
          return value
        })
    }

    const assignColorsAndIcons = () => {
      for (let i = 0; i < siteList.value.length; i++) {
        const site = siteList.value[i]
        if (!site.UserColor) {
          site.UserColor = userList.value.find(
            item => item.UserId === site.UserId
          ).color
        }

        if (!site.ServiceRouteColor) {
          const key = site.ServiceRouteId || site.FrequencyDescription
          site.ServiceRouteColor = routeList.value.find(
            item => item.Key === key
          ).color
        }

        if (!site.ServiceTypeIcon) {
          site.ServiceTypeIcon = defaultServiceIcon
        }
      }
    }

    const recurringServiceClose = () => {
      showRecurringService.value = false
    }

    const recurringServiceUpdate = () => {
      showRecurringService.value = false
      updateMap()
    }

    const siteRouteEditorClose = () => {
      showSiteRouteEditor.value = false
    }

    const siteRouteEditorUpdate = () => {
      updateMap()
      if (invalidAddressList.length == 0) {
        showSiteRouteEditor.value = false
      }
    }

    watch(
      () => limitByUser.value,
      () => {
        if (isAfterInit.value) {
          updateMap()
        }
      }
    )

    watch(
      () => userList.value,
      () => {
        if (isAfterInit.value) {
          updateMap()
        }
      },
      { deep: true }
    )

    watch(
      () => routeList.value,
      () => {
        if (isAfterInit.value) {
          updateMap()
        }
      },
      { deep: true }
    )

    const updateMap = () => {
      const args = {
        canvas: mapOrganizerCanvas.value,
        limitByUser: limitByUser.value,
        siteList: siteList.value,
        userList: userList.value,
        routeList: routeList.value,
        callback: mapMarkerCallback
      }

      mapService.showServiceLocations(args)
    }

    return {
      feature,
      limitByUser,
      showRecurringService,
      mapOrganizerCanvas,
      siteList,
      userList,
      routeList,
      selectedSiteListIndex,
      recurringServiceClose,
      recurringServiceUpdate,
      siteRouteEditorClose,
      siteRouteEditorUpdate,
      invalidAddressList,
      SetupMenuItemList,
      showSiteRouteEditor,
      invalidAddressButtonClick,
      showInvalidSiteAddressList
    }
  }
}
</script>

<style lang="scss" scoped></style>
