<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Termination Types
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="includeDeletedItems"
          label="Include Deleted Items"
          dense
        />

        <v-data-table
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="infoList"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.IsInfoRequired="{ item }">
            <v-icon v-if="item.IsInfoRequired" color="success">
              {{ icon.lineItemIsChecked }}
            </v-icon>
          </template>
          <template v-slot:item.IsDeleted="{ item }">
            <v-icon v-if="item.IsDeleted" color="error">
              {{ icon.lineItemIsDeleted }}
            </v-icon>
          </template>
          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New Termination Type</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showEditorDialog" width="400" persistent>
      <TerminationTypeEditor
        :infoId="selectedItemId"
        :isDialogOpen="showEditorDialog"
        @close="editorCloseEvent"
        @update="editorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch, computed } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import TerminationTypeEditor from '@/components/Setup/Customer/TerminationTypeEditor'
export default {
  name: 'TerminationTypeList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { TerminationTypeEditor },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      retrievedInfoList.value = []
      includeDeletedItems.value = false
      getTerminationTypeList()
    }

    const retrievedInfoList = ref([])
    const selectedItemId = ref(null)
    const includeDeletedItems = ref(false)
    const showEditorDialog = ref(false)

    const getTerminationTypeList = async () => {
      loading.value = true
      const rq = {}
      const rs = await host.terminationType.list(rq)
      retrievedInfoList.value = rs.InfoList
      loading.value = false
    }

    function editItemClick(item) {
      selectedItemId.value = item.TerminationTypeId
      showEditorDialog.value = true
    }

    function deleteItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Termination Type ${item.Code}`
        })
        .then(() => performDeleteTerminationType(item))
        .catch(() => {
          return
        })
    }

    async function performDeleteTerminationType(item) {
      const rq = { InfoId: item.TerminationTypeId }
      const rs = await host.terminationType.delete(rq)
      if (rs.IsSuccess) {
        getTerminationTypeList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newItemClick() {
      selectedItemId.value = null
      showEditorDialog.value = true
    }

    const headers = [
      { value: 'Code', text: 'Code' },
      { value: 'Description', text: 'Description' },
      { value: 'IsInfoRequired', text: 'Info Required', align: 'center' },
      { value: 'IsDeleted', text: 'Deleted', align: 'center' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const infoList = computed(() => {
      return retrievedInfoList.value
        ? retrievedInfoList.value.filter(
            item => includeDeletedItems.value || !item.IsDeleted
          )
        : null
    })

    const editorCloseEvent = () => {
      showEditorDialog.value = false
    }

    const editorUpdateEvent = () => {
      showEditorDialog.value = false
      getTerminationTypeList()
    }

    initDialog()

    return {
      dialogUtil,
      includeDeletedItems,
      infoList,
      headers,
      showEditorDialog,
      selectedItemId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      editorCloseEvent,
      editorUpdateEvent,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
