<template>
  <div>
    <v-card class="indigo lighten-5">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Customer Portal Settings
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData">
        <v-card width="780">
          <v-card-text>
            <div class="d-flex">
              <div style="width: 360px;">
                <p class="subtitle-1">Note:</p>
                <p>
                  The web address for customer online access to your company
                  is:<br />
                  <a :href="formData.OnlineAddress" target="_blank">{{
                    formData.OnlineAddress
                  }}</a>
                </p>
                <p>
                  You may want to put a link to this address on your own
                  company's web site.
                </p>
              </div>
              <div>
                <v-checkbox
                  v-model="formData.IsEnabled"
                  label="Enable customer portal access"
                  dense
                />
                <v-checkbox
                  v-model="formData.ShowTransactions"
                  label="Enable viewing of transactions"
                  dense
                />
                <v-checkbox
                  v-model="formData.IncludeFutureTransactions"
                  label="Include future transactions"
                  dense
                />
                <v-checkbox
                  v-model="formData.ShowTransactionReports"
                  label="Enable viewing of transaction reports"
                  dense
                />
                <v-checkbox
                  v-model="formData.ShowMeasures"
                  label="Enable viewing of measures"
                  dense
                />
                <v-checkbox
                  v-model="formData.ShowTreatments"
                  label="Enable viewing of treatments"
                  dense
                />
                <v-checkbox
                  v-model="formData.ShowServices"
                  label="Enable viewing of services"
                  dense
                />
                <v-text-field
                  v-model="formData.BalanceForwardBeforeDays"
                  label="Balance Forward before days"
                  type="number"
                  dense
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title class="subtitle-1 blue--text"
            >Home Page Text</v-card-title
          >
          <v-card-text width="780">
            <ckeditor
              :editor="editor"
              v-model="formData.HomePageText"
              :config="{}"
              height="400"
            />
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions
        ><v-btn color="success" class="ml-1" @click="saveClick">Update</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'

export default {
  name: 'PortalSettingsEditor',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const editor = ClassicEditor
    const formData = ref()

    const initDialog = async () => {
      formData.value = null
      formData.value = await retrieveCustomerPortalSettings()
    }

    const retrieveCustomerPortalSettings = async () => {
      const rq = {}
      const rs = await host.customerPortalSettings.retrieve(rq)
      return rs.Info
    }

    const updateCustomerPortalSettings = async () => {
      const rq = { Info: formData.value }
      const rs = await host.customerPortalSettings.update(rq)
      return rs
    }

    const saveClick = async () => {
      const rs = await updateCustomerPortalSettings()
      if (rs.IsSuccess) {
        dialogUtil.value.inform({
          text: rs.Message,
          snackbarTimeout: 3000,
          color: 'success'
        })
        context.emit('close')
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const cancelClick = () => {
      context.emit('close')
    }

    initDialog()

    return { dialogUtil, formData, editor, saveClick, cancelClick }
  }
}
</script>

<style lang="scss" scoped>
.ck-editor__editable {
  min-height: 500px;
}
</style>
