<template>
  <div style="height: 80vh;">
    <v-card class="indigo lighten-5" height="100%">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Sync Interfaces
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tabPage">
          <v-tab key="Connect">Connect</v-tab>
          <v-tab key="Sessions">Sessions</v-tab>
          <v-tab key="SessionSteps">Session Steps</v-tab>
          <v-spacer />
          <v-tab key="Select">Select</v-tab>
          <v-tab key="Settings">Settings</v-tab>
          <div
            v-if="selectedSyncWith"
            class="ml-3 pl-5 pt-3 text-subtitle-1 black--text indigo lighten-5"
          >
            Selected Interface: {{ selectedSyncWith.Description }}
          </div>
        </v-tabs>

        <v-tabs-items v-model="tabPage">
          <v-tab-item class="indigo lighten-5" key="Connect">
            <div v-if="selectedSyncWithId" class="d-flex mt-3 indigo lighten-5">
              <SyncWithConnect
                v-if="selectedSyncWithId"
                :isDialogOpen="tabPage == 0"
                :interfaceType="selectedSyncWith.InterfaceType"
                :infoId="selectedSyncWithId"
              />
            </div>
          </v-tab-item>

          <v-tab-item class="indigo lighten-5" key="Sessions">
            <SyncSessionList
              v-if="selectedSyncWithId"
              :isDialogOpen="tabPage == 1"
              :infoId="selectedSyncWithId"
              @select="syncSessionListSelectEvent"
              @refresh="syncSessionListResetEvent"
            />
          </v-tab-item>

          <v-tab-item class="indigo lighten-5" key="SessionSteps">
            <SyncSessionStepList
              v-if="selectedSyncSession"
              :isDialogOpen="tabPage == 2"
              :syncSession="selectedSyncSession"
            />
          </v-tab-item>

          <v-tab-item class="indigo lighten-5" key="Select">
            <div class="d-flex mt-3 indigo lighten-5">
              <div>
                <v-card width="800">
                  <v-card-title class="subtitle-1 blue--text">
                    Sync Interface List
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      v-if="syncWithList && selectLists"
                      dense
                      :headers="syncWithHeaders"
                      :items="syncWithList"
                      class="elevation-1"
                    >
                      <template v-slot:item.select="{ item }">
                        <v-icon class="mr-2" @click="selectSyncWithClick(item)">
                          {{
                            selectedSyncWithId == item.SyncWithId
                              ? icon.radioSelected
                              : icon.radioUnselected
                          }}
                        </v-icon>
                      </template>
                      <template v-slot:item.UserId="{ item }">
                        <v-icon :color="userColor(item.UserId)">
                          {{ icon.user }}
                        </v-icon>
                        <span class="ml-1">{{
                          userDescription(item.UserId)
                        }}</span>
                      </template>
                      <template v-slot:item.InterfaceType="{ item }">
                        <span class="ml-1">{{
                          interfaceTypeDescription(item.InterfaceType)
                        }}</span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="editSyncWithClick(item)"
                        >
                          {{ icon.lineItemEdit }}
                        </v-icon>
                        <v-icon small @click="deleteSyncWithClick(item)">
                          {{ icon.lineItemDelete }}
                        </v-icon>
                      </template>
                      <template v-slot:footer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              absolute
                              class="mt-3 mr-2"
                              @click="addSyncWithClick"
                            >
                              <v-icon>{{ icon.lineItemAdd }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Add Sync Interface</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-tab-item>

          <v-tab-item class="indigo lighten-5" key="Settings">
            <SyncWithSettingsEditor
              v-if="selectedSyncWithId"
              :isDialogOpen="tabPage == 5"
              :infoId="selectedSyncWithId"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showSyncWithEditorDialog" width="400" persistent>
      <SyncWithEditor
        :isDialogOpen="showSyncWithEditorDialog"
        :infoId="selectedSyncWithId"
        @update="syncWithEditorUpdateEvent"
        @close="syncWithEditorCloseEvent"
      />
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { icon } from '@/use/Constants'
import SyncSessionList from './SyncSessionList.vue'
import SyncSessionStepList from './SyncSessionStepList.vue'
import SyncWithConnect from './SyncWithConnect.vue'
import SyncWithEditor from './SyncWithEditor.vue'
import SyncWithSettingsEditor from './SyncWithSettingsEditor.vue'

export default {
  name: 'SyncWithList',
  components: {
    SyncSessionList,
    SyncSessionStepList,
    SyncWithConnect,
    SyncWithEditor,
    SyncWithSettingsEditor
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const dialogUtil = ref(null)
    const syncWithList = ref([])
    const selectedSyncWithId = ref()
    const selectLists = ref()
    const showSyncWithEditorDialog = ref(false)
    const selectedSyncSession = ref()
    const tabPage = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      selectedSyncWithId.value = null
      selectedSyncSession.value = null
      await loadSyncWiths()
      if (syncWithList.value.length > 0) {
        selectedSyncWithId.value = syncWithList.value[0].SyncWithId
      }

      await loadSelectLists()
      tabPage.value = 0
    }

    const loadSyncWiths = async () => {
      const rq = {}
      const rs = await host.syncWith.list(rq)
      syncWithList.value = rs.InfoList
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.Users,
        selectListCache.name.InterfaceTypes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const userColor = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Color'
      )
    }

    const userDescription = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Text'
      )
    }

    const interfaceTypeDescription = value => {
      return selectListCache.attribute(
        selectListCache.name.InterfaceTypes,
        value,
        'Text'
      )
    }

    const addSyncWithClick = () => {
      selectedSyncWithId.value = null
      selectedSyncSession.value = null
      showSyncWithEditorDialog.value = true
    }

    const editSyncWithClick = item => {
      selectedSyncWithId.value = item.SyncWithId
      showSyncWithEditorDialog.value = true
    }

    const deleteSyncWithClick = item => {
      dialogUtil.value
        .confirm({
          warning: 'Careful! This cannot be undone.',
          text:
            'About to delete sync interface ' +
            item.Description +
            '. By deleting this interface, you also remove all settings for this interface, as well as all links to your customers and transactions for this interface',
          requiredMessage: 'Please type DELETE to confirm',
          requiredValue: 'DELETE'
        })
        .then(() => performDeleteSyncWith(item))
        .catch(() => {
          return
        })
    }

    const performDeleteSyncWith = async item => {
      const rq = { InfoId: item.SyncWithId }
      const rs = await host.syncWith.delete(rq)
      if (rs.IsSuccess) {
        await loadSyncWiths()
        if (syncWithList.value.length > 0) {
          selectedSyncWithId.value = syncWithList.value[0].SyncWithId
        }
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const syncWithEditorCloseEvent = () => {
      showSyncWithEditorDialog.value = false
    }

    const syncWithEditorUpdateEvent = () => {
      loadSyncWiths()
      showSyncWithEditorDialog.value = false
    }

    const selectedSyncWith = computed(() => {
      return syncWithList.value.find(
        element => element.SyncWithId == selectedSyncWithId.value
      )
    })

    const syncWithHeaders = [
      { value: 'select', text: 'Select', sortable: false, width: 60 },
      { value: 'Description', text: 'Description' },
      { value: 'InterfaceType', text: 'Type' },
      { value: 'UserId', text: 'User' },
      { value: 'actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const selectSyncWithClick = item => {
      selectedSyncWithId.value = item.SyncWithId
      selectedSyncSession.value = null
    }

    const syncSessionListSelectEvent = async event => {
      selectedSyncSession.value = event
      tabPage.value = 2
    }

    const syncSessionListResetEvent = () => {
      selectedSyncSession.value = null
    }

    initDialog()
    return {
      dialogUtil,
      syncWithList,
      selectedSyncWithId,
      syncWithHeaders,
      userColor,
      userDescription,
      interfaceTypeDescription,
      selectSyncWithClick,
      addSyncWithClick,
      editSyncWithClick,
      deleteSyncWithClick,
      showSyncWithEditorDialog,
      syncWithEditorCloseEvent,
      syncWithEditorUpdateEvent,
      selectedSyncWith,
      tabPage,
      selectLists,
      selectedSyncSession,
      syncSessionListSelectEvent,
      syncSessionListResetEvent,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
