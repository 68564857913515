<template>
  <div>
    <v-card>
      <v-card-title class="subtitle-1"
        >Map for {{ street }}, {{ city }}, {{ state }}
        {{ postCode }}</v-card-title
      >
      <v-card-text>
        <div ref="mapCanvas" style="height: 700px;">
          Map goes here
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { mapService } from '@/services/GoogleMapService'
export default {
  name: 'MapDisplay',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    street: {
      type: String,
      default: null
    },
    city: {
      type: String,
      default: null
    },
    state: {
      type: String,
      default: null
    },
    postCode: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const mapCanvas = ref()

    const init = () => {
      mapService.showAddress(
        {
          Street: props.street,
          City: props.city,
          State: props.state,
          PostCode: props.postCode
        },
        mapCanvas.value
      )
    }

    watch(
      () => [mapCanvas.value, props.isDialogOpen],
      () => {
        init()
      }
    )

    return { mapCanvas }
  }
}
</script>

<style lang="scss" scoped></style>
