<template>
  <div>
    <v-card width="400px">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Online Payment
        </DialogTitle>
        <div v-if="surchargeMessage" class="red--text subtitle-1 text-break">
          {{ surchargeMessage }}
        </div>
      </v-card-title>
      <v-card-text v-if="selectLists">
        <v-text-field
          v-model="formData.PayerName"
          label="Payer Name"
          :error-messages="errorMessages(vuelidate.formData.PayerName)"
          @blur="vuelidate.formData.PayerName.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.Address"
          label="Address"
          :error-messages="errorMessages(vuelidate.formData.Address)"
          @blur="vuelidate.formData.Address.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.PostalCode"
          label="Postal Code"
          :error-messages="errorMessages(vuelidate.formData.PostalCode)"
          @blur="vuelidate.formData.PostalCode.$touch()"
          dense
        />
        <v-radio-group v-model="formData.PaymentType" dense row class="my-0">
          <v-radio label="Credit Card" value="C" />
          <v-radio v-if="action !== 'A'" label="eCheck" value="B" />
        </v-radio-group>
        <div v-if="formData.PaymentType == 'C'">
          <v-text-field
            v-model="formData.CardNumber"
            label="Card Number"
            type="number"
            :error-messages="errorMessages(vuelidate.formData.CardNumber)"
            @blur="vuelidate.formData.CardNumber.$touch()"
            dense
          />
          <div class="d-inline-flex">
            <v-select
              v-model="formData.ExpirationMonth"
              label="Expiration Month"
              :items="monthSelectList"
              item-text="Text"
              item-value="Value"
              dense
            />
            <v-spacer class="px-2"></v-spacer>
            <v-select
              v-model="formData.ExpirationYear"
              label="Expiration Year"
              :items="yearSelectList"
              item-text="Text"
              item-value="Value"
              dense
            />
          </div>
          <v-text-field
            v-model="formData.Cvv"
            label="Cvv"
            type="number"
            :error-messages="errorMessages(vuelidate.formData.Cvv)"
            @blur="vuelidate.formData.Cvv.$touch()"
            dense
          />
        </div>
        <div v-else>
          <v-radio-group v-model="formData.AccountType" dense row class="my-0">
            <v-radio label="Checking" value="C" />
            <v-radio label="Savings" value="S" />
          </v-radio-group>
          <v-text-field
            v-model="formData.AccountNumber"
            label="Account Number"
            type="number"
            :error-messages="errorMessages(vuelidate.formData.AccountNumber)"
            @blur="vuelidate.formData.AccountNumber.$touch()"
            dense
          />
          <v-text-field
            v-model="formData.RoutingNumber"
            label="Routing Number"
            type="number"
            :error-messages="errorMessages(vuelidate.formData.RoutingNumber)"
            @blur="vuelidate.formData.RoutingNumber.$touch()"
            dense
          />
        </div>
        <v-radio-group v-model="action" dense row class="mb-3 mt-0">
          <v-radio label="One Time" value="O" />
          <v-radio label="Recurring" value="R" />
          <v-radio
            v-if="formData.PaymentType !== 'B'"
            label="Auth Only"
            value="A"
          />
        </v-radio-group>
        <div v-if="action === 'R'">
          <v-checkbox
            v-model="recurringPaymentFormData.PayOutstandingBalance"
            label="Pay Outstanding Balance"
            class="my-0"
            dense
          />
          <v-select
            v-model="recurringPaymentFormData.PaymentFrequencyId"
            label="Payment Frequency"
            :items="selectLists.RecurringPaymentFrequencies"
            item-text="Text"
            item-value="Value"
            :error-messages="
              errorMessages(
                vuelidate.recurringPaymentFormData.PaymentFrequencyId
              )
            "
            @blur="
              vuelidate.recurringPaymentFormData.PaymentFrequencyId.$touch()
            "
            dense
          />
        </div>
        <v-currency-field
          v-if="showAmountField"
          v-model="formData.Amount"
          label="Payment Amount"
          dense
          :error-messages="errorMessages(vuelidate.formData.Amount)"
          @blur="vuelidate.formData.Amount.$touch()"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="success"
          class="ml-3"
          :enabled="isPostButtonEnabled"
          @click="saveClick"
          >Post</v-btn
        >
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { selectListCache } from '@/services/SelectListCache'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minValue } from 'vuelidate/lib/validators'
import { luhnCheck, cvvCheck } from '@/use/CustomValidators'
import { toMoney } from '@/use/NumberFormatter'
import {
  populateMonthSelectList,
  populateYearSelectList
} from '@/use/OnlinePaymentUtil'

export default {
  name: 'OnlinePayment',
  props: {
    customerId: {
      type: String,
      required: true
    },
    customerName: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    postalCode: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    const action = ref('O')
    const formData = ref()
    const recurringPaymentFormData = ref()
    const monthSelectList = ref([])
    const yearSelectList = ref([])
    const selectLists = ref()
    const isPostButtonEnabled = ref(true)
    const onlinePaymentSettings = ref()

    const constructSurchargeInfo = (percent, flat, maxFlatOrPercent) => {
      let info = `${percent > 0 ? percent + '%' : ''}`
      if (flat > 0) {
        let formattedAmount = '$' + toMoney(flat)
        if (percent === 0) {
          info += formattedAmount
        } else {
          if (maxFlatOrPercent) {
            info += ` or ${formattedAmount}, whichever is greater`
          } else {
            info += ` plus ${formattedAmount}`
          }
        }
      }

      return info
    }

    const surchargeMessage = computed(() => {
      if (onlinePaymentSettings.value && formData.value) {
        if (onlinePaymentSettings.value.SurchargeTranCodeId) {
          if (
            formData.value.PaymentType == 'C' &&
            (onlinePaymentSettings.value.CreditCardSurchargePercentage > 0 ||
              onlinePaymentSettings.value.CreditCardSurchargeFlatAmount > 0)
          ) {
            return `Note: A convenience fee of ${constructSurchargeInfo(
              onlinePaymentSettings.value.CreditCardSurchargePercentage,
              onlinePaymentSettings.value.CreditCardSurchargeFlatAmount,
              onlinePaymentSettings.value.IsCreditCardSurchargeMaxFlatOrPercent
            )} is applied to credit card payments`
          }

          if (
            formData.value.PaymentType == 'B' &&
            (onlinePaymentSettings.value.AchSurchargePercentage > 0 ||
              onlinePaymentSettings.value.AchSurchargeFlatAmount > 0)
          ) {
            return `Note: A convenience fee of  ${constructSurchargeInfo(
              onlinePaymentSettings.value.AchSurchargePercentage,
              onlinePaymentSettings.value.AchSurchargeFlatAmount,
              onlinePaymentSettings.value.IsAchSurchargeMaxFlatOrPercent
            )} is applied to eCheck payments`
          }
        }
      }
      return null
    })

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      const today = new Date()
      await retrieveSettings()
      formData.value = {
        CustomerId: props.customerId,
        PayerName: props.customerName,
        Address: props.address,
        PostalCode: props.postalCode,
        PaymentType: 'C',
        CardNumber: null,
        ExpirationMonth: today.getMonth() + 1,
        ExpirationYear: today.getFullYear(),
        Cvv: null,
        AccountType: 'C',
        AccountNumber: null,
        RoutingNumber: null,
        Amount: props.amount
      }

      recurringPaymentFormData.value = {
        PaymentFrequencyId: null,
        PayOutstandingBalance: true
      }

      monthSelectList.value = populateMonthSelectList()
      yearSelectList.value = populateYearSelectList()
      await loadSelectLists()

      vuelidate.value.$reset()
      isPostButtonEnabled.value = true
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.RecurringPaymentFrequencies]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const retrieveSettings = async () => {
      const rq = {}
      const rs = await host.onlinePaymentSettings.retrieve(rq)
      onlinePaymentSettings.value = rs.Info
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      isPostButtonEnabled.value = false

      const rs =
        action.value === 'O'
          ? await postOneTimePayment()
          : action.value === 'A'
          ? await createAuthorization()
          : action.value === 'R'
          ? await createRecurringPayment()
          : { IsSuccess: false, Message: 'Unrecognized action' + action.value }

      if (rs.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(rs.Message)
      }

      isPostButtonEnabled.value = true
    }

    const postOneTimePayment = async () => {
      const rq = { Info: formData.value }
      const rs = await host.onlinePayment.post(rq)
      return rs
    }

    const createAuthorization = async () => {
      const rq = { Info: formData.value }
      const rs = await host.onlinePaymentAuth.create(rq)
      return rs
    }

    const createRecurringPayment = async () => {
      const rq = {
        Info: { ...formData.value, ...recurringPaymentFormData.value }
      }
      const rs = await host.recurringPayment.saveNew(rq)
      return rs
    }

    const rules = {
      formData: {
        PayerName: { required },
        Address: { required },
        PostalCode: { required },
        CardNumber: {
          required: requiredIf(() => {
            return formData.value.PaymentType === 'C'
          }),
          luhnCheck
        },
        Cvv: { cvvCheck },
        AccountNumber: {
          required: requiredIf(() => {
            return formData.value.PaymentType === 'B'
          })
        },
        RoutingNumber: {
          required: requiredIf(() => {
            return formData.value.PaymentType === 'B'
          })
        },
        Amount: {
          minValue: minValue(0)
        }
      },
      recurringPaymentFormData: {
        PaymentFrequencyId: {
          required: requiredIf(() => {
            return action.value === 'R'
          })
        }
      }
    }

    const vuelidate = useVuelidate(rules, {
      formData,
      recurringPaymentFormData
    })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const showAmountField = computed(() => {
      let value =
        !recurringPaymentFormData.value.PayOutstandingBalance ||
        action.value !== 'R'
      return value
    })

    initDialog()
    return {
      dialogUtil,
      action,
      showAmountField,
      isPostButtonEnabled,
      surchargeMessage,
      cancelClick,
      saveClick,
      formData,
      recurringPaymentFormData,
      monthSelectList,
      yearSelectList,
      selectLists,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
