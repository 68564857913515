<template>
  <div>
    <v-card :width="formData.AttributeType == 'C' ? '800' : '400'">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Serviceable Attribute Type
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-card :width="400">
          <v-card-text>
            <v-text-field
              v-model="formData.Description"
              label="Description"
              :error-messages="errorMessages(vuelidate.formData.Description)"
              @blur="vuelidate.formData.Description.$touch()"
              dense
            />
            <v-text-field
              v-model="formData.SortOrder"
              label="Sort Order"
              :error-messages="errorMessages(vuelidate.formData.SortOrder)"
              @blur="vuelidate.formData.SortOrder.$touch()"
              dense
            />
            <v-select
              v-model="formData.AttributeType"
              :items="selectLists.ServiceableAttributeTypes"
              item-text="Text"
              item-value="Value"
              label="Attribute Type"
              :error-messages="errorMessages(vuelidate.formData.AttributeType)"
              @blur="vuelidate.formData.AttributeType.$touch()"
              dense
            />
            <v-text-field
              v-if="formData.AttributeType === 'S'"
              v-model="formData.OptionList"
              label="Options (comma separated list)"
              dense
            />
          </v-card-text>
        </v-card>
        <v-card v-if="!isChild && formData.AttributeType == 'C'" class="mt-3">
          <v-card-title class="subtitle-2"
            >Attribute Types in this Category</v-card-title
          >
          <v-card-text>
            <v-data-table
              dense
              :headers="detailHeaderList"
              :items="detailDataList"
              class="elevation-1"
            >
              <template v-slot:item.AttributeType="{ item }">
                {{ attributeTypeDescription(item.AttributeType) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editServiceableAttributeTypeClick(item)"
                >
                  {{ icon.lineItemEdit }}
                </v-icon>
                <v-icon
                  small
                  @click="deleteServiceableAttributeTypeClick(item)"
                >
                  {{ icon.lineItemDelete }}
                </v-icon>
              </template>
              <template v-slot:footer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      absolute
                      class="mt-3 ml-2"
                      @click="addServiceableAttributeTypeClick"
                    >
                      <v-icon>{{ icon.lineItemAdd }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Serviceable Attribute</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card class="mt-3" width="190">
          <v-card-actions
            ><v-btn color="success" class="ml-1" @click="saveClick">{{
              isEditMode ? 'Update' : 'Save'
            }}</v-btn>
            <v-btn color="error" class="ml-3" @click="cancelClick"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showServiceableAttributeTypeEditorDialog"
      max-width="400"
      persistent
    >
      <ServiceableAttributeTypeEditor
        :isDialogOpen="showServiceableAttributeTypeEditorDialog"
        :item="selectedServiceableAttributeTypeItem"
        :selectLists="selectLists"
        @close="serviceableAttributeTypeEditorClose"
        @update="serviceableAttributeTypeEditorUpdate"
        isChild
      />
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { icon } from '@/use/Constants'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ServiceableAttributeTypeEditor from '@/components/Setup/Service/ServiceableAttributeTypeEditor'
export default {
  name: 'ServiceableAttributeTypeEditor',
  props: {
    item: {
      type: Object,
      required: true
    },
    selectLists: {
      type: Object,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    isChild: {
      type: Boolean,
      default: false
    }
  },
  components: { ServiceableAttributeTypeEditor },
  setup(props, context) {
    const dialogUtil = ref()

    const formData = ref()
    const showServiceableAttributeTypeEditorDialog = ref(false)
    const selectedServiceableAttributeTypeItem = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    watch(
      () => formData.value && formData.value.AttributeType,
      newVal => {
        context.emit('updateAttributeType', newVal)
      }
    )

    const initDialog = () => {
      formData.value = { ...props.item.info }
      vuelidate.value.$reset()
    }

    const isEditMode = computed(() => props.item.index >= 0)

    const saveClick = async () => {
      if (!isInvalid()) {
        context.emit('update', {
          info: formData.value,
          index: props.item.index
        })
      }
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const rules = {
      formData: {
        Description: { required },
        SortOrder: { required },
        AttributeType: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const isInvalid = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const detailHeaderList = [
      {
        value: 'SortOrder',
        text: 'Sort Order',
        width: 10,
        align: 'right'
      },
      {
        value: 'Description',
        text: 'Description',
        width: 40,
        align: 'left'
      },
      { value: 'AttributeType', text: 'Type', width: 40, align: 'left' },
      { value: 'actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const detailDataList = computed(() => {
      return formData.value.AttributeTypeList.map((item, index) => {
        return { ...item, actions: null, _idx: index }
      })
    })

    const attributeTypeDescription = value => {
      return value
        ? props.selectLists.ServiceableAttributeTypes.find(
            item => item.Value == value
          ).Text
        : ''
    }

    const addServiceableAttributeTypeClick = () => {
      selectedServiceableAttributeTypeItem.value = {
        index: -1,
        info: {
          ServiceableAttributeTypeId: null,
          SortOrder: 0,
          AttributeType: 'T',
          AttributeTypeList: []
        }
      }
      showServiceableAttributeTypeEditorDialog.value = true
    }

    const editServiceableAttributeTypeClick = item => {
      selectedServiceableAttributeTypeItem.value = {
        index: item._idx,
        info: formData.value.AttributeTypeList[item._idx]
      }
      showServiceableAttributeTypeEditorDialog.value = true
    }

    const serviceableAttributeTypeEditorClose = () => {
      showServiceableAttributeTypeEditorDialog.value = false
    }

    const serviceableAttributeTypeEditorUpdate = newVal => {
      if (newVal.index > -1) {
        Object.assign(
          formData.value.AttributeTypeList[newVal.index],
          newVal.info
        )
      } else {
        formData.value.AttributeTypeList.push(newVal.info)
      }
      showServiceableAttributeTypeEditorDialog.value = false
    }

    const deleteServiceableAttributeTypeClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete Attribute Type ' + item.Description + '. Are you sure?'
        })
        .then(() => deleteServiceableAttributeType(item))
        .catch(() => {
          return
        })
    }

    const deleteServiceableAttributeType = item => {
      if (item.ServiceableAttributeTypeId) {
        formData.value.DeletedAttributeTypeIdList.push(
          item.ServiceableAttributeTypeId
        )
      }
      formData.value.AttributeTypeList.splice(item._idx, 1)
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      saveClick,
      cancelClick,
      detailHeaderList,
      detailDataList,
      attributeTypeDescription,
      addServiceableAttributeTypeClick,
      editServiceableAttributeTypeClick,
      selectedServiceableAttributeTypeItem,
      showServiceableAttributeTypeEditorDialog,
      serviceableAttributeTypeEditorClose,
      serviceableAttributeTypeEditorUpdate,
      deleteServiceableAttributeTypeClick,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
