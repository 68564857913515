<template>
  <div v-if="formData">
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Email Template Insert Item
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-card width="670">
          <v-card-text>
            <v-text-field
              v-model="formData.Name"
              label="Email Template Insert Name"
              :error-messages="errorMessages(vuelidate.formData.Name)"
              @blur="vuelidate.formData.Name.$touch()"
              dense
            ></v-text-field>
            <v-text-field
              v-model="formData.Text"
              label="Email Template Insert Text"
              :error-messages="errorMessages(vuelidate.formData.Text)"
              @blur="vuelidate.formData.Text.$touch()"
              dense
            ></v-text-field>
            <v-text-field
              v-model="formData.SortOrder"
              type="number"
              label="Sort Order"
              dense
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions
        ><v-btn
          v-if="isEditMode"
          color="success"
          class="ml-3"
          @click="updateClick"
          >Update</v-btn
        >
        <v-btn v-else color="success" class="ml-3" @click="saveClick"
          >Save</v-btn
        >
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { onBeforeMount, ref, computed, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'EmailTemplateInsertEditor',
  props: {
    emailTemplateInsertItem: {
      type: Object,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    onBeforeMount(() => {
      initDialog()
    })

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()

    const isEditMode = computed(() => {
      return formData.value.EmailTemplateInsertId
    })

    const cancelClick = () => {
      context.emit('close')
    }

    const rules = {
      formData: {
        Name: { required },
        Text: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    function initDialog() {
      vuelidate.value.$reset()
      formData.value = JSON.parse(JSON.stringify(props.emailTemplateInsertItem))
    }

    function errorMessages(item) {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    function validate() {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    async function saveClick() {
      if (validate()) {
        return
      }

      afterSaveOrUpdate()
    }

    async function updateClick() {
      if (validate()) {
        return
      }

      afterSaveOrUpdate()
    }

    async function afterSaveOrUpdate() {
      context.emit('update', formData.value)
    }

    initDialog()

    return {
      formData,
      isEditMode,
      errorMessages,
      saveClick,
      updateClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
