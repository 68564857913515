import icon from '@/constants/Icons'

export class SetupMenuItem {
  constructor(title, options = {}) {
    this.title = title
    this.category = options.category || 'Everything Else'
    this.icon = options.icon || icon.setupDefault
    this.color = options.color || 'primary'
    this.dialogWidth = options.dialogWidth || 650
    this.showDialog = false
  }

  closeEvent = () => {
    this.showDialog = false
  }
}
