<template>
  <div>
    <v-card width="400">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Auto Task Rule
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData && actionParams">
        <v-select
          v-model="formData.ActionType"
          :items="selectLists.AutoTaskRuleActionTypes"
          item-text="Text"
          item-value="Value"
          label="Create Task When"
          :error-messages="errorMessages(vuelidate.formData.ActionType)"
          @blur="vuelidate.formData.ActionType.$touch()"
          dense
        />
        <ServiceTypePicker
          v-if="formData.ActionType == AutoTaskRuleActionType.CloseService"
          v-model="actionParams.ServiceTypeId"
          label="With Service Type"
          :error-messages="errorMessages(vuelidate.actionParams.ServiceTypeId)"
          @blur="vuelidate.actionParams.ServiceTypeId.$touch()"
        />
        <v-select
          v-if="formData.ActionType == AutoTaskRuleActionType.PostTransaction"
          v-model="actionParams.Expression"
          :items="numberCompareSelectList"
          item-text="Text"
          item-value="Value"
          label="If Amount Is"
          :error-messages="errorMessages(vuelidate.actionParams.Expression)"
          @blur="vuelidate.actionParams.Expression.$touch()"
          dense
        />
        <v-currency-field
          v-if="formData.ActionType == AutoTaskRuleActionType.PostTransaction"
          v-model="actionParams.Amount"
          label="Amount Posted"
          :error-messages="errorMessages(vuelidate.actionParams.Amount)"
          @blur="vuelidate.actionParams.Amount.$touch()"
          dense
        />
        <TranCodePicker
          v-if="formData.ActionType == AutoTaskRuleActionType.PostTransaction"
          v-model="actionParams.TranCodeId"
          label="To Transaction Code"
          :error-messages="errorMessages(vuelidate.actionParams.TranCodeId)"
          @blur="vuelidate.actionParams.TranCodeId.$touch()"
        />
        <v-select
          v-if="
            formData.ActionType == AutoTaskRuleActionType.FirstPaymentPosted
          "
          v-model="actionParams.ReferralTypeId"
          :items="selectLists.ReferralTypes"
          item-text="Text"
          item-value="Value"
          label="To Customer with Referral Type"
          :error-messages="errorMessages(vuelidate.actionParams.ReferralTypeId)"
          @blur="vuelidate.actionParams.ReferralTypeId.$touch()"
          clearable
          dense
        />
        <v-select
          v-model="formData.UserGroupId"
          :items="selectLists.UserGroups"
          item-text="Text"
          item-value="Value"
          label="By Someone In User Group"
          clearable
          :error-messages="errorMessages(vuelidate.formData.UserGroupId)"
          @blur="vuelidate.formData.UserGroupId.$touch()"
          dense
        />
        <UserPicker
          v-model="formData.AssignToUserId"
          label="Assign Task To"
          :error-messages="errorMessages(vuelidate.formData.AssignToUserId)"
          @blur="vuelidate.formData.AssignToUserId.$touch()"
          dense
        />
        <v-select
          v-model="formData.TaskTypeId"
          :items="selectLists.TaskTypes"
          item-text="Text"
          item-value="Value"
          label="Task Type"
          :error-messages="errorMessages(vuelidate.formData.TaskTypeId)"
          @blur="vuelidate.formData.TaskTypeId.$touch()"
          dense
        />
        <v-textarea
          v-model="formData.TaskComments"
          label="Place In Task Comments"
          :error-messages="errorMessages(vuelidate.formData.TaskComments)"
          @blur="vuelidate.formData.TaskComments.$touch()"
          dense
        />
      </v-card-text>
      <v-card-actions
        ><v-btn color="success" class="ml-1" @click="saveClick">{{
          isEditMode ? 'Update' : 'Save'
        }}</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import {
  AutoTaskRuleActionType,
  numberCompareSelectList,
  parseActionParams,
  generateParamField
} from '@/use/AutoTaskRuleUtil'
export default {
  name: 'AutoTaskRuleEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    selectLists: {
      type: Object,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref()

    const isEditMode = ref(false)
    const formData = ref({
      ActionType: '(none)'
    })
    const actionParams = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    watch(
      () => formData.value.ActionType,
      () => {
        computeParamField()
        rules()
      }
    )

    watch(
      () => actionParams.value,
      () => {
        computeParamField()
      },
      { deep: true }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      formData.value = isEditMode.value
        ? await loadAutoTaskRule()
        : await initNewAutoTaskRule()
      actionParams.value = parseActionParams(formData.value.ActionParams)
      vuelidate.value.$reset()
    }

    const computeParamField = () => {
      formData.value.ActionParams = generateParamField(
        formData.value.ActionType,
        actionParams.value
      )
    }

    const saveClick = () => {
      vuelidate.value.$reset()
      vuelidate.value.$touch()

      const invalid =
        vuelidate.value.formData.$invalid ||
        vuelidate.value.actionParams.$invalid

      if (invalid) {
        return
      }

      const promise = isEditMode.value ? update : saveNew
      promise().then(result => afterSaveOrUpdate(result))
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const rules = () => {
      return {
        formData: {
          ActionType: { required },
          UserGroupId: {},
          AssignToUserId: { required },
          TaskTypeId: { required },
          TaskComments: {}
        },
        actionParams: {
          Expression: {
            required: requiredIf(() => {
              return (
                formData.value.ActionType ==
                AutoTaskRuleActionType.PostTransaction
              )
            })
          },
          Amount: {},
          TranCodeId: {
            required: requiredIf(() => {
              return (
                formData.value.ActionType ==
                AutoTaskRuleActionType.PostTransaction
              )
            })
          },
          ServiceTypeId: {
            required: requiredIf(() => {
              return (
                formData.value.ActionType == AutoTaskRuleActionType.CloseService
              )
            })
          },
          ReferralTypeId: {
            required: requiredIf(() => {
              return (
                formData.value.ActionType ==
                AutoTaskRuleActionType.FirstPaymentPosted
              )
            })
          }
        }
      }
    }

    const vuelidate = useVuelidate(rules, { formData, actionParams })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const initNewAutoTaskRule = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.autoTaskRule.newTemplate(rq)
      return rs.Info
    }

    const loadAutoTaskRule = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.autoTaskRule.retrieve(rq)
      return rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.autoTaskRule.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.autoTaskRule.update(rq)
      return rs
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      actionParams,
      numberCompareSelectList,
      errorMessages,
      saveClick,
      cancelClick,
      AutoTaskRuleActionType,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
