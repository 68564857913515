<template>
  <div>
    <v-card class="indigo lighten-5">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Service Type
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData && selectLists">
        <div class="d-flex">
          <div>
            <v-card width="400">
              <v-card-text class="pb-0">
                <v-text-field
                  v-model="formData.Code"
                  label="Code"
                  :error-messages="errorMessages(vuelidate.formData.Code)"
                  @blur="vuelidate.formData.Code.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.Description"
                  label="Description"
                  :error-messages="
                    errorMessages(vuelidate.formData.Description)
                  "
                  @blur="vuelidate.formData.Description.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.SortOrder"
                  label="Sort Order"
                  type="number"
                  :error-messages="errorMessages(vuelidate.formData.SortOrder)"
                  @blur="vuelidate.formData.SortOrder.$touch()"
                  dense
                />
                <v-currency-field
                  v-model="formData.DefaultAmountToCharge"
                  label="Amount to Charge"
                  clearable
                  dense
                />
                <TranCodePicker v-model="formData.TranCodeId" clearable />
                <v-select
                  v-model="formData.ReportLayout"
                  label="Report Layout"
                  :items="selectLists.ServiceDefReportLayouts"
                  item-text="Text"
                  item-value="Value"
                  clearable
                  dense
                />
                <v-checkbox v-model="formData.IsDeleted" label="Deleted" />
              </v-card-text>
            </v-card>
          </div>
          <div>
            <v-card width="400" class="ml-3">
              <v-card-text class="pb-0">
                <v-select
                  v-model="formData.EmailTemplateId"
                  label="Email Template for Completed Service"
                  :items="selectLists.EmailTemplatesServiceCompleted"
                  item-text="Text"
                  item-value="Value"
                  clearable
                  dense
                />
                <v-select
                  v-model="formData.EmailTemplateIdNew"
                  label="Email Template for New Service"
                  :items="selectLists.EmailTemplatesServiceNew"
                  item-text="Text"
                  item-value="Value"
                  clearable
                  dense
                />
                <v-select
                  v-model="formData.EmailTemplateIdReschedule"
                  label="Email Template Service Reschedule"
                  :items="selectLists.EmailTemplatesServiceReschedule"
                  item-text="Text"
                  item-value="Value"
                  clearable
                  dense
                />
                <IconPicker
                  v-model="formData.IconName"
                  label="Icon"
                  :color="feature.services.color"
                  clearable
                />
              </v-card-text>
            </v-card>
          </div>
        </div>
        <v-card class="mt-3">
          <v-card-title class="subtitle-1">Checklist Items</v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="checklistItemHeaderList"
              :items="checklistItemList"
              class="elevation-1"
            >
              <template v-slot:item.IsDeleted="{ item }">
                <v-icon v-if="item.IsDeleted" color="error">
                  {{ icon.lineItemIsDeleted }}
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editCheckListItemClick(item)"
                >
                  {{ icon.lineItemEdit }}
                </v-icon>
                <v-icon small @click="deleteCheckListItemClick(item)">
                  {{ icon.lineItemDelete }}
                </v-icon>
              </template>
              <template v-slot:footer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      absolute
                      class="mt-3 ml-2"
                      @click="addCheckListItemClick"
                    >
                      <v-icon>{{ icon.lineItemAdd }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Checklist Item</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" class="ml-1" @click="saveClick">
              {{ isEditMode ? 'Update' : 'Save' }}
            </v-btn>
            <v-btn color="error" class="ml-3" @click="cancelClick">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showCheckListItemEditorDialog"
      max-width="400"
      persistent
    >
      <ServiceTypeChecklistItemEditor
        :isDialogOpen="showCheckListItemEditorDialog"
        :item="selectedCheckListItemItem"
        @close="checklistItemEditorClose"
        @update="checklistItemEditorUpdate"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { feature, icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { selectListCache } from '@/services/SelectListCache'
import ServiceTypeChecklistItemEditor from '@/components/Setup/Service/ServiceTypeChecklistItemEditor'
export default {
  name: 'ServiceTypeEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { ServiceTypeChecklistItemEditor },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const formData = ref()
    const serviceIcon = ref([])

    watch(
      () => serviceIcon.value,
      newVal => {
        formData.value.Icon = newVal.join('')
      }
    )

    const showCheckListItemEditorDialog = ref(0)
    const selectedCheckListItemItem = ref()
    const selectLists = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      if (isEditMode.value) {
        await loadServiceType(props.infoId)
      } else {
        await initNewServiceType()
      }
      serviceIcon.value = formData.value.Icon.split('')
      loadSelectLists()
      vuelidate.value.$reset()
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.EmailTemplatesServiceCompleted,
        selectListCache.name.EmailTemplatesServiceNew,
        selectListCache.name.EmailTemplatesServiceReschedule,
        selectListCache.name.ServiceDefReportLayouts,
        selectListCache.name.TranCodes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        selectListCache.invalidate(selectListCache.name.ServiceTypes)
        context.emit('update')
      } else {
        dialogUtil.value.inform({
          text: result.Message,
          snackbarTimeout: 10000,
          color: 'error'
        })
      }
    }

    const tranCodeDescription = value => {
      return value
        ? selectLists.value.TranCodes.find(item => item.Value == value).Text
        : ''
    }

    const rules = {
      formData: {
        Code: { required },
        Description: { required },
        SortOrder: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        if (lineItemListErrors.value) {
          dialogUtil.value.error({
            text: lineItemListErrors.value.join('<br/>')
          })
        }
      }
      return vuelidate.value.$invalid
    }

    const lineItemListErrors = computed(() => {
      const errors = []
      if (
        vuelidate.value.formData.checklistItemList.$dirty &&
        vuelidate.value.formData.checklistItemList.$error
      ) {
        errors.push('At least one amount line is required.')
      }
      return errors
    })

    const loadServiceType = async id => {
      const rq = { InfoId: id }
      const rs = await host.serviceType.retrieve(rq)
      formData.value = rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.serviceType.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.serviceType.update(rq)
      return rs
    }

    const initNewServiceType = async () => {
      const rq = {}
      const rs = await host.serviceType.newTemplate(rq)
      formData.value = rs.Info
    }

    const checklistItemHeaderList = [
      { value: 'SortOrder', text: 'Line', align: 'right' },
      { value: 'Description', text: 'Description' },
      { value: 'IsDeleted', text: 'Deleted', align: 'center' },
      { value: 'actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const checklistItemList = computed(() => {
      return formData.value.Checklist.map((item, index) => {
        return { ...item, actions: null, _idx: index }
      })
    })

    const addCheckListItemClick = () => {
      selectedCheckListItemItem.value = {
        index: -1,
        info: {
          CheckListItemIdId: null,
          TranCodeId: null,
          SortOrder: 0,
          Amount: 0
        }
      }
      showCheckListItemEditorDialog.value = true
    }

    const editCheckListItemClick = item => {
      selectedCheckListItemItem.value = {
        index: item._idx,
        info: formData.value.Checklist[item._idx]
      }
      showCheckListItemEditorDialog.value = true
    }

    const deleteCheckListItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete checklist item. Are you sure?'
        })
        .then(() => deleteCheckListItem(item))
        .catch(() => {
          return
        })
    }

    const deleteCheckListItem = item => {
      if (item.CheckListItemId !== null) {
        formData.value.DeletedChecklistIdList.push(item.CheckListItemId)
      }
      formData.value.Checklist.splice(item._idx, 1)
    }

    const checklistItemEditorClose = () => {
      showCheckListItemEditorDialog.value = false
    }

    const checklistItemEditorUpdate = newVal => {
      if (newVal.index > -1) {
        Object.assign(formData.value.Checklist[newVal.index], newVal.info)
      } else {
        formData.value.Checklist.push(newVal.info)
      }
      showCheckListItemEditorDialog.value = false
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      selectLists,
      saveClick,
      cancelClick,
      checklistItemHeaderList,
      checklistItemList,
      tranCodeDescription,
      addCheckListItemClick,
      editCheckListItemClick,
      deleteCheckListItemClick,
      checklistItemEditorClose,
      checklistItemEditorUpdate,
      showCheckListItemEditorDialog,
      selectedCheckListItemItem,
      feature,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
