<template>
  <div class="d-flex rounded-lg white mb-2 pb-0">
    <div style="width: 140px;">
      <v-text-field
        class="mt-2 ml-2"
        v-model="textValue"
        :label="label"
        :color="color"
        type="number"
        filled
        rounded
        dense
        @blur="updateSliderValue"
      />
    </div>
    <div class="flex-grow-1 mt-4 ml-3">
      <v-slider
        v-model="sliderValue"
        :min="min"
        :max="max"
        :step="step"
        :color="color"
        @end="updateValue"
      >
        <template v-slot:prepend>
          <v-icon :color="color" @click="decrement">
            {{ icon.sliderDecrease }}
          </v-icon>
        </template>

        <template v-slot:append>
          <v-icon :color="color" @click="increment">
            {{ icon.sliderIncrease }}
          </v-icon>
        </template></v-slider
      >
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { icon } from '@/use/Constants'
export default {
  name: 'MeasurementPicker',
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    normalMin: {
      type: Number,
      default: -1
    },
    alertMin: {
      type: Number,
      default: -1
    },
    max: {
      type: Number,
      default: 100
    },
    normalMax: {
      type: Number,
      default: -1
    },
    alertMax: {
      type: Number,
      default: -1
    },
    step: {
      type: Number,
      default: 1
    },
    label: {
      type: String,
      default: 'Value'
    }
  },
  setup(props, context) {
    const sliderValue = ref(0)
    const textValue = ref(0)

    watch(
      () => props.value,
      newVal => {
        sliderValue.value = newVal
        textValue.value = newVal
      },
      { immediate: true }
    )

    watch(
      () => sliderValue.value,
      newVal => {
        textValue.value = newVal
        context.emit('input', newVal)
      }
    )

    const color = computed(() => {
      if (sliderValue.value === props.min) {
        return 'gray'
      } else if (props.alertMin !== -1 && sliderValue.value < props.alertMin) {
        return 'red'
      } else if (
        props.normalMin !== -1 &&
        sliderValue.value < props.normalMin
      ) {
        return 'orange'
      } else if (props.alertMax !== -1 && sliderValue.value > props.alertMax) {
        return 'red'
      } else if (
        props.normalMax !== -1 &&
        sliderValue.value > props.normalMax
      ) {
        return 'orange'
      } else {
        return 'green'
      }
    })

    const updateValue = event => {
      context.emit('input', event)
    }

    const updateSliderValue = event => {
      sliderValue.value = parseFloat(event.target.value)
    }

    const decrement = () => {
      sliderValue.value -= props.step
    }

    const increment = () => {
      sliderValue.value += props.step
    }

    return {
      sliderValue,
      textValue,
      color,
      updateValue,
      updateSliderValue,
      decrement,
      increment,
      icon
    }
  }
}
</script>

<style lang="scss" scoped>
.narrow {
  width: 160px;
}
</style>
