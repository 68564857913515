<template>
  <div>
    <v-card width="6000" class="blue lighten-4">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div>{{ measureId == null ? 'New' : 'Edit' }} Measures</div>
          <div class="subtitle-1">For Site: {{ siteDescription }}</div>
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <div v-if="measureInfo" class="d-flex">
          <div>
            <v-card width="300" class="mr-2">
              <v-card-text>
                <DateTimePicker
                  v-model="measureInfo.WhenTaken"
                  label="When Taken"
                  dense
                />
              </v-card-text>
            </v-card>
          </div>
          <div>
            <v-card width="830" class="ml-2">
              <v-card-text>
                <v-textarea
                  v-model="measureInfo.Notes"
                  label="Notes"
                  rows="2"
                  dense
                />
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div v-if="measureInfo" class="mt-3">
          <MeasurementPicker
            v-for="item in measureInfo.ItemList"
            :key="item.MeasureTypeId"
            v-model="item.Value"
            :label="item.Description"
            :min="item.LowValue"
            :max="item.HighValue"
            :step="item.StepValue"
            :normalMin="item.NormalLowValue"
            :alertMin="item.AlertLowValue"
            :normalMax="item.NormalHighValue"
            :alertMax="item.AlertHighValue"
          />
        </div>
      </v-card-text>
      <v-card-actions class="ml-2">
        <v-btn @click="saveButtonClick" color="success">Save</v-btn>
        <v-btn @click="closeButtonClick" color="error">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'

export default {
  name: 'MeasureEditor',
  props: {
    measureId: {
      type: String,
      default: null
    },
    siteId: {
      type: String,
      required: true
    },
    siteDescription: {
      type: String,
      required: true
    },
    scheduledServiceId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      measureInfo.value =
        props.measureId == null
          ? await newTemplateMeasureInfo(props.siteId, props.scheduledServiceId)
          : await retrieveMeasureInfo(props.measureId)
    }

    const newTemplateMeasureInfo = async (siteId, scheduledServiceId) => {
      const rq = {
        Defaults: { SiteId: siteId, ScheduledServiceId: scheduledServiceId }
      }
      const rs = await host.measure.newTemplate(rq)
      return rs.Info
    }

    const retrieveMeasureInfo = async id => {
      const rq = { InfoId: id }
      const rs = await host.measure.retrieve(rq)
      return rs.Info
    }

    const updateMeasureInfo = async info => {
      const rq = { Info: info }
      const rs = await host.measure.update(rq)
      return rs
    }

    const createMeasureInfo = async info => {
      const rq = { Info: info }
      const rs = await host.measure.create(rq)
      return rs
    }

    const measureInfo = ref()

    const closeButtonClick = () => {
      context.emit('close')
    }

    const saveButtonClick = () => {
      if (props.measureId == null) {
        doCreate()
      } else {
        doUpdate()
      }
    }

    const doCreate = async () => {
      const response = await createMeasureInfo(measureInfo.value)
      if (response.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(response.Message)
      }
    }

    const doUpdate = async () => {
      const response = await updateMeasureInfo(measureInfo.value)
      if (response.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(response.Message)
      }
    }

    initDialog()

    return { dialogUtil, measureInfo, closeButtonClick, saveButtonClick }
  }
}
</script>

<style lang="scss" scoped></style>
