<template>
  <div>
    <PageTitle
      featureName="customers"
      functionName="List"
      :pageName="$options.name"
    />
    <v-card class="mt-1 ml-2" max-width="560">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          Search Criteria
        </div>
      </v-card-title>
      <v-card-text>
        <div class="d-flex">
          <div style="width: 100%;">
            <v-select
              v-if="customViewList"
              v-model="customViewId"
              :items="customViewList"
              item-text="Text"
              item-value="Value"
              label="View"
              dense
              @change="customViewChange"
            />
          </div>
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="editViewClick"
                >
                  <v-icon>{{ icon.edit }}</v-icon>
                </v-btn>
              </template>
              <span>
                Edit This View
              </span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="newViewClick"
                >
                  <v-icon>{{ icon.addNew }}</v-icon>
                </v-btn>
              </template>
              <span>
                Create New View
              </span>
            </v-tooltip>
          </div>
          <div>
            <SendEmailButton
              :showWaitDialog="showSendEmailWaitDialog"
              subtitle="Customer Notification"
              @update="sendEmailUpdateEvent"
            />
          </div>
        </div>
        <div>
          <a v-on:click="selectLetter('*')">All</a>
          <a
            v-for="letter in lettersOfAlphabet"
            :key="letter"
            v-on:click="selectLetter(letter)"
          >
            | {{ letter }}</a
          >
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-2 mx-2">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          Search Results
        </div>
      </v-card-title>
      <v-data-table
        v-if="dataTable"
        :items-per-page="50"
        :footer-props="{ 'items-per-page-options': items_per_page }"
        :headers="dataTable.HeaderList"
        :items="dataTable.LineItemList"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="dataTable.TotalRecords"
        :loading="loading"
        @click:row="selectRow"
        class="elevation-1"
        dense
      >
        <template v-slot:item.ServiceDate="{ item }">
          <span>{{ new Date(item.ServiceDate).toLocaleDateString() }}</span>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="showEditorDialog" width="1050" persistent>
      <CustomViewEditor
        :infoId="selectedItemId"
        :isDialogOpen="showEditorDialog"
        @close="editorCloseEvent"
        @update="editorUpdateEvent"
      />
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import SendEmailButton from '@/components/Common/SendEmailButton'
import CustomViewEditor from '@/components/Setup/Customer/CustomView/CustomViewEditor'
export default {
  name: 'SearchCustomers',
  components: { CustomViewEditor, SendEmailButton },
  setup() {
    const dialogUtil = ref()

    const router = useRouter()
    const loading = ref(true)
    const options = ref({})
    const sortBy = ref('')
    const sortDesc = ref(false)
    const customViewList = ref(null)
    const customViewId = ref(null)
    const selectedLetter = ref('')
    const dataTable = ref({})
    const items_per_page = [10, 15, 20, 50]

    const showEditorDialog = ref(false)
    const selectedItemId = ref()

    const lettersOfAlphabet = computed(() => {
      return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    })

    const showSendEmailWaitDialog = ref(false)

    const selectLetter = value => {
      selectedLetter.value = value
    }

    const getCustomViewListFromApi = async () => {
      const rq = {}
      const rs = await host.customer.customViewList(rq)
      customViewList.value = rs.CustomViewList
      customViewId.value = rs.CustomViewId
    }

    const getDataFromApi = async () => {
      if (customViewId.value) {
        loading.value = true
        const { sortBy, sortDesc, page, itemsPerPage } = options.value

        const rq = {
          Pagination: {
            Page: page,
            RowsPerPage: itemsPerPage,
            SortBy: sortBy.length > 0 ? sortBy[0] : 'Date',
            IsDescending: sortDesc.length > 0 ? sortDesc[0] : false
          },
          CustomViewId: customViewId.value,
          Letter: selectedLetter.value
        }

        const rs = await host.customer.loadCustomViewDataTable(rq)
        dataTable.value = rs
        loading.value = false
      }
    }

    const customViewChange = () => {
      getDataFromApi()
    }

    const selectRow = event => {
      router.push({
        name: 'customer-summary',
        params: { id: event.AccountId }
      })
    }

    watch(
      () => options.value,
      () => {
        getDataFromApi().catch(e => alert('error: ' + e.message))
      },
      { deep: true }
    )

    watch(
      () => customViewId.value,
      () => {
        options.value.page = 1
        getDataFromApi().catch(e => alert('error: ' + e.message))
      }
    )

    watch(
      () => selectedLetter.value,
      () => {
        options.value.page = 1
        getDataFromApi().catch(e => alert('error: ' + e.message))
      }
    )

    const editViewClick = () => {
      selectedItemId.value = customViewId.value
      showEditorDialog.value = true
    }

    const newViewClick = () => {
      selectedItemId.value = null
      showEditorDialog.value = true
    }

    const editorCloseEvent = () => {
      showEditorDialog.value = false
    }

    const editorUpdateEvent = () => {
      showEditorDialog.value = false
      getCustomViewListFromApi()
    }

    const sendEmailUpdateEvent = async args => {
      showSendEmailWaitDialog.value = true
      const rq = {
        Info: {
          CustomViewId: customViewId.value,
          Bcc: args.bcc,
          Subject: args.subject,
          Body: args.body
        }
      }
      const rs = await SendCustomerNotifications(rq)
      showSendEmailWaitDialog.value = false
      dialogUtil.value.inform(rs.Message)
    }

    const SendCustomerNotifications = async rq => {
      const rs = await host.email.sendCustomerNotifications(rq)
      return rs
    }

    getCustomViewListFromApi()

    return {
      dialogUtil,
      lettersOfAlphabet,
      selectLetter,
      loading,
      options,
      sortBy,
      sortDesc,
      customViewList,
      customViewId,
      selectedLetter,
      dataTable,
      items_per_page,
      customViewChange,
      selectRow,
      editViewClick,
      newViewClick,
      selectedItemId,
      showEditorDialog,
      editorCloseEvent,
      editorUpdateEvent,
      showSendEmailWaitDialog,
      sendEmailUpdateEvent,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
