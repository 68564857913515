<template>
  <div>
    <v-card width="400px">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Post One-time Payment
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-currency-field
          v-model="formData.PaymentAmount"
          label="Payment Amount"
          dense
          :error-messages="errorMessages(vuelidate.formData.PaymentAmount)"
          @blur="vuelidate.formData.PaymentAmount.$touch()"
        >
        </v-currency-field>
        <v-text-field
          v-model="formData.Reference"
          label="Reference"
          dense
          :error-messages="errorMessages(vuelidate.formData.Reference)"
          @blur="vuelidate.formData.Reference.$touch()"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="isProcessing"
          color="success"
          class="ml-3"
          @click="postButtonClick"
        >
          Post
        </v-btn>
        <v-btn color="error" class="ml-3" @click="cancelButtonClick">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'

export default {
  name: 'RecurringPaymentOneTimePost',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    oneTimePaymentInfo: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref({
      RecurringPaymentId: null,
      PaymentAmount: 0,
      Reference: null
    })

    const isProcessing = ref(false)

    const initDialog = () => {
      vuelidate.value.$reset()
      isProcessing.value = false
      const newVal = props.oneTimePaymentInfo
      formData.value.RecurringPaymentId = newVal.RecurringPaymentId
      formData.value.PaymentAmount = newVal.PaymentAmount
      formData.value.Reference = null
    }

    const postButtonClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      isProcessing.value = true
      const rq = formData.value
      const rs = await host.recurringPayment.oneTimePost(rq)

      if (rs.IsSuccess) {
        dialogUtil.value.inform({
          text: rs.Message,
          snackbarTimeout: 10000,
          color: 'success'
        })
        context.emit('close')
      } else {
        dialogUtil.value.error(rs.Message)
        isProcessing.value = false
      }
    }

    const cancelButtonClick = () => {
      context.emit('close')
    }

    const rules = {
      formData: {
        PaymentAmount: { minValue: minValue(0) },
        Reference: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    initDialog()

    return {
      dialogUtil,
      formData,
      isProcessing,
      postButtonClick,
      cancelButtonClick,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
