import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'

const cache = new Map()

const selectListName = {
  AgedDayList: 'AgedDayList',
  AutoTaskRuleActionTypes: 'AutoTaskRuleActionTypes',
  BillingGroups: 'BillingGroups',
  CommissionOrderBy: 'CommissionOrderBy',
  CommissionTypes: 'CommissionTypes',
  CustomerStatus: 'CustomerStatus',
  CustomerTypes: 'CustomerTypes',
  CustomerAlertTypes: 'CustomerAlertTypes',
  CustomerAgreementDocuments: 'CustomerAgreementDocuments',
  EmailEvents: 'EmailEvents',
  EmailInserts: 'EmailInserts',
  EmailTemplates: 'EmailTemplates',
  EmailTemplatesCustomerNotification: 'EmailTemplatesCustomerNotification',
  EmailTemplatesReport: 'EmailTemplatesReport',
  EmailTemplatesServiceCompleted: 'EmailTemplatesServiceCompleted',
  EmailTemplatesServiceNew: 'EmailTemplatesServiceNew',
  EmailTemplatesServiceReschedule: 'EmailTemplatesServiceReschedule',
  EstimateStatus: 'EstimateStatus',
  InterfaceTypes: 'InterfaceTypes',
  LateFeeOptions: 'LateFeeOptions',
  RecurringPaymentFrequencies: 'RecurringPaymentFrequencies',
  Rates: 'Rates',
  ReferralTypes: 'ReferralTypes',
  ReportMediaTypes: 'ReportMediaTypes',
  SalesTaxes: 'SalesTaxes',
  ServiceableAttributeTypes: 'ServiceableAttributeTypes',
  ServiceCommissionRulePayTos: 'ServiceCommissionRulePayTos',
  ServiceDefReportLayouts: 'ServiceDefReportLayouts',
  ServiceRoutes: 'ServiceRoutes',
  ServiceSeasons: 'ScheduledServiceSeasons',
  ServiceStatus: 'ServiceStatus',
  ServiceTypes: 'ServiceTypes',
  SyncWiths: 'SyncWiths',
  TaskPriorities: 'TaskPriorities',
  TaskTypes: 'TaskTypes',
  TerminationTypes: 'TerminationTypes',
  TimeZones: 'TimeZones',
  TranCodeGroupCategories: 'TranCodeGroupCategories',
  TranCodeGroups: 'TranCodeGroups',
  TranCodes: 'TranCodes',
  UserGroups: 'UserGroups',
  Users: 'Users'
}

const getSelectLists = async selectListNames => {
  const selectLists = {}

  const selectListNamesToRetrieve = selectListNames.filter(selectListName => {
    let selectList = cache.get(selectListName)
    return !selectList || selectList.isStale
  })

  if (selectListNamesToRetrieve.length > 0) {
    const rq = { Criteria: selectListNamesToRetrieve }
    const rs = await loadSelectLists(rq)
    for (const [key, value] of Object.entries(rs)) {
      const modifiedList = mergeExtraData(value)
      const selectList = newSelectListObject(modifiedList)
      cache.set(key, selectList)
    }
  }

  selectListNames.forEach(selectListName => {
    let selectList = cache.get(selectListName)
    selectLists[selectListName] = selectList.list
  })

  return selectLists
}

const loadSelectLists = async rq => {
  const rs = await host.selectList.get(rq)
  return rs.Info
}

const getSelectListItem = (name, value) => {
  const selectList = cache.get(name)
  if (!selectList) {
    return ''
  }

  return selectList.list.find(item => item.Value == value)
}

const getSelectListItemAttribute = (name, value, attributeName) => {
  const selectListItem = getSelectListItem(name, value)
  return selectListItem ? selectListItem[attributeName] : null
}

const invalidateSelectList = name => {
  const selectList = cache.get(name)
  if (selectList) {
    selectList.isStale = true
  }
}

const clearCache = () => {
  cache.clear()
}

export const selectListCache = {
  name: selectListName,
  get: getSelectLists,
  attribute: getSelectListItemAttribute,
  invalidate: invalidateSelectList,
  reset: clearCache
}

const newSelectListObject = list => {
  return {
    timestamp: new Date(),
    isStale: false,
    list: mergeExtraData(list)
  }
}

const mergeExtraData = list => {
  if (list.length > 0 && list[0].ExtraData !== undefined) {
    return list.map(item => {
      return { ...item, ...JSON.parse(item.ExtraData) }
    })
  } else {
    return list
  }
}

const init = () => {
  addTaskPriorityListToCache()
}

const addTaskPriorityListToCache = () => {
  const list = [
    {
      Value: '0',
      Text: 'Low Priority',
      Color: 'green',
      Icon: icon.priorityLow
    },
    {
      Value: '1',
      Text: 'Medium Priority',
      Color: 'orange',
      Icon: icon.priorityMedium
    },
    {
      Value: '2',
      Text: 'High Priority',
      Color: 'red',
      Icon: icon.priorityHigh
    }
  ]

  const selectListObject = newSelectListObject(list)
  selectListObject.timestamp = null
  cache.set('TaskPriorities', selectListObject)
}

init()

export { getSelectLists }
