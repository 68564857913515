<template>
  <v-card width="400px" class="pt-2 pl-3 ma-2 indigo lighten-5">
    <v-card-title>
      <span>{{ cardData.Reference }}</span>
    </v-card-title>
    <v-card-text>
      <v-container class="mb-2" row>
        <div>
          <v-icon :color="batchType[cardData.Type].color">
            {{ batchType[cardData.Type].icon }}
          </v-icon>
          <span class="ml-2">
            {{ batchType[cardData.Type].description }}
          </span>
        </div>
        <v-spacer />
        <div>
          <v-icon :color="batchStatus[cardData.Status].color">
            {{ batchStatus[cardData.Status].icon }}
          </v-icon>
          <span class="ml-2">
            {{ batchStatus[cardData.Status].description }}
          </span>
        </div>
      </v-container>
      <v-card>
        <v-card-text>
          <div>Created {{ toLocaleDateTimeString(cardData.WhenCreated) }}</div>
          <div>By {{ cardData.CreatedBy }}</div>
          <div v-if="cardData.WhenClosed">
            Closed {{ toLocaleDateTimeString(cardData.WhenClosed) }}
          </div>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { batchType, batchStatus } from '@/use/Constants'
import { toLocaleDateTimeString } from '@/use/DateFormatter'

export default {
  name: 'BillingBatchCard',
  props: {
    cardData: {
      type: Object,
      required: true
    }
  },
  setup() {
    return { batchType, batchStatus, toLocaleDateTimeString }
  }
}
</script>

<style lang="scss" scoped></style>
