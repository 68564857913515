<template>
  <v-card width="1000" height="640">
    <v-card-title>
      <DialogTitle :dialogName="$options.name">
        Search
      </DialogTitle>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="currentPage">
        <v-tab key="1">Customers</v-tab>
        <v-tab key="2">Recents</v-tab>
        <v-tab key="3">Transactions</v-tab>
        <v-tab-item key="1"
          ><CustomerSearch
            :isDialogOpen="isDialogOpen"
            @select="customerSearchSelect"
          />
          ></v-tab-item
        >
        <v-tab-item key="2"
          ><RecentlyAccessedRecords
            :isDialogOpen="isDialogOpen"
            @select="recentlyAccessedRecordsSelect"
          />
        </v-tab-item>
        <v-tab-item key="3"
          ><BillingSearch
            :isDialogOpen="isDialogOpen"
            @select="billingSearchSelect"
          />
          ></v-tab-item
        >
      </v-tabs></v-card-text
    >
  </v-card>
</template>

<script>
import { ref, watch } from 'vue'
import RecentlyAccessedRecords from '@/components/controls/RecentlyAccessedRecords'
import CustomerSearch from '@/components/Customer/CustomerSearch'
import BillingSearch from '@/components/Billing/BillingSearch'

export default {
  name: 'SearchDialog',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BillingSearch,
    CustomerSearch,
    RecentlyAccessedRecords
  },
  setup(props, context) {
    const currentPage = ref('1')

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      currentPage.value = '1'
    }

    const recentlyAccessedRecordsSelect = event => {
      context.emit('select', event)
    }

    const customerSearchSelect = event => {
      context.emit('select', {
        RecordType: 'A',
        RecordId: event.CustomerId,
        SiteId: event.SiteId
      })
    }

    const billingSearchSelect = event => {
      context.emit('billingSelect', event)
    }

    return {
      currentPage,
      recentlyAccessedRecordsSelect,
      customerSearchSelect,
      billingSearchSelect
    }
  }
}
</script>
