import axios from 'axios'

// const apiURL = 'http://localhost:5000'
const apiURL = 'https://cloudmanagerapi.poolcareonline.com'

export default {
  headers: {
    Accept: 'application/x-www-form-urlencoded',
    'Content-Type': 'application/json',
    Authorization: 'ApiKey BrU18vxzY1s30YPdVQl88IPmlQyf9Rx6jsOjIHUb'
  },
  baseURL: apiURL,
  doPost(uri, rq) {
    return axios.post(uri, rq, { baseURL: this.baseURL, headers: this.headers })
  }
}
