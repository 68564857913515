<template>
  <div>
    <v-card width="820px" v-if="formData && officeAddress">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Optimize Route
        </DialogTitle>
        <div class="blue--text">
          <div class="text-subtitle-1 ">
            Route {{ formData.Code }} -- {{ formData.Description }}
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="d-flex">
          <RouteOptimizeEndpointEditor
            title="Start From"
            v-model="formData.OptimizeStartFrom"
            :officeAddress="officeAddress"
            :serviceItemList="formData.SiteList"
            :isDialogOpen="isEndpointDataReady"
          />
          <RouteOptimizeEndpointEditor
            class="ml-3"
            title="End At"
            v-model="formData.OptimizeEndAt"
            :officeAddress="officeAddress"
            :serviceItemList="formData.SiteList"
            :isDialogOpen="isEndpointDataReady"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-2" color="primary" @click="optimizeClick">
          <span>Optimize</span>
          <v-icon right>{{ icon.mapOptimize }}</v-icon>
        </v-btn>
        <v-btn color="error" @click="cancelClick">
          <span>Back</span>
          <v-icon right>{{ icon.exit }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <WaitDialog
      v-model="showWaitDialog"
      title="Optimizing"
      :subtitle="waitDialogTitle"
    />
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'
import RouteOptimizeEndpointEditor from '@/components/ScheduledService/RouteOptimizeEndpointEditor'
import WaitDialog from '@/components/Common/WaitDialog'
export default {
  name: 'ServiceRouteOptimizer',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    serviceRouteId: {
      type: String,
      required: true
    }
  },
  components: { RouteOptimizeEndpointEditor, WaitDialog },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const officeAddress = ref()
    const formData = ref()
    const isEndpointDataReady = ref(false)
    const showWaitDialog = ref(false)
    const waitDialogTitle = ref()

    const initDialog = async () => {
      formData.value = await retrieveServiceRoute(props.serviceRouteId)
      const officeInfo = await retrieveOffice()
      officeAddress.value = officeInfo.Address
      waitDialogTitle.value = `Route ${formData.value.Code} - ${formData.value.Description}`
      isEndpointDataReady.value = true
    }

    const retrieveServiceRoute = async id => {
      const rq = { InfoId: id }
      const rs = await host.serviceRoute.retrieveWithSiteList(rq)
      rs.Info.OptimizeStartFrom.UserId =
        rs.Info.OptimizeStartFrom.UserId || rs.Info.UserId
      rs.Info.OptimizeEndAt.UserId =
        rs.Info.OptimizeEndAt.UserId || rs.Info.UserId
      return rs.Info
    }

    const retrieveOffice = async () => {
      const rq = {}
      const rs = await host.office.retrieve(rq)
      return rs.Info
    }

    const optimizeClick = async () => {
      showWaitDialog.value = true

      const rq = {
        ServiceRouteId: formData.value.Id,
        StartFrom: formData.value.OptimizeStartFrom,
        EndAt: formData.value.OptimizeEndAt
      }

      const rs = await host.serviceRoute.optimize(rq)
      showWaitDialog.value = false

      if (rs.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const cancelClick = () => {
      context.emit('close')
    }

    initDialog()
    return {
      dialogUtil,
      formData,
      isEndpointDataReady,
      officeAddress,
      optimizeClick,
      cancelClick,
      waitDialogTitle,
      showWaitDialog,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
