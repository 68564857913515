<template>
  <div>
    <v-dialog v-model="showConfirmDialog" max-width="400" persistent>
      <v-card width="400">
        <v-card-title>
          <DialogTitle>
            <div>
              {{ title }}
            </div>
            <div class="red--text">
              {{ warning }}
            </div>
          </DialogTitle>
        </v-card-title>
        <v-card-text>
          <div class="black--text">
            {{ message }}
          </div>
          <div v-if="confirmRequiredValue !== ''" class="mt-4">
            <v-text-field
              v-model="confirmValue"
              :label="confirmRequiredMessage"
              dense
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn class="ml-3" @click="cancelClick">Cancel</v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="
              confirmRequiredValue && confirmValue !== confirmRequiredValue
            "
            @click="acceptClick"
            >Accept</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showErrorDialog"
      top
      color="red"
      :timeout="errorTimeout"
    >
      <div>
        <v-btn text @click="showErrorDialog = false">
          <div style="width: 100%">
            <span>{{ errorText }}</span>
            <span class="float-right">
              <v-icon right>mdi-close</v-icon>
            </span>
          </div>
        </v-btn>
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="showInformDialog"
      top
      :color="informColor"
      :timeout="informTimeout"
    >
      <div>
        <v-btn text @click="showInformDialog = false">
          <div style="width: 100%">
            <span>{{ informText }}</span>
            <span class="float-right">
              <v-icon right>mdi-close</v-icon>
            </span>
          </div>
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'DialogUtil',
  setup() {
    const showConfirmDialog = ref(false)
    const title = ref()
    const warning = ref()
    const message = ref()
    var dialogResolver = null
    var dialogRejector = null

    const showErrorDialog = ref(false)
    const errorText = ref()
    const errorTimeout = ref(3000)

    const showInformDialog = ref(false)
    const informText = ref()
    const informColor = ref()
    const informTimeout = ref(3000)

    const confirmRequiredValue = ref()
    const confirmRequiredMessage = ref()
    const confirmValue = ref()

    const confirm = args => {
      title.value = args.title ?? 'Please confirm'
      warning.value = args.warning
      message.value = args.text
      confirmRequiredMessage.value = args.requiredMessage
      confirmRequiredValue.value = args.requiredValue
      showConfirmDialog.value = true
      confirmValue.value = ''
      return new Promise((resolve, reject) => {
        dialogResolver = resolve
        dialogRejector = reject
      })
    }

    const error = message => {
      errorText.value = message
      showErrorDialog.value = true
    }

    const inform = args => {
      if (typeof args == 'string' || args instanceof String) {
        informText.value = args
      } else {
        informText.value = args.text ?? 'fyi'
        informColor.value = args.color ?? 'success'
        informTimeout.value = args.timeout ?? 3000
      }

      showInformDialog.value = true
    }

    const acceptClick = async () => {
      showConfirmDialog.value = false
      dialogResolver(true)
    }

    const cancelClick = () => {
      showConfirmDialog.value = false
      dialogRejector(new Error('cancel'))
    }

    return {
      confirm,
      error,
      inform,
      title,
      warning,
      message,
      showConfirmDialog,
      showErrorDialog,
      errorText,
      errorTimeout,
      showInformDialog,
      informText,
      informColor,
      informTimeout,
      confirmRequiredMessage,
      confirmRequiredValue,
      confirmValue,
      acceptClick,
      cancelClick
    }
  }
}
</script>

<style lang="scss" scoped></style>
