<template>
  <div>
    <v-card class="mt-2">
      <v-card-text>
        <div class="d-flex">
          <div>
            <v-btn
              v-for="serviceable in cardData.ServiceableList"
              :key="serviceable.ServiceableId"
              small
              color="primary"
              class="ma-1"
              @click="serviceableItemClick(serviceable)"
              >{{ serviceable.Description }}</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-menu
              :nudge-width="200"
              translate="slide-x-reverse-transition"
              offset-x
            >
              <template #activator="{ on: onMenu }">
                <v-tooltip top>
                  <template #activator="{ on: onTooltip }">
                    <v-btn icon v-on="{ ...onMenu, ...onTooltip }">
                      <v-icon :color="feature.serviceables.color">
                        {{ icon.addNew }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Add Serviceable Item</span>
                </v-tooltip>
              </template>
              <v-card>
                <v-card-text class="py-0">
                  <v-list dense>
                    <v-list-item
                      v-for="serviceableType in cardData.ServiceableTypeList"
                      :key="serviceableType.ServiceableTypeId"
                      @click="addServiceableItemClick(serviceableType)"
                    >
                      <v-list-item-icon color="primary">
                        <v-icon :color="feature.serviceables.color">
                          {{ feature.serviceables.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          Add
                          {{ serviceableType.Description }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="measureListClick"
                >
                  <v-icon :color="feature.serviceables.color">
                    {{ icon.measure }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Measures</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="treatmentListClick"
                >
                  <v-icon :color="feature.serviceables.color">
                    {{ icon.treatment }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Treatments</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showServiceableEditorDialog" :width="1000">
      <ServiceableEditor
        :isDialogOpen="showServiceableEditorDialog"
        :serviceableId="selectedServiceableId"
        :siteId="cardData.SiteId"
        :serviceableTypeId="selectedServiceableTypeId"
        :description="selectedDescription"
        @close="serviceableEditorCloseEvent"
        @update="serviceableEditorUpdateEvent"
      />
    </v-dialog>

    <v-dialog v-model="showMeasureListDialog" width="1200">
      <MeasureList
        :isDialogOpen="showMeasureListDialog"
        :siteId="cardData.SiteId"
        :siteDescription="cardData.Description"
        @close="measureListCloseEvent"
      />
    </v-dialog>

    <v-dialog v-model="showTreatmentListDialog" width="1200">
      <TreatmentList
        :isDialogOpen="showTreatmentListDialog"
        :siteId="cardData.SiteId"
        :siteDescription="cardData.Description"
        @close="treatmentListCloseEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { icon, feature } from '@/use/Constants'
import ServiceableEditor from '@/components/Site/ServiceableEditor'
import MeasureList from '@/components/Site/MeasureList'
import TreatmentList from '@/components/Site/TreatmentList'
export default {
  name: 'ServiceablesCard',
  props: {
    cardData: {
      type: Object,
      required: true
    }
  },
  components: { ServiceableEditor, MeasureList, TreatmentList },
  setup(props, context) {
    const showServiceableEditorDialog = ref(false)
    const selectedServiceableId = ref()
    const selectedServiceableTypeId = ref()
    const selectedDescription = ref()
    const showMeasureListDialog = ref(false)

    const showTreatmentListDialog = ref(false)

    const serviceableItemClick = serviceable => {
      selectedServiceableId.value = serviceable.ServiceableId
      selectedServiceableTypeId.value = null
      selectedDescription.value = serviceable.Description
      showServiceableEditorDialog.value = true
    }

    const addServiceableItemClick = serviceableType => {
      selectedServiceableId.value = null
      selectedServiceableTypeId.value = serviceableType.ServiceableTypeId
      selectedDescription.value = serviceableType.Description
      showServiceableEditorDialog.value = true
    }

    const serviceableEditorCloseEvent = () => {
      showServiceableEditorDialog.value = false
    }

    const serviceableEditorUpdateEvent = () => {
      showServiceableEditorDialog.value = false
      context.emit('update')
    }

    const measureListClick = () => {
      showMeasureListDialog.value = true
    }

    const measureListCloseEvent = () => {
      showMeasureListDialog.value = false
    }

    const treatmentListClick = () => {
      showTreatmentListDialog.value = true
    }

    const treatmentListCloseEvent = () => {
      showTreatmentListDialog.value = false
    }

    return {
      feature,
      serviceableItemClick,
      addServiceableItemClick,
      showServiceableEditorDialog,
      selectedServiceableId,
      selectedServiceableTypeId,
      selectedDescription,
      serviceableEditorCloseEvent,
      serviceableEditorUpdateEvent,
      showMeasureListDialog,
      measureListClick,
      measureListCloseEvent,
      treatmentListClick,
      showTreatmentListDialog,
      treatmentListCloseEvent,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
