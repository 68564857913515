<template>
  <div>
    <v-card class="indigo lighten-5">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">Report Designer</DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-card>
          <v-card-text class="blue--text subtitle-2">
            Use the report designer tool to customize your invoices, statements,
            mailing labels, etc.
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>Download Instructions</v-card-title>
          <v-card-text>
            <div>
              The report designer tool runs on your computer.
            </div>
            <div>
              <a :href="reportDesignerClickOnceInstallUrl" target="_blank"
                >Click here</a
              >
              to install and run the report designer for the first time.
            </div>
            <div class="mt-2">
              Log in using the same user name and password you use when you log
              into PoolCarePro. Select the report you wish to customize. You can
              then relocate items, add your company logo, etc.
            </div>
            <div class="mt-4">
              After you have installed and run the report designer for the first
              time, use the following link to run it again, without having to
              repeat the installation step.
            </div>
            <div class="mt-2">
              <a :href="reportDesignerClickOnceRunUrl" target="_blank"
                >Click here</a
              >
              to re-run the report designer.
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { watch } from 'vue'
export default {
  name: 'ReportDesignerLauncher',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const reportDesignerClickOnceInstallUrl =
      'http://reportdesigner.poolcareonline.com'

    const reportDesignerClickOnceRunUrl =
      reportDesignerClickOnceInstallUrl + '/ReportDesigner.application'

    const initDialog = () => {}

    initDialog()

    return { reportDesignerClickOnceInstallUrl, reportDesignerClickOnceRunUrl }
  }
}
</script>

<style lang="scss" scoped></style>
