<template>
  <div style="height: 80vh;">
    <v-card class="indigo lighten-5" height="100%">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Online Payment Setup
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tabPage">
          <v-tab key="A">Recurring Payment List</v-tab>
          <v-tab key="B">Recurring Payment Frequencies</v-tab>
          <v-tab key="C">Settings</v-tab>

          <v-tab-item key="A" class="indigo lighten-5">
            <div class="mt-3">
              <RecurringPaymentList :isDialogOpen="tabPage == 0" />
            </div>
          </v-tab-item>
          <v-tab-item key="B" class="indigo lighten-5">
            <div class="mt-3">
              <RecurringPaymentFrequencyList :isDialogOpen="tabPage == 1" />
            </div>
          </v-tab-item>
          <v-tab-item key="C" class="indigo lighten-5">
            <div class="mt-3">
              <OnlinePaymentSettings :isDialogOpen="tabPage == 2" />
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import RecurringPaymentFrequencyList from '@/components/Setup/OnlinePayments/RecurringPaymentFrequencyList.vue'
import RecurringPaymentList from '@/components/Setup/OnlinePayments/RecurringPaymentList.vue'
import OnlinePaymentSettings from '@/components/Setup/OnlinePayments/OnlinePaymentSettings.vue'
export default {
  name: 'OnlinePaymentSetup',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OnlinePaymentSettings,
    RecurringPaymentFrequencyList,
    RecurringPaymentList
  },
  setup(props) {
    const tabPage = ref(0)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      tabPage.value = 0
    }

    return { tabPage }
  }
}
</script>

<style lang="scss" scoped></style>
