<template>
  <v-dialog v-model="showDialog" max-width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        rounded
        :color="messagesButtonColor"
        class="mx-1"
        v-bind="attrs"
        v-on="on"
        @click="showDialogClick"
        ><span class="mr-1">{{ messagesButtonText }}</span>
        <v-icon>{{ icon.messageAlert }}</v-icon>
      </v-btn>
    </template>
    <MessagesDialog
      :isDialogOpen="showDialog"
      :new-version-available="newVersionAvailable"
      @close="messageDialogClose"
    />
  </v-dialog>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue'
import MessagesDialog from '@/components/layout/MessagesDialog'
import AzureDevOpsService from '@/services/AzureDevOpsService'
import { officeAlertService } from '@/services/OfficeAlertService'
import { icon } from '@/use/Constants'

export default {
  name: 'MessagesButton',
  components: { MessagesDialog },
  setup() {
    onBeforeMount(() => {
      officeAlertService.subscribe()
      getCurrentVersion()
      setInterval(getCurrentVersion, 14400000)
    })

    const showDialog = ref(false)
    const showDialogClick = () => {
      showDialog.value = true
    }

    const messageDialogClose = () => {
      showDialog.value = false
    }

    const currentVersion = ref(null)
    const newVersionAvailable = ref(false)

    const msgCount = computed(() => {
      return (
        officeAlertService.summary.value.QtyUnreadAlerts +
        (newVersionAvailable.value ? 1 : 0)
      )
    })

    const messagesButtonText = computed(() => {
      return msgCount.value === 1
        ? '1 Message'
        : msgCount.value > 1
        ? msgCount.value.toString() + ' Messages'
        : 'Messages'
    })

    const messagesButtonColor = computed(() => {
      return msgCount.value === 0 ? 'primary' : 'error'
    })

    const getCurrentVersion = async () => {
      const rs = await AzureDevOpsService.getRepoHistory()
      const version = rs.data.value[0].commitId
      newVersionAvailable.value =
        currentVersion.value && version != currentVersion.value

      currentVersion.value = version
    }

    return {
      officeAlertService,
      showDialog,
      showDialogClick,
      messageDialogClose,
      messagesButtonText,
      messagesButtonColor,
      newVersionAvailable,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
