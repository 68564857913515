<template>
  <div>
    <v-card v-if="settings" class="mt-3 ml-3" width="940">
      <v-card-title class="subtitle-1 blue--text">
        <div>Session Settings</div>
        <div class="ml-3 red--text">
          Caution: these settings should not be changed after initially
          configuring this interface.
        </div>
      </v-card-title>
      <v-card-text>
        <div class="d-flex">
          <v-switch
            v-model="settings.IsAutoSync"
            label="Auto Sync Nightly"
            dense
          />
          <div class="ml-9 mt-3">
            <DateTimePicker
              v-model="settings.SyncOnboardDate"
              label="Onboard Date"
              dense
            />
          </div>
        </div>
        <div class="d-flex">
          <div>
            <v-switch
              v-model="settings.IsQueryCustomerTypes"
              label="Query Customer Types"
              dense
            />
            <v-switch
              v-model="settings.IsQueryTranCodeGroups"
              label="Query TranCode Groups"
              dense
            />
            <v-switch
              v-model="settings.IsQueryTranCodes"
              label="Query TranCodes"
              dense
            />
            <v-switch
              v-model="settings.IsQueryCustomers"
              label="Query Customers"
              dense
            />
            <v-switch
              v-model="settings.IsQueryInvoices"
              label="Query Invoices"
              dense
            />
            <v-switch
              v-model="settings.IsQueryPayments"
              label="Query Payments"
              dense
            />
            <v-switch
              v-model="settings.IsQueryCreditMemos"
              label="Query Credit Memos"
              dense
            />
            <v-switch
              v-model="settings.IsQueryCharges"
              label="Query Charges"
              dense
            />
          </div>
          <div class="ml-5">
            <v-switch
              v-model="settings.IsQueryPaymentMethods"
              label="Query Payment Methods"
              dense
            />
            <v-switch
              v-model="settings.IsQueryQBDeletes"
              label="Query Deletes"
              dense
            />
            <v-switch
              v-model="settings.IsQueryCustomerBalances"
              label="Query Customer Balances"
              dense
            />
            <v-switch
              v-model="settings.IsAddCustomers"
              label="Add Customers"
              dense
            />
            <v-switch
              v-model="settings.IsAddTranCodes"
              label="Add TranCodes"
              dense
            />
            <v-switch
              v-model="settings.IsAddInvoices"
              label="Add Invoices"
              dense
            />
            <v-switch
              v-model="settings.IsAddPayments"
              label="Add Payments"
              dense
            />
            <v-switch
              v-model="settings.IsAddTransactions"
              label="Add Transactions"
              dense
            />
          </div>
          <div class="ml-5">
            <v-switch
              v-model="settings.IsModCustomers"
              label="Modify Customers"
              dense
            />
            <v-switch
              v-model="settings.IsModTranCodes"
              label="Modify TranCodes"
              dense
            />
            <v-switch
              v-model="settings.IsModTransactions"
              label="Modify Transactions"
              dense
            />
            <v-switch
              v-model="settings.IsDelTransactions"
              label="Delete Transactions"
              dense
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" @click="updateClick">
          <span>Update</span>
          <v-icon right>{{ icon.save }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'
export default {
  name: 'SyncSessionSettings',
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const settings = ref()

    const loadData = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.syncWithSettings.retrieve(rq)
      settings.value = rs.Info
    }

    const updateClick = async () => {
      const response = await updateSyncSessionSettings(settings.value)
      if (response.IsSuccess) {
        dialogUtil.value.inform({
          text: response.Message,
          snackbarTimeout: 10000,
          color: 'success'
        })
      } else {
        dialogUtil.value.error(response.Message)
      }
    }

    const updateSyncSessionSettings = async info => {
      const rq = { Info: info }
      const rs = await host.syncWithSettings.update(rq)
      return rs
    }

    const initDialog = async () => {
      loadData()
    }

    initDialog()

    return {
      dialogUtil,
      updateClick,
      settings,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
