<template>
  <div>
    <v-card>
      <v-card-title class="subtitle-1">
        {{ isEditMode ? 'Edit' : 'New' }} Activity Log Entry
      </v-card-title>
      <v-card-text v-if="formData">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="formData.Description"
                label="Log Entry"
                :error-messages="errorMessages(vuelidate.formData.Description)"
                @blur="vuelidate.formData.Description.$touch()"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelClick">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="saveClick">Save</v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'ActivityLogEditor',
  props: {
    activityLogId: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: String
    },
    tableName: {
      type: String,
      default: null
    },
    recordId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const formData = ref()
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const cancelClick = () => {
      context.emit('close')
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (!vuelidate.value.$invalid) {
        const response = formData.value.ActivityLogId
          ? await updateActivityLog(formData.value)
          : await createActivityLog(formData.value)
        if (response.IsSuccess) {
          context.emit('update')
        } else {
          dialogUtil.value.error(response.Message)
        }
      }
    }

    const updateActivityLog = async rqData => {
      const rq = { Info: rqData }
      const rs = await host.activityLog.update(rq)
      return rs
    }

    const createActivityLog = async rqData => {
      const rq = { Info: rqData }
      const rs = await host.activityLog.create(rq)
      return rs
    }

    const initDialog = () => {
      formData.value = {
        ActivityLogId: props.activityLogId,
        ActivityType: 'M',
        TableName: props.tableName,
        RecordId: props.recordId,
        Description: props.description
      }
      vuelidate.value.$reset()
    }

    const rules = {
      formData: {
        Description: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    initDialog()

    return {
      dialogUtil,
      isEditMode,
      formData,
      cancelClick,
      saveClick,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
