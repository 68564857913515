<template>
  <div>
    <v-btn
      v-if="isUnsubscribed"
      small
      rounded
      :color="feature.subscribe.color"
      class="mx-1"
      @click="showDialogClick"
    >
      <span class="mr-1">{{ buttonText }}</span>
      <v-icon>{{ feature.subscribe.icon }}</v-icon>
    </v-btn>

    <v-dialog v-model="showDialog" :width="feature.subscribe.dialogWidth">
      <SubscriptionEditor
        :isDialogOpen="showDialog"
        @close="subscriptionEditorCloseEvent"
        @update="subscriptionEditorUpdateEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { feature } from '@/use/Constants'
import { officeAlertService } from '@/services/OfficeAlertService'
import { todayISOString } from '@/use/DateFormatter'
import SubscriptionEditor from '@/components/Setup/Office/SubscriptionEditor'
export default {
  name: 'SubscribeButton',
  components: { SubscriptionEditor },
  setup() {
    const isUnsubscribed = computed(
      () =>
        !officeAlertService.summary.value.IsSubscribed &&
        officeAlertService.summary.value.WhenTrialExpires
    )
    const showDialog = ref(false)

    const showDialogClick = () => {
      showDialog.value = true
    }

    const buttonText = computed(() => {
      var whenExpires = new Date(
        officeAlertService.summary.value.WhenTrialExpires.substring(0, 10)
      )
      var today = new Date(todayISOString())
      const daysLeft = (whenExpires - today) / (1000 * 3600 * 24)
      return Math.max(daysLeft, 0) + ' Days Left -- Subscribe Now!'
    })

    const subscriptionEditorCloseEvent = () => {
      showDialog.value = false
    }

    const subscriptionEditorUpdateEvent = () => {
      showDialog.value = false
    }

    return {
      feature,
      showDialog,
      showDialogClick,
      buttonText,
      isUnsubscribed,
      subscriptionEditorCloseEvent,
      subscriptionEditorUpdateEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
