<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="d-flex" style="width: 100%;">
          <div>
            <div v-if="name">c/o {{ name }}</div>
            <div>{{ street }}</div>
            <div>
              {{ city }}, {{ state }}
              {{ postCode }}
            </div>
          </div>
          <v-spacer />
          <MapIcon
            :street="street"
            :city="city"
            :state="state"
            :postCode="postCode"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MapIcon from '@/components/Common/MapIcon'

export default {
  name: 'AddressCard',
  props: {
    name: {
      type: String,
      default: null
    },
    street: {
      type: String,
      default: null
    },
    city: {
      type: String,
      default: null
    },
    state: {
      type: String,
      default: null
    },
    postCode: {
      type: String,
      default: null
    }
  },
  components: { MapIcon }
}
</script>

<style lang="scss" scoped></style>
