<template>
  <div class="d-flex mt-3 indigo lighten-5">
    <div>
      <v-card width="400">
        <v-card-title class="subtitle-1 blue--text">
          Notes
        </v-card-title>
        <v-card-text>
          <p>
            In order to interface to QuickBooks (Desktop version), you need to
            install the QuickBooks Web Connector. This connector is used by
            Quickbooks to communicate with your
            {{ productName }} data.
          </p>
          <p>
            Note: we do not interface to QuickBooks Online at this time. We
            interface via the QuickBooks Connector to desktop versions of
            QuickBooks.
          </p>
        </v-card-text>
      </v-card>
    </div>
    <div>
      <v-card class="ml-5" width="500">
        <v-card-title class="subtitle-1 blue--text">
          Install the Quickbooks Web Connector
        </v-card-title>
        <v-card-text>
          <a
            target="_blank"
            href="https://developer.intuit.com/docs/0200_quickbooks_desktop/0100_essentials/quickbooks_web_connector"
            >Click here</a
          >
          to go to the Quickbooks site and download the Web Connector.
          <br /><br />
          After installing the Quickbooks Web Connector, you must download and
          install a customized configuration file, which will tell the connector
          how to communicate with your data in
          {{ productName }}. <br /><br />
          Click on the link to the right, and when the dialog appears, click the
          'Save' button. Save the file to your computer -- then press the 'Open'
          button to install the connector. You will need to have your Quickbooks
          company file open and press another button to accept the connection.
        </v-card-text>
      </v-card>
    </div>
    <div>
      <v-card class="ml-5" width="400">
        <v-card-title class="subtitle-1 blue--text">
          Install the configuration file
        </v-card-title>
        <v-card-text>
          <a target="_blank" :href="getConfigFileUrl()">
            Click here to install the connector configuration file.
          </a>
          <br />
          <p>
            The first time you interface between Quickbooks and PoolCarePro, you
            must have your QuickBooks program started and your company file
            open.
          </p>
          <p>
            Now, run the Quickbooks Web Connector by going to your Start Menu,
            then select Programs / QuickBooks and select "Exchange Data with Web
            Services". Then select the checkbox in front of PoolCarePro and
            press the "Update Selected" button. This will do an initial contact
            with PoolCarePro, and will send information to be used on the
            Settings tab.
          </p>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'SyncWithConnectQuickBooksDesktop',
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const productName = ref('PoolCarePro')

    const getConfigFileUrl = () => {
      // TODO: make this work if we wish to continue to support QB desktop in V4.
      return ''
    }

    return {
      productName,
      getConfigFileUrl
    }
  }
}
</script>

<style lang="scss" scoped></style>
