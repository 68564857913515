<template>
  <v-combobox v-model="iconName" v-bind="$attrs" :items="choices" dense>
    <template v-slot:item="{ item }">
      <v-icon :color="color">{{ item }}</v-icon>
      <div class="ml-1">
        {{ item }}
      </div>
    </template>
    <template v-slot:selection="{ item }">
      <v-icon :color="color">{{ item }}</v-icon>
      <div class="ml-1">
        {{ item }}
      </div>
    </template>
  </v-combobox>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
export default {
  name: 'IconPicker',
  props: {
    value: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'black'
    },
    choices: {
      type: Array,
      default: icon.serviceTypeChoices
    }
  },
  setup(props, context) {
    const iconName = ref()

    watch(
      () => props.value,
      newVal => {
        iconName.value = newVal
      }
    )

    watch(
      () => iconName.value,
      newVal => {
        context.emit('input', newVal)
      }
    )

    const initControl = () => {
      iconName.value = props.value
    }

    initControl()

    return { icon, iconName }
  }
}
</script>

<style lang="scss" scoped></style>
