<template>
  <div>
    <v-dialog v-model="showDialog" max-width="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          rounded
          color="primary"
          class="mx-1"
          v-bind="attrs"
          v-on="on"
          @click="showDialogClick"
        >
          <span class="mr-1">Search</span>
          <v-icon>{{ icon.search }}</v-icon>
        </v-btn>
      </template>
      <SearchDialog
        :isDialogOpen="showDialog"
        @close="hideDialogClick"
        @select="selectedSearchItem"
        @billingSelect="billingSelectEvent"
      />
    </v-dialog>

    <TransactionEditLauncher
      v-if="transactionInfo"
      :transactionId="transactionInfo.transactionId"
      :transactionType="transactionInfo.transactionType"
      :customerId="transactionInfo.customerId"
      :showDialog="showTransactionEditLauncherDialog"
      @close="transactonEditLauncherClose"
      @update="transactionEditLauncherUpdate"
    />

    <v-dialog
      v-model="showScheduledServiceDialog"
      :width="feature.services.dialogWidth"
    >
      <ScheduledServiceEditor
        :isDialogOpen="showScheduledServiceDialog"
        :scheduledServiceId="recordId"
        @close="scheduledServiceDialogCloseEvent"
        @update="scheduledServiceDialogCloseEvent"
        @editRecurringService="editRecurringServiceEvent"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="showRecurringServiceEditorDialog"
      :width="feature.recurringServices.dialogWidth"
    >
      <RecurringServiceEditor
        :recurringServiceId="selectedRecurringServiceId"
        :isDialogOpen="showRecurringServiceEditorDialog"
        @update="recurringServiceEditorUpdateEvent"
        @close="recurringServiceEditorCloseEvent"
      />
    </v-dialog>

    <v-dialog
      v-model="showScheduledTaskDialog"
      :width="feature.tasks.dialogWidth"
    >
      <ScheduledTaskEditor
        :isDialogOpen="showScheduledTaskDialog"
        :scheduledTaskId="recordId"
        @close="scheduledTaskDialogCloseEvent"
        @update="scheduledTaskDialogCloseEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { icon, feature } from '@/use/Constants'
import SearchDialog from '@/components/layout/SearchDialog'
import TransactionEditLauncher from '@/components/Billing/TransactionEditLauncher'
import ScheduledServiceEditor from '@/components/ScheduledService/ScheduledServiceEditor'
import RecurringServiceEditor from '@/components/ScheduledService/RecurringServiceEditor'
import ScheduledTaskEditor from '@/components/ScheduledTask/ScheduledTaskEditor'

export default {
  name: 'SearchButton',
  components: {
    SearchDialog,
    TransactionEditLauncher,
    ScheduledServiceEditor,
    RecurringServiceEditor,
    ScheduledTaskEditor
  },
  setup() {
    const router = useRouter()
    const showDialog = ref(0)
    const showDialogClick = () => {
      showDialog.value = true
    }
    const hideDialogClick = () => {
      showDialog.value = false
    }

    const selectedSearchItem = event => {
      const recordTypeMap = {
        T: 'scheduledtask-edit',
        S: 'scheduledservice-edit',
        A: 'customer-summary'
      }

      showDialog.value = false

      if (event.RecordType == 'T') {
        recordId.value = event.RecordId
        showScheduledTaskDialog.value = true
      } else if (event.RecordType == 'S') {
        recordId.value = event.RecordId
        showScheduledServiceDialog.value = true
      } else {
        router.push({
          name: recordTypeMap[event.RecordType],
          params: { id: event.RecordId, siteId: event.SiteId }
        })
      }
    }

    const transactionInfo = ref()
    const showTransactionEditLauncherDialog = ref(false)
    const billingSelectEvent = event => {
      transactionInfo.value = {
        transactionId: event.TransactionId,
        transactionType: event.TransactionType
      }
      showTransactionEditLauncherDialog.value = true
    }

    const transactonEditLauncherClose = () => {
      showTransactionEditLauncherDialog.value = false
    }

    const transactionEditLauncherUpdate = () => {
      showTransactionEditLauncherDialog.value = false
    }

    const recordId = ref()

    const showScheduledTaskDialog = ref(false)
    const scheduledTaskDialogCloseEvent = () => {
      showScheduledTaskDialog.value = false
    }

    const showScheduledServiceDialog = ref(false)
    const scheduledServiceDialogCloseEvent = () => {
      showScheduledServiceDialog.value = false
    }

    const showRecurringServiceEditorDialog = ref(false)
    const selectedRecurringServiceId = ref()

    const editRecurringServiceEvent = id => {
      selectedRecurringServiceId.value = id
      showRecurringServiceEditorDialog.value = true
    }

    const recurringServiceEditorCloseEvent = () => {
      showRecurringServiceEditorDialog.value = false
    }

    const recurringServiceEditorUpdateEvent = () => {
      showRecurringServiceEditorDialog.value = false
    }

    return {
      showDialog,
      showDialogClick,
      hideDialogClick,
      selectedSearchItem,
      billingSelectEvent,
      transactionInfo,
      showTransactionEditLauncherDialog,
      transactonEditLauncherClose,
      transactionEditLauncherUpdate,
      recordId,
      showScheduledTaskDialog,
      scheduledTaskDialogCloseEvent,
      showScheduledServiceDialog,
      scheduledServiceDialogCloseEvent,
      editRecurringServiceEvent,
      showRecurringServiceEditorDialog,
      selectedRecurringServiceId,
      recurringServiceEditorCloseEvent,
      recurringServiceEditorUpdateEvent,
      feature,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
