<template>
  <div>
    <PageTitle
      featureName="payments"
      functionName="Batch List"
      :pageName="$options.name"
    />
    <v-card class="mx-auto">
      <v-data-table
        dense
        :items-per-page="20"
        :footer-props="{
          'items-per-page-options': items_per_page,
          'show-first-last-page': true
        }"
        :headers="dataTable.HeaderList"
        :items="dataTable.LineItemList"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="dataTable.TotalRecords"
        :loading="loading"
        @click:row="selectRow"
        class="elevation-1"
      >
        <template v-slot:item.WhenCreated="{ item }">
          <span>{{ toLocaleDateTimeString(item.WhenCreated) }}</span>
        </template>
        <template v-slot:item.CreatedBy="{ item }">
          <v-icon :color="userColor(item.UserId)">
            {{ icon.user }}
          </v-icon>
          <span class="ml-1">{{ item.CreatedBy }}</span>
        </template>
        <template v-slot:item.Status="{ item }">
          <v-icon :color="batchStatus[item.Status].color">
            {{ batchStatus[item.Status].icon }}</v-icon
          >
          <span class="ml-2">
            {{ batchStatus[item.Status].description }}
          </span>
        </template>
        <template v-slot:item.Type="{ item }">
          <v-icon :color="batchType[item.Type].color">
            {{ batchType[item.Type].icon }}
          </v-icon>
          <span class="ml-2">{{ batchType[item.Type].description }}</span>
        </template>
        <template v-slot:item.BatchTotal="{ item }">
          <span>{{ toMoney(item.BatchTotal) }}</span>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn color="primary" @click="newBatchClick">
          New Batch
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { host } from '@/services/HostAPI'
import { icon, batchStatus, batchType } from '@/use/Constants'
import { selectListCache } from '@/services/SelectListCache'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
import { toMoney } from '@/use/NumberFormatter'

export default {
  name: 'PaymentBatchList',
  setup() {
    const router = useRouter()
    const dialogUtil = ref(null)

    const options = ref({})
    const dataTable = ref([])
    const loading = ref(false)
    const sortBy = ref('Date')
    const sortDesc = ref(false)
    const firstTime = ref(true)

    const items_per_page = [10, 15, 20, 50]
    const selectLists = ref()

    const initForm = async () => {
      await loadSelectLists()
      await loadBatchList()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.Users]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const selectRow = event => {
      router.push({
        name: 'paymentbatch',
        params: { id: event.TransactionBatchId }
      })
    }

    const loadBatchList = async () => {
      loading.value = true
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      const rq = {
        LastPage: firstTime.value,
        BatchTypeList: ['P', 'C'],
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy.length > 0 ? sortBy[0] : 'Date',
          IsDescending: sortDesc.length > 0 ? sortDesc[0] : false
        }
      }
      const rs = await host.billing.loadTransactionBatchesDataTable(rq)
      dataTable.value = rs
      if (firstTime.value) {
        firstTime.value = false
      }
      loading.value = false
    }

    const newBatchClick = async () => {
      const rq = {
        Info: {
          Status: 'A',
          Type: 'P',
          Reference: 'Posted Payments'
        }
      }
      const rs = await host.transactionBatch.create(rq)
      if (rs.IsSuccess) {
        router.push({
          name: 'paymentbatch',
          params: { id: rs.InfoId }
        })
      } else {
        dialogUtil.value.error('Error: ' + rs.Message)
      }
    }

    const userColor = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Color'
      )
    }

    watch(
      () => options.value,
      () => {
        loadBatchList().catch(e => alert('error: ' + e.message))
      }
    )

    initForm()

    return {
      dialogUtil,
      dataTable,
      loading,
      options,
      sortBy,
      sortDesc,
      items_per_page,
      selectRow,
      newBatchClick,
      toLocaleDateTimeString,
      toMoney,
      icon,
      batchStatus,
      batchType,
      userColor
    }
  }
}
</script>

<style lang="scss" scoped></style>
