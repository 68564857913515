import { createHTMLMapMarker } from '@/services/HTMLMapMarker'
import { icon } from '@/use/Constants'

class GoogleMapService {
  constructor() {
    this.directionsService = null
    this.directionsDisplay = null
    this.ready = false
    this.#init()
  }

  #init = () => {
    const googleMapApiId = 'googlemapapi'

    // TODO: Get this from configuration
    const mapKey = 'AIzaSyBRRpiqJvO0vYYDLvo4j1qYjTywSob75gU'

    if (document.getElementById(googleMapApiId)) {
      return
    }

    const script = document.createElement('script')
    script.id = googleMapApiId
    script.type = 'text/javascript'
    script.src = `https://maps.googleapis.com/maps/api/js?v=weekly&key=${mapKey}`
    document.body.appendChild(script)
    script.addEventListener(
      'load',
      () => {
        this.directionsDisplay = new window.google.maps.DirectionsRenderer()
        this.directionsService = new window.google.maps.DirectionsService()
        this.ready = true
      },
      false
    )
  }

  showAddress = (address, canvas) => {
    if (!this.#isReady()) {
      return
    }

    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode(
      {
        address: `${address.Street}, ${address.City}, ${address.State} ${address.PostCode}`
      },
      (results, status) => {
        if (status == 'OK') {
          const latLng = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }

          const mapOptions = {
            center: latLng,
            zoom: 15,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP
          }

          const map = new window.google.maps.Map(canvas, mapOptions)

          var marker = new window.google.maps.Marker({
            position: latLng,
            map: map
          })

          console.log(marker)
        } else {
          alert(
            'Geocode was not successful for the following reason: ' + status
          )
        }
      }
    )
  }

  showNearbyServices = args => {
    if (!this.#isReady()) {
      return
    }

    const map = new window.google.maps.Map(args.canvas)

    this.nearbyMarkerList = []

    this.nearbyMarkerList = args.serviceList.reduce(
      (accumulator, site, index) => {
        if (site.Address.Latitude !== 0.0 || site.Address.Longitude !== 0.0) {
          const callback = () => args.callback(index)
          accumulator.push(
            this.#createMarkerForNearbyService(site, callback, map)
          )
        }
        return accumulator
      },
      []
    )

    const homeMarker = this.#createHomeMarker(args.siteInfo, map)
    this.nearbyMarkerList.unshift(homeMarker)

    const bounds = new window.google.maps.LatLngBounds()

    bounds.extend(homeMarker.latlng)
    this.nearbyMarkerList.forEach(element => bounds.extend(element.latlng))
    map.fitBounds(bounds)
  }

  showRoute = (
    mapCanvas,
    mapDirectionsCanvas,
    serviceItemList,
    startFrom,
    endAt
  ) => {
    if (!this.#isReady()) {
      return
    }

    const centerLat = this.#average(
      serviceItemList.map(item => item.Address.Latitude)
    )
    const centerLong = this.#average(
      serviceItemList.map(item => item.Address.Longitude)
    )

    const mapOptions = {
      center: new window.google.maps.LatLng(centerLat, centerLong),
      zoom: 12,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP
    }

    const map = new window.google.maps.Map(mapCanvas, mapOptions)
    this.directionsDisplay.setMap(map)
    this.directionsDisplay.setPanel(mapDirectionsCanvas)

    let stops = serviceItemList.map(
      item =>
        new window.google.maps.LatLng(
          item.Address.Latitude,
          item.Address.Longitude
        )
    )

    if (startFrom && startFrom.AddressType != 'Site') {
      stops.unshift(
        new window.google.maps.LatLng(
          startFrom.MapAddress.Latitude,
          startFrom.MapAddress.Longitude
        )
      )
    }

    if (endAt && endAt.AddressType != 'Site') {
      stops.push(
        new window.google.maps.LatLng(
          endAt.MapAddress.Latitude,
          endAt.MapAddress.Longitude
        )
      )
    }

    this.#calcRoute(stops, this.directionsService, this.directionsDisplay)
  }

  showServiceLocations = args => {
    if (!this.#isReady()) {
      return
    }

    const map = new window.google.maps.Map(args.canvas)

    const markerList = args.siteList.reduce((list, site, index) => {
      if (site.Address.Latitude == 0.0 || site.Address.Longitude == 0.0) {
        return list
      }

      let routeInfo = args.routeList.find(
        route => route.Key === site.ServiceRouteId
      )

      if (!routeInfo) {
        // Don't try to combine this with the above find,
        // or we might match to a frequency description when a matching serviceRouteId was present
        routeInfo = args.routeList.find(
          route => route.Key === site.FrequencyDescription
        )
      }

      let include = routeInfo && routeInfo.visible

      if (include && args.limitByUser) {
        const userInfo = args.userList.find(user => user.UserId == site.UserId)
        include = userInfo && userInfo.visible
      }

      if (include) {
        const callback = () => args.callback(index)
        const marker = this.#createMarkerForRecurringService(
          site,
          callback,
          map
        )

        list.push(marker)
      }

      return list
    }, [])

    const bounds = new window.google.maps.LatLngBounds()
    markerList.forEach(element => bounds.extend(element.latlng))
    map.fitBounds(bounds)
  }

  showTracker = args => {
    console.log({
      loc: 'GoogleMapService/ShowTracker',
      ready: this.#isReady(),
      args: args
    })

    if (!this.#isReady()) {
      return
    }

    const map = new window.google.maps.Map(args.canvas)
    const bounds = new window.google.maps.LatLngBounds()

    args.trackList.forEach((element, index) => {
      const lat = element.LatLong.Latitude
      const lng = element.LatLong.Longitude
      const callback = () => args.callback(index)
      const html = `<div">
      <i class="v-icon mdi ${icon.user} ${element.UserColor}--text mdi-30px"></i>
      </div>`

      const siteInfo = `<div>${element.SiteName}</div>
      <div>${element.ServiceTypeDescription}</div>`

      const info = `<div>
      <div>${element.WhenLogged.substring(11, 19)}</div>
      <div>${element.UserName}</div>
      <div>${element.ActivityDescription}</div>
      ${element.SiteName ? siteInfo : ''}
    </div>`

      const latLng = {
        lat: lat,
        lng: lng
      }

      console.log({
        loc: 'GoogleMapService/ShowTracker/forEach',
        element: element,
        index: index,
        latLng: latLng
      })

      this.#createMarker(lat, lng, html, info, callback, map)

      bounds.extend(latLng)
    })

    map.fitBounds(bounds)
    map.setZoom(12)
  }

  #sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  #isReady = () => {
    for (let i = 0; i < 10; i++) {
      if (this.ready) {
        return true
      } else {
        this.#sleep(1000)
      }
    }

    return false
  }

  #average = array => {
    const filteredArray = array.filter(item => item != 0)
    return filteredArray.length == 0
      ? 0.0
      : filteredArray.reduce((accumulator, currentValue) => {
          return accumulator + currentValue
        }) / filteredArray.length
  }

  #createMarkerForRecurringService = (site, callback, map) => {
    const lat = site.Address.Latitude
    const lng = site.Address.Longitude
    const html = this.#markerIconRecurringService(site)
    const info = this.#markerInfoRecurringService(site)
    const marker = this.#createMarker(lat, lng, html, info, callback, map)
    return marker
  }

  #calcRoute = (stops, directionsService, directionsDisplay) => {
    const itemsPerBatch = 23 + 2 // google API max waypoints, plus 1 start and 1 stop
    const batches = []
    var itemsCounter = 0

    while (stops.length > itemsCounter) {
      batches.push(
        stops.slice(itemsCounter, itemsCounter + itemsPerBatch).map(stop => {
          return { location: stop, stopover: true }
        })
      )

      itemsCounter += itemsPerBatch - 1 // Subsequent batches start at previous stop
    }

    this.#calcRoute1(batches, directionsService, directionsDisplay)
  }

  #calcRoute1 = (batches, directionsService, directionsDisplay) => {
    var combinedResults
    var unsortedResults = [{}] // to hold the counter and the results themselves as they come back, to later sort
    var directionsResultsReturned = 0

    for (var k = 0; k < batches.length; k++) {
      var lastIndex = batches[k].length - 1
      var start = batches[k][0].location
      var end = batches[k][lastIndex].location

      // trim first and last entry from array
      var waypts = batches[k]
      waypts.splice(0, 1)
      waypts.splice(waypts.length - 1, 1)

      var request = {
        origin: start,
        destination: end,
        waypoints: waypts,
        travelMode: window.google.maps.TravelMode.DRIVING
      }

      ;(function(kk) {
        directionsService.route(request, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            var unsortedResult = {
              order: kk,
              result: result
            }
            unsortedResults.push(unsortedResult)

            directionsResultsReturned++

            if (directionsResultsReturned === batches.length) {
              // we've received all the results. put to map
              // sort the returned values into their correct order
              unsortedResults.sort(function(a, b) {
                return parseFloat(a.order) - parseFloat(b.order)
              })
              var count = 0
              for (var key in unsortedResults) {
                if (unsortedResults[key].result) {
                  // eslint-disable-next-line no-prototype-builtins
                  if (unsortedResults.hasOwnProperty(key)) {
                    if (count === 0)
                      // first results. new up the combinedResults object
                      combinedResults = unsortedResults[key].result
                    else {
                      const route = combinedResults.routes[0] // For later shorthand

                      // only building up legs, overview_path, and bounds in my consolidated object. This is not a complete
                      // directionResults object, but enough to draw a path on the map, which is all I need
                      route.legs = route.legs.concat(
                        unsortedResults[key].result.routes[0].legs
                      )

                      route.overview_path = route.overview_path.concat(
                        unsortedResults[key].result.routes[0].overview_path
                      )

                      route.bounds = route.bounds.extend(
                        unsortedResults[
                          key
                        ].result.routes[0].bounds.getNorthEast()
                      )

                      route.bounds = route.bounds.extend(
                        unsortedResults[
                          key
                        ].result.routes[0].bounds.getSouthWest()
                      )
                    }

                    count++
                  }
                }
              }
              directionsDisplay.setDirections(combinedResults)
            }
          }
        })
      })(k)
    }
  }

  #createMarker = (lat, lng, html, info, callback, map) => {
    const latlng = new window.google.maps.LatLng(lat, lng)
    console.log({
      loc: 'GoogleMapService/#createMarker',
      lat: lat,
      lng: lng,
      latlng: latlng
    })

    const marker = createHTMLMapMarker({
      latlng,
      map,
      html,
      info
    })

    console.log({
      loc: 'GoogleMapService/#createMarker/markercreated',
      marker: marker
    })

    if (callback) {
      marker.addListener('click', callback)
    }

    return marker
  }

  #markerIconRecurringService = site => {
    return `<div">
  <div class="stacked-map-icon-bottom"><i class="v-icon notranslate mdi mdi-circle-outline ${site.UserColor}--text mdi-24px"></i></div>
  <div class="stacked-map-icon-top"><i class="v-icon notranslate mdi ${site.ServiceTypeIcon} ${site.ServiceRouteColor}--text mdi-18px"></i></div>
  </div>`
  }

  #markerInfoRecurringService = site => {
    return `<div>
        <div>Customer ${site.CustomerCode}</div>
        <div>${site.SiteName}</div>
        <div>${site.Address.Street}</div>
        <div>${site.ServiceTypeDescription}</div>
        <div>${
          site.ServiceRouteCode ? 'Route ' + site.ServiceRouteCode : 'Recurring'
        } -- Stop ${site.StopNumber}</div>
        <div>${site.FrequencyDescription}</div>
        <div>Assigned to ${site.UserName}</div>
      </div>`
  }

  #markerInfoNearbyService = site => {
    return `<div>
        <div>${site.SiteName}</div>
        <div>${site.Address.Street}</div>
        <div>${site.ServiceTypeDescription}</div>
        <div>${
          site.ServiceRouteCode ? 'Route ' + site.ServiceRouteCode : 'Recurring'
        } -- Stop ${site.StopNumber}</div>
        <div>${site.FrequencyDescription}</div>
        <div>Assigned to ${site.UserName}</div>
        <div>Distance in miles: ${Math.round(site.DistanceInMiles * 10) /
          10}</div>
      </div>`
  }

  #createMarkerForNearbyService = (site, callback, map) => {
    const lat = site.Address.Latitude
    const lng = site.Address.Longitude
    const html = this.#markerIconRecurringService(site)
    const info = this.#markerInfoNearbyService(site)
    const marker = this.#createMarker(lat, lng, html, info, callback, map)
    return marker
  }

  #createHomeMarker = (siteInfo, map) => {
    const lat = siteInfo.Address.Latitude
    const lng = siteInfo.Address.Longitude

    const html = `<div">
    <i class="v-icon notranslate mdi mdi-star red--text mdi-24px"></i>
    </div>`

    const info = `<div>
      <div>${siteInfo.Description}</div>
      <div>${siteInfo.Address.Street}</div>
    </div>`

    const marker = this.#createMarker(lat, lng, html, info, null, map)
    return marker
  }
}

const mapService = new GoogleMapService()

export { mapService }
