<template>
  <v-dialog v-model="showDialog" max-width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        v-bind="attrs"
        v-on="on"
        @click="showDialogClick"
      >
        <span class="mr-1">Commits</span>
        <v-icon>{{ icon.commit }}</v-icon>
      </v-btn>
    </template>
    <v-card height="700" width="700" shaped class="mx-auto rounded-card">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Commit History
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-list dense nav>
          <v-list-item v-for="item in commits" :key="item.commitId">
            <v-list-item-avatar>
              <v-icon color="blue" class="red lighten-4">
                {{ icon.commit }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle v-text="item.author.date" />
              <v-list-item-content>{{ item.comment }}</v-list-item-content>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue'
import { icon } from '@/use/Constants'
import AzureDevOpsService from '@/services/AzureDevOpsService'
export default {
  name: 'CommitHistoryButton',
  setup() {
    const showDialog = ref(false)
    const commits = ref([])

    function showDialogClick() {
      fetchData()
    }

    async function fetchData() {
      const rs = await AzureDevOpsService.getRepoHistory()
      commits.value = rs.data.value
    }

    return { showDialog, showDialogClick, commits, icon }
  }
}
</script>

<style lang="scss" scoped></style>
