<template>
  <v-app id="main" :style="appStyle">
    <NavBar></NavBar>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { useRouter } from 'vue2-helpers/vue-router'
import NavBar from '@/components/layout/NavBar'
import { isLoggedIn } from '@/store/UserSession'

export default {
  name: 'App',
  components: { NavBar },
  setup(props, context) {
    const router = useRouter()
    const appStyle = () => {
      const vuetify = context.root.$vuetify
      const theme = vuetify.theme.dark ? 'dark' : 'light'
      return { background: vuetify.theme.themes[theme].background }
    }

    if (!isLoggedIn()) {
      const path = '/login'
      if (router.path !== path) {
        router.push(path)
      }
    }

    return { appStyle }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.ck-content {
  height: 300px;
}

.stacked-map-icon-bottom {
  position: absolute;
  left: 0;
  text-align: center;
  width: 100%;
}

.stacked-map-icon-top {
  position: absolute;
  left: 3px;
  top: 3px;
  text-align: center;
  width: 100%;
}
</style>
