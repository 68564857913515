<template>
  <div class="d-flex max-width">
    <div class="flex-grow-1"><slot></slot></div>
    <div><TutorialButton :tutorialName="dialogName" /></div>
  </div>
</template>

<script>
import TutorialButton from '@/components/controls/TutorialButton'
export default {
  name: 'DialogTitle',
  props: {
    dialogName: {
      type: String,
      default: ''
    }
  },
  components: { TutorialButton },
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.max-width {
  width: 100%;
}
</style>
