<template>
  <v-card width="320px" height="330px" class="pt-2 pl-3 ma-2">
    <div class="overline grey--text text-uppercase">Mobile Link</div>
    <v-card-text>
      <div class="text-subtitle-2 green--text font-weight-bold">
        Scan this QRCode to run the mobile app.
      </div>
      <div class="mt-2 ml-8">
        <VueQRCodeComponent :text="mobileLink" :size="192" />
      </div>
      <div>{{ mobileLink }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from 'vue'
import VueQRCodeComponent from 'vue-qr-generator'
import { userData } from '@/store/UserSession'
import { feature, icon } from '@/use/Constants'
export default {
  name: 'MobileLink',
  components: { VueQRCodeComponent },
  setup() {
    const mobileLink = ref()

    mobileLink.value =
      'https://mobile.poolcareonline.com?oc=' + userData.value.OfficeCode

    return { mobileLink, feature, icon }
  }
}
</script>
<style lang="scss" scoped></style>
