<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Sales Taxes
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-tabs>
          <v-tab key="1">Sales Taxes</v-tab>
          <v-tab key="2">Sales Tax Groups</v-tab>

          <v-tab-item key="1">
            <v-data-table
              v-if="salesTaxList && selectLists"
              dense
              :items-per-page="10"
              :footer-props="{
                'show-first-last-page': true
              }"
              :headers="salesTaxHeaders"
              :items="salesTaxList"
              class="elevation-1"
            >
              <template v-slot:item.TranCodeId="{ item }">
                {{ tranCodeDescription(item.TranCodeId) }}
              </template>
              <template v-slot:item.EffectiveDate="{ item }">
                {{ toLocaleDateString(item.EffectiveDate) }}
              </template>
              <template v-slot:item.ExpireDate="{ item }">
                {{ toLocaleDateString(item.ExpireDate) }}
              </template>
              <template v-slot:item.IsDeleted="{ item }">
                <v-icon v-if="item.IsDeleted" color="error">
                  {{ icon.lineItemIsDeleted }}
                </v-icon>
              </template>
              <template v-slot:item.Actions="{ item }">
                <v-icon small class="mr-2" @click="editSalesTaxClick(item)">
                  {{ icon.lineItemEdit }}
                </v-icon>
                <v-icon small @click="deleteSalesTaxClick(item)">
                  {{ icon.lineItemDelete }}
                </v-icon>
              </template>
            </v-data-table>
            <div class="table-footer-prepend d-flex pl-2 align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="newSalesTaxClick">
                    <v-icon>{{ icon.lineItemAdd }}</v-icon>
                  </v-btn>
                </template>
                <span>New Sales Tax</span>
              </v-tooltip>
            </div>
          </v-tab-item>

          <v-tab-item key="2">
            <v-data-table
              v-if="salesTaxGroupList"
              dense
              :items-per-page="10"
              :footer-props="{
                'show-first-last-page': true
              }"
              :headers="salesTaxGroupHeaders"
              :items="salesTaxGroupList"
              class="elevation-1"
            >
              <template v-slot:item.IncludedTaxCodes="{ item }">
                {{ includedTaxCodeList(item) }}
              </template>
              <template v-slot:item.IsDeleted="{ item }">
                <v-icon v-if="item.IsDeleted" color="error">
                  {{ icon.lineItemIsDeleted }}
                </v-icon>
              </template>
              <template v-slot:item.Actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editSalesTaxGroupClick(item)"
                >
                  {{ icon.lineItemEdit }}
                </v-icon>
                <v-icon small @click="deleteSalesTaxGroupClick(item)">
                  {{ icon.lineItemDelete }}
                </v-icon>
              </template>
            </v-data-table>
            <div class="table-footer-prepend d-flex pl-2 align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="newSalesTaxGroupClick">
                    <v-icon>{{ icon.lineItemAdd }}</v-icon>
                  </v-btn>
                </template>
                <span>New Sales Tax Group</span>
              </v-tooltip>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showSalesTaxEditorDialog" width="400" persistent>
      <SalesTaxEditor
        :infoId="selectedItemId"
        :isDialogOpen="showSalesTaxEditorDialog"
        :selectLists="selectLists"
        @close="salesTaxEditorCloseEvent"
        @update="salesTaxEditorUpdateEvent"
      />
    </v-dialog>

    <v-dialog v-model="showSalesTaxGroupEditorDialog" width="400" persistent>
      <SalesTaxGroupEditor
        :infoId="selectedItemId"
        :salesTaxList="salesTaxList"
        :isDialogOpen="showSalesTaxGroupEditorDialog"
        @close="salesTaxGroupEditorCloseEvent"
        @update="salesTaxGroupEditorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { icon } from '@/use/Constants'
import { toLocaleDateString } from '@/use/DateFormatter'
import SalesTaxEditor from '@/components/Setup/Billing/SalesTaxEditor'
import SalesTaxGroupEditor from '@/components/Setup/Billing/SalesTaxGroupEditor'
export default {
  name: 'SalesTaxList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { SalesTaxEditor, SalesTaxGroupEditor },
  setup(props) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      getSalesTaxList()
      getSalesTaxGroupList()
      loadSelectLists()
    }

    const salesTaxList = ref()
    const salesTaxGroupList = ref()
    const selectedItemId = ref(null)
    const showSalesTaxEditorDialog = ref(false)
    const showSalesTaxGroupEditorDialog = ref(false)
    const selectLists = ref()

    const getSalesTaxList = async () => {
      const rq = {}
      const rs = await host.salesTax.list(rq)
      salesTaxList.value = rs.InfoList
    }

    const getSalesTaxGroupList = async () => {
      const rq = {}
      const rs = await host.salesTaxGroup.list(rq)
      salesTaxGroupList.value = rs.InfoList
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const editSalesTaxClick = item => {
      selectedItemId.value = item.SalesTaxId
      showSalesTaxEditorDialog.value = true
    }

    const deleteSalesTaxClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Sales Tax ${item.Code}`
        })
        .then(() => performDeleteSalesTax(item))
        .catch(() => {
          return
        })
    }

    const performDeleteSalesTax = async item => {
      const rq = { InfoId: item.SalesTaxId }
      const rs = await host.salesTax.delete(rq)
      if (rs.IsSuccess) {
        getSalesTaxList()
        getSalesTaxGroupList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const newSalesTaxClick = () => {
      selectedItemId.value = null
      showSalesTaxEditorDialog.value = true
    }

    const editSalesTaxGroupClick = item => {
      selectedItemId.value = item.SalesTaxId
      showSalesTaxGroupEditorDialog.value = true
    }

    const deleteSalesTaxGroupClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Sales Tax Group ${item.Code}`
        })
        .then(() => performDeleteSalesTaxGroup(item))
        .catch(() => {
          return
        })
    }

    const performDeleteSalesTaxGroup = async item => {
      const rq = { InfoId: item.SalesTaxId }
      const rs = await host.salesTaxGroup.delete(rq)
      if (rs.IsSuccess) {
        getSalesTaxGroupList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const newSalesTaxGroupClick = () => {
      selectedItemId.value = null
      showSalesTaxGroupEditorDialog.value = true
    }

    const salesTaxHeaders = [
      { value: 'Code', text: 'Code' },
      { value: 'Description', text: 'Description' },
      { value: 'TaxRate', text: 'Rate', align: 'right' },
      { value: 'TranCodeId', text: 'Tran Code' },
      { value: 'EffectiveDate', text: 'Effective' },
      { value: 'ExpireDate', text: 'Expires' },
      { value: 'IsDeleted', text: 'Deleted', align: 'center' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const salesTaxGroupHeaders = [
      { value: 'Code', text: 'Code', width: 100, align: 'left' },
      { value: 'Description', text: 'Description', width: 80, align: 'left' },
      {
        value: 'IncludedTaxCodes',
        text: 'Included Tax Codes',
        width: 100,
        align: 'left'
      },
      { value: 'IsDeleted', text: 'Deleted', width: 20, align: 'center' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const tranCodeDescription = value => {
      return value
        ? selectLists.value.TranCodes.find(item => item.Value == value).Text
        : ''
    }

    const salesTaxEditorCloseEvent = () => {
      showSalesTaxEditorDialog.value = false
    }

    const salesTaxEditorUpdateEvent = () => {
      showSalesTaxEditorDialog.value = false
      getSalesTaxList()
      getSalesTaxGroupList()
    }

    const salesTaxGroupEditorCloseEvent = () => {
      showSalesTaxGroupEditorDialog.value = false
    }

    const salesTaxGroupEditorUpdateEvent = () => {
      showSalesTaxGroupEditorDialog.value = false
      getSalesTaxGroupList()
    }

    const includedTaxCodeList = item => {
      return item.SalesTaxIdList.map(
        id => salesTaxList.value.find(item => item.SalesTaxId == id).Code
      ).join(', ')
    }

    initDialog()

    return {
      dialogUtil,
      salesTaxList,
      salesTaxHeaders,
      tranCodeDescription,
      showSalesTaxEditorDialog,
      selectedItemId,
      editSalesTaxClick,
      deleteSalesTaxClick,
      newSalesTaxClick,
      showSalesTaxGroupEditorDialog,
      editSalesTaxGroupClick,
      deleteSalesTaxGroupClick,
      newSalesTaxGroupClick,
      salesTaxEditorCloseEvent,
      salesTaxEditorUpdateEvent,
      salesTaxGroupEditorCloseEvent,
      salesTaxGroupEditorUpdateEvent,
      selectLists,
      salesTaxGroupList,
      salesTaxGroupHeaders,
      includedTaxCodeList,
      toLocaleDateString,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
