<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          TranCode Groups
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="selectLists"
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="infoList"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.CategoryCode="{ item }">
            {{ tranCodeGroupCategoryDescription(item.CategoryCode) }}
          </template>
          <template v-slot:item.Actions="{ item }">
            <div class="d-flex">
              <v-icon small class="mr-2" @click="editItemClick(item)">
                {{ icon.lineItemEdit }}
              </v-icon>
              <v-icon small @click="deleteItemClick(item)">
                {{ icon.lineItemDelete }}
              </v-icon>
              <SyncInfoButton small :recordId="item.TranCodeGroupId" />
            </div>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New TranCode Group</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showEditorDialog" width="400" persistent>
      <TranCodeGroupEditor
        :infoId="selectedItemId"
        :isDialogOpen="showEditorDialog"
        :selectLists="selectLists"
        @close="editorCloseEvent"
        @update="editorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { icon } from '@/use/Constants'
import TranCodeGroupEditor from '@/components/Setup/Billing/TranCodeGroupEditor'
import SyncInfoButton from '@/components/Common/SyncInfoButton'

export default {
  name: 'TranCodeGroupList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { TranCodeGroupEditor, SyncInfoButton },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      infoList.value = []
      getTranCodeGroupList()
      loadSelectLists()
    }

    const infoList = ref([])
    const selectedItemId = ref(null)
    const showEditorDialog = ref(false)
    const selectLists = ref()

    const getTranCodeGroupList = async () => {
      loading.value = true
      const rq = {}
      const rs = await host.tranCodeGroup.list(rq)
      infoList.value = rs.InfoList
      loading.value = false
    }

    function editItemClick(item) {
      selectedItemId.value = item.TranCodeGroupId
      showEditorDialog.value = true
    }

    function deleteItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete TranCode Group ${item.Code}`
        })
        .then(() => performDeleteTranCodeGroup(item))
        .catch(() => {
          return
        })
    }

    async function performDeleteTranCodeGroup(item) {
      const rq = { InfoId: item.TranCodeGroupId }
      const rs = await host.tranCodeGroup.delete(rq)
      if (rs.IsSuccess) {
        getTranCodeGroupList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newItemClick() {
      selectedItemId.value = null
      showEditorDialog.value = true
    }

    const headers = [
      { value: 'Code', text: 'Code' },
      { value: 'Description', text: 'Description' },
      { value: 'SortOrder', text: 'Sort Order', align: 'right' },
      { value: 'CategoryCode', text: 'Category' },
      { value: 'Actions', text: 'Actions', sortable: false }
    ]

    const editorCloseEvent = () => {
      showEditorDialog.value = false
    }

    const editorUpdateEvent = () => {
      showEditorDialog.value = false
      getTranCodeGroupList()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodeGroupCategories]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const tranCodeGroupCategoryDescription = value => {
      return selectListCache.attribute('TranCodeGroupCategories', value, 'Text')
    }

    initDialog()

    return {
      dialogUtil,
      infoList,
      headers,
      showEditorDialog,
      selectedItemId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      editorCloseEvent,
      editorUpdateEvent,
      tranCodeGroupCategoryDescription,
      selectLists,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
