<template>
  <div>
    <v-card :width="feature.sendEmail.dialogWidth">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Send Email -- {{ subtitle }}
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="selectLists">
        <div class="d-flex">
          <div>
            <v-card width="350">
              <v-card-text>
                <v-select
                  v-model="emailTemplateId"
                  :items="selectLists.EmailTemplatesCustomerNotification"
                  item-text="Text"
                  item-value="Value"
                  label="Email Template"
                  clearable
                  dense
                />
                <v-text-field v-model="bcc" label="Bcc" dense />
                <v-text-field
                  v-model="subject"
                  label="Subject"
                  :error-messages="errorMessages(vuelidate.subject)"
                  @blur="vuelidate.subject.$touch()"
                  dense
                />
              </v-card-text>
            </v-card>
          </div>
          <div>
            <v-card width="300" class="ml-4">
              <v-card-title>
                <div class="text-caption">
                  Substitutions
                </div>
              </v-card-title>
              <v-card-text>
                <div v-for="(item, index) in substitutionList" :key="index">
                  <span>{</span>{{ index }}<span>}</span> {{ item }}
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <v-card class="mt-3" height="275">
          <div class="red--text">
            {{ vuelidate.body.$errors.map(e => e.$message).join(', ') }}
          </div>
          <ckeditor :editor="editor" v-model="body" :config="{}" height="200" />
        </v-card>
      </v-card-text>
      <v-card class="mt-2">
        <v-card-actions>
          <v-btn color="success" class="ml-2" @click="sendEmailClick">
            Send Email
          </v-btn>
          <v-spacer />
          <v-btn color="error" class="mr-2" @click="cancelClick">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>

    <WaitDialog v-model="showWaitDialog" :title="title" :subtitle="subtitle" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { feature } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import WaitDialog from '@/components/Common/WaitDialog'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'SendEmailDialog',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Sending Email'
    },
    subtitle: {
      type: String,
      default: 'Please wait'
    },
    showWaitDialog: {
      type: Boolean,
      default: false
    },
    emailEventType: {
      type: String,
      default: 'C'
    }
  },
  components: { WaitDialog },
  setup(props, context) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const emailTemplateId = ref(null)
    const bcc = ref('')
    const subject = ref('')
    const body = ref('')
    const editor = ClassicEditor
    const selectLists = ref()
    const substitutionList = ref()

    const initDialog = async () => {
      emailTemplateId.value = null
      subject.value = ''
      bcc.value = ''
      body.value = ''
      await loadSelectLists()
      substitutionList.value = getSubstitutionListFor(props.emailEventType)
      vuelidate.value.$reset()
    }

    const getSubstitutionListFor = eventtype => {
      const extraData = selectListCache.attribute(
        selectListCache.name.EmailEvents,
        eventtype,
        'ExtraData'
      )

      return extraData ? JSON.parse(extraData) : []
    }

    watch(
      () => emailTemplateId.value,
      newVal => {
        loadEmailTemplateInfo(newVal)
      }
    )

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.EmailEvents,
        selectListCache.name.EmailTemplatesCustomerNotification
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const loadEmailTemplateInfo = async id => {
      const rq = { InfoId: id }
      const rs = await host.emailTemplate.retrieve(rq)
      const info = rs.Info
      bcc.value = info.Bcc
      subject.value = info.Subject
      body.value = info.Body
    }

    const sendEmailClick = () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      context.emit('update', {
        bcc: bcc.value,
        subject: subject.value,
        body: body.value
      })
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const rules = {
      subject: { required },
      body: { required }
    }

    const vuelidate = useVuelidate(rules, { subject, body })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    initDialog()

    return {
      selectLists,
      emailTemplateId,
      bcc,
      subject,
      body,
      sendEmailClick,
      cancelClick,
      feature,
      editor,
      substitutionList,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 200px;
}
</style>
