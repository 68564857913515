<template>
  <div>
    <v-card v-if="formData" class="blue lighten-4">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div class="subtitle-1">
            {{ isEditMode ? 'Edit' : 'New' }}
            {{ description }} for site
            {{ formData.SiteDescription }}
          </div>
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <div class="d-flex blue lighten-4">
          <div>
            <v-card width="350" class="ml-2 mt-2">
              <v-card-title class="subtitle-1">Serviceable</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="formData.ServiceableDescription"
                  label="Description"
                  dense
                />
                <v-text-field
                  v-model="formData.ServiceableSortOrder"
                  type="number"
                  label="Sort Order"
                  dense
                />
                <div
                  v-for="attr in attrNodes(formData.AttributeTypeList)"
                  :key="attr.ServiceableAttributeTypeId"
                >
                  <serviceable-attribute-editor
                    v-model="attr.Info"
                    :type="attr.AttributeType"
                    :option-list="attr.OptionList"
                    :label="attr.Description"
                  />
                </div>
              </v-card-text>
            </v-card>

            <v-card width="350" class="ml-2 mt-2">
              <v-card-actions>
                <v-btn color="success" @click="saveClick"
                  ><span>Save</span
                  ><v-icon right>{{ icon.save }}</v-icon></v-btn
                >
                <v-btn class="ml-2" color="error" @click="cancelClick">
                  <span>Back</span>
                  <v-icon right>{{ icon.exit }}</v-icon>
                </v-btn>
                <v-divider />
                <v-btn
                  v-if="formData.ServiceableId"
                  color="secondary"
                  @click="deleteClick"
                >
                  <span>Delete</span>
                  <v-icon right>{{ icon.delete }}</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>

          <div v-if="attrCategories(formData.AttributeTypeList).length > 0">
            <v-tabs vertical class="ml-2 mt-2" background-color="#BBDEFB">
              <v-tab
                v-for="category in attrCategories(formData.AttributeTypeList)"
                :key="category.ServiceableAttributeTypeId"
                >{{ category.Description }}</v-tab
              >
              <v-tab-item
                v-for="category in attrCategories(formData.AttributeTypeList)"
                :key="category.ServiceableAttributeTypeId"
                class="blue lighten-4 full-height"
              >
                <v-card width="350" class="ml-4">
                  <v-card-title class="subtitle-1">{{
                    category.Description
                  }}</v-card-title>
                  <v-card-text>
                    <div
                      v-for="attr in attrNodes(category.AttributeTypeList)"
                      :key="attr.ServiceableAttributeTypeId"
                    >
                      <ServiceableAttributeEditor
                        v-model="attr.Info"
                        :type="attr.AttributeType"
                        :option-list="attr.OptionList"
                        :label="attr.Description"
                      />
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import ServiceableAttributeEditor from '@/components/controls/ServiceableAttributeEditor'
export default {
  name: 'ServiceableEditor',
  props: {
    serviceableId: {
      type: String,
      default: null
    },
    siteId: {
      type: String,
      default: null
    },
    serviceableTypeId: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ServiceableAttributeEditor
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()
    const isEditMode = ref(false)

    const initDialog = async () => {
      isEditMode.value = props.serviceableId !== null
      const serviceableInfo = props.serviceableId
        ? await retrieveServiceable(props.serviceableId)
        : await newServiceableTemplate(props.siteId)

      const serviceableTypeInfo = await retrieveServiceableType(
        props.serviceableId
          ? serviceableInfo.ServiceableTypeId
          : props.serviceableTypeId
      )

      formData.value = mergeValues(serviceableTypeInfo, serviceableInfo)
    }

    const mergeValues = (serviceableTypeInfo, serviceableInfo) => {
      serviceableTypeInfo.ServiceableId = serviceableInfo.ServiceableId
      serviceableTypeInfo.ServiceableDescription = serviceableInfo.ServiceableId
        ? serviceableInfo.Description
        : serviceableTypeInfo.Description
      serviceableTypeInfo.ServiceableSortOrder = serviceableInfo.SortOrder
      serviceableTypeInfo.SiteId = serviceableInfo.SiteId
      serviceableTypeInfo.SiteDescription = serviceableInfo.SiteDescription
      if (serviceableTypeInfo.AttributeTypeList.length > 0) {
        mergeAttributeValues(
          serviceableTypeInfo.AttributeTypeList,
          serviceableInfo.AttributeList
        )
      }
      return serviceableTypeInfo
    }

    const mergeAttributeValues = (attributeTypeList, attributeList) => {
      attributeTypeList.forEach(attr => {
        const attrInfo = attributeList.find(
          item =>
            item.ServiceableAttributeTypeId === attr.ServiceableAttributeTypeId
        )
        attr.ServiceableAttributeId = attrInfo
          ? attrInfo.ServiceableAttributeId
          : null
        attr.Info = attrInfo ? attrInfo.Info : null
        if (attr.AttributeTypeList.length > 0) {
          // Recursive call
          mergeAttributeValues(attr.AttributeTypeList, attributeList)
        }
      })
    }

    const retrieveServiceable = async id => {
      const rq = { InfoId: id }
      const rs = await host.serviceable.retrieve(rq)
      return rs.Info
    }

    const newServiceableTemplate = async id => {
      const rq = { Defaults: id }
      const rs = await host.serviceable.newTemplate(rq)
      return rs.Info
    }

    const retrieveServiceableType = async id => {
      const rq = { InfoId: id }
      const rs = await host.serviceableType.retrieve(rq)
      return rs.Info
    }

    const attrCategories = list => {
      return list.filter(item => item.AttributeType === 'C')
    }

    const attrNodes = list => {
      return list.filter(item => item.AttributeType !== 'C')
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const saveClick = async () => {
      const rq = {
        Info: {
          ServiceableId: formData.value.ServiceableId,
          ServiceableTypeId: formData.value.ServiceableTypeId,
          Description: formData.value.ServiceableDescription,
          SortOrder: formData.value.ServiceableSortOrder,
          SiteId: formData.value.SiteId,
          SiteDescription: formData.value.SiteDescription,
          AttributeList: []
        }
      }

      collectAttributeValues(
        rq.Info.AttributeList,
        formData.value.AttributeTypeList
      )

      const result = rq.Info.ServiceableId
        ? await updateServiceable(rq)
        : await createServiceable(rq)
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const collectAttributeValues = (destArray, attrList) => {
      attrList.forEach(attr => {
        if (attr.AttributeType !== 'C') {
          destArray.push({
            ServiceableAttributeId: attr.ServiceableAttributeId,
            ServiceableAttributeTypeId: attr.ServiceableAttributeTypeId,
            Info: attr.Info
          })
        }

        if (attr.AttributeTypeList && attr.AttributeTypeList.length > 0) {
          // Recursive call
          collectAttributeValues(destArray, attr.AttributeTypeList)
        }
      })
    }

    const deleteClick = () => {
      dialogUtil.value
        .confirm({
          title: 'CAREFUL! Please confirm',
          text:
            `Delete ` +
            formData.value.ServiceableDescription +
            '? This CANNOT be undone.'
        })
        .then(async () => {
          const result = await deleteServiceable({
            InfoId: formData.value.ServiceableId
          })
          if (result.IsSuccess) {
            context.emit('update')
          } else {
            dialogUtil.value.error(result.Message)
          }
        })
        .catch(() => {
          return
        })
    }

    const createServiceable = async rq => {
      const rs = await host.serviceable.create(rq)
      return rs
    }

    const updateServiceable = async rq => {
      const rs = await host.serviceable.update(rq)
      return rs
    }

    const deleteServiceable = async rq => {
      const rs = await host.serviceable.delete(rq)
      return rs
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      attrCategories,
      attrNodes,
      cancelClick,
      saveClick,
      deleteClick,
      icon
    }
  }
}
</script>

<style lang="scss">
.full-height {
  height: 800px;
}
</style>
