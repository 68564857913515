<template>
  <v-card width="400px">
    <v-card-title>{{ operation }} Phone Number</v-card-title>
    <v-card-text>
      <v-combobox
        v-model="formData.PhoneNumberType"
        label="Type"
        :items="phoneNumberTypes"
        :error-messages="errorMessages(vuelidate.formData.PhoneNumberType)"
        @blur="vuelidate.formData.PhoneNumberType.$touch()"
        dense
      ></v-combobox>
      <v-text-field
        v-model="formData.AreaCode"
        label="Area Code"
        :error-messages="errorMessages(vuelidate.formData.AreaCode)"
        @blur="vuelidate.formData.AreaCode.$touch()"
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.PhoneNumber"
        label="Phone Number"
        :error-messages="errorMessages(vuelidate.formData.PhoneNumber)"
        @blur="vuelidate.formData.PhoneNumber.$touch()"
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.Extension"
        label="Extension"
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.Description"
        label="Description"
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.SortOrder"
        label="Priority"
        type="number"
        dense
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" class="ml-3" @click="saveClick">Save</v-btn>
      <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'PhoneNumberEditor',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: true
    },
    phoneNumberInfo: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      formData.value = { ...template, ...props.phoneNumberInfo }
      vuelidate.value.$reset()
    }

    const formData = ref()
    const phoneNumberTypes = ['Primary', 'Home', 'Mobile', 'Fax', 'Work']
    const template = {
      Id: null,
      PhoneNumberType: null,
      AreaCode: null,
      PhoneNumber: null,
      Extension: null,
      Description: null,
      SortOrder: 0
    }

    const operation = computed(() => {
      return formData.value.Id === null ? 'Add' : 'Update'
    })

    const rules = {
      formData: {
        PhoneNumberType: { required },
        AreaCode: { required },
        PhoneNumber: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const saveClick = () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }
      context.emit('update', formData.value)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    initDialog()
    return {
      operation,
      formData,
      phoneNumberTypes,
      saveClick,
      cancelClick,
      vuelidate,
      errorMessages
    }
  }
}
</script>

<style lang="scss" scoped></style>
