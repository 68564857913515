<template>
  <v-card
    v-if="agingSummary"
    width="320px"
    height="330px"
    class="pt-2 pl-3 ma-2"
  >
    <div class="overline grey--text text-uppercase">Aging Summary</div>
    <div class="mt-2">Total Balance: {{ toMoney(agingSummary.Balance) }}</div>
    <div>Current Balance: {{ toMoney(agingSummary.Current) }}</div>
    <ejs-accumulationchart
      id="container"
      style="height: 220px;"
      :enableSmartLabels="enableSmartLabels"
      :textRender="onTextRender"
    >
      <e-accumulation-series-collection>
        <e-accumulation-series
          :dataSource="seriesData"
          xName="x"
          yName="y"
          radius="65%"
          innerRadius="70%"
          :dataLabel="dataLabel"
        >
        </e-accumulation-series>
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
  </v-card>
</template>

<script>
import Vue from 'vue'
import {
  AccumulationChartPlugin,
  PieSeries,
  AccumulationDataLabel
} from '@syncfusion/ej2-vue-charts'
import Vue2Filters from 'vue2-filters'
import { ref, computed } from 'vue'

import { host } from '@/services/HostAPI'
import { toMoney } from '@/use/NumberFormatter'
Vue.use(AccumulationChartPlugin)
Vue.use(Vue2Filters)

export default {
  name: 'AgingCard',
  setup() {
    const agingSummary = ref()

    const dataLabel = ref({
      visible: true,
      position: 'Outside',
      name: 'text',
      template: '<div>${point.x}</div><div>${point.text}</div>'
    })

    const enableSmartLabels = ref(true)

    const getDataFromApi = async () => {
      const rq = {}
      const rs = await host.billing.getAgingSummary(rq)
      agingSummary.value = rs.Info
    }

    const seriesData = computed(() => {
      return agingSummary.value
        ? [
            {
              x: '30 Days',
              y: agingSummary.value.A30,
              text: '30 Days'
            },
            {
              x: '60 Days',
              y: agingSummary.value.A60,
              text: '60 Days'
            },
            {
              x: '90+ Days',
              y: agingSummary.value.A90,
              text: '90+ Days'
            }
          ]
        : []
    })

    const onTextRender = args => {
      args.text = toMoney(parseFloat(args.point.y))
    }

    getDataFromApi()
    return {
      agingSummary,
      dataLabel,
      enableSmartLabels,
      toMoney,
      seriesData,
      onTextRender
    }
  },
  provide: {
    accumulationchart: [PieSeries, AccumulationDataLabel]
  }
}
</script>
