<template>
  <div>
    <v-card width="6000" height="750">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div class="d-flex">
            <div>
              <v-btn
                v-if="invalidAddressList.length > 0"
                color="error"
                class="mr-2"
                @click="invalidAddressButtonClick"
                >{{ invalidAddressList.length }} Invalid Addresses</v-btn
              >
            </div>
            <div>
              Edit Calendar
            </div>
          </div>
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <div v-if="selectLists" class="d-flex">
          <div v-for="(column, index) in scheduledServiceList" :key="index">
            <v-card
              v-if="selectedColumnIndex == -1 || selectedColumnIndex == index"
              width="260"
              height="600"
              class="mr-2 indigo lighten-4"
            >
              <v-card-text>
                <div>
                  <div class="d-flex">
                    <div class="text-subtitle-1 blue--text">
                      {{ column.UserName }}
                    </div>

                    <v-spacer />

                    <div v-if="selectedColumnIndex == -1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            @click="mapShowClick(index)"
                          >
                            <v-icon :color="feature.maps.color">
                              {{ feature.maps.icon }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ feature.maps.toolTip }}</span>
                      </v-tooltip>
                    </div>

                    <div v-else>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            @click="toggleDirectionsClick"
                          >
                            <v-icon :color="toggleDirectionsIconColor">
                              {{ icon.mapDirections }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ toggleDirectionsText }}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            @click="optimizeRouteClick"
                          >
                            <v-icon color="purple">
                              {{ icon.mapOptimize }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Optimize Route</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on" @click="mapHideClick(index)">
                            <v-icon color="red">{{ icon.exit }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Hide Map</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <div class="blue--text">
                    {{ toLocaleDayOfWeekString(column.ServiceDate) }}
                    {{ toLocaleDateString(column.ServiceDate) }}
                  </div>
                </div>
                <draggable
                  v-model="column.ServiceItemList"
                  group="routes"
                  multiDrag
                  selectedClass="dragselect"
                  ghostClass="dragghost"
                  @change="draggableChange($event, index)"
                >
                  <v-card
                    v-for="ss in column.ServiceItemList"
                    :key="ss.ScheduledServiceId"
                    class="my-2"
                    width="240"
                    tabindex="0"
                  >
                    <v-card-text class="pa-1">
                      <div class="d-flex">
                        <div class="subtitle-2">{{ ss.SiteName }}</div>
                        <v-spacer />
                        <div class="mr-2">{{ ss.StopNumber }}</div>
                      </div>
                      <div class="red--text">
                        {{ arrivalWindowDescription(ss) }}
                      </div>
                      <div class="d-flex">
                        <v-icon small :color="feature.services.color">
                          {{ serviceTypeIcon(ss.ServiceTypeId) }}
                        </v-icon>
                        <div class="ml-1 text-caption blue--text">
                          {{ ss.ServiceType }}
                        </div>
                        <v-spacer />
                        <div
                          v-if="ss.Status != 'O'"
                          :class="statusColor(ss.Status)"
                        >
                          {{ statusDescription(ss.Status) }}
                        </div>
                      </div>
                      <div class="d-flex justify-space-between">
                        <div
                          :class="
                            ss.Address.Latitude == 0.0 &&
                            ss.Address.Longitude == 0.0
                              ? 'red--text'
                              : ''
                          "
                        >
                          {{ ss.Address.Street + ', ' + ss.Address.City }}
                        </div>
                        <v-icon @click="toggleExpanded(ss)" tabindex="-1">
                          {{
                            ss.IsSelected ? icon.cardCollapse : icon.cardExpand
                          }}
                        </v-icon>
                      </div>
                      <div v-if="ss.IsSelected" class="d-flex align-end">
                        <div class="flex-grow-1">
                          <div>{{ ss.Phone }}</div>
                          <div v-if="ss.ServiceRouteCode">
                            {{ ss.ServiceRouteCode }}
                          </div>
                          <div v-if="ss.RepeatInfo">{{ ss.RepeatInfo }}</div>
                        </div>
                        <v-spacer />
                        <div>
                          <EditScheduledServiceButton
                            :siteId="ss.SiteId"
                            :scheduledServiceId="ss.ScheduledServiceId"
                            @update="editScheduledServiceButtonUpdate"
                          />
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </draggable>
              </v-card-text>
            </v-card>
          </div>
          <div
            ref="mapCanvas"
            v-show="selectedColumnIndex >= 0"
            class="flex-grow-1 red lighten-4 subtitle-1"
            style="height: 600px;"
          >
            Map goes here
          </div>
          <div
            width="240"
            ref="mapDirectionsCanvas"
            v-show="showDirections"
            class="subtitle-1 px-2"
            style="width: 300px; height: 600px; overflow-y: scroll;"
          ></div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="ml-3" @click="exitClick">
          <span>Back</span>
          <v-icon right>{{ icon.exit }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="showOptimizeDialog"
      :width="feature.services.optimizeDialogWidth"
    >
      <ScheduledServiceOptimizer
        v-if="selectedColumnIndex >= 0"
        :isDialogOpen="showOptimizeDialog"
        :scheduledServiceInfo="scheduledServiceList[selectedColumnIndex]"
        @close="optimizeDialogCloseEvent"
        @update="optimizeDialogUpdateEvent"
      />
    </v-dialog>

    <v-dialog v-model="showInvalidSiteAddressList" max-width="800">
      <InvalidSiteAddressList
        :isDialogOpen="showInvalidSiteAddressList"
        :invalidAddressList="invalidAddressList"
        @update="invalidSiteAddressListUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import {
  toLocaleDateString,
  toLocaleDayOfWeekString
} from '@/use/DateFormatter'
import { host } from '@/services/HostAPI'
import EditScheduledServiceButton from '@/components/ScheduledService/EditScheduledServiceButton'
import draggable from 'vuedraggable-multi'
import { mapService } from '@/services/GoogleMapService'
import ScheduledServiceOptimizer from '@/components/ScheduledService/ScheduledServiceOptimizer'
import InvalidSiteAddressList from '@/components/Site/InvalidSiteAddressList'
import { icon, feature } from '@/use/Constants'
import { selectListCache } from '@/services/SelectListCache'
import { isoTimeToShortTime } from '@/use/TimeFormatter'
export default {
  name: 'CalendarEditor',
  props: {
    list: {
      type: Array,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    draggable,
    EditScheduledServiceButton,
    ScheduledServiceOptimizer,
    InvalidSiteAddressList
  },
  setup(props, context) {
    const mapCanvas = ref()
    const mapDirectionsCanvas = ref()

    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const scheduledServiceList = ref()
    const selectedColumnIndex = ref(-1)
    const hasChanges = ref(false)
    const showDirections = ref(false)
    const showOptimizeDialog = ref(false)
    const showInvalidSiteAddressList = ref(false)
    const selectLists = ref()

    const initDialog = async () => {
      loadSelectLists()
      getData()
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.ServiceStatus,
        selectListCache.name.ServiceTypes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const getData = async () => {
      scheduledServiceList.value = []
      selectedColumnIndex.value = -1
      const rq = {
        Criteria: {
          RequestList: props.list.map(item => {
            return { UserId: item.UserId, ServiceDate: item.Date }
          })
        }
      }

      const rs = await host.scheduledService.cardList(rq)
      scheduledServiceList.value = rs.InfoList
    }

    const exitClick = () => {
      context.emit(hasChanges.value ? 'update' : 'close')
    }

    const serviceTypeIcon = serviceTypeId => {
      return selectListCache.attribute(
        selectListCache.name.ServiceTypes,
        serviceTypeId,
        'IconName'
      )
    }

    const statusColor = status => {
      return status == 'O' ? 'green--text' : 'red--text'
    }

    const statusDescription = status => {
      return selectListCache.attribute(
        selectListCache.name.ServiceStatus,
        status,
        'Text'
      )
    }

    const toggleExpanded = ss => {
      ss.IsSelected = !ss.IsSelected
    }

    const draggableChange = (eventArgs, toColIndex) => {
      if (eventArgs.added) {
        const added = Array.isArray(eventArgs.added)
          ? eventArgs.added
          : [eventArgs.added]

        const columnMoveArgs = {
          fromDate: added[0].element.ServiceDate,
          toDate: scheduledServiceList.value[toColIndex].ServiceDate,
          fromUserId: added[0].element.UserId,
          toUserId: scheduledServiceList.value[toColIndex].UserId,
          scheduledServiceIdList: added.map(
            item => item.element.ScheduledServiceId
          ),
          stopNumber: added[0].newIndex + 1
        }

        const hasRecurringService = added.some(
          item => item.element.RecurringServiceId
        )

        if (columnMoveArgs.fromDate == columnMoveArgs.toDate) {
          moveServiceBetweenColumnsSameDate(columnMoveArgs, hasRecurringService)
        } else {
          moveServiceBetweenColumns(columnMoveArgs)
        }

        hasChanges.value = true
      } else if (eventArgs.moved) {
        const moved = Array.isArray(eventArgs.moved)
          ? eventArgs.moved
          : [eventArgs.moved]

        const columnMoveArgs = {
          fromDate: null,
          toDate: scheduledServiceList.value[toColIndex].ServiceDate,
          fromUserId: null,
          toUserId: scheduledServiceList.value[toColIndex].UserId,
          scheduledServiceIdList: moved.map(
            item => item.element.ScheduledServiceId
          ),
          stopNumber: moved[0].newIndex + 1
        }

        moveServiceWithinColumn(columnMoveArgs)
        // Moved within column. No need to set hasChanges
      }
    }

    const moveServiceBetweenColumnsSameDate = (
      columnMoveArgs,
      hasRecurringService
    ) => {
      if (!hasRecurringService) {
        moveServiceBetweenColumnsSameDate1(columnMoveArgs, false)
        return
      }

      dialogUtil.value
        .confirm({
          title: 'Move service to different user',
          text: 'Apply this move to recurring services?',
          acceptText: 'Yes',
          cancelText: 'No'
        })
        .then(() => {
          moveServiceBetweenColumnsSameDate1(columnMoveArgs, true)
        })
        .catch(() => {
          moveServiceBetweenColumnsSameDate1(columnMoveArgs, false)
        })
    }

    const moveServiceBetweenColumnsSameDate1 = async (
      columnMoveArgs,
      applyToRecurring
    ) => {
      const rq = createRqMoveSSSameDate(columnMoveArgs, applyToRecurring)
      const rs = await host.scheduledService.moveSameDate(rq)
      handleMoveResponse(rs)
    }

    const createRqMoveSSSameDate = (columnMoveArgs, applyToRecurring) => {
      return {
        ScheduledServiceIdList: columnMoveArgs.scheduledServiceIdList,
        UserId: columnMoveArgs.toUserId,
        StopNumber: columnMoveArgs.stopNumber,
        CriteriaForRefresh: createCriteriaForRefresh(columnMoveArgs),
        ApplyToRecurring: applyToRecurring
      }
    }

    const createCriteriaForRefresh = columnMoveArgs => {
      const requestList = []

      if (columnMoveArgs.fromDate && columnMoveArgs.fromUserId) {
        requestList.push(
          criteriaItem(columnMoveArgs.fromUserId, columnMoveArgs.fromDate)
        )
      }

      if (columnMoveArgs.toDate && columnMoveArgs.toUserId) {
        requestList.push(
          criteriaItem(columnMoveArgs.toUserId, columnMoveArgs.toDate)
        )
      }

      return {
        RequestList: requestList
      }
    }

    const criteriaItem = (userId, serviceDate) => {
      return { UserId: userId, ServiceDate: serviceDate }
    }

    const moveServiceBetweenColumns = async columnMoveArgs => {
      const rq = createRqMoveSSToDate(columnMoveArgs)
      const rs = await host.scheduledService.moveToDate(rq)
      handleMoveResponse(rs)
    }

    const createRqMoveSSToDate = columnMoveArgs => {
      return {
        ScheduledServiceIdList: columnMoveArgs.scheduledServiceIdList,
        UserId: columnMoveArgs.toUserId,
        StopNumber: columnMoveArgs.stopNumber,
        CriteriaForRefresh: createCriteriaForRefresh(columnMoveArgs),
        ServiceDate: columnMoveArgs.toDate
      }
    }

    const moveServiceWithinColumn = async columnMoveArgs => {
      const rq = createRqMoveSSSameDate(columnMoveArgs, true)
      const rs = await host.scheduledService.moveSameDate(rq)
      handleMoveResponse(rs)
    }

    const handleMoveResponse = response => {
      if (response.IsSuccess) {
        for (const info of response.InfoList) {
          const columnIndex = scheduledServiceList.value.findIndex(
            item =>
              item.ServiceDate == info.ServiceDate && item.UserId == info.UserId
          )

          if (columnIndex >= 0) {
            scheduledServiceList.value.splice(columnIndex, 1, info)
          }
        }
      } else {
        dialogUtil.value.error(response.Message)
      }
    }

    const mapShowClick = async index => {
      selectedColumnIndex.value = index
      const startStop = await getStartStop(scheduledServiceList.value[index])
      mapService.showRoute(
        mapCanvas.value,
        mapDirectionsCanvas.value,
        scheduledServiceList.value[index].ServiceItemList,
        startStop
      )
    }

    const getStartStop = async column => {
      const criteria = {
        UserId: column.UserId,
        ServiceDate: column.ServiceDate
      }

      const response = await host.serviceRouteAssignment.list({
        Criteria: criteria
      })

      if (response.InfoList.length > 0) {
        return response.InfoList[0]
      } else {
        const response2 = await host.serviceRouteAssignment.newTemplate(
          criteria
        )
        return response2.Item
      }
    }

    const mapHideClick = () => {
      showDirections.value = false
      selectedColumnIndex.value = -1
    }

    const optimizeRouteClick = () => {
      showOptimizeDialog.value = true
    }

    const optimizeDialogCloseEvent = () => {
      showOptimizeDialog.value = false
    }

    const optimizeDialogUpdateEvent = async () => {
      const index = selectedColumnIndex.value
      await getData()
      mapShowClick(index)
      showOptimizeDialog.value = false
    }

    const toggleDirectionsClick = () => {
      showDirections.value = !showDirections.value
    }

    const toggleDirectionsIconColor = computed(() =>
      showDirections.value ? 'red' : 'green'
    )

    const toggleDirectionsText = computed(() =>
      showDirections.value ? 'Hide Directions' : 'Show Directions'
    )

    const editScheduledServiceButtonUpdate = () => {
      initDialog()
    }

    const invalidAddressList = ref([])

    watch(
      () => scheduledServiceList.value,
      newVal => {
        invalidAddressList.value = newVal
          ? extractInvalidAddressesFromScheduledServiceList(newVal)
          : []
      }
    )

    const extractInvalidAddressesFromScheduledServiceList = ss => {
      return ss.reduce((array, item) => {
        return array.concat(
          item.ServiceItemList.filter(
            ss => ss.Address.Latitude == 0 && ss.Address.Longitude == 0
          ).map(ss => {
            return {
              SiteId: ss.SiteId,
              SiteName: ss.SiteName,
              Street: ss.Address.Street,
              City: ss.Address.City,
              State: ss.Address.State
            }
          })
        )
      }, [])
    }

    const invalidAddressButtonClick = () => {
      showInvalidSiteAddressList.value = true
    }

    const invalidSiteAddressListUpdateEvent = () => {
      showInvalidSiteAddressList.value = false
      initDialog()
    }

    const arrivalWindowDescription = ss => {
      const earliest = ss.EarliestArrivalTime
        ? isoTimeToShortTime(ss.EarliestArrivalTime)
        : null
      const latest = ss.LatestArrivalTime
        ? isoTimeToShortTime(ss.LatestArrivalTime)
        : null
      if (earliest || latest) {
        let desc = 'Est. Arrival:'
        if (earliest) {
          desc += (latest ? ' from ' : ' after ') + earliest
        }
        if (latest) {
          desc += (earliest ? ' to ' : ' by ') + latest
        }
        return desc
      } else {
        return null
      }
    }

    initDialog()

    return {
      dialogUtil,
      scheduledServiceList,
      exitClick,
      toggleExpanded,
      toLocaleDateString,
      toLocaleDayOfWeekString,
      draggableChange,
      serviceTypeIcon,
      statusColor,
      statusDescription,
      selectedColumnIndex,
      mapShowClick,
      mapHideClick,
      mapCanvas,
      mapDirectionsCanvas,
      showDirections,
      toggleDirectionsIconColor,
      toggleDirectionsClick,
      toggleDirectionsText,
      optimizeRouteClick,
      showOptimizeDialog,
      optimizeDialogCloseEvent,
      optimizeDialogUpdateEvent,
      editScheduledServiceButtonUpdate,
      invalidAddressList,
      showInvalidSiteAddressList,
      invalidAddressButtonClick,
      invalidSiteAddressListUpdateEvent,
      feature,
      icon,
      selectLists,
      arrivalWindowDescription
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.dragselect {
  background-color: Aqua;
}

.dragghost {
  opacity: 0.5;
  background-color: yellow;
}
</style>
