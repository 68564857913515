<template>
  <div>
    <v-card v-if="formData && selectLists" class="blue lighten-4">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div class="d-flex">
            <div class="subtitle-1">{{ isEditMode ? 'Edit' : 'New' }} Task</div>
            <v-spacer />
            <ActivityLogButton
              v-if="isEditMode"
              description="Scheduled Task"
              tableName="ScheduledTask"
              :recordId="formData.ScheduledTaskId"
            /></div
        ></DialogTitle>
      </v-card-title>
      <v-card-text>
        <div class="d-flex blue lighten-4">
          <div>
            <v-card width="400">
              <v-card-title>
                <div class="text-subtitle-1 blue--text">
                  Task Info
                </div>
              </v-card-title>
              <v-card-text>
                <v-select
                  v-model="formData.TaskDefId"
                  :items="selectLists.TaskTypes"
                  item-text="Text"
                  item-value="Value"
                  label="Task Type"
                  :error-messages="errorMessages(vuelidate.formData.TaskDefId)"
                  dense
                  @blur="vuelidate.formData.TaskDefId.$touch()"
                />
                <TaskPriorityPicker
                  v-model="formData.Priority"
                  label="Priority"
                />
                <CustomerPicker
                  label="Customer"
                  v-model="formData.CustomerId"
                  v-bind:text.sync="formData.CustomerName"
                  clearable
                  dense
                />
                <UserPicker v-model="formData.UserId" label="Assigned To" />
                <DateTimePicker
                  v-model="formData.WhenScheduled"
                  label="Scheduled Date"
                  dense
                />
                <DurationPicker
                  v-model="formData.EstimatedTime"
                  label="Estimated Duration"
                  dense
                />
              </v-card-text>
            </v-card>

            <v-card class="mt-2" width="400px">
              <v-card-text>
                <v-card-actions>
                  <v-btn color="success" @click="saveClick">
                    <span>Save</span>
                    <v-icon right>{{ icon.save }}</v-icon>
                  </v-btn>
                  <v-btn color="error" @click="cancelClick">
                    <span>Back</span>
                    <v-icon right>{{ icon.exit }}</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </div>

          <div>
            <v-card width="400" class="ml-4">
              <v-card-title>
                <div class="text-subtitle-1 blue--text">
                  Notes
                </div>
              </v-card-title>
              <v-card-subtitle>
                <div>
                  Created
                  {{ toLocaleDateTimeString(formData.WhenCreated) }}
                </div>
                <div>By {{ formData.CreatedByUserName }}</div>
              </v-card-subtitle>
              <v-card-text>
                <v-textarea
                  v-model="formData.Comments"
                  label="Comments"
                  dense
                ></v-textarea>
                <v-checkbox
                  v-model="isTaskCompleted"
                  label="Task is Completed"
                />
                <div v-if="isTaskCompleted">
                  <div class="body-2 text--secondary">
                    {{ toLocaleDateTimeString(formData.WhenCompleted) }}
                  </div>
                  <v-textarea
                    v-model="formData.CompletionNotes"
                    label="Completion Notes"
                    dense
                  />
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { toLocaleDateTimeString, toLocaleISOString } from '@/use/DateFormatter'
import { icon } from '@/use/Constants'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ActivityLogButton from '@/components/Common/ActivityLogButton'
import CustomerPicker from '@/components/Customer/CustomerPicker'

export default {
  name: 'ScheduledTaskEditor',
  components: {
    ActivityLogButton,
    CustomerPicker
  },
  props: {
    scheduledTaskId: {
      type: String,
      default: null
    },
    customerId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const formData = ref()
    const selectLists = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      loadSelectLists()
      isEditMode.value = props.scheduledTaskId != null
      formData.value = isEditMode.value
        ? await loadRecurringService(props.scheduledTaskId)
        : await initNewRecurringService(props.customerId)
      vuelidate.value.$reset()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TaskTypes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const loadRecurringService = async id => {
      const rq = { InfoId: id }
      const rs = await host.scheduledTask.retrieve(rq)
      return rs.Info
    }

    const initNewRecurringService = async () => {
      const rq = { Defaults: { CustomerId: props.customerId } }
      const rs = await host.scheduledTask.newTemplate(rq)
      return rs.Info
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (!vuelidate.value.$invalid) {
        const result = await saveTask()
        if (result.IsSuccess) {
          context.emit('update')
        } else {
          dialogUtil.value.error(result.Message)
        }
      }
    }

    const saveTask = async () => {
      const rq = { Info: formData.value }
      const rs = isEditMode.value
        ? await host.scheduledTask.update(rq)
        : await host.scheduledTask.create(rq)
      return rs
    }

    const rules = {
      formData: {
        TaskDefId: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const isTaskCompleted = computed({
      get() {
        return (
          formData.value !== undefined && formData.value.WhenCompleted !== null
        )
      },
      set(val) {
        if (formData.value !== undefined) {
          formData.value.WhenCompleted = val
            ? toLocaleISOString(new Date())
            : null
        }
      }
    })

    initDialog()

    return {
      dialogUtil,
      icon,
      isEditMode,
      formData,
      selectLists,
      isTaskCompleted,
      cancelClick,
      saveClick,
      toLocaleDateTimeString,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
