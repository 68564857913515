<template>
  <div>
    <v-card>
      <v-card-title>Change Password</v-card-title>
      <v-card-text>
        <v-text-field
          label="Old Password"
          v-model="formData.OldPassword"
          :append-icon="showOldPassword ? showIconOn : showIconOff"
          :type="showOldPassword ? 'text' : 'password'"
          :error-messages="errorMessages(vuelidate.formData.OldPassword)"
          @blur="vuelidate.formData.OldPassword.$touch()"
          dense
          @click:append="showOldPassword = !showOldPassword"
        />
        <v-text-field
          label="New Password"
          v-model="formData.NewPassword"
          :append-icon="showNewPassword ? showIconOn : showIconOff"
          :type="showNewPassword ? 'text' : 'password'"
          :error-messages="errorMessages(vuelidate.formData.NewPassword)"
          @blur="vuelidate.formData.NewPassword.$touch()"
          dense
          @click:append="showNewPassword = !showNewPassword"
        />
        <v-text-field
          label="Retype Password"
          v-model="formData.RetypePassword"
          :append-icon="showRetypePassword ? showIconOn : showIconOff"
          :type="showRetypePassword ? 'text' : 'password'"
          :error-messages="errorMessages(vuelidate.formData.RetypePassword)"
          @blur="vuelidate.formData.RetypePassword.$touch()"
          dense
          @click:append="showRetypePassword = !showRetypePassword"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="ml-1" @click="saveClick">Save</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref } from 'vue'
import { icon } from '@/use/Constants'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { sameAs } from '@/use/CustomValidators'
import { host } from '@/services/HostAPI'
import { resetMustChangePassword } from '@/store/UserSession'
export default {
  name: 'ChangePasswordEditor',
  setup(props, context) {
    const dialogUtil = ref(null)

    const formData = ref({
      OldPassword: null,
      NewPassword: null,
      RetypePassword: null
    })

    const showOldPassword = ref(false)
    const showNewPassword = ref(false)
    const showRetypePassword = ref(false)

    const showIconOn = icon.passwordShow
    const showIconOff = icon.passwordHide

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      const result = await changePassword()
      if (result.IsSuccess) {
        await dialogUtil.value.inform({
          text: result.Message,
          snackbarTimeout: 10000,
          color: 'success'
        })
        resetMustChangePassword()
        context.emit('close')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const changePassword = async () => {
      const rq = { Info: formData.value }
      const rs = await host.user.changePassword(rq)
      return rs
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const rules = {
      formData: {
        OldPassword: { required },
        NewPassword: { required, minLength: minLength(5) },
        RetypePassword: {
          required,
          sameAsNewPassword: sameAs(() => {
            return formData.value.NewPassword
          })
        }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    return {
      dialogUtil,
      formData,
      showOldPassword,
      showNewPassword,
      showRetypePassword,
      showIconOn,
      showIconOff,
      saveClick,
      cancelClick,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
