<template>
  <v-select
    v-if="selectLists"
    v-bind="$attrs"
    v-model="input"
    :items="selectLists.TranCodes"
    item-text="Text"
    item-value="Value"
    :label="label"
    dense
  >
    <template slot="selection" slot-scope="data">
      <div :class="tranCodeItemColor(data.item)">
        {{ data.item.Text }}
      </div>
    </template>
    <template slot="item" slot-scope="data">
      <div :class="tranCodeItemColor(data.item)">
        {{ data.item.Text }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { ref, watch } from 'vue'
import { selectListCache } from '@/services/SelectListCache'
export default {
  name: 'TranCodePicker',
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'Transaction Type'
    }
  },
  setup(props, context) {
    const input = ref()
    const selectLists = ref()

    watch(
      () => props.value,
      newVal => {
        loadSelectLists()
        input.value = newVal
      }
    )

    watch(
      () => input.value,
      newVal => {
        context.emit('input', newVal)
        context.emit('change', newVal)
      }
    )

    const initControl = async () => {
      input.value = props.value
      await loadSelectLists()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const tranCodeItemColor = item => {
      const extraData = JSON.parse(item.ExtraData)
      return extraData.IsCredit ? 'red--text' : ''
    }

    initControl()
    return { input, selectLists, tranCodeItemColor }
  }
}
</script>

<style lang="scss" scoped></style>
