const isoTimeToShortTime = isoTime => {
  if (!isoTime) {
    return null
  }

  if (isoTime.includes('T')) {
    let stripBefore = isoTime.indexOf('T')
    isoTime = isoTime.substring(stripBefore + 1, stripBefore + 6)
  }

  let hourNumber = parseInt(isoTime.substring(0, 2))
  const minutes = isoTime.substring(3, 5)
  const isPM = hourNumber >= 12
  if (hourNumber > 12) {
    hourNumber -= 12
  }
  if (hourNumber < 1) {
    hourNumber = 12
  }
  return `${hourNumber}${minutes == '00' ? '' : ':' + minutes} ${
    isPM ? 'PM' : 'AM'
  }`
}

export { isoTimeToShortTime }
