<template>
  <div>
    <v-card width="400px">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Transaction
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="isReady">
        <TranCodePicker
          v-model="formData.TranCodeId"
          label="Transaction Type"
          :error-messages="errorMessages(vuelidate.formData.TranCodeId)"
          @blur="vuelidate.formData.TranCodeId.$touch()"
        />
        <v-text-field
          v-model="formData.Reference"
          label="Reference"
          :error-messages="errorMessages(vuelidate.formData.Reference)"
          @blur="vuelidate.formData.Reference.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.Quantity"
          label="Quantity"
          type="number"
          :error-messages="errorMessages(vuelidate.formData.Quantity)"
          @blur="vuelidate.formData.Quantity.$touch()"
          dense
        />
        <v-currency-field
          v-model="amountEach"
          label="Amount Each"
          dense
          :error-messages="errorMessages(vuelidate.amountEach)"
          @blur="vuelidate.amountEach.$touch()"
        />
        <v-currency-field
          v-model="formData.Amount"
          label="Extended Amount"
          dense
          :error-messages="errorMessages(vuelidate.formData.Amount)"
          @blur="vuelidate.formData.Amount.$touch()"
        />
        <DatePicker
          v-model="formData.BusinessDate"
          label="Business Date"
          dense
        />
      </v-card-text>
      <v-card-actions
        ><v-btn color="success" class="ml-3" @click="saveClick">{{
          isEditMode ? 'Update' : 'Post'
        }}</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        <v-spacer />
        <SyncInfoButton v-if="isEditMode" :recordId="formData.TransactionId" />
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" /></div
></template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import SyncInfoButton from '@/components/Common/SyncInfoButton'

export default {
  name: 'Transaction',
  props: {
    transactionId: {
      type: String,
      default: null
    },
    customerId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { SyncInfoButton },
  setup(props, context) {
    const dialogUtil = ref()

    const isEditMode = ref(false)
    const formData = ref({
      TranCodeId: null,
      Quantity: 1,
      Amount: 0
    })

    const originalFormData = ref()

    const isReady = ref(false)
    const amountEach = ref(0)

    watch(
      () => props.isDialogOpen,
      newVal => {
        if (newVal) {
          initDialog()
        } else {
          isReady.value = false
        }
      }
    )

    watch(
      () => formData.value.TranCodeId,
      newVal => {
        if (newVal) {
          const lookupItem = selectLists.value.TranCodes.find(
            item => item.Value == newVal
          )
          if (
            isEditMode.value &&
            newVal === originalFormData.value.TranCodeId
          ) {
            formData.value.Reference = originalFormData.value.Reference
            formData.value.Amount = originalFormData.value.Amount
          } else {
            formData.value.Reference = lookupItem.Text
            const extraData = JSON.parse(lookupItem.ExtraData)
            formData.value.Amount = extraData.DefaultPrice
          }
        }
      }
    )

    watch(amountEach, newVal => {
      formData.value.Amount = formData.value.Quantity * newVal
    })

    watch(
      () => formData.value.Quantity,
      newVal => {
        formData.value.Amount = newVal * amountEach.value
      }
    )

    watch(
      () => formData.value.Amount,
      newVal => {
        amountEach.value = newVal / formData.value.Quantity
      }
    )

    const selectLists = ref()

    const initDialog = () => {
      loadSelectLists()
      isEditMode.value = props.transactionId != null
      if (isEditMode.value) {
        retrieveTransaction()
      } else {
        initNewTransaction()
      }
      isReady.value = true
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const retrieveTransaction = async () => {
      const rq = { InfoId: props.transactionId }
      const rs = await host.transaction.retrieve(rq)
      formData.value = rs.Info
      originalFormData.value = { ...rs.Info }
      amountEach.value = formData.value.Amount / formData.value.Quantity
    }

    const initNewTransaction = async () => {
      const rq = { Defaults: props.customerId }
      const rs = await host.transaction.newTemplate(rq)
      formData.value = rs.Info
      amountEach.value = formData.value.Amount / formData.value.Quantity
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.inform({
          text: result.Message,
          snackbarTimeout: 10000,
          color: 'error'
        })
      }
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.transaction.update(rq)
      return rs
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.transaction.create(rq)
      return rs
    }

    const rules = {
      amountEach: { required },
      formData: {
        TranCodeId: { required },
        Reference: { required },
        Quantity: { required },
        Amount: { required },
        BusinessDate: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { amountEach, formData })

    function errorMessages(item) {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    function validate() {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    initDialog()

    return {
      dialogUtil,
      originalFormData,
      isEditMode,
      isReady,
      selectLists,
      formData,
      amountEach,
      errorMessages,
      saveClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
