<template>
  <div>
    <v-card class="indigo lighten-5">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Custom View
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <div v-if="formData" class="d-flex">
          <div>
            <v-card width="400">
              <v-card-text>
                <v-text-field
                  v-model="formData.Description"
                  label="Description"
                  :error-messages="
                    errorMessages(vuelidate.formData.Description)
                  "
                  @blur="vuelidate.formData.Description.$touch()"
                  dense
                />
                <v-checkbox
                  v-model="formData.IsPrivate"
                  label="Private"
                  dense
                />
              </v-card-text>
            </v-card>

            <v-card class="my-2" width="600">
              <v-card-title class="subtitle-1">Restrictions</v-card-title>
              <v-card-text>
                <v-data-table
                  dense
                  :items-per-page="10"
                  :footer-props="{ 'show-first-last-page': true }"
                  :headers="restrictionHeaders"
                  :items="restrictionList"
                  class="elevation-1"
                >
                  <template v-slot:item.ColumnName="{ item }">
                    {{ columnTitle(item.ColumnName) }}
                  </template>
                  <template v-slot:item.Comparator="{ item }">
                    {{ comparatorTitle(item.Comparator) }}
                  </template>
                  <template v-slot:item.Actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editRestrictionItemClick(item)"
                    >
                      {{ icon.lineItemEdit }}
                    </v-icon>
                    <v-icon small @click="deleteRestrictionItemClick(item)">
                      {{ icon.lineItemDelete }}
                    </v-icon>
                  </template>
                </v-data-table>
                <div class="table-footer-prepend d-flex pl-2 align-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="newRestrictionItemClick">
                        <v-icon>{{ icon.lineItemAdd }}</v-icon>
                      </v-btn>
                    </template>
                    <span>New Restriction</span>
                  </v-tooltip>
                </div>
              </v-card-text>
            </v-card>

            <v-card class="mt-1" width="200">
              <v-card-actions>
                <v-btn color="success" class="ml-1" @click="saveClick">
                  {{ isEditMode ? 'Update' : 'Save' }}
                </v-btn>
                <v-btn color="error" class="ml-3" @click="cancelClick">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>

          <div>
            <v-card class="my-2 ml-2" width="400">
              <v-card-title class="subtitle-1">Columns</v-card-title>
              <v-card-text>
                <v-data-table
                  dense
                  :items-per-page="10"
                  :footer-props="{
                    'show-first-last-page': true
                  }"
                  :headers="columnHeaders"
                  :items="columnList"
                  class="elevation-1"
                >
                  <template v-slot:item.ColumnName="{ item }">
                    {{ columnTitle(item.ColumnName) }}
                  </template>
                  <template v-slot:item.Actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editColumnItemClick(item)"
                    >
                      {{ icon.lineItemEdit }}
                    </v-icon>
                    <v-icon small @click="deleteColumnItemClick(item)">
                      {{ icon.lineItemDelete }}
                    </v-icon>
                  </template>
                </v-data-table>
                <div class="table-footer-prepend d-flex pl-2 align-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="newColumnItemClick">
                        <v-icon>{{ icon.lineItemAdd }}</v-icon>
                      </v-btn>
                    </template>
                    <span>New Column</span>
                  </v-tooltip>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      v-if="formData"
      v-model="showColumnEditorDialog"
      width="400"
      persistent
    >
      <CustomViewColumnEditor
        :info="selectedRowInfo"
        :index="selectedRowIndex"
        :columnNameList="formData.ColumnNames"
        :isDialogOpen="showColumnEditorDialog"
        @close="columnEditorClose"
        @update="columnEditorUpdate"
      />
    </v-dialog>

    <v-dialog
      v-if="formData"
      v-model="showRestrictionEditorDialog"
      width="400"
      persistent
    >
      <CustomViewRestrictionEditor
        :info="selectedRestrictionInfo"
        :index="selectedRestrictionIndex"
        :columnNameList="formData.ColumnNames"
        :comparatorList="formData.Comparators"
        :isDialogOpen="showRestrictionEditorDialog"
        @close="restrictionEditorClose"
        @update="restrictionEditorUpdate"
      />
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import CustomViewColumnEditor from '@/components/Setup/Customer/CustomView/CustomViewColumnEditor'
import CustomViewRestrictionEditor from '@/components/Setup/Customer/CustomView/CustomViewRestrictionEditor'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'

export default {
  name: 'CustomViewEditor',
  components: {
    CustomViewColumnEditor,
    CustomViewRestrictionEditor
  },
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const formData = ref()

    const showColumnEditorDialog = ref(false)
    const selectedRowInfo = ref()
    const selectedRowIndex = ref()

    const showRestrictionEditorDialog = ref(false)
    const selectedRestrictionInfo = ref()
    const selectedRestrictionIndex = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      if (isEditMode.value) {
        loadCustomView()
      } else {
        formData.value = await newTemplate()
      }
      vuelidate.value.$reset()
    }

    const columnHeaders = [
      { value: 'ColumnName', text: 'Column Name', width: 100, align: 'left' },
      { value: 'SortOrder', text: 'Sort Order', width: 20, align: 'right' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const columnList = computed(() => {
      return formData.value.ColumnList.map((items, index) => ({
        ...items,
        index: index
      }))
    })

    const restrictionList = computed(() => {
      return formData.value.RestrictionList.map((items, index) => ({
        ...items,
        index: index
      }))
    })

    const restrictionHeaders = [
      { value: 'ColumnName', text: 'Column Name', width: 100, align: 'left' },
      { value: 'Comparator', text: 'Compare Type', width: 20, align: 'left' },
      { value: 'CompareValue', text: 'Value', width: 20, align: 'left' },
      { value: 'SortOrder', text: 'Sort Order', width: 20, align: 'right' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const saveClick = async () => {
      if (isInvalid()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.inform({
          text: result.Message,
          snackbarTimeout: 10000,
          color: 'error'
        })
      }
    }

    const rules = {
      formData: {
        Description: { required },
        ColumnList: { required, minLength: minLength(1) }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const columnListErrors = computed(() => {
      const errors = []
      vuelidate.value.formData.ColumnList.$dirty &&
        vuelidate.value.formData.ColumnList.required.$invalid &&
        errors.push('At least one column is required.')
      return errors
    })

    const isInvalid = () => {
      vuelidate.value.$touch()
      if (columnListErrors.value.length > 0) {
        dialogUtil.value.error(columnListErrors.value.join('<br/>'))
      }
      return vuelidate.value.$invalid
    }

    const loadCustomView = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.customView.retrieve(rq)
      formData.value = rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.customView.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.customView.update(rq)
      return rs
    }

    const newTemplate = async () => {
      const rq = { Info: formData.value }
      const rs = await host.customView.newTemplate(rq)
      return rs.Info
    }

    const columnTitle = value => {
      return formData.value.ColumnNames.find(item => item.Value == value).Text
    }

    const comparatorTitle = value => {
      return formData.value.Comparators.find(item => item.Value == value).Text
    }

    const newColumnItemClick = () => {
      selectedRowInfo.value = {
        Id: null,
        ColumnName: null,
        SortOrder: formData.value.ColumnList.length + 1
      }
      selectedRowIndex.value = -1
      showColumnEditorDialog.value = true
    }

    const editColumnItemClick = item => {
      selectedRowInfo.value = formData.value.ColumnList[item.index]
      selectedRowIndex.value = item.index
      showColumnEditorDialog.value = true
    }

    const deleteColumnItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Remove column ${columnTitle(item.ColumnName)}`
        })
        .then(() => {
          if (item.Id) {
            formData.value.DeletedColumnIdList.push(item.Id)
          }
          formData.value.ColumnList.splice(item.index, 1)
        })
        .catch(() => {
          return
        })
    }

    const columnEditorClose = () => {
      showColumnEditorDialog.value = false
    }

    const columnEditorUpdate = ({ item, index }) => {
      if (index < 0) {
        formData.value.ColumnList.push(item)
      } else {
        formData.value.ColumnList.splice(index, 1, item)
      }

      showColumnEditorDialog.value = false
    }

    const newRestrictionItemClick = () => {
      selectedRestrictionInfo.value = {
        Id: null,
        ColumnName: null,
        Comparator: null,
        CompareValue: null,
        SortOrder: formData.value.RestrictionList.length + 1
      }
      selectedRestrictionIndex.value = -1
      showRestrictionEditorDialog.value = true
    }

    const editRestrictionItemClick = item => {
      selectedRestrictionInfo.value = formData.value.RestrictionList[item.index]
      selectedRestrictionIndex.value = item.index
      showRestrictionEditorDialog.value = true
    }

    const deleteRestrictionItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Remove restriction: ${columnTitle(
            item.ColumnName
          )} ${comparatorTitle(item.Comparator)} ${item.CompareValue}`
        })
        .then(() => {
          if (item.Id) {
            formData.value.DeletedRestrictionIdList.push(item.Id)
          }
          formData.value.RestrictionList.splice(item.index, 1)
        })
        .catch(() => {
          return
        })
    }

    const restrictionEditorClose = () => {
      showRestrictionEditorDialog.value = false
    }

    const restrictionEditorUpdate = ({ item, index }) => {
      if (index < 0) {
        formData.value.RestrictionList.push(item)
      } else {
        formData.value.RestrictionList.splice(index, 1, item)
      }

      showRestrictionEditorDialog.value = false
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      columnHeaders,
      restrictionHeaders,
      saveClick,
      cancelClick,
      columnTitle,
      comparatorTitle,
      columnList,
      newColumnItemClick,
      editColumnItemClick,
      deleteColumnItemClick,
      showColumnEditorDialog,
      selectedRowInfo,
      selectedRowIndex,
      columnEditorClose,
      columnEditorUpdate,
      restrictionList,
      newRestrictionItemClick,
      editRestrictionItemClick,
      deleteRestrictionItemClick,
      showRestrictionEditorDialog,
      selectedRestrictionInfo,
      selectedRestrictionIndex,
      restrictionEditorClose,
      restrictionEditorUpdate,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
