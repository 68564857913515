import axios from 'axios'

export default {
  organization: 'arrowstorm',
  project: 'FieldService',
  repositoryId: 'OfficeWeb',
  authToken: 'cgioauo7jaufdm6xzjdfuwwtofnqk4ivmpzijth7mqwdahnoctda',

  getRepoHistory() {
    const url = `https://dev.azure.com/${this.organization}/${this.project}/_apis/git/repositories/${this.repositoryId}/commits?api-version=5.1`
    return axios.get(url, {
      auth: {
        password: this.authToken
      }
    })
  }
}
