<template>
  <v-card
    v-if="summary"
    width="320px"
    height="330px"
    class="pt-2 pl-3 ma-2"
    :to="{ name: feature.tasks.routerName }"
  >
    <div class="overline grey--text text-uppercase">Task Summary</div>
    <div class="mt-2 ml-2 mb-4" style="display: flex;">
      <v-icon large color="info">{{ icon.task }}</v-icon>
      <div class="ml-1">Tasks assigned to {{ summary.UserName }}</div>
    </div>
    <div class="mt-2 ml-2" style="display: flex;">
      <div class="priority">
        <v-icon x-large color="red">{{ icon.priorityHigh }}</v-icon>
        <div class="caption">High</div>
      </div>
      <div
        class="taskcount display-1 text--secondary"
        v-text="summary.QuantityHighPriority"
      ></div>
    </div>
    <div class="mt-2 ml-2" style="display: flex;">
      <div class="priority">
        <v-icon x-large color="orange">{{ icon.priorityMedium }}</v-icon>
        <div class="caption">Medium</div>
      </div>
      <div
        class="taskcount display-1 text--secondary"
        v-text="summary.QuantityMediumPriority"
      ></div>
    </div>
    <div class="mt-2 ml-2" style="display: flex;">
      <div class="priority">
        <v-icon x-large color="green">{{ icon.priorityLow }}</v-icon>
        <div class="caption">Low</div>
      </div>
      <div
        class="taskcount display-1 text--secondary"
        v-text="summary.QuantityLowPriority"
      ></div>
    </div>
  </v-card>
</template>

<script>
import { ref } from 'vue'
import { feature, icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
export default {
  name: 'TaskSummary',
  setup() {
    const summary = ref()

    const loadData = async () => {
      const rq = {
        TaskDate: new Date().toISOString().substr(0, 10)
      }
      const rs = await host.scheduledTask.getSummary(rq)
      summary.value = rs.Info
    }

    loadData()

    return { summary, feature, icon }
  }
}
</script>
<style lang="scss" scoped>
.priority {
  width: 70px;
  height: 70px;
  text-align: center;
}

.priority div {
  width: 100%;
}

.taskcount {
  width: 90px;
  height: 70px;
  vertical-align: center;
  text-align: right;
}
</style>
