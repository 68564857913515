<template>
  <div>
    <div v-if="cardData">
      <PageTitle
        featureName="customers"
        functionName="Summary"
        :target="cardData.Name"
        :pageName="$options.name"
      />
      <div class="d-flex flex-wrap">
        <CustomerCard
          :cardData="cardData"
          @update="updateEvent"
          @delete="deleteEvent"
        />
        <div v-if="cardData.SiteList.length > 1">
          <v-card
            width="440"
            :class="`mt-2 ${feature.sites.cardBackgroundColor}`"
          >
            <v-card-title>Sites</v-card-title>
            <v-card-text>
              <v-checkbox
                v-if="anyInactive"
                v-model="showInactive"
                label="Show inactive sites"
                dense
              />
              <v-data-table
                dense
                :headers="siteHeaderList"
                :items="siteList"
                @click:row="siteRowClick"
                class="elevation-1"
              >
                <template v-slot:item="{ item }">
                  <tr :class="rowColor(item)" @click="siteRowClick(item)">
                    <td>{{ item.Description }}</td>
                    <td>{{ item.Street }}</td>
                    <td>{{ item.City }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
        <div>
          <SiteCard
            :cardData="cardData.SelectedSite"
            :isDeleteEnabled="cardData.SiteList.length > 1"
            @reload="updateEvent"
            @update="updateEvent"
          />
        </div>
      </div>
    </div>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { feature } from '@/use/Constants'
import CustomerCard from '@/components/Customer/CustomerCard'
import SiteCard from '@/components/Site/SiteCard'
import { host } from '@/services/HostAPI'
export default {
  name: 'CustomerSummary',
  components: { CustomerCard, SiteCard },
  props: {
    id: {
      type: String,
      required: true
    },
    siteId: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const router = useRouter()
    const dialogUtil = ref(null)

    watch(
      () => props.id,
      () => {
        load()
      }
    )

    watch(
      () => props.siteId,
      () => {
        load()
      }
    )

    const siteList = computed(() =>
      cardData.value.SiteList.filter(
        item => !item.Inactive || showInactive.value
      )
    )

    const initForm = () => {
      load()
    }

    const cardData = ref()
    const showInactive = ref(false)
    const selectedSiteId = ref()

    const siteHeaderList = [
      { value: 'Description', text: 'Site Name' },
      { value: 'Street', text: 'Address' },
      { value: 'City', text: 'City' }
    ]

    const anyInactive = computed(
      () =>
        cardData.value && cardData.value.SiteList.some(site => site.Inactive)
    )

    const siteRowClick = async event => {
      const rq = { InfoId: event.SiteId }
      const rs = await host.site.card(rq)

      if (!rs.IsSuccess) {
        dialogUtil.value.error(rs.Message)
        return
      }

      cardData.value.SelectedSite = rs.Info
      selectedSiteId.value = rs.Info.SiteId
    }

    const load = async () => {
      const customerId = props.id == 'site' ? null : props.id
      const rq = { Id: customerId, SiteId: props.siteId, RetrieveSites: true }
      const response = await retrieveCustomer(rq)

      if (response.Info === null) {
        router.push({
          name: feature.customers.routerName
        })
      }

      cardData.value = response.Info
      selectedSiteId.value = cardData.value.SelectedSite.SiteId
      showInactive.value = cardData.value.SelectedSite.Inactive
    }

    const retrieveCustomer = async rq => {
      const rs = await host.customer.card(rq)
      return rs
    }

    const updateEvent = async () => {
      await load()
    }

    const deleteEvent = () => {
      router.push({
        name: feature.customers.routerName
      })
    }

    const rowColor = item => {
      return item.SiteId == selectedSiteId.value
        ? feature.sites.cardBackgroundColor
        : item.Inactive
        ? 'red lighten-4'
        : ''
    }

    initForm()
    return {
      dialogUtil,
      cardData,
      siteList,
      siteHeaderList,
      rowColor,
      siteRowClick,
      updateEvent,
      deleteEvent,
      anyInactive,
      showInactive,
      feature
    }
  }
}
</script>

<style lang="scss" scoped></style>
