<template>
  <div>
    <v-card class="my-2">
      <v-card-text>
        <div class="d-flex justify-space-between">
          <div>
            <div v-if="cardData.RecurringPaymentList.length === 0">
              No recurring payment info on file
            </div>
            <div
              v-for="rp in cardData.RecurringPaymentList"
              :key="rp.RecurringPaymentId"
            >
              <div v-if="rp.ExpDate">
                Card {{ rp.PaymentLastFour }} Exp {{ rp.ExpDate }}
              </div>
              <div v-else>ACH {{ rp.PaymentLastFour }}</div>
              <div>
                <span>{{ rp.FrequencyDescription }}</span>
                <span>: </span>
                <span>
                  {{
                    rp.PayOutstandingBalance
                      ? 'Balance'
                      : toCurrency(rp.PaymentAmount)
                  }}
                </span>
              </div>
            </div>
            <div
              v-for="rp in cardData.PaymentAuthorizationList"
              :key="rp.PaymentAuthorizationId"
            >
              <span>
                Auth {{ rp.PaymentLastFour }} Exp {{ rp.ExpDate }} For
                {{ toCurrency(rp.PaymentAmount) }}
              </span>
            </div>
          </div>
          <div v-if="!readonly" class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :color="feature.onlinePayments.color"
                  @click="onlinePaymentClick"
                >
                  <v-icon>
                    {{ feature.onlinePayments.iconNewOnlinePayment }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Post Online Payment
              </span>
            </v-tooltip>

            <v-tooltip v-if="cardData.RecurringPaymentList.length > 0" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="recurringPaymentListClick"
                >
                  <v-icon :color="feature.onlinePayments.color">
                    {{ feature.onlinePayments.iconRecurringPayment }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Recurring Payments
              </span>
            </v-tooltip>

            <v-tooltip v-if="cardData.PaymentAuthorizationList.length > 0" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="authListClick"
                >
                  <v-icon :color="feature.onlinePayments.color">
                    {{ feature.onlinePayments.iconAuth }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Outstanding Payment Authorizations
              </span>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showOnlinePaymentDialog" max-width="400">
      <OnlinePayment
        :customerId="cardData.CustomerId"
        :customerName="cardData.Address.Name"
        :address="cardData.Address.Street"
        :postalCode="cardData.Address.PostCode"
        :amount="cardData.AgingSummary.Balance"
        :isDialogOpen="showOnlinePaymentDialog"
        @close="hideOnlinePaymentDialog(false)"
        @update="hideOnlinePaymentDialog(true)"
      />
    </v-dialog>

    <v-dialog
      v-model="showRecurringPaymentListDialog"
      :customerId="cardData.CustomerId"
      max-width="1000"
    >
      <RecurringPaymentList
        :customerId="cardData.CustomerId"
        :balance="cardData.AgingSummary.Balance"
        :isDialogOpen="showRecurringPaymentListDialog"
        @close="customerOnlinePaymentListClose"
        @update="customerOnlinePaymentListUpdate"
      />
    </v-dialog>

    <v-dialog
      v-model="showAuthListDialog"
      :customerId="cardData.CustomerId"
      max-width="600"
      persistent
    >
      <CustomerAuthList
        :customerId="cardData.CustomerId"
        :isDialogOpen="showAuthListDialog"
        @close="authListClose"
        @update="authListUpdate"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { feature } from '@/use/Constants'
import OnlinePayment from '@/components/OnlinePayment/OnlinePayment'
import RecurringPaymentList from '@/components/OnlinePayment/RecurringPaymentList'
import CustomerAuthList from '@/components/OnlinePayment/CustomerAuthList'
import { toCurrency } from '@/use/NumberFormatter'
export default {
  name: 'OnlinePaymentCard',
  components: { OnlinePayment, RecurringPaymentList, CustomerAuthList },
  props: {
    cardData: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const router = useRouter()
    const showOnlinePaymentDialog = ref(false)
    const showRecurringPaymentListDialog = ref(false)
    const showAuthListDialog = ref(false)

    const onlinePaymentClick = () => {
      showOnlinePaymentDialog.value = true
    }

    const onlinePaymentDetailsClick = () => {
      router.push({
        name: feature.onlinePayments.routerName,
        params: { id: props.cardData.CustomerId }
      })
    }

    const hideOnlinePaymentDialog = reload => {
      showOnlinePaymentDialog.value = false
      if (reload) {
        context.emit('update')
      }
    }

    const customerOnlinePaymentListClose = () => {
      showRecurringPaymentListDialog.value = false
    }

    const customerOnlinePaymentListUpdate = () => {
      showRecurringPaymentListDialog.value = false
      context.emit('update')
    }

    const recurringPaymentListClick = () => {
      showRecurringPaymentListDialog.value = true
    }

    const authListClick = () => {
      showAuthListDialog.value = true
    }

    const authListClose = () => {
      showAuthListDialog.value = false
    }

    const authListUpdate = () => {
      showAuthListDialog.value = false
      context.emit('update')
    }

    return {
      feature,
      showOnlinePaymentDialog,
      hideOnlinePaymentDialog,
      onlinePaymentClick,
      onlinePaymentDetailsClick,
      showRecurringPaymentListDialog,
      customerOnlinePaymentListClose,
      customerOnlinePaymentListUpdate,
      recurringPaymentListClick,
      showAuthListDialog,
      authListClick,
      authListClose,
      authListUpdate,
      toCurrency
    }
  }
}
</script>

<style lang="scss" scoped></style>
