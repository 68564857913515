<template>
  <div>
    <v-card v-if="formData && selectLists" class="blue lighten-4">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div class="d-flex">
            <div class="subtitle-1">
              {{ isEditMode ? 'Edit' : 'New' }} Recurring Service For Site:
              {{ formData.SiteName }}
            </div>
            <v-spacer />
            <ActivityLogButton
              v-if="isEditMode"
              :description="'Recurring Service: ' + formData.SiteName"
              tableName="RecurringService"
              :recordId="formData.RecurringServiceId"
            />
          </div>
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <div class="d-flex blue lighten-4">
          <div>
            <v-card class="mt-2 ml-2" width="400px">
              <v-card-title>
                <div class="text-subtitle-1 blue--text">
                  Recurring Service Info
                </div>
              </v-card-title>
              <v-card-text>
                <SitePicker
                  label="Site"
                  v-model="formData.SiteId"
                  v-bind:text.sync="formData.SiteName"
                  :error-messages="errorMessages(vuelidate.formData.SiteId)"
                  dense
                  @blur="vuelidate.formData.SiteName.$touch()"
                />
                <div class="d-flex">
                  <ServiceTypePicker
                    v-model="formData.ServiceDefId"
                    :error-messages="
                      errorMessages(vuelidate.formData.ServiceDefId)
                    "
                    @blur="vuelidate.formData.ServiceDefId.$touch()"
                  />
                  <NearbyServicesButton
                    v-if="formData.SiteId"
                    :siteId="formData.SiteId"
                    :serviceDate="
                      formData.FrequencyInfo.InitialDate || todayISOString()
                    "
                    :serviceTypeId="formData.ServiceDefId"
                    recurringOnly
                    @update="nearbyServiceSelected"
                  />
                </div>
                <ServiceSeasonPicker
                  v-model="formData.ScheduledServiceSeasonId"
                  label="Season"
                  dense
                  clearable
                />
                <UserPicker
                  v-model="formData.UserId"
                  label="Assigned To"
                  dense
                  clearable
                />
                <v-text-field
                  v-model="formData.StopNumber"
                  type="number"
                  label="Stop Number"
                  dense
                ></v-text-field>
                <div class="d-flex flex-wrap">
                  <v-currency-field
                    v-if="formData.IsAmountOverride"
                    v-model="formData.AmountToCharge"
                    label="Service Amount"
                    clearable
                    dense
                  />
                  <div v-else class="mt-1">
                    Amount: {{ toCurrency(formData.AmountToCharge) }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-model="formData.IsAmountOverride"
                    label="Override"
                  />
                </div>
                <div class="mt-1">Tran Code: {{ tranCodeDesc }}</div>
                <v-textarea
                  v-model="formData.Comments"
                  label="Comments"
                  dense
                />
              </v-card-text>
            </v-card>
            <v-card class="mt-2 ml-2" width="400px">
              <v-card-text>
                <v-card-actions>
                  <v-btn color="success" @click="saveClick">
                    <span>Save</span>
                    <v-icon right>{{ icon.save }}</v-icon>
                  </v-btn>
                  <v-btn color="error" @click="cancelClick">
                    <span>Back</span>
                    <v-icon right>{{ icon.exit }}</v-icon>
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    v-if="isEditMode"
                    color="secondary"
                    @click="deleteClick"
                  >
                    <span>Delete</span>
                    <v-icon right>{{ icon.delete }}</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </div>

          <div class="ml-4 mt-2">
            <RecurringRulesPicker v-model="formData.FrequencyInfo" />
          </div>
        </div>
      </v-card-text>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import NearbyServicesButton from '@/components/ScheduledService/NearbyServicesButton'
import ServiceSeasonPicker from '@/components/controls/ServiceSeasonPicker'
import SitePicker from '@/components/Site/SitePicker'
import RecurringRulesPicker from '@/components/controls/RecurringRulesPicker'
import ActivityLogButton from '@/components/Common/ActivityLogButton'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { toCurrency } from '@/use/NumberFormatter'
import { todayISOString } from '@/use/DateFormatter'

export default {
  name: 'RecurringServiceEditor',
  props: {
    siteId: {
      type: String,
      default: null
    },
    recurringServiceId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ActivityLogButton,
    NearbyServicesButton,
    SitePicker,
    RecurringRulesPicker,
    ServiceSeasonPicker
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const isEditMode = ref(false)
    const formData = ref()
    const selectLists = ref()
    const tranCodeDesc = ref('')
    const defaultAmountToCharge = ref()

    const initDialog = async () => {
      loadSelectLists()
      isEditMode.value = props.recurringServiceId != null
      const rs = isEditMode.value
        ? await host.recurringService.retrieve({
            InfoId: props.recurringServiceId
          })
        : await host.recurringService.newTemplate({ Defaults: props.siteId })
      formData.value = rs.Info
      createFormDataWatches()
      vuelidate.value.$reset()
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.Users,
        selectListCache.name.ServiceTypes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const createFormDataWatches = () => {
      watch(
        () => formData.value.ServiceDefId,
        newVal => {
          const serviceDefEntry = selectLists.value.ServiceTypes.find(
            item => item.Value == newVal
          )
          if (serviceDefEntry) {
            const extraData = JSON.parse(serviceDefEntry.ExtraData)
            defaultAmountToCharge.value = extraData.DefaultAmountToCharge
            if (!formData.value.IsAmountOverride) {
              formData.value.AmountToCharge = extraData.DefaultAmountToCharge
            }
            tranCodeDesc.value = extraData.TranCodeDesc
          }
        }
      )

      watch(
        () => formData.value.IsAmountOverride,
        newVal => {
          if (!newVal) {
            formData.value.AmountToCharge = defaultAmountToCharge.value
          }
        }
      )
    }

    const nearbyServiceSelected = async args => {
      formData.value.UserId = args.UserId
      formData.value.StopNumber = args.StopNumber + 1
      formData.value.ServiceDefId =
        formData.value.ServiceDefId || args.ServiceDefId
      const rq = { InfoId: args.RecurringServiceId }
      const rs = await host.recurringService.retrieve(rq)
      formData.value.FrequencyInfo.InitialDate = rs.FrequencyInfo.InitialDate
      formData.value.FrequencyInfo.EndDate = rs.FrequencyInfo.EndDate
      formData.value.FrequencyInfo.AutoExtend = rs.FrequencyInfo.AutoExtend
      formData.value.FrequencyInfo.RecurPeriod = rs.FrequencyInfo.RecurPeriod
      formData.value.FrequencyInfo.RecurOption = rs.FrequencyInfo.RecurOption
      formData.value.FrequencyInfo.Interval1 = rs.FrequencyInfo.Interval1
      formData.value.FrequencyInfo.Interval2 = rs.FrequencyInfo.Interval2
      formData.value.FrequencyInfo.Interval3 = rs.FrequencyInfo.Interval3
    }

    const rules = {
      formData: {
        SiteId: { required },
        ServiceDefId: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      const rq = { Info: formData.value }
      const rs = isEditMode.value
        ? await host.recurringService.update(rq)
        : await host.recurringService.create(rq)

      if (rs.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const deleteClick = () => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete this recurring service. Are you sure?'
        })
        .then(() => deleteRecurringService())
        .catch(() => {
          return
        })
    }

    const deleteRecurringService = async () => {
      const rq = { InfoId: formData.value.RecurringServiceId }
      const rs = await host.recurringService.delete(rq)
      if (rs.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    initDialog()

    return {
      dialogUtil,
      icon,
      formData,
      isEditMode,
      selectLists,
      tranCodeDesc,
      saveClick,
      cancelClick,
      deleteClick,
      toCurrency,
      todayISOString,
      nearbyServiceSelected,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss"></style>
