<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon small v-on="on" @click="showMapClick">
          <v-icon :color="feature.maps.color">{{ icon.mapFind }}</v-icon>
        </v-btn>
      </template>
      <span>Find nearby services</span>
    </v-tooltip>
    <v-dialog v-model="showDialog" :width="dialogWidth">
      <v-card :width="dialogWidth">
        <v-card-title>
          <DialogTitle :dialogName="$options.name">
            <div class="subtitle-1">
              Nearby Services
            </div>
          </DialogTitle>
        </v-card-title>
        <v-card-text class="pb-0">
          <div class="d-flex">
            <v-btn
              color="primary"
              fab
              x-small
              class="mx-2"
              @click="previousDayClick"
            >
              <v-icon>{{ icon.timePeriodPrevious }}</v-icon>
            </v-btn>
            <div style="width: 260px;">
              <DatePicker
                v-model="selectedServiceDate"
                label="Service Date"
                displayFormat="EEEE MMMM d, yyyy"
                dense
              />
            </div>
            <v-btn
              color="primary"
              fab
              x-small
              class="mx-2"
              @click="nextDayClick"
            >
              <v-icon>{{ icon.timePeriodNext }}</v-icon>
            </v-btn>
            <v-spacer />
            <div style="width: 340px;">
              <ServiceTypePicker v-model="selectedServiceTypeId" clearable />
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div
        ref="nearbyServicesCanvas"
        style="height: 600px; width: 100%;"
        class="flex-grow-1"
      />
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { icon, feature } from '@/use/Constants'
import { mapService } from '@/services/GoogleMapService'
import { addDays } from '@/use/DateFormatter'
export default {
  name: 'NearbyServicesButton',
  props: {
    siteId: {
      type: String,
      required: true
    },
    serviceDate: {
      type: String,
      required: true
    },
    serviceTypeId: {
      type: String,
      default: null
    },
    recurringOnly: {
      type: Boolean,
      default: false
    },
    routeOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogWidth = ref(1000)
    const nearbyServicesCanvas = ref(null)
    const showDialog = ref(false)
    const serviceList = ref([])
    const selectedServiceDate = ref()
    const siteInfo = ref()
    const selectedServiceTypeId = ref()

    const initDialog = () => {
      selectedServiceDate.value = props.serviceDate
      selectedServiceTypeId.value = props.serviceTypeId
      updateMap()
      createWatches()
    }

    const createWatches = () => {
      watch(
        () => selectedServiceDate.value,
        () => {
          updateMap()
        }
      )

      watch(
        () => selectedServiceTypeId.value,
        () => {
          updateMap()
        }
      )
    }

    const previousDayClick = () => {
      selectedServiceDate.value = addDays(selectedServiceDate.value, -1)
    }

    const nextDayClick = () => {
      selectedServiceDate.value = addDays(selectedServiceDate.value, 1)
    }

    const updateMap = async () => {
      if (!siteInfo.value || siteInfo.value.SiteId != props.siteId) {
        const siteRq = { InfoId: props.siteId, IsLatLongNeeded: true }
        const siteRs = await host.site.retrieve(siteRq)
        siteInfo.value = siteRs.Info
      }

      const rq = {
        SiteId: props.siteId,
        ServiceDate: selectedServiceDate.value
      }

      const rs = await host.scheduledService.getNearby(rq)

      serviceList.value = rs.InfoList.filter(
        item =>
          ((!props.recurringOnly || item.RecurringServiceId) &&
            (!props.routeOnly || item.ServiceRouteId) &&
            !selectedServiceTypeId.value) ||
          item.ServiceDefId == selectedServiceTypeId.value
      ).slice(0, 50)

      const args = {
        canvas: nearbyServicesCanvas.value,
        siteInfo: siteInfo.value,
        serviceList: serviceList.value,
        callback: mapMarkerCallback
      }

      mapService.showNearbyServices(args)
    }

    const showMapClick = () => {
      initDialog()
      showDialog.value = true
    }

    const mapMarkerCallback = index => {
      const selectedInfo = serviceList.value[index]
      context.emit('update', {
        ServiceDate: selectedServiceDate.value,
        ...selectedInfo
      })
      showDialog.value = false
    }

    return {
      dialogWidth,
      selectedServiceDate,
      siteInfo,
      icon,
      feature,
      showMapClick,
      showDialog,
      serviceList,
      previousDayClick,
      nextDayClick,
      nearbyServicesCanvas,
      selectedServiceTypeId
    }
  }
}
</script>

<style lang="scss" scoped></style>
