<template>
  <div>
    <PageTitle
      featureName="tasks"
      functionName="List"
      :pageName="$options.name"
    />
    <v-card class="ma-2" max-width="850">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          Search Criteria
        </div>
      </v-card-title>
      <v-card-text v-if="selectLists">
        <div class="d-flex align-content-space-around flex-wrap">
          <v-card width="400px" class="mt-2 mr-2">
            <v-card-text>
              <v-select
                v-model="searchCriteria.TaskDefId"
                :items="selectLists.TaskTypes"
                item-text="Text"
                item-value="Value"
                label="Task Type"
                clearable
                dense
                @change="refreshSearch"
              />
              <DatePicker
                v-model="searchCriteria.TaskDateFirst"
                label="First Task Date"
                @change="refreshSearch"
                clearable
                dense
              />
              <DatePicker
                v-model="searchCriteria.TaskDateLast"
                label="Last Task Date"
                @change="refreshSearch"
                clearable
                dense
              />
            </v-card-text>
          </v-card>
          <v-card width="400px" class="mt-2 mr-2">
            <v-card-text>
              <v-checkbox
                class="mt-1 mb-3"
                v-model="searchCriteria.IncludeClosedTasks"
                label="Include Completed Tasks"
                @change="refreshSearch"
              />
              <UserPicker
                v-model="searchCriteria.AssignedToUserId"
                label="Assigned To"
                @change="refreshSearch"
                clearable
                dense
              />
              <CustomerPicker
                v-model="searchCriteria.CustomerId"
                v-bind:text.sync="syncCustomerName"
                label="Customer"
                clearable
                dense
                @input="refreshSearch"
              />
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mx-2" max-width="1200">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          Search Results
        </div>
      </v-card-title>
      <v-data-table
        :items-per-page="10"
        :headers="headers"
        :items="dataTable.LineItemList"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="dataTable.TotalRecords"
        :footer-props="{ 'items-per-page-options': items_per_page }"
        :loading="loading"
        @click:row="selectRow"
        class="elevation-1"
        dense
      >
        <template v-slot:item.WhenScheduled="{ item }">
          <span>{{ toLocaleDateString(item.WhenScheduled) }}</span>
        </template>
        <template v-slot:item.AssignedTo="{ item }">
          <v-icon :color="userColor(item.UserId)">
            {{ icon.user }}
          </v-icon>
          <span class="ml-1">{{ item.AssignedTo }}</span>
        </template>
        <template v-slot:item.WhenCompleted="{ item }">
          <v-icon v-if="item.WhenCompleted" color="green">{{
            icon.completed
          }}</v-icon>
          <span class="ml-2">{{
            toLocaleDateTimeString(item.WhenCompleted)
          }}</span>
        </template>
        <template v-slot:item.Priority="{ item }">
          <v-icon :color="taskPriorityColor(item.Priority)">
            {{ taskPriorityIcon(item.Priority) }}
          </v-icon>
        </template>
        <template v-slot:item.CustomerId="{ item }">
          <span>{{ item.CustomerName }}</span>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showScheduledTaskEditorDialog"
      :width="feature.tasks.dialogWidth"
      persistent
    >
      <ScheduledTaskEditor
        :isDialogOpen="showScheduledTaskEditorDialog"
        :scheduledTaskId="selectedScheduledTaskId"
        @update="scheduledTaskEditorUpdateEvent"
        @close="scheduledTaskEditorCloseEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { feature, icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import {
  toLocaleDateString,
  toLocaleDateTimeString,
  toLocaleISOString
} from '@/use/DateFormatter'
import { selectListCache } from '@/services/SelectListCache'
import { userData } from '@/store/UserSession'
import CustomerPicker from '@/components/Customer/CustomerPicker'
import ScheduledTaskEditor from '@/components/ScheduledTask/ScheduledTaskEditor'

export default {
  name: 'ScheduledTaskList',
  props: {
    customerId: {
      type: String,
      default: null
    },
    customerName: {
      type: String,
      default: null
    }
  },
  components: { CustomerPicker, ScheduledTaskEditor },
  setup(props) {
    const selectLists = ref()
    const showScheduledTaskEditorDialog = ref(false)
    const selectedScheduledTaskId = ref()

    const searchCriteria = ref({
      AssignedToUserId: userData.value.UserId,
      TaskDateFirst: null,
      TaskDateLast: toLocaleISOString(new Date()).substring(0, 10),
      TaskDefId: null,
      IncludeClosedTasks: false,
      CustomerId: props.customerId
    })

    const initForm = async () => {
      await loadSelectLists()
      syncCustomerName.value = props.customerName
      refreshSearch()
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.Users,
        selectListCache.name.TaskTypes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const items_per_page = [5, 10, 15, 20, 50]

    const headers = [
      { value: 'WhenScheduled', text: 'Date' },
      { value: 'Priority', text: 'Priority', align: 'center' },
      { value: 'CustomerId', text: 'Customer' },
      { value: 'TaskType', text: 'Task Type' },
      { value: 'AssignedTo', text: 'Assigned To' },
      { value: 'WhenCompleted', text: 'Completed' }
    ]

    const options = ref({})
    const dataTable = ref([])
    const loading = ref(false)
    const sortBy = ref('Date')
    const sortDesc = ref(false)
    const syncCustomerName = ref(null)

    const getDataFromApi = async () => {
      loading.value = true
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      const rq = {
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy.length > 0 ? sortBy[0] : 'Date',
          IsDescending: sortDesc.length > 0 ? sortDesc[0] : false
        },
        ...searchCriteria.value
      }
      const rs = await host.scheduledTask.loadDataTable(rq)
      dataTable.value = rs
      loading.value = false
    }

    const refreshSearch = () => {
      getDataFromApi()
    }

    watch(
      () => options.value,
      () => {
        refreshSearch().catch(e => alert('error: ' + e.message))
      }
    )

    const selectRow = event => {
      selectedScheduledTaskId.value = event.ItemKey
      showScheduledTaskEditorDialog.value = true
    }

    const scheduledTaskEditorCloseEvent = () => {
      showScheduledTaskEditorDialog.value = false
    }

    const scheduledTaskEditorUpdateEvent = () => {
      showScheduledTaskEditorDialog.value = false
      refreshSearch()
    }

    const userColor = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Color'
      )
    }

    const taskPriorityColor = value => {
      return selectListCache.attribute(
        selectListCache.name.TaskPriorities,
        value,
        'Color'
      )
    }

    const taskPriorityIcon = value => {
      return selectListCache.attribute(
        selectListCache.name.TaskPriorities,
        value,
        'Icon'
      )
    }

    initForm()

    return {
      searchCriteria,
      syncCustomerName,
      showScheduledTaskEditorDialog,
      scheduledTaskEditorCloseEvent,
      scheduledTaskEditorUpdateEvent,
      selectLists,
      items_per_page,
      headers,
      dataTable,
      loading,
      options,
      sortBy,
      sortDesc,
      refreshSearch,
      toLocaleDateString,
      toLocaleDateTimeString,
      selectRow,
      selectedScheduledTaskId,
      feature,
      icon,
      userColor,
      taskPriorityColor,
      taskPriorityIcon
    }
  }
}
</script>

<style lang="scss" scoped></style>
