<template>
  <div>
    <v-card width="480">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Sales Tax
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData">
        <v-text-field
          v-model="formData.Code"
          label="Code"
          :error-messages="errorMessages(vuelidate.formData.Code)"
          @blur="vuelidate.formData.Code.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.Description"
          label="Description"
          :error-messages="errorMessages(vuelidate.formData.Description)"
          @blur="vuelidate.formData.Description.$touch()"
          dense
        />
        <v-currency-field
          v-model="formData.TaxRate"
          label="Rate"
          :error-messages="errorMessages(vuelidate.formData.TaxRate)"
          @blur="vuelidate.formData.TaxRate.$touch()"
          dense
        />
        <TranCodePicker
          v-model="formData.TranCodeId"
          :error-messages="errorMessages(vuelidate.formData.TranCodeId)"
          @blur="vuelidate.formData.TranCodeId.$touch()"
        />
        <DatePicker
          v-model="formData.EffectiveDate"
          label="Effective Date"
          dense
        />
        <DatePicker
          v-model="formData.ExpireDate"
          label="Expiration Date"
          dense
        />
        <v-checkbox v-model="formData.IsDeleted" label="Deleted" />
      </v-card-text>
      <v-card-actions
        ><v-btn color="success" class="ml-1" @click="saveClick">{{
          isEditMode ? 'Update' : 'Save'
        }}</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'SalesTaxEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    selectLists: {
      type: Object,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref()

    const isEditMode = ref(false)
    const formData = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      if (isEditMode.value) {
        await loadSalesTax()
      } else {
        initNewSalesTax()
      }
      vuelidate.value.$reset()
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const rules = {
      formData: {
        Code: { required },
        Description: { required },
        TaxRate: { required },
        TranCodeId: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const loadSalesTax = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.salesTax.retrieve(rq)
      formData.value = rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.salesTax.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.salesTax.update(rq)
      return rs
    }

    const initNewSalesTax = () => {
      formData.value = {
        Id: null,
        Code: null,
        Description: null,
        SortOrder: 0,
        IsDeleted: false,
        DetailList: []
      }
    }

    const monthName = index => {
      let baseDate = new Date()
      baseDate.setDate(1)
      baseDate.setMonth(index)
      return baseDate.toLocaleString('en-us', { month: 'long' })
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      monthName,
      errorMessages,
      saveClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
