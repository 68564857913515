import icon from '@/constants/Icons'

export default {
  dashboard: {
    title: 'Dashboard',
    routerName: 'dashboard',
    color: 'black',
    icon: icon.dashboard
  },
  sendEmail: {
    color: 'purple',
    dialogWidth: 800,
    icon: icon.email
  },
  setup: {
    color: 'blue',
    singularTitle: 'Setup'
  },
  customers: {
    title: 'Customers',
    singularTitle: 'Customer',
    routerName: 'customer',
    routerNameSummary: 'customer-summary',
    dialogWidth: 1250,
    routerNameAdd: 'customer-add',
    routerNameEdit: 'customer-edit',
    color: 'blue',
    icon: icon.customer,
    iconEdit: icon.customerEdit,
    statusColors: {
      A: 'green--text',
      I: 'red--text',
      P: 'red--text',
      T: 'green--text',
      B: 'red--text'
    }
  },
  invoice: {
    color: 'purple'
  },
  maps: {
    toolTip: 'View Map',
    dialogWidth: 1000,
    color: 'red',
    icon: icon.map
  },
  services: {
    title: 'Services',
    singularTitle: 'Service',
    routerName: 'scheduledservice-search',
    dialogWidth: 1025,
    color: 'purple',
    icon: icon.scheduledService,
    iconNewService: icon.addNew,
    optimizeDialogWidth: 820
  },
  recurringServices: {
    title: 'Recurring Services',
    singularTitle: 'Recurring Service',
    routerName: 'scheduledservice-search',
    dialogWidth: 900,
    reassignDialogWidth: 900,
    color: 'purple',
    icon: icon.recurringService,
    iconNewService: icon.addNew
  },
  serviceables: {
    title: 'Serviceables',
    singularTitle: 'Serviceable',
    routerNameEdit: 'serviceable-edit',
    routerNameAdd: 'serviceable-add',
    icon: icon.serviceable,
    color: 'purple'
  },
  subscribe: {
    icon: icon.subscribe,
    color: 'error',
    dialogWidth: 688
  },
  sync: {
    icon: icon.sync,
    color: '#FF4500',
    dialogWidth: 800
  },
  tasks: {
    title: 'Tasks',
    singularTitle: 'Task',
    routerName: 'scheduledtask-search',
    dialogWidth: 850,
    routerNameAdd: 'scheduledtask-add',
    routerNameAddForCustomer: 'scheduledtask-add-for-customer',
    routerNameEdit: 'scheduledtask-edit',
    color: 'orange',
    icon: icon.scheduledTask,
    iconNewTask: icon.addNew
  },
  reports: {
    title: 'Reports',
    routerName: 'reports',
    color: 'pink',
    colorParameter: 'pink lighten-2',
    icon: icon.report
  },
  billing: {
    title: 'Billing',
    singularTitle: 'Billing',
    routerName: 'billing-home',
    routerNameEdit: 'billing-details',
    color: 'green',
    icon: icon.billing
  },
  routes: {
    title: 'Routes',
    singularTitle: 'Route',
    routerName: 'serviceroute-assignments',
    color: 'teal',
    icon: icon.routes
  },
  calendar: {
    title: 'Calendar',
    singularTitle: 'Calendar',
    routerName: 'calendar-home',
    color: 'blue',
    icon: icon.calendar
  },
  commissions: {
    title: 'Commissions',
    singularTitle: 'Commission',
    routerName: 'commission-search',
    color: 'cyan',
    icon: icon.commission
  },
  estimates: {
    title: 'Estimates',
    singularTitle: 'Estimate',
    routerName: 'estimate-search',
    color: 'purple',
    icon: icon.estimate,
    iconNew: icon.addNew
  },
  payments: {
    title: 'Payments',
    singularTitle: 'Payments',
    routerName: 'paymentbatchlist',
    routerNameBatch: 'paymentbatch',
    color: 'red',
    icon: icon.payment
  },
  sites: {
    title: 'Sites',
    routerNameEdit: 'site-edit',
    routerNameAdd: 'site-add',
    color: 'green darken-2',
    icon: icon.site,
    iconEdit: icon.siteEdit,
    iconAdd: icon.siteAdd,
    dialogWidth: 1110,
    cardWidth: 400,
    cardBackgroundColor: 'light-green lighten-4'
  },
  onlinePayments: {
    title: 'Online Payments',
    singularTitle: 'Online Payment',
    color: 'green',
    icon: icon.onlinePayment,
    iconNewOnlinePayment: icon.addNew,
    iconRecurringPayment: icon.onlinePaymentRecurring,
    iconAuth: icon.onlinePaymentAuth
  }
}
