<template>
  <v-card
    height="700"
    width="700"
    shaped
    class="mx-auto rounded-card d-flex flex-column"
  >
    <v-card-title>
      <DialogTitle :dialogName="$options.name">
        Messages
      </DialogTitle>
    </v-card-title>
    <v-card-text>
      <v-list dense nav>
        <v-list-item v-if="newVersionAvailable">
          <v-list-item-avatar>
            <v-icon color="red">{{ icon.messageAlert }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle v-text="systemText"> </v-list-item-subtitle>
            <v-list-item-content>
              <div class="red--text font-weight-bold">
                New Version is Available!
              </div>
              <div>
                Press the button below to reload the web site with the new
                version.
              </div>
              <v-btn color="primary" @click="loadNewVersionClick">
                Load New Version
              </v-btn>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="item in messages" :key="item.Id">
          <v-list-item-avatar>
            <v-icon :color="iconColor(item.IsMarkedRead)">
              {{ icon.messageAlert }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle v-text="item.AlertType" />
            <v-list-item-content v-html="item.Message" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-spacer />
    <v-card-actions>
      <v-btn color="success" class="ml-5 mb-3" @click="markMessagesAsRead">
        Got It
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { officeAlertService } from '@/services/OfficeAlertService'
import { icon } from '@/use/Constants'

export default {
  name: 'MessagesDialog',
  props: {
    newVersionAvailable: {
      type: Boolean,
      default: false
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const router = useRouter()
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      messages.value = await officeAlertService.retrieve()
    }

    const messages = ref([])
    const systemText = computed(() => {
      return 'System '
    })

    const iconColor = isMarkedRead => {
      return isMarkedRead ? 'secondary' : 'primary'
    }

    const markMessagesAsRead = async () => {
      const officeAlertIdList = messages.value
        .filter(alert => !alert.IsMarkedRead)
        .map(alert => alert.Id)

      if (officeAlertIdList) {
        await officeAlertService.markAsRead(officeAlertIdList)
        officeAlertService.clearUnreadAlertCount()
      }

      context.emit('close')
    }

    const loadNewVersionClick = () => {
      router.go('/')
    }

    initDialog()

    return {
      messages,
      systemText,
      iconColor,
      markMessagesAsRead,
      loadNewVersionClick,
      icon
    }
  }
}
</script>

<style lang="scss" scoped>
.rounded-card {
  border-radius: 10px;
}
</style>
