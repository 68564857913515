<template>
  <div>
    <v-card width="400">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit' : 'New' }} Treatment Type
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData && selectLists">
        <v-text-field
          v-model="formData.Code"
          label="Code"
          :error-messages="errorMessages(vuelidate.formData.Code)"
          @blur="vuelidate.formData.Code.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.Description"
          label="Description"
          :error-messages="errorMessages(vuelidate.formData.Description)"
          @blur="vuelidate.formData.Description.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.UnitDescription"
          label="Unit Description"
          :error-messages="errorMessages(vuelidate.formData.UnitDescription)"
          @blur="vuelidate.formData.Description.$touch()"
          dense
        />
        <v-currency-field
          v-model="formData.LowestToAdd"
          label="Lowest units added at any one time"
          dense
        />
        <v-currency-field
          v-model="formData.HighestToAdd"
          label="Highest units added at any one time"
          :error-messages="errorMessages(vuelidate.formData.HighestToAdd)"
          @blur="vuelidate.formData.HighestToAdd.$touch()"
          dense
        />
        <v-currency-field
          v-model="formData.UnitIncrement"
          label="Unit increment"
          :error-messages="errorMessages(vuelidate.formData.UnitIncrement)"
          @blur="vuelidate.formData.UnitIncrement.$touch()"
          dense
        />
        <v-currency-field
          v-model="formData.PricePerUnit"
          label="Price per unit"
          :error-messages="errorMessages(vuelidate.formData.PricePerUnit)"
          @blur="vuelidate.formData.PricePerUnit.$touch()"
          dense
        />
        <v-currency-field
          v-model="formData.CostPerUnit"
          label="Cost per unit"
          :error-messages="errorMessages(vuelidate.formData.CostPerUnit)"
          @blur="vuelidate.formData.CostPerUnit.$touch()"
          dense
        />
        <TranCodePicker v-model="formData.TranCodeId" clearable />
        <v-checkbox v-model="formData.IsDeleted" label="Deleted" />
      </v-card-text>
      <v-card-actions
        ><v-btn color="success" class="ml-1" @click="saveClick">{{
          isEditMode ? 'Update' : 'Save'
        }}</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'TreatmentTypeEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref()

    const isEditMode = ref(false)
    const formData = ref()
    const selectLists = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      isEditMode.value = props.infoId != null
      if (isEditMode.value) {
        loadTreatmentType()
      } else {
        initNewTreatmentType()
      }
      loadSelectLists()
      vuelidate.value.$reset()
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const rules = {
      formData: {
        Code: { required },
        Description: { required },
        UnitDescription: { required },
        HighestToAdd: { required },
        UnitIncrement: { required },
        PricePerUnit: { required },
        CostPerUnit: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const loadTreatmentType = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.treatmentType.retrieve(rq)
      formData.value = rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.treatmentType.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.treatmentType.update(rq)
      return rs
    }

    const initNewTreatmentType = () => {
      formData.value = {
        Id: null,
        Code: null,
        Description: null,
        SortOrder: 0,
        IsDeleted: false
      }
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      selectLists,
      errorMessages,
      saveClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
