<template>
  <div>
    <v-card width="400px">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Modify Recurring Payment
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData && selectLists">
        <v-text-field
          v-model="formData.PayerName"
          label="Payer Name"
          :error-messages="errorMessages(vuelidate.formData.PayerName)"
          @blur="vuelidate.formData.PayerName.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.Address"
          label="Address"
          :error-messages="errorMessages(vuelidate.formData.Address)"
          @blur="vuelidate.formData.Address.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.PostalCode"
          label="Postal Code"
          :error-messages="errorMessages(vuelidate.formData.PostalCode)"
          @blur="vuelidate.formData.PostalCode.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.PaymentLastFour"
          label="Payment Last Four"
          type="number"
          readonly
          dense
        />
        <div v-if="formData.ExpirationMonth !== null">
          <div class="d-inline-flex">
            <v-select
              v-model="formData.ExpirationMonth"
              label="Expiration Month"
              :items="monthSelectList"
              item-text="Text"
              item-value="Value"
              dense
            />
            <v-spacer class="px-2" />
            <v-select
              v-model="formData.ExpirationYear"
              label="Expiration Year"
              :items="yearSelectList"
              item-text="Text"
              item-value="Value"
              dense
            />
          </div>
          <v-text-field
            v-model="formData.Cvv"
            label="Cvv"
            type="number"
            dense
          />
        </div>
        <v-checkbox
          v-model="formData.PayOutstandingBalance"
          label="Pay Outstanding Balance"
          class="my-0"
          dense
        />
        <v-select
          v-model="formData.RecurringPaymentFrequencyId"
          label="Payment Frequency"
          :items="selectLists.RecurringPaymentFrequencies"
          item-text="Text"
          item-value="Value"
          :error-messages="
            errorMessages(vuelidate.formData.RecurringPaymentFrequencyId)
          "
          @blur="vuelidate.formData.RecurringPaymentFrequencyId.$touch()"
          clearable
          dense
        />
        <div v-if="!formData.PayOutstandingBalance">
          <v-currency-field
            v-model="formData.PaymentAmount"
            label="Payment Amount"
            dense
            :error-messages="errorMessages(vuelidate.formData.PaymentAmount)"
            @blur="vuelidate.formData.PaymentAmount.$touch()"
          />
        </div>
      </v-card-text>
      <v-card-actions
        ><v-btn color="success" class="ml-3" @click="updateClick">Update</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'RecurringPaymentEditor',
  props: {
    recurringPaymentId: {
      type: String,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()
    const selectLists = ref()
    const monthSelectList = ref([])
    const yearSelectList = ref([])

    const initDialog = async () => {
      const info = await retrieveRecurringPayment(props.recurringPaymentId)
      formData.value = mapResults(info)
      await loadSelectLists()
      initExpDateSelectLists()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.RecurringPaymentFrequencies]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const retrieveRecurringPayment = async infoId => {
      const rq = { InfoId: infoId }
      const rs = await host.recurringPayment.retrieve(rq)
      return rs.Info
    }

    const mapResults = info => {
      return {
        RecurringPaymentId: info.RecurringPaymentId,
        PayerName: info.Address.Name,
        Address: info.Address.Street,
        PostalCode: info.Address.PostCode,
        PaymentLastFour: info.PaymentLastFour,
        ExpirationYear:
          info.ExpDate === null ? null : parseInt(info.ExpDate.substring(0, 4)),
        ExpirationMonth:
          info.ExpDate === null ? null : parseInt(info.ExpDate.substring(5, 7)),
        Cvv: null,
        RecurringPaymentFrequencyId: info.RecurringPaymentFrequencyId,
        PayOutstandingBalance: info.PayOutstandingBalance,
        PaymentAmount: info.PaymentAmount
      }
    }

    const initExpDateSelectLists = () => {
      const today = new Date()
      for (let i = 0; i < 12; i++) {
        const value = i + 1
        monthSelectList.value.push({
          Text: String(value).padStart(2, '0'),
          Value: value
        })
      }
      for (let i = 0; i < 11; i++) {
        const value = today.getFullYear() - 3 + i
        yearSelectList.value.push({ Text: value.toString(), Value: value })
      }
    }

    const rules = () => {
      return {
        formData: {
          PayerName: { required },
          Address: { required },
          PostalCode: { required },
          PaymentAmount: {
            customMinValue: value => {
              return formData.value.PayOutstandingBalance ? true : value >= 0
            }
          },
          RecurringPaymentFrequencyId: {}
        }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const updateClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      const rq = { Info: formData.value }
      const rs = await host.recurringPayment.modify(rq)
      if (!rs.IsSuccess) {
        dialogUtil.value.error(rs.Message)
      } else {
        context.emit('update')
      }
    }

    initDialog()

    return {
      dialogUtil,
      formData,
      selectLists,
      monthSelectList,
      yearSelectList,
      errorMessages,
      updateClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
