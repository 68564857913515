import { EventBus } from '@/services/EventBus'

// This is a poor man's event subscribe mechanism.
// NavBar is a top-level component (always there), which contains the ReportHandler component.
// ReportHandler subscribes to these events.
export default {
  emitShowReportDialog(reportInfo) {
    EventBus.$emit('show-report-dialog', reportInfo)
  },
  onShowReportDialog(callback) {
    EventBus.$on('show-report-dialog', callback)
  },

  emitLaunchReport(reportInfo) {
    EventBus.$emit('launch-report', reportInfo)
  },
  onLaunchReport(callback) {
    EventBus.$on('launch-report', callback)
  }
}
