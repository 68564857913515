import { toXml } from '@/use/XmlUtil'

const AutoTaskRuleParamName = {
  Amount: 'Amount',
  Expression: 'Expression',
  Params: 'Params',
  ReferralTypeId: 'ReferralTypeId',
  ServiceDefId: 'ServiceDefId',
  TranCode: 'TranCode'
}

const AutoTaskRuleActionType = {
  CreateInvoice: 'Create Invoice',
  PostTransaction: 'Post Transaction',
  FirstPaymentPosted: 'First Payment',
  InactiveAccountToZeroBalance: 'Inactive Zero',
  CloseService: 'Close Service'
}

const numberCompareSelectList = [
  { Value: 'Greater Than', Text: 'Greater than' },
  { Value: 'Less Than', Text: 'Less than' },
  { Value: 'Equal to', Text: 'Equal to' }
]

const parseActionParams = actionParams => {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(actionParams, 'text/xml')
  return {
    Amount: getXMLValue(xmlDoc, AutoTaskRuleParamName.Amount),
    Expression: getXMLValue(xmlDoc, AutoTaskRuleParamName.Expression),
    ReferralTypeId: getXMLValue(xmlDoc, AutoTaskRuleParamName.ReferralTypeId),
    ServiceTypeId: getXMLValue(xmlDoc, AutoTaskRuleParamName.ServiceDefId),
    TranCodeId: getXMLValue(xmlDoc, AutoTaskRuleParamName.TranCode)
  }
}

const getXMLValue = (xmlDoc, tagName) => {
  const foundNodes = xmlDoc.getElementsByTagName(tagName)
  return foundNodes.length > 0 && foundNodes[0].childNodes.length > 0
    ? foundNodes[0].childNodes[0].nodeValue
    : null
}

const generateParamField = (actionType, actionParams) => {
  switch (actionType) {
    case AutoTaskRuleActionType.PostTransaction:
      return toXml('Params', {
        Expression: actionParams.Expression,
        Amount: actionParams.Amount,
        TranCode: actionParams.TranCodeId
      })
    case AutoTaskRuleActionType.CloseService:
      return toXml('Params', {
        ServiceDefId: actionParams.ServiceTypeId
      })
    case AutoTaskRuleActionType.FirstPaymentPosted:
      return toXml('Params', {
        ReferralTypeId: actionParams.ReferralTypeId
      })
    default:
      return ''
  }
}

export {
  AutoTaskRuleParamName,
  AutoTaskRuleActionType,
  numberCompareSelectList,
  parseActionParams,
  generateParamField
}
