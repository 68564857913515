const toXml = (name, val2) => {
  const map = {
    '<': '&lt;',
    '>': '&gt;',
    '&': '&amp;',
    "'": '&apos',
    '"': '&quot;'
  }
  const val = val2 == null ? '' : val2
  if (Array.isArray(val)) return val.map(elem => toXml(name, elem)).join``
  const content =
    Object(val) === val
      ? Object.keys(val).map(key => toXml(key, val[key])).join``
      : String(val).replace(/[<>&'"]/g, m => map[m])
  return `<${name}>${content}</${name}>`
}

export { toXml }
