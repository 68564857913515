import BillingDetails from '@/views/Billing/BillingDetails'
import BillingHome from '@/views/Billing/BillingHome'

export default [
  {
    path: '/billing/home',
    name: 'billing-home',
    component: BillingHome
  },
  {
    path: '/billing/details/:id',
    name: 'billing-details',
    component: BillingDetails,
    props: true
  }
]
