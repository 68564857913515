<template>
  <v-card height="90px" class="pt-2 pl-3 ma-2">
    <div style="display: flex;">
      <img :src="logoUrl" />
      <div height="64px">
        <div class="office-name ml-4 mt-4" v-text="officeName"></div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { computed } from 'vue'
import { userData } from '@/store/UserSession'
import FieldService from '@/services/FieldService'

export default {
  setup() {
    const officeName = computed(() => {
      return userData.value.OfficeName
    })

    const logoUrl = computed(() => {
      return `${
        FieldService.apiURL
      }/Office/Logo?tenant-id=${FieldService.getTenantId()}&officeId=${
        userData.value.OfficeId
      }`
    })

    return { officeName, logoUrl }
  }
}
</script>
<style lang="scss" scoped>
.office-name {
  font-weight: 400;
  font-size: 1.5em;
}
</style>
