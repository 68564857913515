<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Transaction Codes
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="includeDeletedItems"
          label="Include Deleted Items"
          dense
        />
        <v-data-table
          v-if="selectLists"
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="filteredInfoList"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.TranCodeGroupId="{ item }">
            {{ tranCodeGroupDescription(item.TranCodeGroupId) }}
          </template>
          <template v-slot:item.IsCredit="{ item }">
            <v-icon v-if="item.IsCredit" color="red">
              {{ icon.lineItemIsChecked }}
            </v-icon>
          </template>
          <template v-slot:item.IsRevenue="{ item }">
            <v-icon v-if="item.IsRevenue" color="green">
              {{ icon.lineItemIsChecked }}
            </v-icon>
          </template>
          <template v-slot:item.IsTaxable="{ item }">
            <v-icon v-if="item.IsTaxable" color="black">
              {{ icon.lineItemIsChecked }}
            </v-icon>
          </template>
          <template v-slot:item.IsSummarizedOnStatement="{ item }">
            <v-icon v-if="item.IsSummarizedOnStatement" color="purple">
              {{ icon.lineItemIsChecked }}
            </v-icon>
          </template>
          <template v-slot:item.DefaultAmount="{ item }">
            <div v-if="item.DefaultAmount">
              {{ toMoney(item.DefaultAmount) }}
            </div>
          </template>
          <template v-slot:item.IsDeleted="{ item }">
            <v-icon v-if="item.IsDeleted" color="error">
              {{ icon.lineItemIsDeleted }}
            </v-icon>
          </template>
          <template v-slot:item.Actions="{ item }">
            <div class="d-flex">
              <v-icon small class="mr-2" @click="editItemClick(item)">
                {{ icon.lineItemEdit }}
              </v-icon>
              <v-icon small @click="deleteItemClick(item)">
                {{ icon.lineItemDelete }}
              </v-icon>
              <SyncInfoButton small :recordId="item.TranCodeId" />
            </div>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New TranCode </span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showEditorDialog" width="400" persistent>
      <TranCodeEditor
        :infoId="selectedItemId"
        :isDialogOpen="showEditorDialog"
        :selectLists="selectLists"
        @close="editorCloseEvent"
        @update="editorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, computed, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { toMoney } from '@/use/NumberFormatter'
import { icon } from '@/use/Constants'
import TranCodeEditor from '@/components/Setup/Billing/TranCodeEditor'
import SyncInfoButton from '@/components/Common/SyncInfoButton'
export default {
  name: 'TranCodeList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { TranCodeEditor, SyncInfoButton },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const includeDeletedItems = ref(false)
    const infoList = ref([])
    const selectedItemId = ref(null)
    const showEditorDialog = ref(false)
    const selectLists = ref()

    const initDialog = () => {
      infoList.value = []
      getTranCodeList()
      loadSelectLists()
    }

    const filteredInfoList = computed(() => {
      return infoList.value
        ? infoList.value.filter(
            item => includeDeletedItems.value || !item.IsDeleted
          )
        : null
    })

    const getTranCodeList = async () => {
      loading.value = true
      const rq = {}
      const rs = await host.tranCode.list(rq)
      infoList.value = rs.InfoList
      loading.value = false
    }

    function editItemClick(item) {
      selectedItemId.value = item.TranCodeId
      showEditorDialog.value = true
    }

    function deleteItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete TranCode  ${item.Code}`
        })
        .then(() => performDeleteTranCode(item))
        .catch(() => {
          return
        })
    }

    async function performDeleteTranCode(item) {
      const rq = { InfoId: item.TranCodeId }
      const rs = await host.tranCode.delete(rq)
      if (rs.IsSuccess) {
        getTranCodeList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newItemClick() {
      selectedItemId.value = null
      showEditorDialog.value = true
    }

    const headers = [
      { value: 'Code', text: 'Code', width: 100, align: 'left' },
      { value: 'Description', text: 'Description', width: 100, align: 'left' },
      {
        value: 'TranCodeGroupId',
        text: 'TranCode Group',
        width: 20,
        align: 'left'
      },
      { value: 'SortOrder', text: 'Sort Order', width: 20, align: 'left' },
      { value: 'IsCredit', text: 'Credit', width: 10, align: 'center' },
      { value: 'IsRevenue', text: 'Revenue', width: 10, align: 'center' },
      { value: 'IsTaxable', text: 'Taxable', width: 10, align: 'center' },
      {
        value: 'IsSummarizedOnStatement',
        text: 'Summarize',
        width: 10,
        align: 'center'
      },
      {
        value: 'DefaultAmount',
        text: 'Default Amount',
        width: 10,
        align: 'right'
      },
      { value: 'IsDeleted', text: 'Deleted', width: 10, align: 'center' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const editorCloseEvent = () => {
      showEditorDialog.value = false
    }

    const editorUpdateEvent = () => {
      showEditorDialog.value = false
      getTranCodeList()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodeGroups]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const tranCodeGroupDescription = value => {
      return selectListCache.attribute(
        selectListCache.name.TranCodeGroups,
        value,
        'Text'
      )
    }

    initDialog()

    return {
      dialogUtil,
      includeDeletedItems,
      filteredInfoList,
      headers,
      showEditorDialog,
      selectedItemId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      editorCloseEvent,
      editorUpdateEvent,
      tranCodeGroupDescription,
      selectLists,
      toMoney,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
