import SearchEstimates from '@/views/Estimates/SearchEstimates'

export default [
  {
    path: '/estimate/search/:customerId?/:customerName?',
    name: 'estimate-search',
    component: SearchEstimates,
    props: true
  }
]
