<template>
  <div>
    <div v-if="formData && selectLists">
      <v-card width="9000">
        <v-card-title>
          <DialogTitle :dialogName="$options.name">
            {{ isEditMode ? 'Edit Estimate' : 'New Estimate' }}
          </DialogTitle>
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-wrap">
            <v-card width="300">
              <v-card-text>
                <v-text-field
                  v-model="formData.EstimateNumber"
                  label="Estimate Number"
                  :error-messages="
                    errorMessages(vuelidate.formData.EstimateNumber)
                  "
                  @blur="vuelidate.formData.EstimateNumber.$touch()"
                  dense
                ></v-text-field>
                <CustomerPicker
                  v-model="formData.CustomerId"
                  v-bind:text.sync="formData.CustomerName"
                  @update:email="setEmail"
                  label="Customer"
                  dense
                ></CustomerPicker>
                <SitePicker
                  v-model="formData.SiteId"
                  v-bind:text.sync="formData.SiteDescription"
                  label="Site"
                  dense
                ></SitePicker>
                <DatePicker
                  v-model="formData.WhenExpires"
                  label="When Expires"
                  clearable
                  dense
                >
                </DatePicker>
              </v-card-text>
            </v-card>
            <v-card width="300" class="ml-4">
              <v-card-text>
                <v-textarea
                  v-model="formData.Details"
                  label="Details"
                  rows="3"
                  dense
                ></v-textarea>
                <v-textarea
                  v-model="formData.TermsAndConditions"
                  label="Terms and Conditions"
                  rows="2"
                  dense
                ></v-textarea>
              </v-card-text>
            </v-card>
            <v-card width="300" class="ml-4">
              <v-card-text>
                <v-select
                  v-model="formData.Status"
                  label="Status"
                  :items="selectLists.EstimateStatus"
                  item-text="Text"
                  item-value="Value"
                  :error-messages="errorMessages(vuelidate.formData.Status)"
                  @blur="vuelidate.formData.Status.$touch()"
                  dense
                />
                <DatePicker
                  v-model="formData.WhenCreated"
                  label="When Created"
                  :error-messages="
                    errorMessages(vuelidate.formData.WhenCreated)
                  "
                  @blur="vuelidate.formData.WhenCreated.$touch()"
                  dense
                />
                <UserPicker
                  v-model="formData.UserId"
                  label="Created By"
                  :error-messages="errorMessages(vuelidate.formData.UserId)"
                  @blur="vuelidate.formData.UserId.$touch()"
                />
                <div v-if="formData.Status == 'I'">
                  <div>
                    <span>When Invoiced: </span
                    ><span class="red--text font-weight-bold">{{
                      new Date(formData.WhenInvoiced).toLocaleDateString()
                    }}</span>
                  </div>
                  <div>
                    <span>Invoice Number: </span
                    ><span class="red--text font-weight-bold">{{
                      formData.InvoiceNumber
                    }}</span>
                  </div>
                </div>
                <div v-if="copyFromEstimateNumber">
                  <span class="red--text font-weight-bold"
                    >Copy of Estimate Number {{ copyFromEstimateNumber }}</span
                  >
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div>
            <v-card class="mt-2"
              ><v-card-text>
                <v-data-table
                  dense
                  :items-per-page="5"
                  :footer-props="{
                    'items-per-page-options': items_per_page,
                    'show-first-last-page': true
                  }"
                  :headers="headers"
                  :items="formData.LineItemList"
                  class="elevation-1"
                >
                  <template v-slot:item.TranCodeId="{ item }"
                    ><span>{{
                      selectLists.TranCodes.find(
                        sl => sl.Value == item.TranCodeId
                      ).Text
                    }}</span>
                  </template>
                  <template v-slot:item.Rate="{ item }"
                    ><span>{{ toMoney(item.Rate) }}</span>
                  </template>
                  <template v-slot:item.Quantity="{ item }"
                    ><span>{{ item.Quantity }}</span>
                  </template>
                  <template v-slot:item.Amount="{ item }"
                    ><span>{{ toMoney(item.Amount) }}</span>
                  </template>
                  <template v-slot:item.Actions="{ item }">
                    <v-icon small class="mr-2" @click="editLineItemClick(item)">
                      {{ icon.lineItemEdit }}
                    </v-icon>
                    <v-icon small @click="deleteLineItemClick(item)">
                      {{ icon.lineItemDelete }}
                    </v-icon>
                  </template>
                </v-data-table>
                <div class="table-footer-prepend d-flex pl-2 align-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="newLineItemClick">
                        <v-icon>{{ icon.lineItemAdd }}</v-icon>
                      </v-btn>
                    </template>
                    <span>New Estimate Line</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <span class="subtitle-1 font-weight-black purple--text mr-4"
                    >Estimate Total: {{ toMoney(estimateTotal) }}</span
                  >
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions
          ><v-btn
            v-if="isEditMode"
            color="success"
            class="ml-3"
            @click="updateClick"
            >Update</v-btn
          >
          <v-btn v-else color="success" class="ml-3" @click="saveClick"
            >Save</v-btn
          >
          <v-btn
            v-if="
              formData.EstimateId &&
                formData.CustomerId &&
                !formData.InvoiceNumber
            "
            color="primary"
            class="ml-3"
            @click="invoiceClick"
            >Invoice</v-btn
          >
          <v-tooltip v-if="isEditMode && !copyFromEstimateNumber" top>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" class="ml-3" v-on="on" @click="copyClick">
                Copy
              </v-btn>
            </template>
            <span>Create a copy of this estimate</span>
          </v-tooltip>
          <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="mr-2"
            @click="estimateReportClick"
          >
            <v-icon class="mr-2">{{ icon.report }}</v-icon>
            Print / Email
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog v-model="showEstimateLineDialog" max-width="400" persistent>
        <InvoiceLine
          :isDialogOpen="showEstimateLineDialog"
          :itemInfo="selectedItemInfo"
          :isEdit="isEditLine"
          :tranCodeList="selectLists.TranCodes"
          lineType="Estimate"
          @close="showEstimateLineDialog = false"
          @update="invoiceLineUpdate"
        ></InvoiceLine>
      </v-dialog>
    </div>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { toMoney } from '@/use/NumberFormatter'
import CustomerPicker from '@/components/Customer/CustomerPicker'
import SitePicker from '@/components/Site/SitePicker'
import InvoiceLine from '@/components/Billing/InvoiceLine'
import ReportService from '@/services/ReportService'
import reportInfo from '@/constants/reports'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { icon } from '@/use/Constants'

export default {
  name: 'Estimate',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: String,
      default: null
    },
    estimateId: {
      type: String,
      default: null
    }
  },
  components: {
    InvoiceLine,
    CustomerPicker,
    SitePicker
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()
    const copyFromEstimateNumber = ref()
    const selectLists = ref()
    const showEstimateLineDialog = ref(false)
    const isEditLine = ref(false)
    const selectedItemInfo = ref()

    const initDialog = () => {
      loadSelectLists()
      if (props.estimateId) {
        retrieve(props.estimateId)
      } else {
        newTemplate()
      }
    }

    const isEditMode = computed(() => {
      return formData.value && formData.value.EstimateId != null
    })

    const newTemplate = async () => {
      const rq = { Defaults: { CustomerId: props.customerId } }
      const rs = await host.estimate.newTemplate(rq)
      if (rs.IsSuccess) {
        formData.value = rs.Info
      } else {
        dialogUtil.error(rs.Message)
      }
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.TranCodes,
        selectListCache.name.EstimateStatus
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const retrieve = async id => {
      const rq = { InfoId: id }
      const rs = await host.estimate.retrieve(rq)
      formData.value = rs.Info
    }

    const rules = {
      formData: {
        EstimateNumber: { required },
        WhenCreated: { required },
        Status: { required },
        UserId: { required },
        LineItemList: { required, minLength: minLength(1) }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    function errorMessages(item) {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const lineItemListErrors = computed(() => {
      const errors = []
      vuelidate.value.formData.LineItemList.$dirty
      vuelidate.value.formData.LineItemList.required.$invalid &&
        errors.push('At least one detail line is required.')
      return errors
    })

    const cancelClick = () => {
      context.emit('close')
    }

    const saveClick = async () => {
      if (await applyChanges()) {
        context.emit('update')
      }
    }

    const updateClick = async () => {
      if (await applyChanges()) {
        context.emit('update')
      }
    }

    const copyClick = async () => {
      const rq = { Defaults: { CustomerId: props.customerId } }
      const rs = await host.estimate.newTemplate(rq)
      copyFromEstimateNumber.value = formData.value.EstimateNumber
      formData.value.EstimateId = null
      formData.value.EstimateNumber = rs.Info.EstimateNumber
      formData.value.WhenCreated = rs.Info.WhenCreated
      formData.value.WhenExpires = null
      if (formData.value.Status != 'P' && formData.value.Status != 'A') {
        formData.value.Status = 'A'
      }
    }

    const applyChanges = async () => {
      vuelidate.value.$touch()

      if (vuelidate.value.$invalid) {
        if (lineItemListErrors.value) {
          dialogUtil.value.error(lineItemListErrors.value.join('<br/>'))
        }
        return false
      }

      const response = isEditMode.value ? await update() : await saveNew()

      if (response.IsSuccess) {
        if (!isEditMode.value) {
          formData.value.EstimateId = response.EstimateId // eslint-disable-line require-atomic-updates
        }
      } else {
        dialogUtil.value.error(response.Message)
      }

      return response.IsSuccess
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.estimate.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.estimate.update(rq)
      return rs
    }

    const estimateReportClick = async () => {
      if (!(await applyChanges())) {
        return
      }
      ReportService.emitShowReportDialog({
        ReportId: reportInfo.report.estimate.reportId,
        ParameterList: [
          { Name: 'EstimateId', Value: formData.value.EstimateId }
        ],
        ParameterHideList: ['EstimateId'],
        EmailInfo: {
          SendEmail: false,
          CustomerId: formData.value.CustomerId,
          CustomerName: formData.value.CustomerName,
          Recipient: formData.value.Email
        }
      })
    }

    const headers = [
      { value: 'SortOrder', text: 'Line', width: 40, align: 'right' },
      { value: 'TranCodeId', text: 'Code', width: 100, align: 'left' },
      { value: 'Reference', text: 'Reference', width: 100, align: 'left' },
      { value: 'Rate', text: 'Rate', width: 100, align: 'right' },
      { value: 'Quantity', text: 'Quantity', width: 100, align: 'right' },
      { value: 'Amount', text: 'Amount', width: 100, align: 'right' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const items_per_page = [5, 10, 15, 20, 50]

    const estimateTotal = computed(() => {
      return formData.value.LineItemList.reduce(function(total, currentValue) {
        return total + currentValue.Amount
      }, 0)
    })

    const maxSortOrder = computed(() => {
      return formData.value.LineItemList.length === 0
        ? 0
        : parseInt(
            formData.value.LineItemList.reduce(function(prev, current) {
              return current.SortOrder < 999 &&
                current.SortOrder > prev.SortOrder
                ? current
                : prev
            }).SortOrder
          )
    })

    const invoiceClick = () => {
      if (!applyChanges()) {
        return
      }

      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Create invoice for this estimate.`
        })
        .then(() => performCreateInvoice())
        .catch(() => {
          return
        })
    }

    const performCreateInvoice = async () => {
      const rq = { InfoId: formData.value.EstimateId }
      const rs = await host.estimate.invoice(rq)
      if (rs.IsSuccess) {
        context.emit('invoiced', rs.InfoId)
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const editLineItemClick = item => {
      selectedItemInfo.value = item
      isEditLine.value = true
      showEstimateLineDialog.value = true
    }

    const newLineItemClick = () => {
      selectedItemInfo.value = {
        EstimateLineId: null,
        TranCodeId: null,
        Quantity: 1,
        Rate: 0,
        Amount: 0,
        Reference: '',
        SortOrder: maxSortOrder.value + 1,
        InventoryItemId: null
      }
      isEditLine.value = false
      showEstimateLineDialog.value = true
    }

    const deleteLineItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete this estimate line item.`
        })
        .then(() => performDeleteLineItem(item))
        .catch(() => {
          return
        })
    }

    const performDeleteLineItem = item => {
      const index = formData.value.LineItemList.indexOf(item)
      formData.value.LineItemList.splice(index, 1)
    }

    const invoiceLineUpdate = event => {
      selectedItemInfo.value.SortOrder = event.SortOrder
      selectedItemInfo.value.TranCodeId = event.TranCodeId
      selectedItemInfo.value.Reference = event.Reference
      selectedItemInfo.value.Rate = parseFloat(event.Rate)
      selectedItemInfo.value.Quantity = parseFloat(event.Quantity)
      selectedItemInfo.value.Amount = parseFloat(event.Amount)
      if (!isEditLine.value) {
        formData.value.LineItemList.push(selectedItemInfo.value)
      }
      showEstimateLineDialog.value = false
    }

    const setEmail = event => {
      formData.value.Email = event
    }

    initDialog()

    return {
      dialogUtil,
      isEditMode,
      formData,
      selectLists,
      estimateTotal,
      cancelClick,
      saveClick,
      updateClick,
      copyClick,
      invoiceClick,
      editLineItemClick,
      newLineItemClick,
      deleteLineItemClick,
      isEditLine,
      estimateReportClick,
      copyFromEstimateNumber,
      showEstimateLineDialog,
      headers,
      items_per_page,
      selectedItemInfo,
      invoiceLineUpdate,
      setEmail,
      errorMessages,
      toMoney,
      icon,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
