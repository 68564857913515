import PaymentBatchList from '@/views/Payments/PaymentBatchList'
import PaymentBatch from '@/views/Payments/PaymentBatch'

export default [
  {
    path: '/payment/batchlist',
    name: 'paymentbatchlist',
    component: PaymentBatchList
  },
  {
    path: '/payment/batch/:id',
    name: 'paymentbatch',
    component: PaymentBatch,
    props: true
  }
]
