import { SetupMenuItem } from '@/components/layout/SetupMenuItem'
import icon from '@/constants/Icons'

export default {
  officeInfo: new SetupMenuItem('Office Info', {
    icon: icon.office,
    dialogWidth: 1080,
    category: 'Office'
  }),
  officeSettings: new SetupMenuItem('Miscellaneous Settings', {
    dialogWidth: 400,
    category: 'Office'
  }),
  emailTemplates: new SetupMenuItem('Email Templates', {
    icon: icon.emailEdit,
    dialogWidth: 1000,
    category: 'Office'
  }),
  users: new SetupMenuItem('Users', {
    icon: icon.user,
    dialogWidth: 800,
    category: 'Office'
  }),
  userGroups: new SetupMenuItem('User Groups', {
    icon: icon.userGroup,
    category: 'Office'
  }),
  taskTypes: new SetupMenuItem('Task Types', {
    category: 'Office'
  }),
  autoTaskRules: new SetupMenuItem('Auto Task Rules', {
    dialogWidth: 1000,
    category: 'Office'
  }),
  reportDesigner: new SetupMenuItem('Report Designer', {
    icon: icon.report,
    dialogWidth: 800,
    category: 'Office'
  }),
  customerTypes: new SetupMenuItem('Customer Types', {
    category: 'Customer'
  }),
  referralTypes: new SetupMenuItem('Referral Types', { category: 'Customer' }),
  terminationTypes: new SetupMenuItem('Termination Types', {
    category: 'Customer'
  }),
  customerAlertDefs: new SetupMenuItem('Alert Types', {
    category: 'Customer'
  }),
  customerCustomInfo: new SetupMenuItem('Customer Custom Info', {
    category: 'Customer'
  }),
  siteCustomInfo: new SetupMenuItem('Site Custom Info', {
    category: 'Customer'
  }),
  customViews: new SetupMenuItem('Custom Views', {
    dialogWidth: 500,
    category: 'Customer'
  }),
  customerPortalSettings: new SetupMenuItem('Portal Settings', {
    dialogWidth: 800,
    category: 'Customer'
  }),
  billingGroups: new SetupMenuItem('Billing Groups', {
    dialogWidth: 800,
    category: 'Billing'
  }),
  tranCodeGroups: new SetupMenuItem('TranCode Groups', {
    dialogWidth: 900,
    category: 'Billing'
  }),
  tranCodes: new SetupMenuItem('Transaction Codes', {
    dialogWidth: 1200,
    category: 'Billing'
  }),
  rates: new SetupMenuItem('Rates', {
    category: 'Billing'
  }),
  commissions: new SetupMenuItem('Commissions & Rules', {
    dialogWidth: 1200,
    category: 'Billing'
  }),
  onlinePaymentSetup: new SetupMenuItem('Online Payments', {
    dialogWidth: 1200,
    category: 'Billing'
  }),
  salesTaxes: new SetupMenuItem('Sales Taxes', {
    dialogWidth: 1200,
    category: 'Billing'
  }),
  serviceTypes: new SetupMenuItem('Service Types', {
    category: 'Service'
  }),
  serviceRoutes: new SetupMenuItem('Routes', {
    dialogWidth: 1200,
    category: 'Service'
  }),
  syncWithList: new SetupMenuItem('Sync With (QuickBooks)', {
    dialogWidth: 1400,
    category: 'Billing'
  }),
  measureTypes: new SetupMenuItem('Measure Types', {
    dialogWidth: 800,
    category: 'Service'
  }),
  treatmentTypes: new SetupMenuItem('Treatment Types', {
    dialogWidth: 800,
    category: 'Service'
  }),
  serviceableTypes: new SetupMenuItem('Serviceable Types', {
    category: 'Service'
  }),
  serviceSeasons: new SetupMenuItem('Service Seasons', {
    dialogWidth: 900,
    category: 'Service'
  })
}
