<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Auto Task Rules
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="selectLists"
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="infoList"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:item.UserGroupId="{ item }">
            {{ userGroupDescription(item.UserGroupId) }}
          </template>
          <template v-slot:item.AssignToUserId="{ item }">
            {{ userDescription(item.AssignToUserId) }}
          </template>
          <template v-slot:item.TaskTypeId="{ item }">
            {{ taskTypeDescription(item.TaskTypeId) }}
          </template>
          <template v-slot:item.ActionParams="{ item }">
            {{ parameterDescription(item) }}
          </template>
          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New Task Type</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showEditorDialog" width="400" persistent>
      <AutoTaskRuleEditor
        :infoId="selectedItemId"
        :selectLists="selectLists"
        :isDialogOpen="showEditorDialog"
        @close="editorCloseEvent"
        @update="editorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { icon } from '@/use/Constants'
import AutoTaskRuleEditor from '@/components/Setup/Office/AutoTaskRuleEditor'
import {
  AutoTaskRuleActionType,
  parseActionParams
} from '@/use/AutoTaskRuleUtil'
import { toMoney } from '@/use/NumberFormatter'
export default {
  name: 'AutoTaskRuleList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { AutoTaskRuleEditor },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      infoList.value = []
      getAutoTaskRuleList()
      loadSelectLists()
    }

    const infoList = ref([])
    const selectedItemId = ref(null)
    const showEditorDialog = ref(false)
    const selectLists = ref()

    const getAutoTaskRuleList = async () => {
      loading.value = true
      const rq = {}
      const rs = await host.autoTaskRule.list(rq)
      infoList.value = rs.InfoList
      loading.value = false
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.AutoTaskRuleActionTypes,
        selectListCache.name.ReferralTypes,
        selectListCache.name.ServiceTypes,
        selectListCache.name.TaskTypes,
        selectListCache.name.TranCodes,
        selectListCache.name.UserGroups,
        selectListCache.name.Users
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    function editItemClick(item) {
      selectedItemId.value = item.AutoTaskRuleId
      showEditorDialog.value = true
    }

    function deleteItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Task Type ${item.Code}`
        })
        .then(() => performDeleteAutoTaskRule(item))
        .catch(() => {
          return
        })
    }

    async function performDeleteAutoTaskRule(item) {
      const rq = { InfoId: item.AutoTaskRuleId }
      const rs = await host.autoTaskRule.delete(rq)
      if (rs.IsSuccess) {
        getAutoTaskRuleList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newItemClick() {
      selectedItemId.value = null
      showEditorDialog.value = true
    }

    const headers = [
      { value: 'ActionType', text: 'Action Type' },
      { value: 'UserGroupId', text: 'User Group' },
      { value: 'AssignToUserId', text: 'Assigned To' },
      { value: 'TaskTypeId', text: 'Task Type' },
      { value: 'ActionParams', text: 'Parameters' },
      { value: 'Actions', text: 'Actions', sortable: false }
    ]

    const editorCloseEvent = () => {
      showEditorDialog.value = false
    }

    const editorUpdateEvent = () => {
      showEditorDialog.value = false
      getAutoTaskRuleList()
    }

    const referralTypeDescription = value => {
      return value
        ? selectLists.value.ReferralTypes.find(item => item.Value == value).Text
        : ''
    }

    const serviceTypeDescription = value => {
      return value
        ? selectLists.value.ServiceTypes.find(item => item.Value == value).Text
        : ''
    }

    const userGroupDescription = value => {
      return value
        ? selectLists.value.UserGroups.find(item => item.Value == value).Text
        : ''
    }

    const userDescription = value => {
      return value
        ? selectLists.value.Users.find(item => item.Value == value).Text
        : ''
    }

    const taskTypeDescription = value => {
      return value
        ? selectLists.value.TaskTypes.find(item => item.Value == value).Text
        : ''
    }

    const tranCodeDescription = value => {
      return value
        ? selectLists.value.TranCodes.find(item => item.Value == value).Text
        : ''
    }

    const parameterDescription = autoTaskRow => {
      const actionParams = parseActionParams(autoTaskRow.ActionParams)
      switch (autoTaskRow.ActionType) {
        case AutoTaskRuleActionType.PostTransaction:
          return `${tranCodeDescription(actionParams.TranCodeId)} ${
            actionParams.Expression
          } ${toMoney(parseFloat(actionParams.Amount))}`
        case AutoTaskRuleActionType.CloseService:
          return `Service Type: ${serviceTypeDescription(
            actionParams.ServiceTypeId
          )}`
        case AutoTaskRuleActionType.FirstPaymentPosted:
          return `Referral Type: ${referralTypeDescription(
            actionParams.ReferralTypeId
          )}`
        default:
          return ''
      }
    }

    initDialog()

    return {
      dialogUtil,
      infoList,
      headers,
      showEditorDialog,
      selectedItemId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      editorCloseEvent,
      editorUpdateEvent,
      selectLists,
      userGroupDescription,
      userDescription,
      taskTypeDescription,
      parameterDescription,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
