<template>
  <div v-if="enabled">
    <v-tooltip bottom>
      <template #activator="{ on: onTooltip }">
        <v-btn icon v-on="{ ...onTooltip }" @click="syncInfoClick">
          <v-icon v-bind="$attrs" :color="feature.sync.color">{{
            feature.sync.icon
          }}</v-icon>
        </v-btn>
      </template>
      <span>View Sync Info</span>
    </v-tooltip>

    <v-dialog v-model="showSyncInfoDialog" :width="feature.sync.dialogWidth">
      <v-card>
        <v-card-title class="subtitle-1">
          <span>Sync Info</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="infoList"
            dense
            :loading="loading"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
import { feature } from '@/use/Constants'
import { syncWithService } from '@/services/SyncWithService'
import { host } from '@/services/HostAPI'
export default {
  name: 'SyncInfoButton',
  props: {
    recordId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props) {
    const loading = ref(false)
    const enabled = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const infoList = ref([])
    const showSyncInfoDialog = ref(false)

    const initDialog = async () => {
      const hasSyncWiths = await syncWithService.hasSyncWiths()
      enabled.value = hasSyncWiths
    }

    const syncInfoClick = async () => {
      loading.value = true
      const rq = { Criteria: props.recordId }
      const rs = await host.sync.list(rq)
      infoList.value = rs.InfoList
      loading.value = false
      showSyncInfoDialog.value = true
    }

    const headers = [
      { value: 'SyncWithDescription', text: 'Sync Name' },
      { value: 'TableName', text: 'Record Type' },
      { value: 'RemoteKey', text: 'Remote Key' },
      { value: 'RemoteVersion', text: 'Remote Version' }
    ]

    initDialog()

    return {
      enabled,
      headers,
      infoList,
      syncInfoClick,
      showSyncInfoDialog,
      toLocaleDateTimeString,
      feature,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
