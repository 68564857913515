<template>
  <div>
    <v-data-table
      dense
      :items-per-page="10"
      :footer-props="{
        'show-first-last-page': true
      }"
      :headers="recurringPaymentListHeaders"
      :items="recurringPaymentList"
      :loading="loadingRecurringPaymentList"
      class="elevation-1"
    >
      <template v-slot:item.RecurringPaymentFrequencyId="{ item }">
        {{ frequencyDescription(item.RecurringPaymentFrequencyId) }}
      </template>
      <template v-slot:item.PaymentAmount="{ item }">
        <div v-if="!item.PayOutstandingBalance">
          {{ toMoney(item.PaymentAmount) }}
        </div>
      </template>
      <template v-slot:item.ExpDate="{ item }">
        {{ item.ExpDate === null ? '(ACH)' : toExpDateString(item.ExpDate) }}
      </template>
      <template v-slot:item.PayOutstandingBalance="{ item }">
        <v-icon v-if="item.PayOutstandingBalance" color="green">
          {{ icon.lineItemIsChecked }}
        </v-icon>
      </template>
      <template v-slot:item.processorData="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="processorDataClick(item.RecurringPaymentId)"
            >
              <v-icon :color="feature.onlinePayments.color">
                {{ icon.onlinePaymentProcessorInfo }}
              </v-icon>
            </v-btn>
          </template>
          <span>Show Processor Info</span>
        </v-tooltip>
      </template>
      <template v-slot:item.Actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editRecurringPaymentClick(item.RecurringPaymentId)"
        >
          {{ icon.lineItemEdit }}
        </v-icon>
        <v-icon small @click="deleteRecurringPaymentClick(item)">
          {{ icon.lineItemDelete }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="showProcessorInfoDialog" max-width="300">
      <v-card>
        <v-card-title>Processor Info</v-card-title>
        <v-card-text>
          <div v-html="processorInfo"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showRecurringPaymentEditorDialog"
      max-width="400"
      persistent
    >
      <RecurringPaymentEditor
        :recurringPaymentId="selectedItemId"
        :isDialogOpen="showRecurringPaymentEditorDialog"
        @close="recurringPaymentEditorCloseEvent"
        @update="recurringPaymentEditorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { icon, feature } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { toLocaleDateString, toExpDateString } from '@/use/DateFormatter'
import { toMoney } from '@/use/NumberFormatter'
import RecurringPaymentEditor from '@/components/OnlinePayment/RecurringPaymentEditor'
export default {
  name: 'RecurringPaymentList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RecurringPaymentEditor
  },
  setup(props) {
    const dialogUtil = ref(null)
    const loadingRecurringPaymentList = ref(false)
    const selectLists = ref([])

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      await getRecurringPaymentList()
      await loadSelectLists()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.RecurringPaymentFrequencies]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const recurringPaymentList = ref([])
    const selectedItemId = ref(null)

    const showRecurringPaymentEditorDialog = ref(false)
    const showProcessorInfoDialog = ref(false)
    const processorInfo = ref()

    const recurringPaymentListHeaders = [
      { value: 'CustomerName', text: 'Customer Name' },
      { value: 'PaymentLastFour', text: 'Last Four' },
      { value: 'ExpDate', text: 'Expires' },
      { value: 'RecurringPaymentFrequencyId', text: 'When to Charge' },
      { value: 'PaymentAmount', text: 'Amount', align: 'right' },
      { value: 'PayOutstandingBalance', text: 'Pay Balance', align: 'center' },
      { value: 'processorData', text: 'Processor Info', align: 'center' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const getRecurringPaymentList = async () => {
      loadingRecurringPaymentList.value = true
      const rq = {}
      const rs = await host.recurringPayment.list(rq)
      loadingRecurringPaymentList.value = false
      recurringPaymentList.value = rs.InfoList
    }

    const frequencyDescription = value => {
      return value
        ? selectListCache.attribute(
            selectListCache.name.RecurringPaymentFrequencies,
            value,
            'Text'
          )
        : ''
    }

    const processorDataClick = async item => {
      await loadProcessorData(item)
      showProcessorInfoDialog.value = true
    }

    const loadProcessorData = async id => {
      const rq = { InfoId: id }
      const rs = await host.recurringPayment.getProcessorData(rq)
      processorInfo.value = rs.Info
    }

    const editRecurringPaymentClick = id => {
      selectedItemId.value = id
      showRecurringPaymentEditorDialog.value = true
    }

    const deleteRecurringPaymentClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: 'Delete this recurring payment. Are you sure?'
        })
        .then(() => performDeleteRecurringPayment(item.Id))
        .catch(() => {
          return
        })
    }

    const performDeleteRecurringPayment = async id => {
      const rs = deleteRecurringPayment(id)
      if (rs.IsSucess) {
        await getRecurringPaymentList()
      } else {
        await dialogUtil.value.inform({
          text: rs.Message,
          snackbarTimeout: 10000,
          color: 'error'
        })
      }
    }

    const deleteRecurringPayment = async id => {
      const rq = { InfoId: id }
      const rs = await host.recurringPayment.delete(rq)
      return rs
    }

    const recurringPaymentEditorCloseEvent = () => {
      showRecurringPaymentEditorDialog.value = false
    }

    const recurringPaymentEditorUpdateEvent = async () => {
      await getRecurringPaymentList()
      showRecurringPaymentEditorDialog.value = false
    }

    initDialog()

    return {
      dialogUtil,
      recurringPaymentListHeaders,
      recurringPaymentList,
      showRecurringPaymentEditorDialog,
      recurringPaymentEditorCloseEvent,
      recurringPaymentEditorUpdateEvent,
      editRecurringPaymentClick,
      deleteRecurringPaymentClick,
      selectedItemId,
      frequencyDescription,
      toLocaleDateString,
      toExpDateString,
      toMoney,
      icon,
      feature,
      showProcessorInfoDialog,
      processorInfo,
      processorDataClick,
      loadingRecurringPaymentList
    }
  }
}
</script>

<style lang="scss" scoped></style>
