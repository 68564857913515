<template>
  <div>
    <v-card width="480">
      <v-card-title>
        {{ isEditMode ? 'Edit' : 'New' }} Service Commission Rule
      </v-card-title>
      <v-card-text v-if="formData">
        <v-select
          v-model="formData.CommissionTypeId"
          :items="commissionTypeList"
          item-text="Description"
          item-value="CommissionTypeId"
          label="Commission Type"
          :error-messages="errorMessages(vuelidate.formData.CommissionTypeId)"
          @blur="vuelidate.formData.CommissionTypeId.$touch()"
          dense
        />
        <v-select
          v-model="formData.ServiceTypeId"
          :items="selectLists.ServiceTypes"
          item-text="Text"
          item-value="Value"
          label="Service Type"
          :error-messages="errorMessages(vuelidate.formData.ServiceTypeId)"
          @blur="vuelidate.formData.ServiceTypeId.$touch()"
          dense
        />
        <v-select
          v-model="formData.PayTo"
          :items="selectLists.ServiceCommissionRulePayTos"
          item-text="Text"
          item-value="Value"
          label="Pay To"
          :error-messages="errorMessages(vuelidate.formData.PayTo)"
          @blur="vuelidate.formData.PayTo.$touch()"
          dense
        />
        <v-select
          v-model="formData.UserId"
          :items="selectLists.Users"
          item-text="Text"
          item-value="Value"
          label="User"
          hint="Leave blank for user associated with service."
          clearable
          dense
        />
        <v-currency-field
          v-model="formData.Amount"
          label="Amount"
          :error-messages="errorMessages(vuelidate.formData.Amount)"
          @blur="vuelidate.formData.Code.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.Reason"
          label="Reason"
          :error-messages="errorMessages(vuelidate.formData.Reason)"
          @blur="vuelidate.formData.Reason.$touch()"
          dense
        />
      </v-card-text>
      <v-card-actions
        ><v-btn color="success" class="ml-1" @click="saveClick">{{
          isEditMode ? 'Update' : 'Save'
        }}</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'ServiceCommissionRuleEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    selectLists: {
      type: Object,
      required: true
    },
    commissionTypeList: {
      type: Array,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref()

    const isEditMode = ref(false)
    const formData = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      formData.value = isEditMode.value
        ? await loadServiceCommissionRule()
        : await initNewServiceCommissionRule()
      vuelidate.value.$reset()
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const rules = {
      formData: {
        CommissionTypeId: { required },
        ServiceTypeId: { required },
        PayTo: { required },
        Amount: { required },
        Reason: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const loadServiceCommissionRule = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.serviceCommissionRule.retrieve(rq)
      return rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.serviceCommissionRule.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.serviceCommissionRule.update(rq)
      return rs
    }

    const initNewServiceCommissionRule = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.serviceCommissionRule.newTemplate(rq)
      return rs.Info
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      errorMessages,
      saveClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
