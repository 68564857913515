<template>
  <div>
    <v-card class="indigo lighten-5">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">Office Info</DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData">
        <div class="d-flex">
          <div>
            <v-card width="400" class="mb-2"
              ><v-card-text>
                <v-text-field
                  v-model="formData.OfficeCode"
                  label="Office Code"
                  readonly
                  dense
                />
                <v-text-field
                  v-model="formData.OfficeName"
                  label="Office Name"
                  :error-messages="errorMessages(vuelidate.formData.OfficeName)"
                  @blur="vuelidate.formData.OfficeName.$touch()"
                  dense
                />
              </v-card-text>
            </v-card>

            <addressEditor
              :name="formData.Address.Name"
              @updateName="updateAddressName"
              :street="formData.Address.Street"
              @updateStreet="updateAddressStreet"
              :city="formData.Address.City"
              @updateCity="updateAddressCity"
              :state="formData.Address.State"
              @updateState="updateAddressState"
              :postCode="formData.Address.PostCode"
              @updatePostCode="updateAddressPostCode"
            />

            <v-card class="mt-2">
              <v-card-text>
                <v-text-field
                  v-model="formData.AdminEmailAddress"
                  label="Admin Email Address"
                  :error-messages="
                    errorMessages(vuelidate.formData.AdminEmailAddress)
                  "
                  @blur="vuelidate.formData.AdminEmailAddress.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.EmailFromAddress"
                  label="Show Email From As"
                  :error-messages="
                    errorMessages(vuelidate.formData.EmailFromAddress)
                  "
                  @blur="vuelidate.formData.EmailFromAddress.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.OnlinePaymentSummaryEmailAddress"
                  label="Send Online Payment Summary Email To"
                  :error-messages="
                    errorMessages(
                      vuelidate.formData.OnlinePaymentSummaryEmailAddress
                    )
                  "
                  @blur="
                    vuelidate.formData.OnlinePaymentSummaryEmailAddress.$touch()
                  "
                  dense
                />
                <v-select
                  v-model="formData.TimeZone"
                  label="Time Zone"
                  :items="selectLists.TimeZones"
                  item-text="Text"
                  item-value="Value"
                  :error-messages="errorMessages(vuelidate.formData.TimeZone)"
                  @blur="vuelidate.formData.TimeZone.$touch()"
                  dense
                />
                <v-checkbox
                  v-model="formData.IsAutoCloseServicesAtEndOfDay"
                  label="Auto close services at End of Day"
                  dense
                />
                <v-select
                  v-model="formData.CustomerAgreementDocumentId"
                  label="Customer Agreement Document"
                  :items="selectLists.CustomerAgreementDocuments"
                  item-text="Text"
                  item-value="Value"
                  clearable
                  dense
                />
                <v-checkbox
                  v-model="formData.ClearAllCustomerAgreements"
                  label="Clear all customer agreements"
                  dense
                /> </v-card-text
            ></v-card>
          </div>
          <div>
            <v-card class="ml-3" width="600px">
              <v-card-title>
                <div class="text-subtitle-1 blue--text">
                  Phone Numbers
                </div>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  dense
                  :headers="phoneHeaderList"
                  :items="phoneDataList"
                  class="elevation-1"
                >
                  <template v-slot:item.actions="{ item, index }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editPhoneNumberClick(item)"
                    >
                      {{ icon.lineItemEdit }}
                    </v-icon>
                    <v-icon small @click="deletePhoneNumberClick(item, index)">
                      {{ icon.lineItemDelete }}
                    </v-icon>
                  </template>
                  <template v-slot:footer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          absolute
                          class="mt-3 ml-2"
                          @click="addPhoneNumberClick"
                        >
                          <v-icon>{{ icon.lineItemAdd }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Add Phone Number</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card class="ml-3 mt-3">
              <v-card-title>Documents</v-card-title>
              <v-card-text>
                <DocumentList />
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="ml-1" @click="saveClick">Update</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        <v-btn color="primary" class="ml-3" @click="subscriptionClick">
          Subscription
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showPhoneNumberEditorDialog" max-width="400" persistent>
      <PhoneNumberEditor
        :isDialogOpen="showPhoneNumberEditorDialog"
        :phoneNumberInfo="selectedPhoneNumberItem"
        @close="phoneNumberEditorClose"
        @update="phoneNumberEditorUpdate"
      />
    </v-dialog>

    <v-dialog
      v-model="showSubscriptionEditorDialog"
      :width="feature.subscribe.dialogWidth"
    >
      <SubscriptionEditor
        :isDialogOpen="showSubscriptionEditorDialog"
        @close="subscriptionEditorCloseEvent"
        @update="subscriptionEditorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { icon, feature } from '@/use/Constants'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { userData } from '@/store/UserSession'
import AddressEditor from '@/components/Common/AddressEditor'
import DocumentList from '@/components/Common/DocumentList'
import PhoneNumberEditor from '@/components/Common/PhoneNumberEditor'
import SubscriptionEditor from '@/components/Setup/Office/SubscriptionEditor'
export default {
  name: 'OfficeInfoEditor',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddressEditor,
    PhoneNumberEditor,
    DocumentList,
    SubscriptionEditor
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const formData = ref()
    const selectLists = ref()
    const selectedPhoneNumberIndex = ref(-1)
    const selectedPhoneNumberItem = ref()
    const showPhoneNumberEditorDialog = ref(false)
    const showSubscriptionEditorDialog = ref(false)

    const initDialog = () => {
      vuelidate.value.$reset()
      retrieveOfficeInfo()
      loadSelectLists()
    }

    const phoneHeaderList = [
      { value: 'PhoneNumberType', text: 'Type' },
      { value: 'AreaCode', text: 'Area Code' },
      { value: 'PhoneNumber', text: 'Number' },
      { value: 'Extension', text: 'Ext' },
      { value: 'Description', text: 'Description' },
      { value: 'actions', text: 'Actions', sortable: false }
    ]

    const phoneDataList = computed(() => {
      return formData.value.PhoneList.map(item => {
        return { ...item, actions: null }
      })
    })

    const rules = {
      formData: {
        OfficeName: { required },
        AdminEmailAddress: { email },
        EmailFromAddress: { email },
        OnlinePaymentSummaryEmailAddress: { email },
        TimeZone: { required },
        Address: {
          Street: { required }
        }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    async function retrieveOfficeInfo() {
      const rq = {
        InfoId: userData.value.OfficeId
      }
      const rs = await host.office.retrieve(rq)
      formData.value = rs.Info
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.TimeZones,
        selectListCache.name.CustomerAgreementDocuments
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }
      const result = await update()
      afterSaveOrUpdate(result)
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.office.update(rq)
      return rs
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = async result => {
      if (result.IsSuccess) {
        context.emit('close')
      } else {
        await dialogUtil.value.error(result.Message)
      }
    }

    const addPhoneNumberClick = () => {
      selectedPhoneNumberIndex.value = -1
      selectedPhoneNumberItem.value = {
        Id: null,
        PhoneNumberType: 'Primary',
        SortOrder: 0
      }
      showPhoneNumberEditorDialog.value = true
    }

    const editPhoneNumberClick = item => {
      selectedPhoneNumberIndex.value = formData.value.PhoneList.indexOf(item)
      selectedPhoneNumberItem.value = item
      showPhoneNumberEditorDialog.value = true
    }

    const deletePhoneNumberClick = (item, index) => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete phone number ' + item.PhoneNumber + '. Are you sure?'
        })
        .then(() => deletePhoneNumber(item, index))
        .catch(() => {
          return
        })
    }

    const deletePhoneNumber = (item, index) => {
      if (item.Id !== null) {
        formData.value.DeletedPhoneList.push(item.Id)
      }
      formData.value.PhoneList.splice(index, 1)
    }

    const phoneNumberEditorClose = () => {
      showPhoneNumberEditorDialog.value = false
    }

    const phoneNumberEditorUpdate = newVal => {
      if (selectedPhoneNumberIndex.value > -1) {
        Object.assign(
          formData.value.PhoneList[selectedPhoneNumberIndex],
          newVal
        )
      } else {
        formData.value.PhoneList.push(newVal)
      }
      showPhoneNumberEditorDialog.value = false
    }

    const subscriptionClick = () => {
      showSubscriptionEditorDialog.value = true
      context.emit('close')
    }

    const subscriptionEditorCloseEvent = () => {
      showSubscriptionEditorDialog.value = false
    }

    const subscriptionEditorUpdateEvent = () => {
      showSubscriptionEditorDialog.value = false
    }

    const updateAddressName = event => {
      formData.value.Address.Name = event
    }

    const updateAddressStreet = event => {
      formData.value.Address.Street = event
    }

    const updateAddressCity = event => {
      formData.value.Address.City = event
    }

    const updateAddressState = event => {
      formData.value.Address.State = event
    }

    const updateAddressPostCode = event => {
      formData.value.Address.PostCode = event
    }

    initDialog()
    return {
      dialogUtil,
      formData,
      selectLists,
      phoneHeaderList,
      phoneDataList,
      showPhoneNumberEditorDialog,
      selectedPhoneNumberItem,
      addPhoneNumberClick,
      editPhoneNumberClick,
      deletePhoneNumberClick,
      phoneNumberEditorClose,
      phoneNumberEditorUpdate,
      subscriptionClick,
      showSubscriptionEditorDialog,
      subscriptionEditorCloseEvent,
      subscriptionEditorUpdateEvent,
      saveClick,
      cancelClick,
      icon,
      feature,
      updateAddressName,
      updateAddressStreet,
      updateAddressCity,
      updateAddressState,
      updateAddressPostCode,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
