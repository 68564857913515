<template>
  <div>
    <div v-if="frequencyInfo">
      <v-card>
        <v-card-title>
          <DialogTitle :dialogName="$options.name">
            <span>Reassign Recurring Services</span>
          </DialogTitle>
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <div>
              <v-card width="450">
                <v-card-title>
                  <div class="text-subtitle-1 blue--text">
                    Recurring Services by Service Tech
                  </div>
                </v-card-title>
                <v-card-text>
                  <div style="height: 600px; overflow-y: scroll;">
                    <v-treeview
                      v-model="selectedRecurringServiceIdList"
                      :items="treeItems"
                      :load-children="retrieveTreeChildren"
                      transition
                      selectable
                      dense
                    >
                      <template v-slot:prepend="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="item.level == 0"
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              elevation="4"
                              @click="setAssignToUserIdClick(item)"
                            >
                              <v-icon :color="userColor(item)">
                                {{ icon.user }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Reassign to this user</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="item.level == 1"
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              elevation="4"
                              @click="setFrequencyInfoClick(item)"
                            >
                              <v-icon :color="feature.recurringServices.color">
                                {{ icon.recurringService }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Reassign to this frequency</span>
                        </v-tooltip>
                        <v-icon
                          v-if="item.level == 2"
                          :color="feature.recurringServices.color"
                        >
                          {{ serviceTypeIcon(item) }}
                        </v-icon>
                      </template>
                    </v-treeview>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div class="ml-3">
              <v-card width="400">
                <v-card-title>
                  <div class="text-subtitle-1 blue--text">
                    Reassign Info
                  </div>
                </v-card-title>
                <v-card-text>
                  <UserPicker
                    v-model="assignToUserId"
                    label="Assign To"
                    clearable
                  />
                  <RecurringRulesPicker v-model="frequencyInfo" noEndDate />
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="isSaveButtonDisabled"
            color="primary"
            @click="applyButtonClick"
          >
            <span>Apply Changes</span>
            <v-icon right>{{ icon.save }}</v-icon>
          </v-btn>
          <v-btn color="error" @click="cancelClick">
            <span>Back</span>
            <v-icon right>{{ icon.exit }}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { host } from '@/services/HostAPI'
import RecurringRulesPicker from '@/components/controls/RecurringRulesPicker'
import { selectListCache } from '@/services/SelectListCache'
import { icon, feature } from '@/use/Constants'
import { todayISOString } from '@/use/DateFormatter'

export default {
  name: 'RecurringServiceReassign',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RecurringRulesPicker
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const selectLists = ref()
    const frequencyInfo = ref()
    const treeItems = ref([])
    const assignToUserId = ref()
    const selectedRecurringServiceIdList = ref([])

    const initTreeItems = () => {
      treeItems.value = selectLists.value.Users.map(item => {
        return {
          id: item.Value,
          name: item.Text,
          children: [],
          level: 0
        }
      })
    }

    const initDialog = async () => {
      await loadSelectLists()
      initTreeItems()
      frequencyInfo.value = {
        InitialDate: todayISOString(),
        EndDate: null,
        AutoExtend: false,
        RecurPeriod: 'W',
        RecurOption: false,
        Interval1: 0,
        Interval2: 0,
        Interval3: 0
      }
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.Users,
        selectListCache.name.ServiceTypes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const retrieveTreeChildren = async item => {
      const rs = await host.recurringService.listForUserByPattern({
        Criteria: item.id
      })
      const treeItem = rs.InfoList.map(pattern => {
        return {
          id: pattern.Description,
          name: pattern.Description,
          level: 1,
          frequencyInfo: pattern.FrequencyInfo,
          children: pattern.InfoList.map(item => {
            return {
              id: item.RecurringServiceId,
              name:
                item.SiteName +
                ' / ' +
                selectListCache.attribute(
                  'ServiceTypes',
                  item.ServiceTypeId,
                  'Text'
                ),
              serviceTypeId: item.ServiceTypeId,
              level: 2
            }
          })
        }
      })
      item.children.push(...treeItem)
    }

    const userColor = item => {
      return selectListCache.attribute('Users', item.id, 'Color')
    }

    const serviceTypeIcon = item => {
      return selectListCache.attribute(
        'ServiceTypes',
        item.serviceTypeId,
        'IconName'
      )
    }

    const isSaveButtonDisabled = computed(() => {
      return !(
        assignToUserId.value && selectedRecurringServiceIdList.value.length > 0
      )
    })

    const applyButtonClick = async () => {
      const rq = {
        AssignToUserId: assignToUserId.value,
        FrequencyInfo: frequencyInfo.value,
        RecuringServiceIdList: selectedRecurringServiceIdList.value
      }

      const rs = await host.recurringService.reassign(rq)
      if (rs.IsSuccess) {
        dialogUtil.value.inform(rs.Message)
        initDialog()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const setAssignToUserIdClick = item => {
      assignToUserId.value = item.id
    }

    const setFrequencyInfoClick = item => {
      frequencyInfo.value = item.frequencyInfo
    }

    initDialog()
    return {
      dialogUtil,
      isSaveButtonDisabled,
      assignToUserId,
      icon,
      feature,
      frequencyInfo,
      selectedRecurringServiceIdList,
      cancelClick,
      treeItems,
      retrieveTreeChildren,
      userColor,
      serviceTypeIcon,
      applyButtonClick,
      setAssignToUserIdClick,
      setFrequencyInfoClick
    }
  }
}
</script>

<style lang="scss" scoped></style>
