<template>
  <div style="max-height: 600px; overflow-y: scroll;">
    <v-card
      v-for="photo in photoList"
      :key="photo.PhotoId"
      class="ml-3 mb-3 pa-2"
      height="250"
      width="250"
    >
      <div class="d-flex">
        <v-btn icon class="mr-1" small @click="editClick(photo.PhotoId)">
          <v-icon color="primary">{{ icon.photoEdit }}</v-icon>
        </v-btn>
        <div>
          <div class="caption">{{ photo.Title }}</div>
          <div class="caption">
            {{ toLocaleDateString(photo.WhenTaken) }}
          </div>
        </div>
      </div>
      <a target="_blank" :href="createPhotoUrlFor(photo.URI)">
        <img
          :src="createPhotoUrlFor(photo.URI)"
          class="thumbnail"
          :alt="photo.Title"
        />
      </a>
      <div>{{ photo.Description }}</div>
    </v-card>

    <v-card class="ml-3 mb-3 pa-2 fluid" height="75" width="250">
      <v-btn class="ml-2 mt-2" rounded color="primary" @click="uploadClick">
        <v-icon>{{ icon.lineItemAdd }}</v-icon>
        Upload new photo
      </v-btn>
    </v-card>

    <v-dialog v-model="showDialog" width="400">
      <PhotoEditor
        :isDialogOpen="showDialog"
        :photoId="selectedPhotoId"
        :siteId="siteId"
        :scheduledServiceId="scheduledServiceId"
        @close="photoEditorClose"
        @update="photoEditorUpdate"
      />
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import FieldService from '@/services/FieldService'
import { host } from '@/services/HostAPI'
import { toLocaleDateString } from '@/use/DateFormatter'
import PhotoEditor from '@/components/Common/PhotoEditor'
export default {
  name: 'PhotoList',
  props: {
    siteId: {
      type: String,
      required: true
    },
    scheduledServiceId: {
      type: String,
      default: null
    }
  },
  components: {
    PhotoEditor
  },
  setup(props) {
    const initDialog = () => {
      fetchData()
    }

    const photoList = ref()
    const selectedPhotoId = ref()
    const showDialog = ref(false)

    watch(
      () => [props.siteId, props.scheduledServiceId],
      () => {
        initDialog()
      }
    )

    const fetchData = async () => {
      const rq = {
        Criteria: {
          SiteId: props.siteId,
          ScheduledServiceId: props.scheduledServiceId
        }
      }
      const rs = await host.photo.list(rq)
      photoList.value = rs.InfoList
    }

    const createPhotoUrlFor = uri => {
      return `${
        FieldService.apiURL
      }${uri}&Tenant-Id=${FieldService.getTenantId()}`
    }

    const editClick = id => {
      selectedPhotoId.value = id
      showDialog.value = true
    }

    const photoEditorClose = () => {
      showDialog.value = false
    }

    const photoEditorUpdate = () => {
      showDialog.value = false
      fetchData()
    }

    const uploadClick = () => {
      selectedPhotoId.value = null
      showDialog.value = true
    }

    initDialog()

    return {
      photoList,
      toLocaleDateString,
      createPhotoUrlFor,
      editClick,
      uploadClick,
      showDialog,
      selectedPhotoId,
      photoEditorClose,
      photoEditorUpdate,
      icon
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px; /* Rounded border */
  padding: 5px; /* Some padding */
  height: 150px;
  width: 200px;
}

/* Add a hover effect (blue shadow) */
.thumbnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
</style>
