<template>
  <div>
    <v-card width="400px">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Batch Billing
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <DatePicker
          v-model="formData.BusinessDate"
          label="Business Date"
          :error-messages="errorMessages(vuelidate.formData.BusinessDate)"
          @blur="vuelidate.formData.BusinessDate.$touch()"
          dense
        ></DatePicker>
        <v-select
          v-model="formData.BillingGroupId"
          label="Billing Group"
          :items="selectLists.BillingGroups"
          item-text="Text"
          item-value="Value"
          :error-messages="errorMessages(vuelidate.formData.BillingGroupId)"
          @blur="vuelidate.formData.BillingGroupId.$touch()"
          dense
        ></v-select>
        <v-text-field
          v-model="formData.Reference"
          label="Reference"
          :error-messages="errorMessages(vuelidate.formData.Reference)"
          @blur="vuelidate.formData.Reference.$touch()"
          dense
        ></v-text-field>
      </v-card-text>
      <v-card-actions
        ><v-btn
          color="success"
          class="ml-3"
          :disabled="isPostDisabled"
          @click="postClick"
          >Post</v-btn
        ><v-btn color="error" class="ml-3" @click="cancelClick"
          >Cancel</v-btn
        ></v-card-actions
      >
    </v-card>
    <DialogUtil ref="dialogUtil" />
    <WaitDialog
      v-model="showWaitDialog"
      :title="waitDialogTitle"
      :subtitle="waitDialogSubtitle"
    />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { host } from '@/services/HostAPI'
import WaitDialog from '@/components/Common/WaitDialog'
import { selectListCache } from '@/services/SelectListCache'
import { parseISO, format } from 'date-fns'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'PostBillingBatch',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { WaitDialog },
  setup(props, context) {
    const dialogUtil = ref(null)
    const showWaitDialog = ref(false)
    const isPostDisabled = ref(false)
    const waitDialogTitle = ref('Posting Monthly Charges')

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      isPostDisabled.value = false
      showWaitDialog.value = false
      const now = new Date()
      formData.value.BusinessDate = now.toISOString().substring(0, 10)
      loadBillingGroups()
    }

    const formData = ref({
      BusinessDate: null,
      BillingGroupId: null,
      Reference: null
    })

    const waitDialogSubtitle = computed(() => formData.value.Reference)

    const selectLists = ref([])

    watch(
      () => formData.value.BusinessDate,
      newVal => {
        formData.value.Reference = format(parseISO(newVal), 'MMMM') + ' Billing'
      }
    )

    const loadBillingGroups = async () => {
      const selectListNames = [selectListCache.name.BillingGroups]
      selectLists.value = await selectListCache.get(selectListNames)
    }

    const rules = {
      formData: {
        BusinessDate: { required },
        BillingGroupId: { required },
        Reference: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    function errorMessages(item) {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const postClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      isPostDisabled.value = true
      showWaitDialog.value = true

      const rq = { Info: formData.value }
      const rs = await host.billing.postBillingBatch(rq)

      showWaitDialog.value = false

      if (rs.IsSuccess === true) {
        context.emit('update')
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    initDialog()

    return {
      dialogUtil,
      formData,
      selectLists,
      waitDialogTitle,
      waitDialogSubtitle,
      isPostDisabled,
      cancelClick,
      postClick,
      showWaitDialog,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
