<template>
  <div>
    <v-card v-if="formData" width="400px" class="indigo lighten-5">
      <v-card-title>Online Payment</v-card-title>
      <v-card-text>
        <v-card>
          <v-card-text
            ><div>{{ formData.CustomerName }}</div>
            <div>Posted: {{ toLocaleDateTimeString(formData.WhenPosted) }}</div>
            <div>Amount: {{ toCurrency(formData.Amount) }}</div>
            <div>Last Four: {{ formData.LastFour }}</div>
            <div>Reference: {{ formData.Reference }}</div>
          </v-card-text>
        </v-card>
        <div
          v-if="errorMessage"
          class="mt-3 ml-1 red--text text-subtitle-1 font-weight-bold"
        >
          {{ errorMessage }}
        </div>
      </v-card-text>
      <v-card-actions>
        <div v-if="!errorMessage && formData.Amount != 0">
          <v-btn
            v-if="formData.IsVoidable"
            color="error"
            class="ml-3"
            @click="voidPaymentClick"
          >
            <span>Void</span>
            <v-icon right>{{ icon.onlinePaymentVoid }}</v-icon>
          </v-btn>
          <v-btn v-else color="error" class="ml-3" @click="refundPaymentClick">
            <span>Refund</span>
            <v-icon right>{{ icon.onlinePaymentRefund }}</v-icon>
          </v-btn>
        </div>
        <v-btn color="secondary" class="ml-3" @click="cancelClick">
          <span>Back</span>
          <v-icon right>{{ icon.exit }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
import { toCurrency } from '@/use/NumberFormatter'

export default {
  name: 'OnlinePaymentModify',
  props: {
    transactionId: {
      type: String,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },

  setup(props, context) {
    const dialogUtil = ref(null)

    const formData = ref()
    const errorMessage = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      errorMessage.value = null
      getOnlinePaymentInfo()
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const refundPaymentClick = () => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Refund this payment.`
        })
        .then(() => performRefundPayment())
        .catch(() => {
          return
        })
    }

    const performRefundPayment = async () => {
      const rq = { Info: props.transactionId }
      const rs = await host.onlinePayment.refund(rq)
      if (rs.IsSuccess) {
        context.emit('update')
      } else {
        errorMessage.value = rs.Message
      }
    }

    const voidPaymentClick = () => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Void this payment.`
        })
        .then(() => performVoidPayment())
        .catch(() => {
          return
        })
    }

    const performVoidPayment = async () => {
      const rq = { Info: props.transactionId }
      const rs = await host.onlinePayment.void(rq)
      if (rs.IsSuccess) {
        context.emit('update')
      } else {
        errorMessage.value = rs.Message
      }
    }

    const getOnlinePaymentInfo = async () => {
      const rq = { InfoId: props.transactionId }
      const rs = await host.onlinePayment.retrieveTransactionInfo(rq)
      formData.value = rs.Info
    }

    initDialog()

    return {
      dialogUtil,
      formData,
      refundPaymentClick,
      voidPaymentClick,
      cancelClick,
      errorMessage,
      toCurrency,
      toLocaleDateTimeString,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
