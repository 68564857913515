<template>
  <v-select
    v-if="selectLists && selectLists.ScheduledServiceSeasons.length > 0"
    v-bind="$attrs"
    :value="value"
    @input="emit1('input', $event)"
    :items="selectLists.ScheduledServiceSeasons"
    item-text="Text"
    item-value="Value"
  >
    <template v-slot:item="{ item }">
      <v-tooltip top>
        <template #activator="{ on }">
          <div v-on="on" class="d-flex">
            <v-icon small :color="item.Color">{{ item.IconName }}</v-icon>
            <div class="ml-1">
              {{ item.Text }}
            </div>
          </div>
        </template>
        <div>Starts {{ item.Starts }}</div>
        <div>Ends {{ item.Ends }}</div>
      </v-tooltip>
    </template>
    <template v-slot:selection="{ item }">
      <v-tooltip top>
        <template #activator="{ on }">
          <div v-on="on" class="d-flex">
            <v-icon small :color="item.Color">{{ item.IconName }}</v-icon>
            <div class="ml-1">
              {{ item.Text }}
            </div>
          </div>
        </template>
        <div>Starts {{ item.Starts }}</div>
        <div>Ends {{ item.Ends }}</div>
      </v-tooltip>
    </template>
  </v-select>
</template>

<script>
import { ref } from 'vue'
import { selectListCache } from '@/services/SelectListCache'
export default {
  name: 'ServiceSeasonPicker',
  props: {
    value: {
      type: String,
      default: null
    }
  },
  setup(props, context) {
    const selectLists = ref()

    const initControl = async () => {
      await loadSelectLists()
    }

    const emit1 = (eventName, eventValue) => {
      context.emit(eventName, eventValue)
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.ServiceSeasons]
      selectLists.value = await selectListCache.get(selectListNames)
    }

    initControl()
    return { selectLists, emit1 }
  }
}
</script>

<style lang="scss" scoped></style>
