<template>
  <div>
    <v-card width="380">
      <v-card-title class="text-subtitle-1 blue--text">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="formData">
        <v-radio-group v-model="formData.AddressType" dense>
          <v-radio label="Office" value="Office" />
          <v-radio label="Service Tech Home" value="User" />
          <v-radio label="Site" value="Site" />
          <v-radio label="Address" value="Address" />
        </v-radio-group>
        <div v-if="formData.AddressType == 'Office' && officeAddress">
          <v-icon color="primary">{{ icon.office }}</v-icon>
          {{ officeAddress.Street }}, {{ officeAddress.City }}
          {{ officeAddress.State }}
        </div>
        <UserPicker
          v-if="formData.AddressType == 'User'"
          v-model="formData.UserId"
          label="Service Tech"
        />
        <v-select
          v-if="formData.AddressType == 'Site'"
          v-model="formData.SiteId"
          label="Site"
          :items="serviceItemList"
          item-text="SiteDescription"
          item-value="SiteId"
          dense
        >
          <template v-slot:item="{ item }">
            <v-icon small :color="feature.sites.color">{{ icon.site }}</v-icon>
            <div class="ml-1">{{ item.SiteDescription }}</div>
            <div>--{{ item.Address.Street }}</div>
          </template>
          <template v-slot:selection="{ item }">
            <v-icon small :color="feature.sites.color">{{ icon.site }}</v-icon>
            <div class="ml-1">{{ item.SiteDescription }}</div>
            <div>--{{ item.Address.Street }}</div>
          </template>
        </v-select>
        <AddressEditor
          v-if="formData.AddressType == 'Address'"
          omitName
          :street="formData.Address.Street"
          @updateStreet="updateAddressStreet"
          :city="formData.Address.City"
          @updateCity="updateAddressCity"
          :state="formData.Address.State"
          @updateState="updateAddressState"
          :postCode="formData.Address.PostCode"
          @updatePostCode="updateAddressPostCode"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon, feature } from '@/use/Constants'
import AddressEditor from '@/components/Common/AddressEditor'
export default {
  name: 'RouteOptimizeEndpointEditor',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Endpoint'
    },
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    officeAddress: {
      type: Object,
      default: () => {
        return { Street: '', City: '', State: '' }
      }
    },
    serviceItemList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: { AddressEditor },
  setup(props, context) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      formData.value = { ...props.value }
    }

    const formData = ref()

    watch(
      () => formData.value,
      () => {
        context.emit('update', formData.value)
      },
      { deep: true }
    )

    const updateAddressStreet = event => {
      formData.value.Address.Street = event
    }

    const updateAddressCity = event => {
      formData.value.Address.City = event
    }

    const updateAddressState = event => {
      formData.value.Address.State = event
    }

    const updateAddressPostCode = event => {
      formData.value.Address.PostCode = event
    }

    formData.value = props.value

    return {
      formData,
      updateAddressStreet,
      updateAddressCity,
      updateAddressState,
      updateAddressPostCode,
      icon,
      feature
    }
  }
}
</script>

<style lang="scss" scoped></style>
