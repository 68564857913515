<template>
  <div>
    <SyncWithConnectQuickBooksDesktop
      v-if="isQbDesktop"
      :infoId="infoId"
      :isDialogOpen="isDialogOpen"
    />
    <SyncWithConnectQuickBooksOnline
      v-if="isQbOnline"
      :infoId="infoId"
      :interfaceType="interfaceType"
      :isDialogOpen="isDialogOpen"
    />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import SyncWithConnectQuickBooksDesktop from './SyncWithConnectQuickBooksDesktop.vue'
import SyncWithConnectQuickBooksOnline from './SyncWithConnectQuickBooksOnline.vue'
export default {
  name: 'SyncWithConnect',
  components: {
    SyncWithConnectQuickBooksDesktop,
    SyncWithConnectQuickBooksOnline
  },
  props: {
    infoId: {
      type: String,
      default: null
    },
    interfaceType: {
      type: String,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const isQbDesktop = computed(() => props.interfaceType == 'Quickbooks')
    const isQbOnline = computed(
      () =>
        props.interfaceType == 'QuickBooksOnline' ||
        props.interfaceType == 'QuickBooksOnlineSandbox'
    )

    const test = ref('test')

    const initDialog = async () => {}

    initDialog()

    return {
      test,
      isQbDesktop,
      isQbOnline
    }
  }
}
</script>

<style lang="scss" scoped></style>
