<template>
  <v-card flat width="1000" height="414">
    <v-toolbar flat>
      <v-radio-group v-model="mySearchType" row @change="searchTypeChange">
        <v-radio label="Site Name" value="N"></v-radio>
        <v-radio label="Customer Code" value="C"></v-radio>
        <v-radio label="Customer Name" value="A"></v-radio>
        <v-radio label="Route Code" value="R"></v-radio>
      </v-radio-group>
      <v-text-field
        v-model="mySearchText"
        label="Search Text"
        :prepend-icon="icon.search"
        class="mt-5"
        @blur="fetchData"
      />
      <v-spacer></v-spacer>
      <v-btn small class="ma-1" color="info" @click="fetchData">Search</v-btn>
    </v-toolbar>
    <v-data-table
      dense
      :headers="headers"
      :items-per-page="10"
      :items="dataTable.LineItemList"
      :options.sync="options"
      :sort-by.sync="options.sortBy"
      :sort-desc.sync="options.sortDesc"
      :server-items-length="dataTable.TotalRecords"
      :loading="loading"
      @click:row="selectRow"
      class="elevation-1"
    >
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'
export default {
  name: 'SiteSearch',
  props: {
    searchText: {
      type: String,
      default: ''
    },
    searchType: {
      type: String,
      default: 'N'
    }
  },
  setup(props, context) {
    const mySearchText = ref('')
    const mySearchType = ref('')

    const loading = ref(false)
    const options = ref({})

    const headers = [
      { value: 'SiteName', text: 'Site Name' },
      { value: 'CustomerCode', text: 'Code' },
      { value: 'CustomerName', text: 'Customer Name' },
      { value: 'Status', text: 'Status' },
      { value: 'Street', text: 'Address' },
      { value: 'Phone', text: 'Phone' }
    ]

    const dataTable = ref({})

    watch(
      () => props.searchText,
      newVal => {
        mySearchText.value = newVal
      },
      { immediate: true }
    )

    watch(
      () => props.searchType,
      newVal => {
        mySearchType.value = newVal
      },
      { immediate: true }
    )

    watch(
      () => options.value,
      () => {
        fetchData()
      },
      { deep: true }
    )

    const fetchData = async () => {
      if (mySearchText.value === '') {
        return
      }
      loading.value = true
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      const rq = {
        SearchText: mySearchText.value,
        SearchType: props.searchType,
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy.length > 0 ? sortBy[0] : '',
          IsDescending: sortDesc.length > 0 ? sortDesc[0] : false
        }
      }

      const rs = await host.site.keywordSearch(rq)
      dataTable.value = rs
      loading.value = false
    }

    const selectRow = event => {
      context.emit('select', event)
    }

    const searchTypeChange = event => {
      mySearchType.value = event
      mySearchText.value = ''
    }

    return {
      mySearchText,
      mySearchType,
      searchTypeChange,
      loading,
      options,
      headers,
      dataTable,
      fetchData,
      selectRow,
      icon
    }
  }
}
</script>
