<template>
  <div class="d-flex rounded-lg white mb-2 pb-0">
    <div style="width: 140px;">
      <v-text-field
        class="mt-2 ml-2"
        v-model="textValue"
        :label="label"
        type="number"
        filled
        rounded
        dense
        @blur="updateSliderValue"
      />
    </div>
    <div style="width: 80px;">
      <div class="ml-3 mt-2 caption">Billable</div>
      <v-simple-checkbox
        class="ml-5"
        label="Billable"
        dense
        v-model="isBillable"
      />
    </div>
    <div class="flex-grow-1 mt-4 ml-3">
      <v-slider
        v-model="sliderValue"
        min="0"
        :max="max"
        :step="step"
        dense
        @end="updateValue"
      >
        <template v-slot:prepend>
          <v-icon @click="decrement">
            {{ icon.sliderDecrease }}
          </v-icon>
        </template>
        <template v-slot:append>
          <v-icon @click="increment">
            {{ icon.sliderIncrease }}
          </v-icon>
        </template></v-slider
      >
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
export default {
  name: 'TreatmentPicker',
  props: {
    value: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    label: {
      type: String,
      default: 'Value'
    },
    caption: {
      type: String,
      default: 'Unit'
    },
    billable: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const sliderValue = ref(0)
    const textValue = ref(0)
    const isBillable = ref(false)

    watch(
      () => props.value,
      newVal => {
        sliderValue.value = newVal
        textValue.value = newVal
      },
      { immediate: true }
    )

    watch(
      () => sliderValue.value,
      newVal => {
        textValue.value = newVal
        context.emit('input', newVal)
      }
    )

    watch(
      () => props.billable,
      newVal => {
        isBillable.value = newVal
      },
      { immediate: true }
    )

    watch(
      () => isBillable.value,
      newVal => {
        context.emit('billable-input', newVal)
      }
    )

    const updateValue = event => {
      context.emit('input', event)
    }

    const updateSliderValue = event => {
      sliderValue.value = parseFloat(event.target.value)
    }

    const decrement = () => {
      sliderValue.value -= props.step
    }

    const increment = () => {
      sliderValue.value += props.step
    }

    return {
      sliderValue,
      textValue,
      isBillable,
      updateValue,
      updateSliderValue,
      decrement,
      increment,
      icon
    }
  }
}
</script>

<style lang="scss" scoped>
.narrow {
  width: 160px;
}
</style>
