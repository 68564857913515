<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on: onTooltip }">
        <v-btn icon v-on="{ ...onTooltip }" @click="activityLogClick">
          <v-icon>{{ icon.activityLog }}</v-icon>
        </v-btn>
      </template>
      <span>Activity Log</span>
    </v-tooltip>

    <v-dialog v-model="showActivityLogDialog" width="1100">
      <v-card>
        <v-card-title class="subtitle-1">
          <span>Activity Log</span>
          <span v-if="description" class="ml-1"> for {{ description }} </span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-if="selectLists"
            :headers="headers"
            :items="infoList"
            dense
            :loading="loading"
          >
            <template v-slot:item.WhenCreated="{ item }">
              {{ toLocaleDateTimeString(item.WhenCreated) }}
            </template>
            <template v-slot:item.UserId="{ item }">
              <v-icon :color="userColor(item.UserId)">
                {{ icon.user }}
              </v-icon>
              <span class="ml-1">
                {{ userName(item.UserId) }}
              </span>
            </template>
            <template v-slot:item.ActivityType="{ item }">
              {{ getActivityTypeDescription(item.ActivityType) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="item.ActivityType === 'M'">
                <v-icon small class="mr-2" @click="editItemClick(item)">
                  {{ icon.lineItemEdit }}
                </v-icon>
                <v-icon small @click="deleteItemClick(item)">
                  {{ icon.lineItemDelete }}
                </v-icon>
              </div>
            </template>
          </v-data-table>
          <div
            v-if="tableName != null"
            class="table-footer-prepend d-flex pl-2 align-center"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="addItemClick">
                  <v-icon>{{ icon.lineItemAdd }}</v-icon>
                </v-btn>
              </template>
              <span>Add Log Entry</span>
            </v-tooltip>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showActivityLogEditorDialog" max-width="500px">
      <ActivityLogEditor
        :isDialogOpen="showActivityLogEditorDialog"
        :activityLogId="selectedActivityLogId"
        :tableName="tableName"
        :recordId="recordId"
        :description="selectedActivityLogDescription"
        @close="activityLogEditorCloseEvent"
        @update="activityLogEditorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import ActivityLogEditor from '@/components/Common/ActivityLogEditor'
import { selectListCache } from '@/services/SelectListCache'
export default {
  name: 'ActivityLogButton',
  props: {
    description: {
      type: String,
      default: String
    },
    tableName: {
      type: String,
      default: null
    },
    recordId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { ActivityLogEditor },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const infoList = ref([])
    const showActivityLogDialog = ref(false)
    const selectLists = ref()

    const initDialog = () => {
      loadSelectLists()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.Users]
      selectLists.value = await selectListCache.get(selectListNames)
    }

    const activityLogClick = () => {
      const criteria = {
        UserId: null,
        TableName: props.tableName,
        RecordId: props.recordId,
        ParentTableName: null,
        ParentRecordId: null,
        WhenCreatedFirst: null,
        WhenCreatedLast: null
      }

      getActivityLogList(criteria)
      showActivityLogDialog.value = true
    }

    const getActivityLogList = async criteria => {
      loading.value = true
      const rq = {
        Criteria: criteria
      }
      const rs = await host.activityLog.list(rq)
      infoList.value = rs.InfoList
      loading.value = false
    }

    const headers = [
      { value: 'WhenCreated', text: 'Log Date/Time' },
      { value: 'UserId', text: 'User' },
      { value: 'ActivityType', text: 'Activity' },
      { value: 'Description', text: 'Description' },
      { value: 'actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const showActivityLogEditorDialog = ref(false)
    const selectedActivityLogId = ref()
    const selectedActivityLogDescription = ref()

    const addItemClick = () => {
      selectedActivityLogId.value = null
      selectedActivityLogDescription.value = null
      showActivityLogEditorDialog.value = true
    }

    const editItemClick = item => {
      selectedActivityLogId.value = item.ActivityLogId
      selectedActivityLogDescription.value = item.Description
      showActivityLogEditorDialog.value = true
    }

    const deleteItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete this log entry.`
        })
        .then(() => performDeleteLineItem(item))
        .catch(() => {
          return
        })
    }

    const performDeleteLineItem = async item => {
      const rq = { InfoId: item.ActivityLogId }
      const rs = await host.activityLog.delete(rq)
      if (rs.IsSuccess) {
        activityLogClick()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const activityLogEditorCloseEvent = () => {
      showActivityLogEditorDialog.value = false
    }

    const activityLogEditorUpdateEvent = () => {
      showActivityLogEditorDialog.value = false
      activityLogClick()
    }

    const activityTypeDescriptionDictionary = {
      C: 'Create',
      R: 'Info',
      U: 'Update',
      D: 'Delete',
      M: 'Manual'
    }

    const getActivityTypeDescription = activityType => {
      return Object.prototype.hasOwnProperty.call(
        activityTypeDescriptionDictionary,
        activityType
      )
        ? activityTypeDescriptionDictionary[activityType]
        : 'Unknown'
    }

    const userColor = id => {
      return selectListCache.attribute(selectListCache.name.Users, id, 'Color')
    }

    const userName = id => {
      const name = selectListCache.attribute(
        selectListCache.name.Users,
        id,
        'Text'
      )
      return name || '(System)'
    }

    initDialog()

    return {
      dialogUtil,
      headers,
      infoList,
      activityLogClick,
      showActivityLogDialog,
      getActivityTypeDescription,
      toLocaleDateTimeString,
      editItemClick,
      deleteItemClick,
      addItemClick,
      showActivityLogEditorDialog,
      selectedActivityLogId,
      selectedActivityLogDescription,
      activityLogEditorCloseEvent,
      activityLogEditorUpdateEvent,
      userColor,
      userName,
      icon,
      selectLists,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
