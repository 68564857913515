<template>
  <v-dialog v-model="value" max-width="300">
    <v-card width="300" height="260">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
      <v-layout justify-center>
        <v-progress-circular
          :value="spinnerValue"
          size="100"
          rotate="270"
          width="15"
          color="teal"
          class="mt-6"
        />
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'WaitDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Preparing'
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    var timer
    const spinnerValue = ref(0)

    const intervalFunction = () => {
      spinnerValue.value =
        spinnerValue.value === 100 ? 0 : spinnerValue.value + 10
    }

    watch(
      () => props.value,
      newVal => {
        if (newVal) {
          startCounter()
        } else {
          stopCounter()
        }
      }
    )

    const startCounter = () => {
      spinnerValue.value = 0
      timer = setInterval(intervalFunction, 1000)
    }

    const stopCounter = () => {
      clearInterval(timer)
    }

    return { spinnerValue }
  }
}
</script>

<style lang="scss" scoped></style>
