<template>
  <v-select v-model="color" v-bind="$attrs" :items="colorList" clearable>
    <template v-slot:item="{ item }">
      <v-chip x-small :color="item" />
      <div class="ml-1">
        {{ item }}
      </div>
    </template>
    <template v-slot:selection="{ item }">
      <v-chip x-small :color="item" />
      <div class="ml-1">
        {{ item }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'ColorPicker',
  props: {
    value: {
      type: String,
      default: null
    }
  },
  setup(props, context) {
    const color = ref()
    const colorList = [
      'red',
      'pink',
      'purple',
      'indigo',
      'blue',
      'cyan',
      'teal',
      'green',
      'lime',
      'yellow',
      'amber',
      'orange',
      'brown',
      'grey'
    ]

    watch(
      () => props.value,
      newVal => {
        color.value = newVal
      }
    )

    watch(
      () => color.value,
      newVal => {
        context.emit('input', newVal)
      }
    )

    const initControl = () => {
      color.value = props.value
    }

    initControl()

    return { colorList, color }
  }
}
</script>

<style lang="scss" scoped></style>
