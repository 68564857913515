<template>
  <div class="d-flex">
    <div>
      <v-icon class="ml-3 mt-1" medium :color="feature[featureName].color">{{
        feature[featureName].icon
      }}</v-icon>
    </div>
    <div class="mx-2 mt-1">
      {{ feature[featureName].singularTitle }}
    </div>
    <div class="mt-1">
      <v-icon small>{{ icon.pageTitleDelimiter }}</v-icon>
    </div>
    <div class="mx-2 mt-1">{{ functionName }}</div>
    <div v-if="target" class="mt-1">
      <v-icon small>{{ icon.pageTitleDelimiter }}</v-icon>
    </div>
    <div v-if="target" class="mx-2 mt-1">{{ target }}</div>
    <TutorialButton class="ml-auto" :tutorialName="pageName" />
  </div>
</template>

<script>
import { icon, feature } from '@/use/Constants'
import TutorialButton from '@/components/controls/TutorialButton'
export default {
  name: 'PageTitle',
  props: {
    featureName: {
      type: String,
      default: ''
    },
    functionName: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: ''
    },
    pageName: {
      type: String,
      default: ''
    }
  },
  components: {
    TutorialButton
  },
  setup() {
    return { icon, feature }
  }
}
</script>

<style lang="scss" scoped></style>
