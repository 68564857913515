<template>
  <v-navigation-drawer v-model="isNavDrawerOpen" app>
    <AddNewItemButton />
    <v-list nav dense>
      <v-list-item-group>
        <v-list-item
          v-for="item in drawerLaunchItems"
          :key="item"
          @click="navigate(item)"
        >
          <v-list-item-icon>
            <v-icon :color="feature[item].color">
              {{ feature[item].icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ feature[item].title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { watch, ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { feature } from '@/use/Constants'
import AddNewItemButton from '@/components/layout/AddNewItemButton'

export default {
  name: 'NavDrawer',
  components: {
    AddNewItemButton
  },
  props: {
    state: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const isNavDrawerOpen = ref(true)

    const router = useRouter()

    watch(
      () => props.state,
      () => {
        isNavDrawerOpen.value = !isNavDrawerOpen.value
      }
    )

    const navigate = item => {
      router.push({ name: feature[item].routerName })
    }

    const drawerLaunchItems = [
      'dashboard',
      'customers',
      'services',
      'tasks',
      'reports',
      'billing',
      'payments',
      'estimates',
      'commissions',
      'routes',
      'calendar'
    ]

    return { feature, isNavDrawerOpen, drawerLaunchItems, navigate }
  }
}
</script>

<style lang="scss" scoped></style>
