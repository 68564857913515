<template>
  <v-select
    :value="value"
    @input="emit('change', $event)"
    v-bind="$attrs"
    :items="dayOfWeekList"
  />
</template>

<script>
import { getDayOfWeekDesc } from '@/use/DateFormatter'
export default {
  name: 'DayOfWeekPicker',
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  setup(props, context) {
    const emit = (eventName, eventValue) => {
      context.emit(eventName, eventValue)
    }

    const dayOfWeekList = [...Array(7)].map(
      (key, value) =>
        new Object({ value: value + 1, text: getDayOfWeekDesc(value + 1) })
    )

    return { emit, dayOfWeekList }
  }
}
</script>

<style lang="scss" scoped></style>
