<template>
  <div>
    <v-card v-if="formData && selectLists" class="blue lighten-4">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div class="d-flex">
            <div class="subtitle-1">
              {{ isEditMode ? 'Edit' : 'New' }} Customer
              {{ formData.CustomerName }}
            </div>
            <v-spacer />
            <ApplicationLogButton
              v-if="isEditMode"
              :description="'Customer: ' + formData.CustomerName"
              tableName="Customer"
              :recordId="formData.CustomerId"
            />
          </div>
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <div class="d-flex blue lighten-4">
          <div>
            <v-card class="mt-2 ml-2" width="400px">
              <v-card-title class="d-flex justify-space-between">
                <div class="text-subtitle-1 blue--text">
                  Customer
                </div>
                <div class="d-flex text-subtitle-1">
                  <span :class="statusClass">{{ statusDescription }}</span>
                </div>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="formData.CustomerName"
                  label="Customer Name"
                  :error-messages="
                    errorMessages(vuelidate.formData.CustomerName)
                  "
                  @blur="vuelidate.formData.CustomerName.$touch()"
                  dense
                />
                <v-text-field
                  v-model="formData.CustomerCode"
                  label="Customer Code"
                  :error-messages="
                    errorMessages(vuelidate.formData.CustomerCode)
                  "
                  @blur="vuelidate.formData.CustomerCode.$touch()"
                  dense
                />
                <v-select
                  v-model="formData.CustomerTypeId"
                  :items="selectLists.CustomerTypes"
                  item-text="Text"
                  item-value="Value"
                  label="Customer Type"
                  clearable
                  dense
                />
                <v-select
                  v-model="formData.CustomerAlertId"
                  :items="selectLists.CustomerAlertTypes"
                  item-text="Text"
                  item-value="Value"
                  label="Alert"
                  clearable
                  class="text-input-alert"
                  dense
                />
                <v-text-field
                  v-model="formData.AlertText"
                  label="Alert Comments"
                  class="text-input-alert"
                  dense
                />
              </v-card-text>
            </v-card>

            <v-card class="mt-2 ml-2" width="400px">
              <v-card-text>
                <v-card-actions>
                  <v-btn color="success" @click="saveClick">
                    <span>Save</span>
                    <v-icon right>{{ icon.save }}</v-icon>
                  </v-btn>
                  <v-btn color="error" @click="cancelClick">
                    <span>Back</span>
                    <v-icon right>{{ icon.exit }}</v-icon>
                  </v-btn>
                  <v-divider />
                  <v-btn
                    v-if="formData.CustomerId"
                    color="secondary"
                    @click="deleteClick"
                  >
                    <span>Delete</span>
                    <v-icon right>{{ icon.delete }}</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </div>

          <div class="ml-4 mt-2">
            <v-tabs vertical background-color="#BBDEFB">
              <v-tab key="1">Address</v-tab>
              <v-tab key="2" :class="statusInfoTabErrors">Status Info</v-tab>
              <v-tab key="3" :class="emailTabErrors">Email</v-tab>
              <v-tab key="4">Phone Numbers</v-tab>
              <v-tab key="5">Extra Info</v-tab>
              <v-tab key="6">Rates</v-tab>
              <v-tab key="7" v-if="isEditMode">Documents</v-tab>

              <v-tab-item key="1" class="blue lighten-4">
                <AddressEditor
                  :name="formData.Address.Name"
                  @updateName="updateAddressName"
                  :street="formData.Address.Street"
                  @updateStreet="updateAddressStreet"
                  :city="formData.Address.City"
                  @updateCity="updateAddressCity"
                  :state="formData.Address.State"
                  @updateState="updateAddressState"
                  :postCode="formData.Address.PostCode"
                  @updatePostCode="updateAddressPostCode"
                  :latitude="formData.Address.Latitude"
                  @updateLatitude="updateAddressLatitude"
                  :longitude="formData.Address.Longitude"
                  @updateLongitude="updateAddressLongitude"
                />
              </v-tab-item>

              <v-tab-item key="2" class="blue lighten-4">
                <v-card width="400px">
                  <v-card-title>
                    <div class="text-subtitle-1 blue--text">
                      Status Info
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-select
                      v-model="formData.Status"
                      :items="selectLists.CustomerStatus"
                      item-text="Text"
                      item-value="Value"
                      label="Status"
                      dense
                    />
                    <DatePicker
                      v-if="isActive"
                      v-model="formData.DateStarted"
                      label="Date Started"
                      dense
                    />
                    <DatePicker
                      v-else
                      v-model="formData.DateTerminated"
                      label="Date Terminated"
                      dense
                    />
                    <v-select
                      v-if="isActive"
                      v-model="formData.ReferralTypeId"
                      :items="selectLists.ReferralTypes"
                      item-text="Text"
                      item-value="Value"
                      label="Referral Type"
                      clearable
                      dense
                    />
                    <v-select
                      v-else
                      v-model="formData.TerminationTypeId"
                      :items="selectLists.TerminationTypes"
                      item-text="Text"
                      item-value="Value"
                      label="Termination Reason"
                      clearable
                      dense
                    />
                    <v-text-field
                      v-if="isActive"
                      v-model="formData.ReferralInfo"
                      label="Referral Info"
                      dense
                    />
                    <v-text-field
                      v-else
                      v-model="formData.TerminationInfo"
                      label="Termination Info"
                      dense
                    />
                    <v-select
                      v-model="formData.BillingGroupId"
                      :items="selectLists.BillingGroups"
                      item-text="Text"
                      item-value="Value"
                      label="Billing Group"
                      :error-messages="
                        errorMessages(vuelidate.formData.BillingGroupId)
                      "
                      @blur="vuelidate.formData.BillingGroupId.$touch()"
                      dense
                    />
                    <v-select
                      v-model="formData.SalesTaxId"
                      :items="selectLists.SalesTaxes"
                      item-text="Text"
                      item-value="Value"
                      label="Sales Tax"
                      dense
                    />
                    <v-textarea v-model="formData.Comments" label="Comments" />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="3" class="blue lighten-4">
                <v-card width="500px" min-height="300px">
                  <v-card-title>
                    <div class="text-subtitle-1 blue--text">
                      Email
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-model="formData.Email"
                      type="email"
                      label="Login Email"
                      :error-messages="errorMessages(vuelidate.formData.Email)"
                      @blur="vuelidate.formData.Email.$touch()"
                      dense
                    />
                    <div class="caption">Notification Emails</div>
                    <v-data-table
                      dense
                      :headers="emailHeaderList"
                      :items="formData.EmailList"
                      class="elevation-1"
                    >
                      <template v-slot:item.IsSentStatements="{ item }">
                        <v-icon v-if="item.IsSentStatements">
                          {{ icon.lineItemIsChecked }}
                        </v-icon>
                      </template>
                      <template
                        v-slot:item.IsSentServiceNotifications="{ item }"
                      >
                        <v-icon v-if="item.IsSentServiceNotifications">
                          {{ icon.lineItemIsChecked }}
                        </v-icon>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="editEmailClick(item)"
                        >
                          {{ icon.lineItemEdit }}
                        </v-icon>
                        <v-icon small @click="deleteEmailClick(item)">
                          {{ icon.lineItemDelete }}
                        </v-icon>
                      </template>
                      <template v-slot:footer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              absolute
                              class="mt-3 ml-2"
                              @click="addEmailClick"
                            >
                              <v-icon>{{ icon.lineItemAdd }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Add Email</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                    <v-checkbox
                      v-model="formData.PrintNoStatement"
                      label="Do not include in batch printed Statements"
                      dense
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      v-if="
                        formData.CustomerId !== null &&
                          formData.Email &&
                          !formData.HasPassword &&
                          errorMessages(vuelidate.formData.Email).length === 0
                      "
                      color="success"
                      class="ml-2"
                      @click="sendInvitationEmail"
                    >
                      Send Invitation Email
                      <v-icon class="ml-2">{{ icon.email }}</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="
                        formData.CustomerId !== null &&
                          formData.Email &&
                          formData.HasPassword &&
                          errorMessages(vuelidate.formData.Email).length === 0
                      "
                      color="success"
                      class="ml-2"
                      @click="resetPasswordClick"
                    >
                      Reset Password
                      <v-icon class="ml-2">{{ icon.email }}</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>

              <v-tab-item key="4" class="blue lighten-4">
                <v-card width="600px">
                  <v-card-title>
                    <div class="text-subtitle-1 blue--text">
                      Phone Numbers
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      dense
                      :headers="phoneHeaderList"
                      :items="formData.PhoneList"
                      class="elevation-1"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="editPhoneNumberClick(item)"
                        >
                          {{ icon.lineItemEdit }}
                        </v-icon>
                        <v-icon small @click="deletePhoneNumberClick(item)">
                          {{ icon.lineItemDelete }}
                        </v-icon>
                      </template>
                      <template v-slot:footer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              absolute
                              class="mt-3 ml-2"
                              @click="addPhoneNumberClick"
                            >
                              <v-icon>{{ icon.lineItemAdd }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Add Phone Number</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="5" class="blue lighten-4">
                <v-card width="400px">
                  <v-card-title>
                    <div class="text-subtitle-1 blue--text">
                      Extra Info
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-for="extraInfo in formData.ExtraInfoList"
                      v-bind:key="extraInfo.InfoTypeId"
                      v-model="extraInfo.Value"
                      :label="extraInfo.Title"
                      dense
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="6" class="blue lighten-4">
                <v-card width="600px" min-height="300px">
                  <v-card-title>
                    <div class="text-subtitle-1 blue--text">
                      Rates
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-checkbox
                      v-model="showSiteRates"
                      label="Show Site Rates"
                      dense
                    />
                    <v-data-table
                      dense
                      :headers="rateHeaderList"
                      :items="formData.RateList"
                      class="elevation-1"
                    >
                      <template v-slot:item.RateId="{ item }">
                        <span>{{ extraRateData(item.RateId, 'Text') }}</span>
                      </template>
                      <template v-slot:item.description="{ item }">
                        <span>
                          {{ extraRateData(item.RateId, 'Description') }}
                        </span>
                      </template>
                      <template v-slot:item.SiteId="{ item }">
                        <span>
                          {{ siteDescription(item.SiteId) }}
                        </span>
                      </template>
                      <template v-slot:item.amount="{ item }">
                        <span>
                          {{ toMoney(extraRateData(item.RateId, 'Amount')) }}
                        </span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editRateClick(item)">
                          {{ icon.lineItemEdit }}
                        </v-icon>
                        <v-icon small @click="deleteRateClick(item)">
                          {{ icon.lineItemDelete }}
                        </v-icon>
                      </template>
                      <template v-slot:footer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              absolute
                              class="mt-3 ml-2"
                              @click="addRateClick"
                            >
                              <v-icon>{{ icon.lineItemAdd }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Add Rate</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="7" class="blue lighten-4">
                <v-card>
                  <v-card-title>
                    <div class="text-subtitle-1 blue--text">
                      Documents
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <DocumentList :customerId="formData.CustomerId" />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showPhoneNumberEditorDialog" max-width="400" persistent>
      <PhoneNumberEditor
        :isDialogOpen="showPhoneNumberEditorDialog"
        :phoneNumberInfo="selectedPhoneNumberItem"
        @close="showPhoneNumberEditorDialog = false"
        @update="updatePhoneNumber"
      />
    </v-dialog>

    <v-dialog
      v-model="showCustomerEmailEditorDialog"
      max-width="400"
      persistent
    >
      <CustomerEmailAddressEditor
        :isDialogOpen="showCustomerEmailEditorDialog"
        :emailInfo="selectedEmailItem"
        @close="emailEditorClose"
        @update="emailEditorUpdate"
      />
    </v-dialog>

    <v-dialog
      v-if="formData && selectLists"
      v-model="showRateEditorDialog"
      max-width="400"
      persistent
    >
      <CustomerRateEditor
        :isDialogOpen="showRateEditorDialog"
        :rateInfo="selectedRateItem"
        :siteList="formData.SiteList"
        @close="showRateEditorDialog = false"
        @update="updateRate"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { icon, feature } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import AddressEditor from '@/components/Common/AddressEditor'
import { selectListCache } from '@/services/SelectListCache'
import ApplicationLogButton from '@/components/Common/ActivityLogButton'
import PhoneNumberEditor from '@/components/Common/PhoneNumberEditor'
import CustomerEmailAddressEditor from '@/components/Customer/CustomerEmailAddressEditor'
import CustomerRateEditor from '@/components/Customer/CustomerRateEditor'
import DocumentList from '@/components/Common/DocumentList'
import { toMoney } from '@/use/NumberFormatter'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: 'CustomerEditor',
  components: {
    AddressEditor,
    ApplicationLogButton,
    PhoneNumberEditor,
    CustomerEmailAddressEditor,
    CustomerRateEditor,
    DocumentList
  },
  props: {
    customerId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const isEditMode = ref(false)
    const formData = ref()
    const selectLists = ref()

    const showPhoneNumberEditorDialog = ref(false)
    const selectedPhoneNumberIndex = ref(-1)
    const selectedPhoneNumberItem = ref()

    const showCustomerEmailEditorDialog = ref(false)
    const selectedEmailIndex = ref(-1)
    const selectedEmailItem = ref()

    const showRateEditorDialog = ref(false)
    const selectedRateIndex = ref(-1)
    const selectedRateItem = ref()
    const showSiteRates = ref(false)

    const cancelClick = () => {
      context.emit('close')
    }

    const initDialog = () => {
      isEditMode.value = props.customerId !== null
      loadSelectLists()
      if (props.customerId !== null) {
        loadCustomer(props.customerId)
      } else {
        initNewCustomer()
      }
    }

    const phoneHeaderList = [
      { value: 'PhoneNumberType', text: 'Type' },
      { value: 'AreaCode', text: 'Area Code', width: 40 },
      { value: 'PhoneNumber', text: 'Number' },
      { value: 'Extension', text: 'Ext', width: 40 },
      { value: 'Description', text: 'Description' },
      { value: 'SortOrder', text: 'Priority', align: 'right' },
      { value: 'actions', text: 'Actions', sortable: false }
    ]

    const emailHeaderList = [
      { value: 'Email', text: 'Email' },
      { value: 'IsSentStatements', text: 'Statements', align: 'center' },
      {
        value: 'IsSentServiceNotifications',
        text: 'Service Notifications',
        align: 'center'
      },
      { value: 'actions', text: 'Actions', sortable: false }
    ]

    const rateHeaderList = computed(() => [
      { value: 'RateId', text: 'Code' },
      {
        value: showSiteRates.value ? 'SiteId' : 'description',
        text: showSiteRates.value ? 'Site' : 'Description'
      },
      { value: 'amount', text: 'Amount', align: 'right' },
      { value: 'SortOrder', text: 'Order', align: 'right' },
      { value: 'actions', text: 'Actions', sortable: false }
    ])

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.CustomerStatus,
        selectListCache.name.ReferralTypes,
        selectListCache.name.TerminationTypes,
        selectListCache.name.BillingGroups,
        selectListCache.name.SalesTaxes,
        selectListCache.name.CustomerTypes,
        selectListCache.name.CustomerAlertTypes,
        selectListCache.name.Rates
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const statusDescription = computed(() => {
      return selectListCache.attribute(
        selectListCache.name.CustomerStatus,
        formData.value.Status,
        'Text'
      )
    })

    const statusClass = computed(() => {
      return formData.value
        ? feature.customers.statusColors[formData.value.Status]
        : ''
    })

    const isActive = computed(() => {
      return formData.value
        ? formData.value.Status !== 'I' && formData.value.Status !== 'P'
        : false
    })

    const extraRateData = (id, name) => {
      return selectListCache.attribute(selectListCache.name.Rates, id, name)
    }

    const deleteClick = () => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete this customer. Are you sure?'
        })
        .then(() => deleteCustomer())
        .catch(() => {
          return
        })
    }

    const deleteCustomer = async () => {
      const rq = { InfoId: formData.value.CustomerId }
      const rs = await host.customer.delete(rq)
      if (rs.IsSuccess) {
        context.emit('delete')
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const addPhoneNumberClick = () => {
      selectedPhoneNumberIndex.value = -1
      selectedPhoneNumberItem.value = {
        Id: null,
        PhoneNumberType: 'Primary',
        SortOrder: 0
      }
      showPhoneNumberEditorDialog.value = true
    }

    const editPhoneNumberClick = item => {
      selectedPhoneNumberIndex.value = formData.value.PhoneList.indexOf(item)
      selectedPhoneNumberItem.value = item
      showPhoneNumberEditorDialog.value = true
    }

    const updatePhoneNumber = newVal => {
      if (selectedPhoneNumberIndex.value > -1) {
        Object.assign(
          formData.value.PhoneList[selectedPhoneNumberIndex.value],
          newVal
        )
      } else {
        formData.value.PhoneList.push(newVal)
      }
      showPhoneNumberEditorDialog.value = false
    }

    const deletePhoneNumberClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete phone number ' + item.PhoneNumber + '. Are you sure?'
        })
        .then(() => deletePhoneNumber(item))
        .catch(() => {
          return
        })
    }

    const deletePhoneNumber = item => {
      const index = formData.value.PhoneList.indexOf(item)
      if (item.Id !== null) {
        formData.value.DeletedPhoneList.push(item.Id)
      }
      formData.value.PhoneList.splice(index, 1)
    }

    const addEmailClick = () => {
      selectedEmailIndex.value = -1
      selectedEmailItem.value = {
        Id: null,
        IsSentStatements: false,
        IsSentServiceNotifications: false
      }
      showCustomerEmailEditorDialog.value = true
    }

    const editEmailClick = item => {
      selectedEmailIndex.value = formData.value.EmailList.indexOf(item)
      selectedEmailItem.value = item
      showCustomerEmailEditorDialog.value = true
    }

    const deleteEmailClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete email ' + item.Email + '. Are you sure?'
        })
        .then(() => deleteEmail(item))
        .catch(() => {
          return
        })
    }

    const deleteEmail = item => {
      const index = formData.value.EmailList.indexOf(item)
      if (item.Id !== null) {
        formData.value.DeletedEmailList.push(item.Id)
      }
      formData.value.EmailList.splice(index, 1)
    }

    const emailEditorClose = () => {
      showCustomerEmailEditorDialog.value = false
    }

    const emailEditorUpdate = newVal => {
      if (selectedEmailIndex.value > -1) {
        Object.assign(
          formData.value.EmailList[selectedEmailIndex.value],
          newVal
        )
      } else {
        formData.value.EmailList.push(newVal)
      }
      showCustomerEmailEditorDialog.value = false
    }

    const addRateClick = () => {
      selectedRateIndex.value = -1
      selectedRateItem.value = {
        Id: null,
        SortOrder: 0
      }
      showRateEditorDialog.value = true
    }

    const editRateClick = item => {
      selectedRateIndex.value = formData.value.RateList.indexOf(item)
      selectedRateItem.value = item
      showRateEditorDialog.value = true
    }

    const updateRate = newVal => {
      if (selectedRateIndex.value > -1) {
        Object.assign(formData.value.RateList[selectedRateIndex.value], newVal)
      } else {
        formData.value.RateList.push(newVal)
      }
      showRateEditorDialog.value = false
    }

    const deleteRateClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text:
            'Remove Rate ' +
            selectListCache.attribute(
              selectListCache.name.Rates,
              item.RateId,
              'Text'
            ) +
            ' from this customer. Are you sure?'
        })
        .then(() => deleteRate(item))
        .catch(() => {
          return
        })
    }

    const deleteRate = item => {
      const index = formData.value.RateList.indexOf(item)
      if (item.Id !== null) {
        formData.value.DeletedRateList.push(item.Id)
      }
      formData.value.RateList.splice(index, 1)
    }

    const loadCustomer = async customerId => {
      const rq = { InfoId: customerId }
      const rs = await host.customer.retrieve(rq)
      formData.value = rs.Info
    }

    const initNewCustomer = async () => {
      const rq = {}
      const rs = await host.customer.newTemplate(rq)
      formData.value = rs.Info
    }

    const sendInvitationEmail = async () => {
      const rq = {
        CustomerId: formData.value.CustomerId
      }
      const rs = await host.customer.resetPassword(rq)
      if (rs.IsSuccess) {
        dialogUtil.value.inform({
          text: rs.Message,
          snackbarTimeout: 3000,
          color: 'success'
        })
      } else {
        dialogUtil.value.error({
          text: rs.Message,
          snackbarTimeout: 3000
        })
      }
    }

    const resetPasswordClick = () => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Reset password.`
        })
        .then(() => performResetPassword())
        .catch(() => {
          return
        })
    }

    const performResetPassword = async () => {
      const rq = {
        CustomerId: formData.value.CustomerId
      }
      const rs = await host.customer.resetPassword(rq)
      if (rs.IsSuccess) {
        dialogUtil.value.inform({
          text: rs.Message,
          snackbarTimeout: 3000,
          color: 'success'
        })
      } else {
        dialogUtil.value.error({
          text: rs.Message,
          snackbarTimeout: 3000
        })
      }
    }

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        dialogUtil.value.error('Please fix validation errors')
        return
      }

      if (isEditMode.value) {
        const rq = { Info: formData.value }
        const response = await host.customer.update(rq)
        if (response.IsSuccess) {
          context.emit('update')
        } else {
          dialogUtil.value.error(response.Message)
        }
      } else {
        const rq = { Info: formData.value }
        const response = await host.customer.create(rq)
        if (response.IsSuccess) {
          context.emit('update', response.InfoId)
        } else {
          dialogUtil.value.error(response.Message)
        }
      }
    }

    const updateAddressName = event => {
      formData.value.Address.Name = event
    }

    const updateAddressStreet = event => {
      formData.value.Address.Street = event
    }

    const updateAddressCity = event => {
      formData.value.Address.City = event
    }

    const updateAddressState = event => {
      formData.value.Address.State = event
    }

    const updateAddressPostCode = event => {
      formData.value.Address.PostCode = event
    }

    const updateAddressLatitude = event => {
      formData.value.Address.Latitude = event
    }

    const updateAddressLongitude = event => {
      formData.value.Address.Longitude = event
    }

    const statusInfoTabErrors = computed(() => {
      return errorMessages(vuelidate.value.formData.BillingGroupId).length > 0
        ? 'red--text'
        : ''
    })

    const emailTabErrors = computed(() => {
      return errorMessages(vuelidate.value.formData.Email).length > 0
        ? 'red--text'
        : ''
    })

    const siteDescription = siteId => {
      if (siteId === null) {
        return ''
      }

      const siteItem = formData.value.SiteList.find(
        item => item.Value == siteId
      )

      return siteItem ? siteItem.Text : ''
    }

    const rules = {
      formData: {
        BillingGroupId: { required },
        CustomerCode: { required },
        CustomerName: { required },
        Email: { email }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    initDialog()

    return {
      dialogUtil,
      isEditMode,
      formData,
      isActive,
      statusClass,
      statusDescription,
      extraRateData,
      selectLists,
      phoneHeaderList,
      showPhoneNumberEditorDialog,
      selectedPhoneNumberIndex,
      selectedPhoneNumberItem,
      addPhoneNumberClick,
      editPhoneNumberClick,
      updatePhoneNumber,
      deletePhoneNumberClick,
      emailHeaderList,
      showCustomerEmailEditorDialog,
      selectedEmailIndex,
      selectedEmailItem,
      addEmailClick,
      editEmailClick,
      deleteEmailClick,
      emailEditorClose,
      emailEditorUpdate,
      showRateEditorDialog,
      showSiteRates,
      selectedRateIndex,
      rateHeaderList,
      selectedRateItem,
      siteDescription,
      addRateClick,
      editRateClick,
      updateRate,
      deleteRateClick,
      sendInvitationEmail,
      resetPasswordClick,
      updateAddressName,
      updateAddressStreet,
      updateAddressCity,
      updateAddressState,
      updateAddressPostCode,
      updateAddressLatitude,
      updateAddressLongitude,
      saveClick,
      cancelClick,
      deleteClick,
      statusInfoTabErrors,
      emailTabErrors,
      toMoney,
      icon,
      feature,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss">
.text-input-alert .v-text-field__slot input,
.text-input-alert .v-select__selection {
  color: red !important;
  font-weight: bold;
}

/* The following are to put the checkboxes in dense mode */
.v-input--selection-controls {
  margin-top: 0px;
}
.v-input--selection-controls .v-input__control .v-messages {
  display: none;
}
</style>
