<template>
  <div>
    <div class="mt-2 ml-2">
      <span class="subtitle-1">Ticket {{ syncSession.Ticket }}</span>
      <span class="ml-5">
        Started {{ toLocaleDateTimeString(syncSession.WhenStarted) }}
      </span>
    </div>
    <v-data-table
      v-if="syncSessionStepList"
      dense
      :items-per-page="-1"
      :footer-props="{
        'show-first-last-page': true
      }"
      :headers="syncSessionStepHeaders"
      :items="syncSessionStepList"
      class="mt-3 elevation-1"
    >
      <template v-slot:item.Request="{ item }">
        <v-btn v-if="item.Request" @click="showRequest(item)" icon>
          <v-icon>{{ icon.document }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.Response="{ item }">
        <v-btn v-if="item.Response" @click="showResponse(item)" icon>
          <v-icon>{{ icon.document }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.Notes="{ item }">
        <span style="white-space: pre;">{{ item.Notes }}</span>
      </template>
      <template v-slot:footer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              absolute
              class="mt-3 mr-2"
              @click="refreshClick"
            >
              <v-icon>{{ icon.refresh }}</v-icon>
            </v-btn>
          </template>
          <span>Refresh list</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="showSyncSessionStepInfo" width="1000">
      <v-card v-if="messageInfo" width="1000">
        <v-card-title>
          <span class="subtitle-1 blue--text">Sync Session Step Info</span>
          <span class="ml-2 caption">
            Step {{ messageInfo.StepNumber }} ({{ messageInfo.TaskType }}),
            Iteration {{ messageInfo.Iteration }}
          </span>
          <span class="ml-2 subtitle-1 blue--text">
            {{ messageInfo.Mode }}
          </span>
        </v-card-title>
        <v-card-text>
          <div class="messageInfo">
            {{ messageInfo.Text }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
import beautify from 'xml-beautifier'
import { format as jsonBeautify } from 'json-string-formatter'
export default {
  name: 'SyncSessionStepList',
  components: {},
  props: {
    syncSession: {
      type: Object,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const syncSessionStepList = ref()
    const showSyncSessionStepInfo = ref(false)
    const messageInfo = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      syncSessionStepList.value = await getSyncSessionStepList(
        props.syncSession.SyncSessionId
      )
    }

    const getSyncSessionStepList = async syncSessionId => {
      const rq = { Criteria: { ParentInfoId: syncSessionId } }
      const rs = await host.syncSession.stepList(rq)
      return rs.InfoList
    }

    const refreshClick = async () => {
      syncSessionStepList.value = await getSyncSessionStepList(
        props.syncSession.SyncSessionId
      )
    }

    const syncSessionStepHeaders = [
      { value: 'StepNumber', text: 'Step' },
      { value: 'Iteration', text: 'Iteration' },
      { value: 'TaskType', text: 'Task Type' },
      { value: 'Request', text: 'Request' },
      { value: 'Response', text: 'Response' },
      { value: 'Notes', text: 'Notes' }
    ]

    const showResponse = step => {
      messageInfo.value = {
        Mode: 'Response',
        TaskType: step.TaskType,
        StepNumber: step.StepNumber,
        Iteration: step.Iteration,
        Text: formatMessage(step.Response)
      }

      showSyncSessionStepInfo.value = true
    }

    const formatMessage = message => {
      if (message.startsWith('<')) {
        return beautify(message)
      } else if (message.startsWith('{')) {
        return jsonBeautify(message)
      }
      return message
    }

    const showRequest = step => {
      messageInfo.value = {
        Mode: 'Request',
        TaskType: step.TaskType,
        StepNumber: step.StepNumber,
        Iteration: step.Iteration,
        Text: beautify(step.Request)
      }

      showSyncSessionStepInfo.value = true
    }

    initDialog()

    return {
      icon,
      toLocaleDateTimeString,
      syncSessionStepHeaders,
      syncSessionStepList,
      showRequest,
      showResponse,
      showSyncSessionStepInfo,
      refreshClick,
      messageInfo
    }
  }
}
</script>

<style lang="scss" scoped>
.messageInfo {
  font-weight: bolder;
  font-family: monospace;
  white-space: pre-wrap;
}
</style>
