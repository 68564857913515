<template>
  <div>
    <v-card flat width="750" height="370">
      <v-card-text class="pb-0">
        <v-data-table
          dense
          :headers="headers"
          :items-per-page="10"
          :items="dataTable.ItemList"
          :footer-props="{
            disableItemsPerPage: true
          }"
          @click:row="selectRow"
          class="elevation-1"
        >
          <template v-slot:item.WhenAccessed="{ item }">
            <span>{{ new Date(item.WhenAccessed).toLocaleString() }}</span>
          </template>
          <template v-slot:item.RecordType="{ item }">
            <v-icon :color="feature[recordTypeFeature[item.RecordType]].color">
              {{ feature[recordTypeFeature[item.RecordType]].icon }}
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { feature } from '@/use/Constants'

export default {
  name: 'RecentlyAccessedRecords',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      fetchData()
    }

    const headers = ref([
      { value: 'RecordType', text: 'Type', align: 'center' },
      { value: 'Description', text: 'Description' },
      { value: 'WhenAccessed', text: 'When Accessed' }
    ])

    const dataTable = ref([])
    const currentItem = ref('')

    const recordTypeFeature = {
      A: 'customers',
      S: 'services',
      T: 'tasks'
    }

    const fetchData = async () => {
      const rq = {}
      const rs = await host.user.recentlyAccessedItems(rq)
      dataTable.value = rs
    }

    const selectRow = event => {
      context.emit('select', event)
    }

    initDialog()

    return {
      headers,
      dataTable,
      currentItem,
      feature,
      recordTypeFeature,
      selectRow
    }
  }
}
</script>
