<template>
  <div>
    <div class="mb-2 blue--text">{{ title }}</div>
    <v-radio-group
      :value="mode"
      @change="emit1('modeChange', $event)"
      dense
      row
    >
      <v-radio label="Month/Day" value="D" />
      <v-radio label="Week/Day of week" value="W" />
    </v-radio-group>
    <div v-if="mode == 'D'" class="d-flex">
      <MonthPicker
        class="mr-4"
        :value="month"
        @input="emit1('monthChange', $event)"
        dense
      />
      <div style="width: 120px;">
        <v-select
          :value="day"
          @input="emit1('dayChange', $event)"
          :items="daysOfMonthList"
          item-text="text"
          item-value="value"
          dense
        />
      </div>
    </div>
    <div v-else class="d-flex">
      <div style="width: 120px;">
        <v-select
          :value="week"
          @input="emit1('weekChange', $event)"
          :items="weeksOfMonthList"
          item-text="text"
          item-value="value"
          dense
        />
      </div>
      <DayOfWeekPicker
        :value="dayOfWeek"
        @input="emit1('dayOfWeekChange', $event)"
        class="ml-2"
        dense
      />
      <div class="mt-2">&nbsp;of&nbsp;</div>
      <MonthPicker :value="month" @input="emit('monthChange', $event)" dense />
    </div>
  </div>
</template>

<script>
import MonthPicker from '@/components/controls/MonthPicker'
import DayOfWeekPicker from '@/components/controls/DayOfWeekPicker'
import { ordinal } from '@/use/NumberFormatter'
import { getDayOfWeekDesc } from '@/use/DateFormatter'
export default {
  name: 'SeasonDatePicker',
  props: {
    mode: { type: String, default: 'D' },
    month: { type: Number, required: true },
    day: { type: Number, required: true },
    week: { type: Number, required: true },
    dayOfWeek: { type: Number, required: true },
    title: { type: String, default: 'Season Date' }
  },
  components: { MonthPicker, DayOfWeekPicker },
  setup(props, context) {
    const daysOfMonthList = [...Array(31)].map(
      (key, value) => new Object({ value: value + 1, text: ordinal(value + 1) })
    )

    const weeksOfMonthList = [...Array(4)].map(
      (key, value) => new Object({ value: value + 1, text: ordinal(value + 1) })
    )

    const daysOfWeekList = [...Array(7)].map(
      (key, value) =>
        new Object({
          value: (value + 1).toString(),
          text: getDayOfWeekDesc(value)
        })
    )

    const emit1 = (eventName, eventValue) => {
      context.emit(eventName, eventValue)
    }

    return { daysOfMonthList, weeksOfMonthList, daysOfWeekList, emit1 }
  }
}
</script>

<style lang="scss" scoped></style>
