import icon from '@/constants/Icons'

export default {
  P: {
    icon: icon.payment,
    color: 'red',
    description: 'Posted payments'
  },
  C: {
    icon: icon.onlinePayment,
    color: 'green',
    description: 'Online payments'
  }
}
