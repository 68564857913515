<template>
  <div v-if="items.length > 0">
    <v-card width="450">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          Your recent history for this report
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items-per-page="20"
          :headers="headers"
          :items="items"
          class="elevation-1"
        >
          <template v-slot:item.WhenGenerated="{ item }">
            <span>{{ toLocaleDateTimeString(item.WhenGenerated) }}</span>
          </template>
          <template v-slot:item.Parameters="{ item }">
            <v-icon
              class="mr-2"
              :color="feature.reports.colorParameter"
              @click="recentReportParametersClick(item)"
            >
              {{ icon.reportInfo }}
            </v-icon>
          </template>
          <template v-slot:item.InstanceId="{ item }">
            <v-icon
              class="mr-2"
              :color="feature.reports.color"
              @click="showReportClick(item)"
            >
              {{ icon.report }}
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showReportParameterDialog" max-width="600">
      <v-card>
        <v-card-title>
          <div class="text-subtitle-1 blue--text">{{ label }} Parameters</div>
        </v-card-title>
        <v-card-subtitle>
          {{ toLocaleDateTimeString(selectedInstanceWhenGenerated) }}
        </v-card-subtitle>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(parameter, name) in selectedInstanceReportParameters"
                  v-bind:key="name"
                  class="my-0"
                >
                  <td class="font-weight-bold" width="180">{{ name }}</td>
                  <td>{{ parameter }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon, feature } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import FieldService from '@/services/FieldService'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
import { userData } from '@/store/UserSession'
export default {
  name: 'ReportHistory',
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'Report'
    },
    version: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    watch(
      () => props.value,
      async () => {
        loadData(props.value)
      }
    )

    watch(
      () => props.version,
      async () => {
        loadData(props.value)
      }
    )

    const container = ref()
    const items = ref([])
    const showReportParameterDialog = ref(false)
    const selectedInstanceReportParameters = ref()
    const selectedInstanceWhenGenerated = ref()

    const headers = [
      { value: 'WhenGenerated', text: 'Requested' },
      { value: 'Parameters', text: 'Parameters', align: 'center' },
      { value: 'InstanceId', text: 'View', align: 'center' }
    ]

    const loadData = async id => {
      const rq = { InfoId: id }
      const rs = await host.report.getHistory(rq)

      items.value = rs.Info.InstanceList
      container.value = rs.Info.ContainerName
    }

    const recentReportParametersClick = item => {
      selectedInstanceReportParameters.value = item.Parameters
      selectedInstanceWhenGenerated.value = item.WhenGenerated
      showReportParameterDialog.value = true
    }

    const showReportClick = item => {
      const url = `${FieldService.apiURL}/report/view?id=${
        item.InstanceId
      }&officeId=${
        userData.value.OfficeId
      }&Tenant-Id=${FieldService.getTenantId()}`
      window.open(url, 'pcpReport')
    }

    return {
      container,
      headers,
      items,
      recentReportParametersClick,
      showReportParameterDialog,
      selectedInstanceReportParameters,
      selectedInstanceWhenGenerated,
      showReportClick,
      toLocaleDateTimeString,
      feature,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
