import { ref, computed } from 'vue'
import FieldService from '@/services/FieldService'
import { host } from '@/services/HostAPI'
import { officeAlertService } from '@/services/OfficeAlertService'
import { syncWithService } from '@/services/SyncWithService'
import { selectListCache } from '@/services/SelectListCache'

const sessionResponse = ref({
  UserData: {
    UserName: 'Unknown'
  },
  ApiKey: '',
  MustChangePassword: false,
  IsSysAdmin: false
})

const urlOfficeCode = ref()

const login = async (officeCode, rq) => {
  FieldService.setTenantId(officeCode)
  const rs = await host.session.login(rq)
  if (rs.IsSuccess) {
    urlOfficeCode.value = officeCode
    saveToLocalStorage(rs)
    sessionResponse.value = rs
    FieldService.setAuthToken(sessionResponse.value.ApiKey)
    officeAlertService.subscribe()
    syncWithService.reset()
    selectListCache.reset()
  }

  return { IsSuccess: rs.IsSuccess, Message: rs.Message }
}

const isLoggedIn = () => {
  if (sessionResponse.value.UserData.OfficeCode) {
    return true
  }

  retrieveFromLocalStorage()
  return sessionResponse.value.UserData.OfficeCode ? true : false
}

const saveToLocalStorage = info => {
  const userString = JSON.stringify(info)
  localStorage.setItem('user', userString)
  localStorage.setItem('officeCode', urlOfficeCode.value)
}

const retrieveFromLocalStorage = () => {
  const userString = localStorage.getItem('user')
  if (
    userString !== undefined &&
    userString !== null &&
    userString.startsWith('{')
  ) {
    sessionResponse.value = JSON.parse(userString)
    FieldService.setAuthToken(sessionResponse.value.ApiKey)
    urlOfficeCode.value = localStorage.getItem('officeCode')
    FieldService.setTenantId(urlOfficeCode.value) // TODO: Tenant-Id is *not* the same as office code
  }
}

const resetMustChangePassword = () => {
  sessionResponse.value.MustChangePassword = false
  saveToLocalStorage(sessionResponse.value)
}

const userData = computed(() => {
  return sessionResponse.value.UserData
})

const apiKey = computed(() => {
  return sessionResponse.value.ApiKey
})

const mustChangePassword = computed(() => {
  return sessionResponse.value.MustChangePassword
})

const isSysAdmin = computed(() => {
  return sessionResponse.value.IsSysAdmin
})

export {
  userData,
  urlOfficeCode,
  apiKey,
  mustChangePassword,
  login,
  isLoggedIn,
  resetMustChangePassword,
  isSysAdmin
}
