<template>
  <div>
    <v-card :width="width">
      <v-card-title>
        <DialogTitle v-if="!isSummary" :dialogName="$options.name">
          <div>Measures</div>
          <div class="subtitle-1">For Site: {{ siteDescription }}</div>
        </DialogTitle>
        <div v-else class="text-subtitle-1 blue--text">
          Measures
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="headers && info && info.length > 0"
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="info"
          class="elevation-1"
        >
          <template v-slot:item.WhenTaken="{ item }">
            {{ toLocaleDateTimeString(item.WhenTaken) }}
          </template>
          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div
          v-if="!isSummary"
          class="table-footer-prepend d-flex pl-2 align-center"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New Measure</span>
          </v-tooltip>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="!isSummary" @click="closeButtonClick" color="success">
          Close
        </v-btn>
        <v-btn
          v-if="isSummary"
          class="ml-2"
          small
          color="primary"
          @click="newItemClick"
        >
          <span>Add New</span>
          <v-icon right>{{ icon.lineItemAdd }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showMeasureEditorDialog" width="1200" persistent>
      <MeasureEditor
        :measureId="selectedMeasureId"
        :siteId="siteId"
        :siteDescription="siteDescription"
        :scheduledServiceId="scheduledServiceId"
        :isDialogOpen="showMeasureEditorDialog"
        @close="measureEditorCloseEvent"
        @update="measureEditorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
import MeasureEditor from '@/components/Site/MeasureEditor'
import { icon } from '@/use/Constants'
export default {
  name: 'MeasureList',
  props: {
    siteId: {
      type: String,
      required: true
    },
    siteDescription: {
      type: String,
      required: true
    },
    scheduledServiceId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    isSummary: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 6000
    }
  },
  components: {
    MeasureEditor
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const info = ref()
    const headers = ref([])

    const showMeasureEditorDialog = ref(false)
    const selectedMeasureId = ref()

    const initDialog = async () => {
      const measureTypeList = await getMeasureTypeList()
      const headerInfo = [{ value: 'WhenTaken', text: 'Date' }]
      if (!props.isSummary) {
        for (let ih = 0; ih < measureTypeList.length && ih <= 9; ih++) {
          headerInfo.push({
            value: measureTypeList[ih].MeasureTypeId,
            text: measureTypeList[ih].Code,
            align: 'right'
          })
        }
        headerInfo.push({ value: 'Notes', text: 'Notes' })
      }
      headerInfo.push({ value: 'Actions', text: 'Actions' })
      headers.value = headerInfo

      const measureInfoList = await getMeasureList(
        props.siteId,
        props.scheduledServiceId
      )

      info.value = []
      for (let i = 0; i < measureInfoList.length; i++) {
        let item = {
          MeasureId: measureInfoList[i].MeasureId,
          ScheduledServiceId: measureInfoList[i].ScheduledServiceId,
          WhenTaken: measureInfoList[i].WhenTaken,
          Notes: measureInfoList[i].Notes
        }
        for (let j = 0; j < measureInfoList[i].ItemList.length; j++) {
          let value = measureInfoList[i].ItemList[j].Value
          item[measureInfoList[i].ItemList[j].MeasureTypeId] =
            value > 0 ? value : ''
        }
        info.value.push(item)
      }
    }

    const getMeasureTypeList = async () => {
      const rq = {}
      const rs = await host.measureType.list(rq)
      return rs.InfoList
    }

    const getMeasureList = async (siteId, scheduledServiceId) => {
      const rq = {
        Criteria: { SiteId: siteId, ScheduledServiceId: scheduledServiceId }
      }
      const rs = await host.measure.list(rq)
      return rs.InfoList
    }

    const newItemClick = () => {
      selectedMeasureId.value = null
      showMeasureEditorDialog.value = true
    }

    const editItemClick = item => {
      selectedMeasureId.value = item.MeasureId
      showMeasureEditorDialog.value = true
    }

    const deleteItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Measure on ${toLocaleDateTimeString(item.WhenTaken)}`
        })
        .then(() => performDeleteMeasure(item))
        .catch(() => {
          return
        })
    }

    const closeButtonClick = () => {
      context.emit('close')
    }

    const performDeleteMeasure = async item => {
      const rq = { InfoId: item.MeasureId }
      const rs = await host.measure.delete(rq)
      if (rs.IsSuccess) {
        initDialog()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const measureEditorCloseEvent = () => {
      showMeasureEditorDialog.value = false
    }

    const measureEditorUpdateEvent = () => {
      showMeasureEditorDialog.value = false
      initDialog()
    }

    initDialog()

    return {
      dialogUtil,
      icon,
      info,
      headers,
      closeButtonClick,
      toLocaleDateTimeString,
      newItemClick,
      editItemClick,
      deleteItemClick,
      showMeasureEditorDialog,
      selectedMeasureId,
      measureEditorCloseEvent,
      measureEditorUpdateEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
