<template>
  <div>
    <v-card width="400">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div class="d-flex">
            <div class="subtitle-1">
              {{ isEditMode ? 'Edit' : 'New' }} Transaction Code
              {{ formData.Code }}
            </div>
            <v-spacer />
            <ApplicationLogButton
              v-if="isEditMode"
              :description="'Transaction Code: ' + formData.Code"
              tableName="TranCode"
              :recordId="formData.TranCodeId"
            />
          </div>
        </DialogTitle>
      </v-card-title>
      <v-card-text v-if="formData">
        <v-text-field
          v-model="formData.Code"
          label="Code"
          :error-messages="errorMessages(vuelidate.formData.Code)"
          @blur="vuelidate.formData.Code.$touch()"
          dense
        />
        <v-text-field
          v-model="formData.Description"
          label="Description"
          :error-messages="errorMessages(vuelidate.formData.Description)"
          @blur="vuelidate.formData.Description.$touch()"
          dense
        />
        <v-select
          v-if="selectLists"
          v-model="formData.TranCodeGroupId"
          label="TranCode Group"
          :items="selectLists.TranCodeGroups"
          item-text="Text"
          item-value="Value"
          clearable
          dense
        />
        <v-text-field
          v-model="formData.SortOrder"
          label="SortOrder"
          type="number"
          dense
        />
        <v-currency-field
          v-model="formData.DefaultAmount"
          label="Default Amount"
          clearable
          dense
        ></v-currency-field>
        <v-checkbox v-model="formData.IsCredit" label="Credit" />
        <v-checkbox v-model="formData.IsRevenue" label="Revenue" />
        <v-checkbox v-model="formData.IsTaxable" label="Taxable" />
        <v-checkbox
          v-model="formData.IsSummarizedOnStatement"
          label="Summarize on Statement"
        />
        <v-checkbox v-model="formData.IsDeleted" label="Deleted" />
      </v-card-text>
      <v-card-actions
        ><v-btn color="success" class="ml-1" @click="saveClick">{{
          isEditMode ? 'Update' : 'Save'
        }}</v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { selectListCache } from '@/services/SelectListCache'
import ApplicationLogButton from '@/components/Common/ActivityLogButton'
export default {
  name: 'TranCodeEditor',
  components: {
    ApplicationLogButton
  },
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref()

    const isEditMode = ref(false)
    const formData = ref()
    const selectLists = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodeGroups]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      await loadSelectLists()
      if (isEditMode.value) {
        loadTranCode()
      } else {
        initNewTranCode()
      }
      vuelidate.value.$reset()
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }
      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        selectListCache.invalidate(selectListCache.name.TranCodes)
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const rules = {
      formData: {
        Code: { required },
        Description: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const loadTranCode = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.tranCode.retrieve(rq)
      formData.value = rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.tranCode.create(rq)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.tranCode.update(rq)
      return rs
    }

    const initNewTranCode = () => {
      formData.value = {
        Id: null,
        Code: null,
        Description: null,
        TranCodeGroupId: null,
        SortOrder: 0,
        IsCredit: false,
        IsRevenue: false,
        IsTaxable: false,
        IsSummarizedOnStatement: false,
        DefaultAmount: null
      }
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      selectLists,
      formData,
      errorMessages,
      saveClick,
      cancelClick,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
