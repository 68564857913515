<template>
  <div>
    <v-card width="820px">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Optimize Scheduled Services
        </DialogTitle>
        <div class="blue--text text-subtitle-1">
          <div>
            Service Date:
            {{ toLocaleDayOfWeekString(scheduledServiceInfo.ServiceDate) }}
            {{ toLocaleDateString(scheduledServiceInfo.ServiceDate) }}
          </div>
          <div>Service Tech: {{ scheduledServiceInfo.UserName }}</div>
        </div>
      </v-card-title>
      <v-card-text v-if="formData">
        <div class="d-flex">
          <RouteOptimizeEndpointEditor
            title="Start From"
            v-model="formData.StartFrom"
            :officeAddress="officeAddress"
            :serviceItemList="siteList"
            :isDialogOpen="isEndpointDataReady"
          />
          <RouteOptimizeEndpointEditor
            class="ml-3"
            title="End At"
            v-model="formData.EndAt"
            :officeAddress="officeAddress"
            :serviceItemList="siteList"
            :isDialogOpen="isEndpointDataReady"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="optimizeClick">
          <span>Optimize</span>
          <v-icon right>{{ icon.mapOptimize }}</v-icon>
        </v-btn>
        <v-btn color="error" @click="cancelClick">
          <span>Back</span>
          <v-icon right>{{ icon.exit }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { host } from '@/services/HostAPI'
import {
  toLocaleDateString,
  toLocaleDayOfWeekString
} from '@/use/DateFormatter'
import { icon } from '@/use/Constants'
import RouteOptimizeEndpointEditor from '@/components/ScheduledService/RouteOptimizeEndpointEditor'
export default {
  name: 'ScheduledServiceOptimizer',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    scheduledServiceInfo: {
      type: Object,
      required: true
    }
  },
  components: { RouteOptimizeEndpointEditor },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const officeAddress = ref()
    const formData = ref()
    const isEndpointDataReady = ref(false)

    const initDialog = async () => {
      const rq = {}
      const rs = await host.office.retrieve(rq)
      officeAddress.value = rs.Info.Address

      formData.value = {
        ServiceDate: props.scheduledServiceInfo.ServiceDate,
        UserId: props.scheduledServiceInfo.UserId,
        StartFrom: { ...props.scheduledServiceInfo.OptimizeStartFrom },
        EndAt: { ...props.scheduledServiceInfo.OptimizeEndAt }
      }

      isEndpointDataReady.value = true
    }

    const siteList = computed(() =>
      props.scheduledServiceInfo.ServiceItemList.map(value => {
        return {
          SiteId: value.SiteId,
          SiteDescription: value.SiteName,
          Address: value.Address
        }
      })
    )

    const optimizeClick = async () => {
      var rs = await host.scheduledService.optimize(formData.value)
      if (rs.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const cancelClick = () => {
      context.emit('close')
    }

    initDialog()
    return {
      dialogUtil,
      formData,
      siteList,
      officeAddress,
      isEndpointDataReady,
      toLocaleDateString,
      toLocaleDayOfWeekString,
      optimizeClick,
      cancelClick,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
