import { host } from '@/services/HostAPI'

const statusTimeout = 1000 * 60 * 10
var whenSyncWithListRetrieved = Date.now()
var syncWithList = {}

const needToGetSyncWithList = () => {
  const result = Date.now() - whenSyncWithListRetrieved > statusTimeout
  return result
}

const getSyncWithList = async () => {
  const rq = {}
  const rs = await host.syncWith.list(rq)
  syncWithList = rs.InfoList
  whenSyncWithListRetrieved = Date.now()
}

const hasSyncWiths = async () => {
  if (needToGetSyncWithList()) {
    await getSyncWithList()
  }

  const result = syncWithList.length > 0 ? true : false
  return result
}

const reset = () => {
  whenSyncWithListRetrieved = Date.now() - statusTimeout
}

const syncWithService = {
  hasSyncWiths: hasSyncWiths,
  reset: reset
}

reset()

export { syncWithService }
