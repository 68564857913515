<template>
  <div>
    <video :src="videoUrl" width="1200" controls autoplay ref="videoPlayer" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { getVideoUrl } from '@/store/TutorialCache'
export default {
  name: 'TutorialPlayer',
  props: {
    name: {
      type: String,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props) {
    const videoPlayer = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        if (newVal) {
          startVideo()
        } else {
          stopVideo()
        }
      }
    )

    const videoUrl = computed(() => {
      return getVideoUrl(props.name)
    })

    const stopVideo = () => {
      videoPlayer.value.pause()
    }

    const startVideo = () => {
      videoPlayer.value.currentTime = 0
      videoPlayer.value.play()
    }

    const initDialog = () => {}

    initDialog()
    return { videoUrl, videoPlayer }
  }
}
</script>

<style lang="scss" scoped></style>
