<template>
  <div>
    <PageTitle
      featureName="calendar"
      functionName="Home"
      :pageName="$options.name"
    />
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn
            v-if="anyItemsSelected"
            color="primary"
            @click="detailsButtonClick"
          >
            Details
          </v-btn>
        </v-col>
        <v-col>
          <v-row justify="center">
            <v-btn
              color="primary"
              fab
              x-small
              class="ma-2"
              @click="$refs.calendar.prev()"
            >
              <v-icon>{{ icon.timePeriodPrevious }}</v-icon>
            </v-btn>
            <span v-if="$refs.calendar" class="title mt-2 mx-2">
              {{ $refs.calendar.title }}
            </span>
            <v-btn
              color="primary"
              fab
              x-small
              class="ma-2"
              @click="$refs.calendar.next()"
            >
              <v-icon>{{ icon.timePeriodNext }}</v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col>
          <v-row justify="end">
            <v-radio-group v-model="searchCriteria.Mode" dense row class="mt-2">
              <v-radio label="Month" value="month" />
              <v-radio label="Week" value="week" />
              <v-radio label="Day" value="day" />
            </v-radio-group>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-sheet height="600" v-if="selectLists">
      <v-calendar
        ref="calendar"
        v-model="searchCriteria.StartDate"
        :type="searchCriteria.Mode"
        :events="calendarEventList"
        color="primary"
        event-overlap-mode="stack"
        event-overlap-threshold="30"
        @change="calendarChange"
        @click:date="calendarDateClick"
      >
        <template v-slot:event="{ event }">
          <div @click="selectClick(event)">
            <v-icon v-if="isSelected(event)" class="ml-1">
              {{ icon.lineItemIsChecked }}
            </v-icon>
            <span class="ml-1">{{ event.name }}</span>
          </div>
        </template>
      </v-calendar>
    </v-sheet>

    <v-dialog v-model="showCalendarEditorDialog" width="1365" persistent>
      <CalendarEditor
        :list="selectedEventList"
        :isDialogOpen="showCalendarEditorDialog"
        @close="calendarEditorCloseEvent"
        @update="calendarEditorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import CalendarEditor from '@/components/Calendar/CalendarEditor'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'
import { selectListCache } from '@/services/SelectListCache'
export default {
  name: 'Calendar',
  components: { CalendarEditor },
  setup() {
    const dialogUtil = ref(null)

    const eventList = ref([])
    const showCalendarEditorDialog = ref(false)
    const selectLists = ref()
    const selectedEventList = ref([])

    const initForm = async () => {
      await loadSelectLists()
      retrieveEventList()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.Users]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const searchCriteria = ref({
      StartDate: null,
      Mode: 'month',
      UserId: null,
      EventTypes: ['OfficeServicesForMonth']
    })

    const isSelected = event => {
      return selectedEventList.value.some(item => compareKeys(item, event.key))
    }

    const compareKeys = (key1, key2) => {
      return key1.Date == key2.Date && key1.UserId == key2.UserId
    }

    const calendarEventList = computed(() => {
      return eventList.value.map(item => ({
        start: Date.parse(item.EventDate),
        name: `${item.AssignedToName || '(Unassigned)'} (${item.Quantity}) ${
          item.QuantityClosed > 0 ? 'Closed: ' + item.QuantityClosed : ''
        }`,
        color: selectListCache.attribute(
          selectListCache.name.Users,
          item.AssignedTo,
          'Color'
        ),
        key: {
          Date: item.EventDate,
          UserId: item.AssignedTo
        }
      }))
    })

    const retrieveEventList = async () => {
      const rq = { Criteria: searchCriteria.value }
      const rs = await host.calendar.list(rq)
      eventList.value = rs.InfoList
    }

    const calendarChange = event => {
      searchCriteria.value.StartDate = event.start.date
      retrieveEventList()
    }

    const selectClick = event => {
      const selectedEventIndex = selectedEventList.value.findIndex(item =>
        compareKeys(item, event.key)
      )
      if (selectedEventIndex >= 0) {
        selectedEventList.value.splice(selectedEventIndex, 1)
      } else {
        if (selectedEventList.value.length < 5) {
          selectedEventList.value.push(event.key)
        } else {
          dialogUtil.value.error('Only up to five items may be selected.')
        }
      }
    }

    const anyItemsSelected = computed(() => {
      return selectedEventList.value.length > 0
    })

    const detailsButtonClick = () => {
      showCalendarEditorDialog.value = true
    }

    const calendarEditorCloseEvent = () => {
      showCalendarEditorDialog.value = false
    }

    const calendarEditorUpdateEvent = () => {
      showCalendarEditorDialog.value = false
      retrieveEventList()
    }

    const calendarDateClick = args => {
      searchCriteria.value.Mode = 'day'
      searchCriteria.value.StartDate = args.date
      retrieveEventList()
    }

    initForm()

    return {
      dialogUtil,
      searchCriteria,
      calendarChange,
      eventList,
      selectedEventList,
      calendarEventList,
      isSelected,
      selectClick,
      anyItemsSelected,
      detailsButtonClick,
      showCalendarEditorDialog,
      calendarEditorCloseEvent,
      calendarEditorUpdateEvent,
      calendarDateClick,
      icon,
      selectLists
    }
  }
}
</script>

<style lang="scss" scoped></style>
