<template>
  <v-dialog v-model="showLoginDialog" persistent max-width="400">
    <Login
      :isDialogOpen="showLoginDialog"
      :defaultOfficeCode="officeCode"
      @loggedIn="loggedInEvent"
    />
  </v-dialog>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import Login from '@/components/User/Login'
export default {
  name: 'LoginPage',
  props: {
    officeCode: {
      type: String,
      default: null
    }
  },
  components: { Login },
  setup() {
    const router = useRouter()
    const showLoginDialog = ref(true)

    const initForm = () => {}

    const loggedInEvent = () => {
      showLoginDialog.value = false
      router.push({ name: 'dashboard' })
    }

    initForm()
    return {
      showLoginDialog,
      loggedInEvent
    }
  }
}
</script>
