<template>
  <div>
    <PageTitle
      featureName="services"
      functionName="List"
      :pageName="$options.name"
    />
    <v-card class="ma-2 indigo lighten-5" max-width="1110">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          Search Criteria
        </div>
      </v-card-title>
      <v-card-text v-if="selectLists">
        <div class="d-flex align-content-space-around flex-wrap">
          <v-card width="350px" class="mt-2">
            <v-card-text>
              <ServiceTypePicker
                v-model="searchCriteria.ServiceDefId"
                clearable
              />
              <DatePicker
                v-model="searchCriteria.ServiceDateFirst"
                label="First Service Date"
                clearable
                dense
              />
              <DatePicker
                v-model="searchCriteria.ServiceDateLast"
                label="Last Service Date"
                clearable
                dense
              />
            </v-card-text>
          </v-card>
          <v-card width="350px" class="mt-2 ml-3">
            <v-card-text>
              <v-select
                v-model="searchCriteria.Status"
                :items="selectLists.ServiceStatus"
                item-text="Text"
                item-value="Value"
                label="Status"
                clearable
                dense
              />
              <UserPicker
                v-model="searchCriteria.AssignedToUserId"
                label="Assigned To"
                clearable
              />
              <v-checkbox
                v-model="searchCriteria.HasPhotos"
                label="Has Photo"
                dense
              />
            </v-card-text>
          </v-card>
          <v-card width="350px" class="mt-2 ml-3">
            <v-card-text>
              <SitePicker
                v-model="searchCriteria.SiteId"
                v-bind:text.sync="syncSiteDescription"
                label="Site"
                clearable
                dense
              />
              <ServiceRoutePicker
                v-model="searchCriteria.ServiceRouteId"
                clearable
              />
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mx-2 mt-3">
      <v-card-title>
        <span class="text-subtitle-1 blue--text">
          Search Results
        </span>
        <span class="ml-4 mb-1">
          <SendEmailButton
            :showWaitDialog="showSendEmailWaitDialog"
            emailEventType="O"
            hint="Send email to all services in search results"
            subtitle="Scheduled Service Notification"
            @update="sendEmailUpdateEvent"
        /></span>
      </v-card-title>
      <v-data-table
        :items-per-page="50"
        :headers="headers"
        :footer-props="{
          'items-per-page-options': items_per_page,
          'show-first-last-page': true
        }"
        :items="infoList"
        :loading="loading"
        class="elevation-1"
        dense
      >
        <template v-slot:header.SendEmail="{}">
          <div class="d-flex">
            <v-icon class="mt-2" :color="feature.sendEmail.color">
              {{ feature.sendEmail.icon }}
            </v-icon>
            <v-checkbox v-model="emailAllRows" />
          </div>
        </template>
        <template v-slot:item.ServiceDate="{ item }">
          <div @click="selectRow(item)">
            {{ toLocaleDateString(item.ServiceDate) }}
          </div>
        </template>
        <template v-slot:item.Status="{ item }">
          <div @click="selectRow(item)">
            {{ item.Status }}
          </div>
        </template>
        <template v-slot:item.SiteName="{ item }">
          <div @click="selectRow(item)">
            {{ item.SiteName }}
          </div>
        </template>
        <template v-slot:item.ServiceType="{ item }">
          <div class="d-flex" @click="selectRow(item)">
            <v-icon :color="feature.services.color">
              {{ serviceTypeIcon(item.ServiceTypeId) }}
            </v-icon>
            <div class="ml-1">{{ item.ServiceType }}</div>
          </div>
        </template>
        <template v-slot:item.AssignedTo="{ item }">
          <div class="d-flex" @click="selectRow(item)">
            <v-icon :color="userColor(item.AssignedToUserId)">
              {{ icon.user }}
            </v-icon>
            <div class="ml-1">{{ item.AssignedTo }}</div>
          </div>
        </template>
        <template v-slot:item.RouteCode="{ item }">
          <div class="d-flex" @click="selectRow(item)">
            <v-icon
              v-if="item.RouteCode"
              :color="serviceRouteColor(item.ServiceRouteId)"
            >
              {{ icon.routes }}
            </v-icon>
            <v-icon
              v-if="item.RecurringServiceId"
              :color="feature.recurringServices.color"
            >
              {{ icon.recurringService }}
            </v-icon>
            <span class="ml-1">{{ item.RouteCode }}{{ item.Repeats }}</span>
          </div>
        </template>
        <template v-slot:item.WhenCompleted="{ item }">
          <div class="d-flex" @click="selectRow(item)">
            <v-icon v-if="item.WhenCompleted" color="green">
              {{ icon.completed }}
            </v-icon>
            <span class="ml-2">
              {{ toLocaleDateTimeString(item.WhenCompleted) }}
            </span>
          </div>
        </template>
        <template v-slot:item.SendEmail="{ item }">
          <v-checkbox v-model="item.SendEmail" dense />
        </template>
        <template v-slot:item.HasPhotos="{ item }">
          <v-icon v-if="item.HasPhotos" :color="feature.services.color">
            {{ icon.photo }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      rounded
      small
      class="primary mt-2 ml-2"
      @click="recurringServiceReassignClick"
    >
      <span>Reassign Recurring Services</span>
    </v-btn>

    <v-dialog
      persistent
      v-model="showScheduledServiceEditorDialog"
      :width="feature.services.dialogWidth"
    >
      <ScheduledServicesEditor
        :isDialogOpen="showScheduledServiceEditorDialog"
        :scheduledServiceId="selectedScheduledServiceId"
        @close="scheduledServiceEditorCloseEvent"
        @update="scheduledServiceEditorUpdateEvent"
        @editRecurringService="editRecurringServiceEvent"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="showRecurringServiceEditorDialog"
      :width="feature.recurringServices.dialogWidth"
    >
      <RecurringServiceEditor
        :recurringServiceId="selectedRecurringServiceId"
        :isDialogOpen="showRecurringServiceEditorDialog"
        @update="recurringServiceEditorUpdateEvent"
        @close="recurringServiceEditorCloseEvent"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="showRecurringServiceReassignerDialog"
      :width="feature.recurringServices.reassignDialogWidth"
    >
      <RecurringServiceReassigner
        :isDialogOpen="showRecurringServiceReassignerDialog"
        @update="recurringServiceReassignerUpdateEvent"
        @close="recurringServiceReassignerCloseEvent"
      />
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { feature, icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import {
  toLocaleDateString,
  toLocaleDateTimeString,
  todayISOString
} from '@/use/DateFormatter'
import SendEmailButton from '@/components/Common/SendEmailButton'
import ServiceRoutePicker from '@/components/controls/ServiceRoutePicker'
import SitePicker from '@/components/Site/SitePicker'
import RecurringServiceEditor from '@/components/ScheduledService/RecurringServiceEditor'
import RecurringServiceReassigner from '@/components/ScheduledService/RecurringServiceReassigner'
import ScheduledServicesEditor from '@/components/ScheduledService/ScheduledServiceEditor'
import { selectListCache } from '@/services/SelectListCache'

export default {
  name: 'SearchServices',
  components: {
    SitePicker,
    RecurringServiceEditor,
    RecurringServiceReassigner,
    ScheduledServicesEditor,
    ServiceRoutePicker,
    SendEmailButton
  },
  props: {
    siteId: {
      type: String,
      default: null
    },
    siteDescription: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const dialogUtil = ref()

    const selectLists = ref()

    const searchCriteria = ref({
      ServiceDefId: null,
      ServiceDateFirst: null,
      ServiceDateLast: null,
      AssignedToUserId: null,
      Status: 'O',
      ServiceRouteId: null,
      SiteId: null,
      HasPhotos: false
    })

    watch(
      () => searchCriteria.value,
      () => loadInfoList(),
      { deep: true }
    )

    const showScheduledServiceEditorDialog = ref(false)
    const selectedScheduledServiceId = ref()
    const showSendEmailWaitDialog = ref(false)
    const showRecurringServiceReassignerDialog = ref(false)

    const scheduledServiceEditorCloseEvent = () => {
      showScheduledServiceEditorDialog.value = false
    }

    const scheduledServiceEditorUpdateEvent = () => {
      showScheduledServiceEditorDialog.value = false
      loadInfoList()
    }

    const editRecurringServiceEvent = id => {
      selectedRecurringServiceId.value = id
      showRecurringServiceEditorDialog.value = true
    }

    const showRecurringServiceEditorDialog = ref(false)
    const selectedRecurringServiceId = ref()

    const recurringServiceEditorCloseEvent = () => {
      showRecurringServiceEditorDialog.value = false
    }

    const recurringServiceEditorUpdateEvent = () => {
      showRecurringServiceEditorDialog.value = false
      loadInfoList()
    }

    const selectRow = event => {
      selectedScheduledServiceId.value = event.ItemKey
      showScheduledServiceEditorDialog.value = true
    }

    const today = todayISOString()

    const initForm = () => {
      searchCriteria.value.ServiceDateFirst = today
      searchCriteria.value.ServiceDateLast = props.siteId ? null : today
      searchCriteria.value.SiteId = props.siteId
      loadSelectLists()
      syncSiteDescription.value = props.siteDescription
      loadInfoList()
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.Users,
        selectListCache.name.ServiceStatus,
        selectListCache.name.ServiceRoutes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const items_per_page = [5, 10, 15, 20, 50]

    const headers = [
      { value: 'ServiceDate', text: 'Date' },
      { value: 'Status', text: 'Status' },
      { value: 'SiteName', text: 'Site' },
      { value: 'ServiceType', text: 'Service Type' },
      { value: 'AssignedTo', text: 'Assigned To' },
      { value: 'RouteCode', text: 'Route / Recur' },
      { value: 'WhenCompleted', text: 'Completed' },
      { value: 'SendEmail', text: 'Email', sortable: false },
      { value: 'HasPhotos', text: '' }
    ]

    const infoList = ref([])
    const emailAllRows = ref(true)
    const loading = ref(false)
    const syncSiteDescription = ref(null)

    const loadInfoList = async () => {
      loading.value = true
      const rq = {
        Criteria: searchCriteria.value
      }
      const rs = await host.scheduledService.list(rq)
      infoList.value = rs.InfoList.map(row => {
        return { ...row, SendEmail: true }
      })
      loading.value = false
    }

    const sendEmailUpdateEvent = async args => {
      showSendEmailWaitDialog.value = true
      const rq = {
        Info: {
          ScheduledServiceIdList: infoList.value
            .filter(row => row.SendEmail)
            .map(row => row.ItemKey),
          Bcc: args.bcc,
          Subject: args.subject,
          Body: args.body
        }
      }

      console.log({ loc: 'SearchServices/sendEmailUpdateEvent', rq: rq })
      const rs = await host.email.sendScheduledServiceNotifications(rq)
      showSendEmailWaitDialog.value = false
      dialogUtil.value.inform(rs.Message)
    }

    const recurringServiceReassignClick = () => {
      showRecurringServiceReassignerDialog.value = true
    }

    const recurringServiceReassignerCloseEvent = () => {
      showRecurringServiceReassignerDialog.value = false
    }

    const recurringServiceReassignerUpdateEvent = () => {
      showRecurringServiceReassignerDialog.value = false
      loadInfoList()
    }

    const serviceTypeIcon = value => {
      return selectListCache.attribute(
        selectListCache.name.ServiceTypes,
        value,
        'IconName'
      )
    }

    const userColor = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Color'
      )
    }

    const serviceRouteColor = value => {
      return selectListCache.attribute(
        selectListCache.name.ServiceRoutes,
        value,
        'Color'
      )
    }

    watch(
      () => emailAllRows.value,
      newVal => {
        infoList.value.map(row => {
          row.SendEmail = newVal
          return row
        })
      }
    )

    initForm()

    return {
      dialogUtil,
      feature,
      icon,
      searchCriteria,
      syncSiteDescription,
      selectLists,
      items_per_page,
      headers,
      infoList,
      loading,
      selectRow,
      emailAllRows,
      showScheduledServiceEditorDialog,
      selectedScheduledServiceId,
      scheduledServiceEditorCloseEvent,
      scheduledServiceEditorUpdateEvent,
      editRecurringServiceEvent,
      showRecurringServiceEditorDialog,
      selectedRecurringServiceId,
      recurringServiceEditorCloseEvent,
      recurringServiceEditorUpdateEvent,
      toLocaleDateString,
      toLocaleDateTimeString,
      serviceTypeIcon,
      userColor,
      serviceRouteColor,
      showSendEmailWaitDialog,
      sendEmailUpdateEvent,
      recurringServiceReassignClick,
      showRecurringServiceReassignerDialog,
      recurringServiceReassignerCloseEvent,
      recurringServiceReassignerUpdateEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
