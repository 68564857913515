<template>
  <div>
    <v-card :width="width">
      <v-card-title>
        <DialogTitle v-if="!isSummary" :dialogName="$options.name">
          <div>Treatments</div>
          <div class="subtitle-1">For Site: {{ siteDescription }}</div>
        </DialogTitle>
        <div v-else class="text-subtitle-1 blue--text">
          Treatments
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="headers && info && info.length > 0"
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="info"
          class="elevation-1"
        >
          <template v-slot:item.WhenAdded="{ item }">
            {{ toLocaleDateTimeString(item.WhenAdded) }}
          </template>
          <template v-slot:item.IsBillable="{ item }">
            <v-icon v-if="item.IsBillable" color="primary">
              {{ icon.lineItemIsSelected }}
            </v-icon>
          </template>
          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div
          v-if="!isSummary"
          class="table-footer-prepend d-flex pl-2 align-center"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>Add New</span>
          </v-tooltip>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="!isSummary" @click="closeButtonClick" color="success"
          >Close</v-btn
        >
        <v-btn
          v-if="isSummary"
          class="ml-2"
          small
          color="primary"
          @click="newItemClick"
          ><span>Add New</span><v-icon right>{{ icon.addNew }}</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showTreatmentEditorDialog" width="1200" persistent>
      <TreatmentEditor
        :treatmentId="selectedTreatmentId"
        :siteId="siteId"
        :siteDescription="siteDescription"
        :scheduledServiceId="scheduledServiceId"
        :isDialogOpen="showTreatmentEditorDialog"
        @close="treatmentEditorCloseEvent"
        @update="treatmentEditorUpdateEvent"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
import TreatmentEditor from '@/components/Site/TreatmentEditor'
export default {
  name: 'TreatmentList',
  props: {
    siteId: {
      type: String,
      required: true
    },
    siteDescription: {
      type: String,
      required: true
    },
    scheduledServiceId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    isSummary: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 6000
    }
  },
  components: {
    TreatmentEditor
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const info = ref()
    const headers = [
      { value: 'WhenAdded', text: 'Date' },
      { value: 'Details', text: 'Details' },
      { value: 'Notes', text: 'Notes' },
      { value: 'Actions', text: 'Actions' }
    ]

    const showTreatmentEditorDialog = ref(false)
    const selectedTreatmentId = ref()

    const initDialog = async () => {
      // const treatmentTypeList = await getTreatmentTypeList()
      const treatmentInfoList = await getTreatmentList(
        props.siteId,
        props.scheduledServiceId
      )
      info.value = treatmentInfoList.map(item => {
        return {
          TreatmentId: item.TreatmentId,
          WhenAdded: item.WhenAdded,
          Details: getDetails(item.ItemList),
          Notes: item.Notes
        }
      })
    }

    const getDetails = itemList => {
      return itemList
        .filter(item => item.TreatmentDetailId != null)
        .map(
          item =>
            `${item.Description}/${item.UnitDescription}: ${item.UnitsAdded}${
              item.IsBillable ? ' (bill)' : ''
            }`
        )
        .join(', ')
    }

    const getTreatmentList = async (siteId, scheduledServiceId) => {
      const rq = {
        Criteria: { SiteId: siteId, ScheduledServiceId: scheduledServiceId }
      }
      const rs = await host.treatment.list(rq)
      return rs.InfoList
    }

    const newItemClick = () => {
      selectedTreatmentId.value = null
      showTreatmentEditorDialog.value = true
    }

    const editItemClick = item => {
      selectedTreatmentId.value = item.TreatmentId
      showTreatmentEditorDialog.value = true
    }

    const deleteItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Treatment on ${toLocaleDateTimeString(item.WhenAdded)}`
        })
        .then(() => performDeleteTreatment(item))
        .catch(() => {
          return
        })
    }

    const closeButtonClick = () => {
      context.emit('close')
    }

    const performDeleteTreatment = async item => {
      const rq = { InfoId: item.TreatmentId }
      const rs = await host.treatment.delete(rq)
      if (rs.IsSuccess) {
        initDialog()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const treatmentEditorCloseEvent = () => {
      showTreatmentEditorDialog.value = false
    }

    const treatmentEditorUpdateEvent = () => {
      showTreatmentEditorDialog.value = false
      initDialog()
    }

    initDialog()

    return {
      dialogUtil,
      info,
      headers,
      closeButtonClick,
      toLocaleDateTimeString,
      newItemClick,
      editItemClick,
      deleteItemClick,
      showTreatmentEditorDialog,
      selectedTreatmentId,
      treatmentEditorCloseEvent,
      treatmentEditorUpdateEvent,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
