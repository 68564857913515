<template>
  <div>
    <v-dialog v-model="showTransactionDialog" max-width="400" persistent>
      <Transaction
        :customerId="customerId"
        :transactionId="transactionId"
        :isDialogOpen="showTransactionDialog"
        @close="hideTransactionDialog(false)"
        @update="hideTransactionDialog(true)"
      ></Transaction>
    </v-dialog>

    <v-dialog v-model="showInvoiceDialog" width="1000" persistent>
      <Invoice
        :isDialogOpen="showInvoiceDialog"
        :customerId="customerId"
        :invoiceId="transactionId"
        @close="hideInvoiceDialog(false)"
        @update="hideInvoiceDialog(true)"
      ></Invoice>
    </v-dialog>

    <v-dialog v-model="showOnlinePaymentModifyDialog" width="400">
      <OnlinePaymentModify
        :transactionId="transactionId"
        :isDialogOpen="showOnlinePaymentModifyDialog"
        @close="hideOnlinePaymentModifyDialog(false)"
        @update="hideOnlinePaymentModifyDialog(true)"
      ></OnlinePaymentModify>
    </v-dialog>
  </div>
</template>

<script>
import { watch, computed, onBeforeMount } from 'vue'
import Transaction from '@/components/Billing/Transaction'
import Invoice from '@/components/Billing/Invoice'
import OnlinePaymentModify from '@/components/OnlinePayment/OnlinePaymentModify'
export default {
  name: 'TransactionEditLauncher',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    transactionId: {
      type: String,
      required: true
    },
    transactionType: {
      type: String,
      required: true
    },
    customerId: {
      type: String,
      default: null
    }
  },
  components: {
    Transaction,
    Invoice,
    OnlinePaymentModify
  },
  setup(props, context) {
    const showTransactionDialog = computed(() => {
      return props.showDialog && props.transactionType == 'T'
    })
    const showInvoiceDialog = computed(() => {
      return props.showDialog && props.transactionType == 'I'
    })
    const showOnlinePaymentModifyDialog = computed(() => {
      return props.showDialog && props.transactionType == 'O'
    })

    onBeforeMount(() => {
      initDialog()
    })

    watch(
      () => props.showDialog,
      () => {
        initDialog()
      }
    )

    function initDialog() {}

    const hideTransactionDialog = emitUpdate => {
      if (emitUpdate) {
        context.emit('update', props.transactionId)
      } else {
        context.emit('close')
      }
    }

    const hideInvoiceDialog = emitUpdate => {
      if (emitUpdate) {
        context.emit('update', props.transactionId)
      } else {
        context.emit('close')
      }
    }

    const hideOnlinePaymentModifyDialog = emitUpdate => {
      if (emitUpdate) {
        context.emit('update', props.transactionId)
      } else {
        context.emit('close')
      }
    }

    return {
      showTransactionDialog,
      showInvoiceDialog,
      showOnlinePaymentModifyDialog,
      hideTransactionDialog,
      hideInvoiceDialog,
      hideOnlinePaymentModifyDialog
    }
  }
}
</script>

<style lang="scss" scoped></style>
