<template>
  <div>
    <v-card>
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Payment Authorizations
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :headers="authorizationHeaderList"
          :items="authList"
          class="elevation-1"
        >
          <template v-slot:item.WhenCreated="{ item }">
            <span>{{ toLocaleDateTimeString(item.WhenCreated) }}</span>
          </template>
          <template v-slot:item.Amount="{ item }">
            <span>{{ toMoney(item.Amount) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="applyAuthItemClick(item)"
                >
                  <v-icon :color="feature.onlinePayments.color">
                    {{ icon.onlinePaymentAuthApply }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Convert this authorization into a payment.</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="ml-1 mb-1" @click="closeButtonClick">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon, feature } from '@/use/Constants'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
import { toMoney } from '@/use/NumberFormatter'
import { host } from '@/services/HostAPI'

export default {
  name: 'CustomerAuthList',
  props: {
    customerId: {
      type: String,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      retrieveAuthList()
    }

    const updateNeeded = ref(false)

    const authList = ref([])
    const authorizationHeaderList = [
      { value: 'WhenCreated', text: 'When Created' },
      { value: 'Amount', text: 'Amount', align: 'right' },
      { value: 'PaymentLastFour', text: 'Last Four' },
      { value: 'actions', text: 'Actions', sortable: false, width: 50 }
    ]

    const closeButtonClick = () => {
      if (updateNeeded.value) {
        context.emit('update')
      } else {
        context.emit('close')
      }
    }

    const retrieveAuthList = async () => {
      const rq = { Criteria: props.customerId }
      const rs = await host.onlinePaymentAuth.list(rq)
      authList.value = rs.InfoList
    }

    const applyAuthItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: 'Convert authorization into payment. Are you sure?'
        })
        .then(() => performApplyAuth(item.Id))
        .catch(() => {
          return
        })
    }

    const performApplyAuth = async id => {
      const rs = await completeAuthorization(id)
      if (rs.IsSuccess) {
        updateNeeded.value = true
        await retrieveAuthList()
        if (authList.value.length == 0) {
          closeButtonClick()
        }
      } else {
        dialogUtil.value.inform({
          text: rs.Message,
          snackbarTimeout: 10000,
          color: 'error'
        })
      }
    }

    const completeAuthorization = async id => {
      const rq = { Info: id }
      const rs = await host.onlinePaymentAuth.complete(rq)
      return rs
    }

    initDialog()

    return {
      dialogUtil,
      authList,
      authorizationHeaderList,
      closeButtonClick,
      toLocaleDateTimeString,
      toMoney,
      applyAuthItemClick,
      feature,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
