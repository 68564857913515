<template>
  <div>
    <v-card v-if="formData && selectLists" :class="`${cardColor}`">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          <div class="d-flex">
            <div class="subtitle-1">
              {{ isEditMode ? 'Edit' : 'New' }} site for customer
              {{ formData.CustomerName }}
            </div>
            <v-spacer />
            <ApplicationLogButton
              v-if="isEditMode"
              :description="'Site: ' + formData.Description"
              tableName="Site"
              :recordId="formData.SiteId"
            />
          </div>
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <div :class="`d-flex ${cardColor} full-height`">
          <div>
            <v-card class="mt-2 ml-2" width="400px">
              <v-card-title>
                <div :class="`text-subtitle-1 ${textColor}`">
                  Site
                </div>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="formData.Description"
                  label="Site Name"
                  :error-messages="
                    errorMessages(vuelidate.formData.Description)
                  "
                  @blur="vuelidate.formData.Description.$touch()"
                  dense
                />
                <v-select
                  v-model="formData.UserId"
                  :items="selectLists.Users"
                  item-text="Text"
                  item-value="Value"
                  label="Supervisor"
                  dense
                />
                <CustomerPicker
                  label="Managed By"
                  v-model="formData.ManagedByCustomerId"
                  v-bind:text.sync="formData.ManagedByCustomerName"
                  dense
                  clearable
                />
                <v-checkbox
                  v-model="formData.ChargeForChemicals"
                  label="Charge for Chemicals"
                  dense
                />
                <v-checkbox
                  v-model="formData.Inactive"
                  label="Inactive"
                  dense
                />
              </v-card-text>
            </v-card>

            <v-card class="mt-2 ml-2" width="400px">
              <v-card-text>
                <v-card-actions>
                  <v-btn color="success" @click="saveClick">
                    <span>Save</span>
                    <v-icon right>{{ icon.save }}</v-icon>
                  </v-btn>
                  <v-btn color="error" @click="cancelClick"
                    ><span>Back</span
                    ><v-icon right>{{ icon.exit }}</v-icon></v-btn
                  >
                  <v-spacer />
                  <v-btn
                    v-if="isDeleteEnabled"
                    color="secondary"
                    @click="deleteClick"
                  >
                    <span>Delete</span>
                    <v-icon right>{{ icon.delete }}</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </div>

          <div class="ml-4 mt-2">
            <v-tabs
              v-model="tabPage"
              vertical
              class="my-tabs"
              :color="`${textColor}`"
              :background-color="`${cardColor}`"
            >
              <v-tab key="1">Address</v-tab>
              <v-tab key="2">Extra Info</v-tab>
              <v-tab key="3">Routes</v-tab>
              <v-tab key="4">Rates</v-tab>
              <v-tab key="5" v-if="isEditMode">Photos</v-tab>
              <v-tab key="6" v-if="isEditMode">Documents</v-tab>

              <v-tab-item key="1" :class="`${cardColor}`">
                <AddressEditor
                  :captionColor="textColor"
                  :name="formData.Address.Name"
                  @updateName="updateAddressName"
                  :street="formData.Address.Street"
                  @updateStreet="updateAddressStreet"
                  :city="formData.Address.City"
                  @updateCity="updateAddressCity"
                  :state="formData.Address.State"
                  @updateState="updateAddressState"
                  :postCode="formData.Address.PostCode"
                  @updatePostCode="updateAddressPostCode"
                  :latitude="formData.Address.Latitude"
                  @updateLatitude="updateAddressLatitude"
                  :longitude="formData.Address.Longitude"
                  @updateLongitude="updateAddressLongitude"
                />
                <v-card class="mt-2" width="400px">
                  <v-card-text>
                    <v-card-actions>
                      <v-btn small color="primary" @click="copyAddressClick">
                        <span>Paste Customer Address</span>
                        <v-icon right>{{ icon.paste }}</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="2" :class="`${cardColor}`">
                <v-card width="400px">
                  <v-card-title>
                    <div :class="`text-subtitle-1 ${textColor}`">
                      Extra Info
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-for="extraInfo in formData.ExtraInfoList"
                      v-bind:key="extraInfo.InfoTypeId"
                      v-model="extraInfo.Value"
                      :label="extraInfo.Title"
                      dense
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="3" :class="`${cardColor}`">
                <v-card width="400px" min-height="300px">
                  <v-card-title>
                    <div :class="`text-subtitle-1 ${textColor}`">
                      Routes
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      dense
                      :headers="routeHeaderList"
                      :items="formData.ServiceRouteSiteList"
                      class="elevation-1"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="editRouteClick(item)"
                        >
                          {{ icon.lineItemEdit }}
                        </v-icon>
                        <v-icon small @click="deleteRouteClick(item)">
                          {{ icon.lineItemDelete }}
                        </v-icon>
                      </template>
                      <template v-slot:footer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              absolute
                              class="mt-3 ml-2"
                              @click="addRouteClick"
                            >
                              <v-icon>{{ icon.lineItemAdd }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Add Route</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="4" :class="{ cardColor }">
                <v-card width="500px" min-height="300px">
                  <v-card-title>
                    <div :class="`text-subtitle-1 ${textColor}`">
                      Rates
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      dense
                      :headers="rateHeaderList"
                      :items="formData.RateList"
                      class="elevation-1"
                    >
                      <template v-slot:item.RateId="{ item }">
                        <span>{{ extraRateData(item.RateId, 'Text') }}</span>
                      </template>
                      <template v-slot:item.description="{ item }">
                        <span>
                          {{ extraRateData(item.RateId, 'Description') }}
                        </span>
                      </template>
                      <template v-slot:item.SiteId="{ item }">
                        <span>
                          {{ siteDescription(item.SiteId) }}
                        </span>
                      </template>
                      <template v-slot:item.amount="{ item }">
                        <span>
                          {{ toMoney(extraRateData(item.RateId, 'Amount')) }}
                        </span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editRateClick(item)">
                          {{ icon.lineItemEdit }}
                        </v-icon>
                        <v-icon small @click="deleteRateClick(item)">
                          {{ icon.lineItemDelete }}
                        </v-icon>
                      </template>
                      <template v-slot:footer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              absolute
                              class="mt-3 ml-2"
                              @click="addRateClick"
                            >
                              <v-icon>{{ icon.lineItemAdd }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Add Rate</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="5" :class="`${cardColor}`">
                <v-card>
                  <v-card-title>
                    <div :class="`text-subtitle-1 ${textColor}`">
                      Photos
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <PhotoList :siteId="formData.SiteId" />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item key="6" :class="{ cardColor }">
                <v-card>
                  <v-card-title>
                    <div :class="`text-subtitle-1 ${textColor}`">
                      Documents
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <DocumentList
                      :customerId="formData.CustomerId"
                      :siteId="formData.SiteId"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      v-if="formData && selectLists"
      v-model="showRouteEditorDialog"
      max-width="400"
      persistent
    >
      <SiteRouteEditor
        :isDialogOpen="showRouteEditorDialog"
        :routeInfo="selectedRouteItem"
        :siteId="formData.SiteId"
        :siteName="formData.Description"
        @close="showRouteEditorDialog = false"
        @update="updateRouteEvent"
      />
    </v-dialog>

    <v-dialog
      v-if="formData && selectLists"
      v-model="showRateEditorDialog"
      max-width="400"
      persistent
    >
      <CustomerRateEditor
        :isDialogOpen="showRateEditorDialog"
        :rateInfo="selectedRateItem"
        :siteList="formData.SiteList"
        @close="showRateEditorDialog = false"
        @update="updateRate"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { selectListCache } from '@/services/SelectListCache'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { toMoney } from '@/use/NumberFormatter'
import AddressEditor from '@/components/Common/AddressEditor'
import ApplicationLogButton from '@/components/Common/ActivityLogButton'
import CustomerPicker from '@/components/Customer/CustomerPicker'
import CustomerRateEditor from '@/components/Customer/CustomerRateEditor'
import DocumentList from '@/components/Common/DocumentList'
import PhotoList from '@/components/Common/PhotoList'
import SiteRouteEditor from '@/components/Site/SiteRouteEditor'

export default {
  name: 'SiteEditor',
  components: {
    AddressEditor,
    CustomerPicker,
    CustomerRateEditor,
    PhotoList,
    SiteRouteEditor,
    DocumentList,
    ApplicationLogButton
  },
  props: {
    customerId: {
      type: String,
      default: null
    },
    siteId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    isDeleteEnabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const tabPage = ref(0)
    const formData = ref()
    const selectLists = ref()
    const showRouteEditorDialog = ref(false)
    const selectedRouteIndex = ref(-1)
    const selectedRouteItem = ref()
    const cardColor = 'light-green lighten-4'
    const textColor = 'green--text text--darken-3'

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      loadSelectLists()
      isEditMode.value = props.siteId != null
      formData.value = isEditMode.value ? await loadSite() : await initNewSite()
      tabPage.value = 0
      vuelidate.value.$reset()
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.ServiceRoutes,
        selectListCache.name.Users
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const rateHeaderList = [
      { value: 'RateId', text: 'Code' },
      { value: 'Description', text: 'Description' },
      { value: 'amount', text: 'Amount', align: 'right' },
      { value: 'SortOrder', text: 'Order', align: 'right' },
      { value: 'actions', text: 'Actions', sortable: false }
    ]

    const saveClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      const rq = { Info: formData.value }
      const rs = isEditMode.value
        ? await host.site.update(rq)
        : await host.site.create(rq)

      if (rs.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const deleteClick = () => {
      dialogUtil.value
        .confirm({
          title: 'CAREFUL! Please confirm',
          text:
            `Delete Site ` +
            formData.value.Description +
            '? This CANNOT be undone.'
        })
        .then(async () => {
          const result = await performDelete({
            InfoId: formData.value.SiteId
          })
          if (result.IsSuccess) {
            context.emit('update')
          } else {
            dialogUtil.value.error(result.Message)
          }
        })
        .catch(() => {
          return
        })
    }

    const performDelete = async rq => {
      const rs = await host.site.delete(rq)
      return rs
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const loadSite = async () => {
      const rq = { InfoId: props.siteId }
      const rs = await host.site.retrieve(rq)
      return rs.Info
    }

    const initNewSite = async () => {
      const rq = { Defaults: props.customerId }
      const rs = await host.site.newTemplate(rq)
      if (rs.IsSuccess === false) {
        dialogUtil.value
          .confirm({
            title: 'Error',
            color: 'red',
            text: 'Problem initializing new site.'
          })
          .then(() => context.emit('close'))
      }
      return rs.Info
    }

    const routeHeaderList = [
      { value: 'RouteCode', text: 'Code' },
      { value: 'RouteDescription', text: 'Description' },
      { value: 'StopNumber', text: 'Stop', align: 'right' },
      { value: 'actions', text: 'Actions', width: 20, sortable: false }
    ]

    const rules = {
      formData: {
        Description: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const selectedRoute = id => {
      return selectListLookup(id, selectLists.value.ServiceRoutes)
    }

    const selectListLookup = (itemValue, selectList) => {
      const matchedItem = selectList.find(item => item.Value == itemValue)
      return matchedItem
    }

    const deleteRouteClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text:
            'Remove Route ' +
            selectedRoute(item.ServiceRouteId).Text +
            ' from this site. Are you sure?'
        })
        .then(() => deleteRoute(item))
        .catch(() => {
          return
        })
    }

    const deleteRoute = item => {
      const index = formData.value.ServiceRouteSiteList.indexOf(item)
      if (item.ServiceRouteSiteId) {
        formData.value.DeletedServiceRouteSiteIdList.push(
          item.ServiceRouteSiteId
        )
      }
      formData.value.ServiceRouteSiteList.splice(index, 1)
    }

    const addRouteClick = () => {
      selectedRouteIndex.value = -1
      selectedRouteItem.value = {
        ServiceRouteSiteId: null,
        ServiceRouteId: null,
        StopNumber: 0
      }
      showRouteEditorDialog.value = true
    }

    const editRouteClick = item => {
      selectedRouteIndex.value = formData.value.ServiceRouteSiteList.indexOf(
        item
      )
      selectedRouteItem.value = item
      showRouteEditorDialog.value = true
    }

    const updateRouteEvent = newVal => {
      if (selectedRouteIndex.value > -1) {
        Object.assign(
          formData.value.ServiceRouteSiteList[selectedRouteIndex.value],
          newVal
        )
      } else {
        formData.value.ServiceRouteSiteList.push(newVal)
      }
      showRouteEditorDialog.value = false
    }

    const extraRateData = (id, name) => {
      return selectListCache.attribute(selectListCache.name.Rates, id, name)
    }

    const showRateEditorDialog = ref(false)
    const selectedRateIndex = ref(-1)
    const selectedRateItem = ref()

    const addRateClick = () => {
      selectedRateIndex.value = -1
      selectedRateItem.value = {
        Id: null,
        SortOrder: 0,
        SiteId: props.siteId
      }
      showRateEditorDialog.value = true
    }

    const editRateClick = item => {
      selectedRateIndex.value = formData.value.RateList.indexOf(item)
      selectedRateItem.value = item
      showRateEditorDialog.value = true
    }

    const updateRate = newVal => {
      if (selectedRateIndex.value > -1) {
        Object.assign(formData.value.RateList[selectedRateIndex.value], newVal)
      } else {
        formData.value.RateList.push(newVal)
      }
      showRateEditorDialog.value = false
    }

    const deleteRateClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text:
            'Remove Rate ' +
            selectListCache.attribute(
              selectListCache.name.Rates,
              item.RateId,
              'Text'
            ) +
            ' from this customer. Are you sure?'
        })
        .then(() => deleteRate(item))
        .catch(() => {
          return
        })
    }

    const deleteRate = item => {
      const index = formData.value.RateList.indexOf(item)
      if (item.Id !== null) {
        formData.value.DeletedRateList.push(item.Id)
      }
      formData.value.RateList.splice(index, 1)
    }

    const copyAddressClick = () => {
      formData.value.Address.Name = formData.value.CustomerAddress.Name
      formData.value.Address.Street = formData.value.CustomerAddress.Street
      formData.value.Address.City = formData.value.CustomerAddress.City
      formData.value.Address.State = formData.value.CustomerAddress.State
      formData.value.Address.PostCode = formData.value.CustomerAddress.PostCode
      formData.value.Address.StandardizationCode =
        formData.value.CustomerAddress.StandardizationCode
      formData.value.Address.Latitude = formData.value.CustomerAddress.Latitude
      formData.value.Address.Longitude =
        formData.value.CustomerAddress.Longitude
    }

    const updateAddressName = event => {
      formData.value.Address.Name = event
    }

    const updateAddressStreet = event => {
      formData.value.Address.Street = event
    }

    const updateAddressCity = event => {
      formData.value.Address.City = event
    }

    const updateAddressState = event => {
      formData.value.Address.State = event
    }

    const updateAddressPostCode = event => {
      formData.value.Address.PostCode = event
    }

    const updateAddressLatitude = event => {
      formData.value.Address.Latitude = event
    }

    const updateAddressLongitude = event => {
      formData.value.Address.Longitude = event
    }

    initDialog()

    return {
      dialogUtil,
      isEditMode,
      tabPage,
      formData,
      selectLists,
      saveClick,
      cancelClick,
      deleteClick,
      routeHeaderList,
      showRouteEditorDialog,
      selectedRouteIndex,
      selectedRouteItem,
      deleteRouteClick,
      selectedRoute,
      addRouteClick,
      editRouteClick,
      updateRouteEvent,
      rateHeaderList,
      showRateEditorDialog,
      selectedRateItem,
      extraRateData,
      addRateClick,
      editRateClick,
      updateRate,
      deleteRateClick,
      copyAddressClick,
      updateAddressName,
      updateAddressStreet,
      updateAddressCity,
      updateAddressState,
      updateAddressPostCode,
      updateAddressLatitude,
      updateAddressLongitude,
      toMoney,
      cardColor,
      textColor,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss"></style>
