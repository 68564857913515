<template>
  <div>
    <v-card width="6000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          User Group List
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :items-per-page="10"
          :footer-props="{
            'items-per-page-options': itemsPerPage,
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="userGroupList"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:item.Actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newItemClick">
                <v-icon>{{ icon.lineItemAdd }}</v-icon>
              </v-btn>
            </template>
            <span>New User Group</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showUserGroupEditorDialog" width="650" persistent>
      <UserGroupEditor
        :userGroupId="selectedUserGroupId"
        :isDialogOpen="showUserGroupEditorDialog"
        @close="userGroupEditorCloseEvent"
        @update="userGroupEditorUpdateEvent"
      ></UserGroupEditor>
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import UserGroupEditor from '@/components/Setup/User/UserGroupEditor'

export default {
  name: 'UserGroupList',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { UserGroupEditor },
  setup(props) {
    const dialogUtil = ref(null)
    const loading = ref(false)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      userGroupList.value = []
      getUserGroupList()
    }

    const userGroupList = ref([])
    const selectedUserGroupId = ref(null)
    const showUserGroupEditorDialog = ref(false)

    const headers = [
      { value: 'UserGroupName', text: 'User Name' },
      { value: 'Actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const itemsPerPage = [5, 10, 15, 20, 50]

    function editItemClick(item) {
      selectedUserGroupId.value = item.UserGroupId
      showUserGroupEditorDialog.value = true
    }

    function deleteItemClick(item) {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete User Group ${item.UserGroupName}`
        })
        .then(() => performDeleteUserGroup(item.UserGroupId))
        .catch(() => {
          return
        })
    }

    function userGroupEditorCloseEvent() {
      showUserGroupEditorDialog.value = false
    }

    function userGroupEditorUpdateEvent() {
      showUserGroupEditorDialog.value = false
      getUserGroupList()
    }

    async function getUserGroupList() {
      loading.value = true
      const rq = {}
      const rs = await host.userGroup.list(rq)
      userGroupList.value = rs.InfoList
      loading.value = false
    }

    async function performDeleteUserGroup(id) {
      const rq = { InfoId: id }
      const rs = await host.userGroup.delete(rq)
      if (rs.IsSuccess) {
        getUserGroupList()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    function newItemClick() {
      selectedUserGroupId.value = null
      showUserGroupEditorDialog.value = true
    }

    initDialog()

    return {
      dialogUtil,
      headers,
      itemsPerPage,
      userGroupList,
      selectedUserGroupId,
      editItemClick,
      deleteItemClick,
      newItemClick,
      showUserGroupEditorDialog,
      userGroupEditorCloseEvent,
      userGroupEditorUpdateEvent,
      icon,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>
