import numeral from 'numeral'

const toMoney = value => {
  if (isNaN(parseFloat(value))) {
    return value
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
  })
  return formatter.format(value)
}

const toCurrency = value => {
  if (isNaN(parseFloat(value))) {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
  return formatter.format(value)
}

const ordinal = number => {
  return numeral(number).format('Oo')
}

export { toMoney, toCurrency, ordinal }
