import SearchCustomers from '@/views/Customer/SearchCustomers'
import CustomerSummary from '@/views/Customer/CustomerSummary'

export default [
  {
    path: '/customer',
    name: 'customer',
    component: SearchCustomers
  },
  {
    path: '/customer/summary/:id/:siteId?',
    name: 'customer-summary',
    component: CustomerSummary,
    props: true
  }
]
