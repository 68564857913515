<template>
  <div>
    <v-card width="400">
      <v-card-title>
        {{ isEditMode ? 'Edit Document Info' : 'Upload Info' }}
      </v-card-title>
      <v-card-text v-if="formData">
        <v-text-field
          v-model="formData.FileName"
          label="FileName"
          :error-messages="errorMessages(vuelidate.formData.FileName)"
          @blur="vuelidate.formData.FileName.$touch()"
          dense
        />
        <div v-if="isEditMode" class="ml-6 mb-4">
          <div class="caption">
            {{ toLocaleDateString(formData.WhenTaken) }}
          </div>
          <a
            v-auth-href
            :href="createDocumentUrlFor(formData.URI)"
            :download="formData.FileName"
          >
            <v-icon large class="mt-2 ml-6">{{ icon.document }}</v-icon>
          </a>
        </div>
        <div v-else>
          <v-file-input
            v-model="fileLocation"
            accept="image/pdf"
            show-size
            :prepend-icon="icon.documentUpload"
            label="Document Location"
            :error-messages="errorMessages(vuelidate.fileLocation)"
            @change="fileLocationChange"
            @blur="vuelidate.fileLocation.$touch()"
          />
        </div>
        <v-textarea v-model="formData.Description" label="Description" />
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="ml-1" @click="saveClick">
          {{ isEditMode ? 'Update' : 'Upload' }}
        </v-btn>
        <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="isEditMode" color="secondary" @click="deleteClick">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import FieldService from '@/services/FieldService'
import { host } from '@/services/HostAPI'
import { toLocaleDateString } from '@/use/DateFormatter'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
export default {
  name: 'DocumentEditor',
  props: {
    documentId: {
      type: String,
      default: null
    },
    customerId: {
      type: String,
      default: null
    },
    siteId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    const isEditMode = ref(false)
    const formData = ref()
    const fileLocation = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      isEditMode.value = props.documentId != null
      if (isEditMode.value) {
        loadDocument()
      } else {
        initNewDocument()
      }
      vuelidate.value.$reset()
    }

    const fileLocationChange = event => {
      formData.value.FileName = event.name
    }

    const saveClick = async () => {
      if (validate()) {
        return
      }

      const result = isEditMode.value ? await update() : await saveNew()
      afterSaveOrUpdate(result)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const deleteClick = () => {
      dialogUtil.value
        .confirm({
          title: 'Please Confirm',
          text: 'Delete this document. Are you sure?'
        })
        .then(() => performDelete())
        .catch(() => {
          return
        })
    }

    const afterSaveOrUpdate = result => {
      if (result.IsSuccess) {
        context.emit('update')
      } else {
        dialogUtil.value.error(result.Message)
      }
    }

    const rules = {
      fileLocation: {
        required: requiredIf(() => {
          return !isEditMode.value
        })
      },
      formData: {
        FileName: { required },
        Description: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { fileLocation, formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const validate = () => {
      vuelidate.value.$touch()
      return vuelidate.value.$invalid
    }

    const loadDocument = async () => {
      const rq = { InfoId: props.documentId }
      const rs = await host.document.retrieve(rq)
      formData.value = rs.Info
    }

    const saveNew = async () => {
      const rq = { Info: formData.value }
      const rs = await host.document.create(rq, fileLocation.value)
      return rs
    }

    const update = async () => {
      const rq = { Info: formData.value }
      const rs = await host.document.update(rq)
      return rs
    }

    const performDelete = async () => {
      const result = await deleteDocument(props.documentId)
      afterSaveOrUpdate(result)
      context.emit('update')
    }

    const deleteDocument = async id => {
      const rq = { InfoId: id }
      const rs = await host.document.delete(rq)
      return rs
    }

    const initNewDocument = () => {
      fileLocation.value = null
      formData.value = {
        DocumentId: null,
        CustomerId: props.customerId,
        SiteId: props.siteId,
        FileName: 'Uploaded Document',
        Description: null
      }
    }

    const createDocumentUrlFor = uri => {
      return `${
        FieldService.apiURL
      }${uri}&Tenant-Id=${FieldService.getTenantId()}`
    }

    initDialog()
    return {
      dialogUtil,
      isEditMode,
      formData,
      fileLocation,
      fileLocationChange,
      createDocumentUrlFor,
      saveClick,
      cancelClick,
      deleteClick,
      toLocaleDateString,
      icon,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
