<template>
  <div>
    <div class="d-flex">
      <div v-if="showVideoAdminEnabled">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              small
              rounded
              color="success"
              class="mr-2"
              @click="uploadVideoClick"
            >
              <v-icon>{{ icon.tutorialUpload }}</v-icon>
            </v-btn>
          </template>
          Upload Tutorial Video
        </v-tooltip>
      </div>
      <div v-if="showVideoEnabled">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              small
              rounded
              color="primary"
              class="mr-3"
              @click="showVideoClick"
            >
              <v-icon>{{ icon.tutorial }}</v-icon>
            </v-btn>
          </template>
          Play Tutorial Video
        </v-tooltip>
      </div>
    </div>

    <v-dialog v-model="showTutorialUploader" width="400">
      <TutorialUploader
        :isDialogOpen="showTutorialUploader"
        :tutorialName="tutorialName"
        @close="tutorialUploaderCloseEvent"
        @update="tutorialUploaderUpdateEvent"
      />
    </v-dialog>

    <v-dialog v-model="showTutorialPlayer" width="1200">
      <TutorialPlayer :isDialogOpen="showTutorialPlayer" :name="tutorialName" />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { icon } from '@/use/Constants'
import { isSysAdmin } from '@/store/UserSession'
import { isTutorialAvailable } from '@/store/TutorialCache'
import TutorialUploader from '@/components/controls/TutorialUploader'
import TutorialPlayer from '@/components/controls/TutorialPlayer'
export default {
  name: 'TutorialButton',
  props: {
    tutorialName: {
      type: String,
      default: ''
    }
  },
  components: { TutorialUploader, TutorialPlayer },
  setup(props) {
    const showTutorialUploader = ref(false)

    const showVideoEnabled = ref(false)
    const showVideoAdminEnabled = ref(false)

    const showTutorialPlayer = ref(false)

    const showVideoClick = async () => {
      showTutorialPlayer.value = true
    }

    const uploadVideoClick = async () => {
      showTutorialUploader.value = true
    }

    const init = async () => {
      showVideoEnabled.value = await isTutorialAvailable(props.tutorialName)
      showVideoAdminEnabled.value = props.tutorialName != '' && isSysAdmin.value
    }

    const tutorialUploaderCloseEvent = () => {
      showTutorialUploader.value = false
    }

    const tutorialUploaderUpdateEvent = async () => {
      showTutorialUploader.value = false
      showVideoEnabled.value = await isTutorialAvailable(props.tutorialName)
    }

    init()

    return {
      showVideoClick,
      uploadVideoClick,
      showVideoEnabled,
      showVideoAdminEnabled,
      showTutorialUploader,
      tutorialUploaderCloseEvent,
      tutorialUploaderUpdateEvent,
      showTutorialPlayer,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
