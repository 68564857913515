<template>
  <div>
    <v-dialog v-model="showReportCriteriaPicker" max-width="450">
      <ReportCriteriaPicker
        :reportInfo="defaultReportCriteria"
        @close="reportCriteriaPickerClose"
        @update="reportCriteriaPickerUpdate"
      ></ReportCriteriaPicker>
    </v-dialog>

    <WaitDialog v-model="showWaitDialog" :subtitle="reportTitle" />
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue'
import ReportService from '@/services/ReportService'
import { host } from '@/services/HostAPI'
import FieldService from '@/services/FieldService'
import ReportCriteriaPicker from '@/components/Report/ReportCriteriaPicker'
import WaitDialog from '@/components/Common/WaitDialog'
import { userData } from '@/store/UserSession'

export default {
  name: 'ReportHandler',
  components: {
    ReportCriteriaPicker,
    WaitDialog
  },
  setup() {
    onBeforeMount(() => {
      ReportService.onShowReportDialog(onShowReportDialog)
      ReportService.onLaunchReport(onLaunchReport)
    })

    const dialogUtil = ref()
    const showReportCriteriaPicker = ref(false)
    const showWaitDialog = ref(false)

    const timerInterval = 3000
    const timeout = 120000
    const elapsedTimeOnQueue = ref()

    const defaultReportCriteria = ref(null)
    const reportTitle = ref('')

    const onShowReportDialog = event => {
      defaultReportCriteria.value = event
      showReportCriteriaPicker.value = true
    }

    const onLaunchReport = async event => {
      await queueReport(event)
    }

    const openReportWaitDialog = title => {
      reportTitle.value = title
      showWaitDialog.value = true
    }

    const closeReportWaitDialog = () => {
      reportTitle.value = ''
      showWaitDialog.value = false
    }

    const reportCriteriaPickerClose = () => {
      showReportCriteriaPicker.value = false
    }

    const reportCriteriaPickerUpdate = async reportInfo => {
      showReportCriteriaPicker.value = false
      await queueReport(reportInfo)
    }

    const queueReport = async reportInfo => {
      const rs = await host.report.queue(reportInfo)
      if (rs.IsSuccess === true) {
        if (reportInfo.EmailInfo.SendEmail) {
          dialogUtil.value.inform({
            text: 'Preparing to email: ' + reportInfo.Title,
            snackbarTimeout: 3000,
            color: 'primary'
          })
        } else {
          openReportWaitDialog(reportInfo.Title)
        }

        setTimeout(() => {
          pollReport(
            reportInfo.Title,
            rs.InstanceId,
            reportInfo.EmailInfo.SendEmail
          )
        }, timerInterval)
      } else {
        dialogUtil.value.error('Error queuing report: ' + rs.Message)
      }
    }

    const pollReport = async (reportTitle, reportInstanceId, isEmail) => {
      const rq = { InstanceId: reportInstanceId }
      const rs = await host.report.poll(rq)
      if (rs.IsSuccess === true && rs.IsReady === true) {
        closeReportWaitDialog()
        if (isEmail) {
          dialogUtil.value.inform({
            text: 'Email submitted for : ' + reportTitle,
            snackbarTimeout: 3000,
            color: 'success'
          })
        } else {
          const url = `${
            FieldService.apiURL
          }/report/view?id=${reportInstanceId}&officeId=${
            userData.value.OfficeId
          }&Tenant-Id=${FieldService.getTenantId()}`
          window.open(url, 'pcpReport')
        }
      } else {
        elapsedTimeOnQueue.value += timerInterval
        if (elapsedTimeOnQueue.value > timeout) {
          dialogUtil.value.error('Timeout report: ' + reportTitle)
          closeReportWaitDialog()
        } else {
          setTimeout(() => {
            pollReport(reportTitle, reportInstanceId, isEmail)
          }, timerInterval)
        }
      }
    }

    return {
      dialogUtil,
      defaultReportCriteria,
      showReportCriteriaPicker,
      showWaitDialog,
      reportTitle,
      reportCriteriaPickerClose,
      reportCriteriaPickerUpdate,
      openReportWaitDialog,
      closeReportWaitDialog
    }
  }
}
</script>

<style lang="scss" scoped></style>
