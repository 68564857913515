<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon small v-on="on" @click="mapShowClick">
          <v-icon :color="feature.maps.color">{{ feature.maps.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ feature.maps.toolTip }}</span>
    </v-tooltip>

    <v-dialog v-model="showMapDisplay" :width="feature.maps.dialogWidth">
      <MapDisplay
        :isDialogOpen="showMapDisplay"
        :street="street"
        :city="city"
        :state="state"
        :postCode="postCode"
      />
    </v-dialog>
  </div>
</template>

<script>
import { feature } from '@/use/Constants'
import MapDisplay from '@/components/Common/MapDisplay'
import { ref } from 'vue'

export default {
  name: 'MapIcon',
  props: {
    name: {
      type: String,
      default: null
    },
    street: {
      type: String,
      default: null
    },
    city: {
      type: String,
      default: null
    },
    state: {
      type: String,
      default: null
    },
    postCode: {
      type: String,
      default: null
    }
  },
  components: { MapDisplay },
  setup() {
    const showMapDisplay = ref(false)

    const mapShowClick = () => {
      showMapDisplay.value = true
    }

    return { feature, mapShowClick, showMapDisplay }
  }
}
</script>

<style lang="scss" scoped></style>
