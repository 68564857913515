<template>
  <v-card
    v-if="weather"
    width="320px"
    height="330px"
    class="pt-2 pl-3 ma-2"
    @click="showWeatherPage"
  >
    <div class="overline grey--text text-uppercase">Weather</div>
    <div class="weather-title">
      <span class="body-2 font-weight-bold">{{ weather.City }}</span>
    </div>
    <div class="weather-row my-4">
      <div class="weather-today-icon mr-2">
        <img width="64px" height="64px" :src="todayWeatherIcon" />
      </div>
      <div class="weather-today-details">
        <div class="body-1 font-weight-bold mb-1">
          {{ weather.Current.DegreesF }}&#176;
        </div>
        <div class="caption">{{ weather.Current.Condition }}</div>
        <div class="caption">{{ weather.Current.Wind }}</div>
      </div>
      <v-spacer></v-spacer>
      <div class="weather-link mr-2">
        <img
          width="64px"
          height="64px"
          src="/weather/logos/NationalWeatherService.png"
        />
      </div>
    </div>
    <div class="weather-row mt-5">
      <div v-for="(day, index) in weather.Forecast" :key="index">
        <div class="weather-day-details">
          <div class="weather-day-title caption">{{ day.DayOfWeek }}</div>
          <div>
            <img
              class="weather-day-icon"
              width="32px"
              height="32px"
              :src="dayWeatherIconList[index].Icon"
            />
          </div>
          <div class="weather-day-degrees">
            <span class="caption font-weight-bold">{{ day.High }}&#176;</span
            ><span class="caption"> {{ day.Low }}&#176;</span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { ref, computed } from 'vue'
import { host } from '@/services/HostAPI'
export default {
  name: 'Weather',
  setup() {
    const weather = ref()

    const init = () => {
      getWeather()
    }

    const getWeather = async () => {
      const rq = {}
      const rs = await host.weather.retrieve(rq)
      weather.value = rs.Info
    }

    const weatherConditionMap = {
      Clear: '32.png',
      Sunny: '32.png',
      Mostlysunny: '34.png',
      Partlycloudy: '30.png',
      Mostlycloudy: '26.png',
      ChanceOfStorm: '39.png',
      Rain: '40.png',
      ChanceOfRain: '39.png',
      ChanceOfSnow: '41.png',
      Mist: '9.png',
      Storm: '5.png',
      Thunderstorm: '17.png',
      Sleet: '11.png',
      Snow: '43.png',
      Icy: '8.png',
      Dust: '19.png',
      Fog: '20.png',
      Smoke: '22.png',
      Hazy: '21.png',
      Flurries: '15.png',
      Unspecified: 'na.png'
    }

    const todayWeatherIcon = computed(() => {
      return (
        weather.value &&
        weather.value.Current &&
        'weather/64x64/' + weatherConditionMap[weather.value.Current.Condition]
      )
    })

    const dayWeatherIconList = computed(() => {
      let result =
        weather.value &&
        weather.value.Forecast.map(day => {
          day.Icon = 'weather/32x32/' + weatherConditionMap[day.Condition]
          return day
        })

      return result
    })

    const showWeatherPage = () => {
      window.open(weather.value.WeatherSourceUrl, 'popUp')
    }

    init()
    return {
      weather,
      todayWeatherIcon,
      dayWeatherIconList,
      showWeatherPage
    }
  }
}
</script>
<style lang="scss" scoped>
.weather-row {
  display: flex;
}

.weather-today-icon {
  width: 70px;
}

.weather-today-details {
  width: 130px;
}

.weather-forecast-container {
  display: flex;
}

.weather-day-details {
  width: 68px;
}

.weather-day-title {
  text-align: center;
}

.weather-day-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.weather-day-degrees {
  text-align: center;
}

.weather-link {
  width: 70px;
  margin-left: 6px;
}
</style>
