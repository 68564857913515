import { ordinal } from '@/use/NumberFormatter'
import {
  getDayOfWeekDesc,
  getDaysOfWeek,
  getMonthName
} from '@/use/DateFormatter'

const formatFrequency = (
  recurPeriod,
  recurOption,
  interval1,
  interval2,
  interval3
) => {
  switch (recurPeriod) {
    case 'D':
      return `Every ${
        recurOption ? 'weekday' : interval1 == 1 ? 'day' : interval1 + ' days'
      }`
    case 'W':
      return `Every ${
        interval1 == 1 ? '' : ordinal(interval1) + ' week on '
      }${getDaysOfWeek(interval2)}`
    case 'M':
      return recurOption
        ? `${interval2 >= 0 ? 'The' : 'Every'} ${ordinal(
            interval1
          )} ${getDayOfWeekDesc(interval2)} of every${
            interval3 == 1 ? '' : ' ' + ordinal(interval3)
          } month`
        : interval2 == 1
        ? 'The ' + ordinal(interval1) + ' of each month'
        : 'Every ' + ordinal(interval2) + ' month on the ' + ordinal(interval1)
    case 'Y':
      return recurOption
        ? `The ${
            interval1 == 0 ? 'last' : ordinal(interval1)
          } ${getDayOfWeekDesc(interval2)} of ${getMonthName(interval3)}`
        : `Every ${getMonthName(interval1)} ${ordinal(interval2)}`
    default:
      return ''
  }
}

export { formatFrequency }
