import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { colors } from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        background: colors.grey.lighten4
      },
      dark: {
        background: colors.grey.darken5
      }
    },
    dark: false
  }
})
