<template>
  <v-card width="400px">
    <v-card-title>{{ operation }} Customer Rate</v-card-title>
    <v-card-text v-if="formData && selectLists">
      <v-select
        v-model="formData.RateId"
        label="Code"
        :items="selectLists.Rates"
        item-text="Text"
        item-value="Value"
        :error-messages="errorMessages(vuelidate.formData.RateId)"
        @blur="vuelidate.formData.RateId.$touch()"
        dense
      />
      <div v-if="formData.RateId">
        <div class="caption">Description</div>
        <div class="body-1">{{ description }}</div>
        <div class="caption">Amount</div>
        <div class="body-1 mb-5">{{ toCurrency(amount) }}</div>
      </div>
      <v-text-field
        v-model="formData.SortOrder"
        type="number"
        label="Order"
        :error-messages="errorMessages(vuelidate.formData.SortOrder)"
        @blur="vuelidate.formData.SortOrder.$touch()"
        dense
      />
      <v-select
        v-if="siteList.length > 0"
        v-model="formData.SiteId"
        label="Site"
        :items="siteList"
        item-text="Text"
        item-value="Value"
        clearable
        dense
      />
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" class="ml-3" @click="saveClick">Save</v-btn>
      <v-btn color="error" class="ml-3" @click="cancelClick">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { selectListCache } from '@/services/SelectListCache'
import { toCurrency } from '@/use/NumberFormatter'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'CustomerRateEditor',
  props: {
    rateInfo: {
      type: Object,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    siteList: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const selectLists = ref()
    const formData = ref()

    const initDialog = async () => {
      loadSelectLists()
      formData.value = {
        ...props.rateInfo
      }

      vuelidate.value.$reset()
    }

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.Rates]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const rules = {
      formData: {
        RateId: { required },
        SortOrder: { required }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const saveClick = () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      context.emit('update', formData.value)
    }

    const cancelClick = () => {
      context.emit('close')
    }

    const operation = computed(() => {
      return props.rateInfo.Id === null ? 'Add' : 'Update'
    })

    const description = computed(() => {
      return selectListCache.attribute(
        selectListCache.name.Rates,
        formData.value.RateId,
        'Description'
      )
    })

    const amount = computed(() => {
      return selectListCache.attribute(
        selectListCache.name.Rates,
        formData.value.RateId,
        'Amount'
      )
    })

    initDialog()

    return {
      formData,
      operation,
      selectLists,
      description,
      amount,
      toCurrency,
      saveClick,
      cancelClick,
      errorMessages,
      vuelidate
    }
  }
}
</script>

<style lang="scss" scoped></style>
