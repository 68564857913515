<template>
  <v-card>
    <v-card-title>My Preferences</v-card-title>
    <v-card-text>
      <v-switch
        v-model="$vuetify.theme.dark"
        label="Dark Theme"
        color="purple"
      ></v-switch>
    </v-card-text>
  </v-card>
</template>

<script>
import { watch } from 'vue'
export default {
  name: 'UserPreferenceEditor',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {}

    initDialog()
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
